import get from 'lodash-es/get'
import {compose} from 'redux'

import {enable} from 'permissions/users/selection'

import {ifSelection, withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withUsers from 'containers/withUsers'

import PowerIcon from 'icons/power.svg'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: enable}),
    withVisibility(),
    withUsers(),
    withProps(({enable}) => ({
        onClick: enable,
        Icon: PowerIcon,
        title: __('Enable users'),
        message: __('Do you really want to enable selected users?'),
        label: __('Enable'),
    })),
    withSelectionHandler(),
    withConfirmation(),
    ifSelection((id, {users}) => !get(users, ['store', 'byIds', id, 'isEnabled'], false)),
)(Button)
import {
    handleActions
} from 'redux-actions'

import {
    update,
    clear,
    setRange,
    fetch,
    fetchMore
} from './actions'

const defaultState = {}

export default handleActions({
    [clear]() {
        return defaultState
    },

    [fetch](state, {payload: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                page: [],
                isLoading: true,
            },
        }
    },

    [fetchMore](state, {payload: {panelId}}) {
        return {
            ...state,
            [panelId]: {
                ...state[panelId],
                hasMore: {isLoading: true},
            },
        }
    },

    [setRange](state, {payload}) {
        const {panelId, from, to} = payload

        return {
            ...state,
            [panelId]: {
                page: [],
                isLoading: true,
                from,
                to,
            },
        }
    },

    [update](state, {payload, meta: {panelId, count}, error}) {
        if (error) {
            return {
                ...state,
                [panelId]: {
                    ...state[panelId],
                    isLoading: false,
                    error: payload,
                },
            }
        }

        const old = state[panelId] || {page: []}

        if (!old.page) {
            old.page = []
        }

        const page = payload
            .map(Number)
            .filter(id => old.page.indexOf(id) == -1)
            .concat(old.page)
            .sort((a, b) => b - a)

        return {
            ...state,
            [panelId]: {
                ...old,
                isLoading: false,
                error: null,
                page,
                count,
                hasMore: count > page.length,
            },
        }
    },
}, defaultState)
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {stateIcon, stateTitle, stateModifierTitle} from 'constants/state'

import Button from 'ui/Button'
import Buttons from 'ui/Buttons'
import DropDownButton from 'ui/DropDownButton'
import {ALIGN_RIGHT, ALIGN_BOTTOM} from 'ui/DropDown'
import Menu, {MenuItem} from 'ui/Menu'

const stopPropagation = e => e.stopPropagation()

export default class StateButton extends Component {

    static propTypes = {
        sendStateAction: PropTypes.func.isRequired,
        name: PropTypes.string.isRequired,
        modifiers: PropTypes.array.isRequired,
        active: PropTypes.bool.isRequired,
        disabled: PropTypes.bool,
    }

    clickHandlers = {}

    constructor(props) {
        super(props)

        this.clickHandlers = props.modifiers
            .reduce((acc, modifier) => ({
                ...acc,
                [modifier]: this.handleSetState.bind(this, modifier),
            }), {})
    }

    handleSetState(modifier = null) {
        const {sendStateAction, name} = this.props
        sendStateAction(name, modifier)
    }

    handleSetDefaultState = () => this.handleSetState()

    render() {
        const {active, name, modifiers, disabled} = this.props

        return (
            <Buttons small flat primary={active} onClick={stopPropagation}>
                <Button
                    Icon={stateIcon(name)}
                    onClick={this.handleSetDefaultState}
                    disabled={disabled}>
                    {stateTitle(name)}
                </Button>

                {!disabled && modifiers.length > 0 &&
                <DropDownButton align={ALIGN_RIGHT | ALIGN_BOTTOM}>
                    <Menu>
                        {modifiers.map(modifier => (
                            <MenuItem
                                className="menu-item--uppercase"
                                key={modifier}
                                onClick={this.clickHandlers[modifier]}>
                                {stateModifierTitle(modifier)}
                            </MenuItem>
                        ))}
                    </Menu>
                </DropDownButton>
                }
            </Buttons>
        )
    }
}
import {combineReducers} from 'redux'

import list from './list/reducer'
import walktest from './walktest/reducer'
import reference from './reference/reducer'
import meteo from './meteo/reducer'
import vod from './vod/reducer'

export default combineReducers({
    list,
    walktest,
    reference,
    meteo,
    vod,
})
import get from 'lodash-es/get'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import {
    addDevice,
} from 'modules/forms/handlers'

import CardForm from 'ui/CardForm'
import Input from 'ui/Input'

import {__, __n} from 'utils/i18n'
import withForm from 'containers/withForm'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchPartitions} from 'modules/panels/state/actions'
import {withDeviceReference} from 'containers/withDevices'
import withLifeCycle from 'containers/withLifeCycle'

import {VENDOR_NEO, VENDOR_POWER_MASTER} from 'constants/panelVendorType'
import {deviceSubtype as deviceSubtypeName} from 'constants/deviceSubtype'

import {fetchUsers} from 'modules/panels/one/actions'
import MultiSelect from 'ui/MultiSelect'
import Select from 'ui/Select'

const PartitionSelect = compose(
    withSelectLoader(
        (prefix, maxOptionsToShow, {panelId}) => fetchPartitions(panelId),
        ({panels: {state}}, {panelId}) => !(state && state.byIds && state.byIds[panelId] && state.byIds[panelId].partitions),
        ({panels: {state}}, {isLoading, panelId}) => !isLoading ? Object.values(state.byIds[panelId].partitions)
            .filter(({id}) => id > 0)
            .map(({id, name}) => ({value: id, label: name})) : [],
    ),
)(MultiSelect)

const UserSelect = compose(
    withSelectLoader(
        (prefix, maxOptionsToShow, {panelId}) => fetchUsers(panelId),
        ({panels: {store}}, {panelId}) => !(store && store.byIds && store.byIds[panelId] && store.byIds[panelId].userNames),
        ({panels: {store}, devices: {list: panels}}, {isLoading, panelId, deviceSubtype}) => {
            if (isLoading) {
                return []
            }

            const usersWithKeyfobs = Object.values(panels[panelId].byIds).reduce((acc, device) => {
                if (device.subtype === deviceSubtype && device.traits && device.traits.owner) {
                    acc.push(device.traits.owner.id)
                }

                return acc
            }, [])

            const options = Object.entries(store.byIds[panelId].userNames)
                .filter(([id]) => !usersWithKeyfobs.includes(parseInt(id)))
                .map(([id, name]) => ({value: id, label: name || __('User %s', id)}))

            return options.length ? options
                : [{label: __('All users already have %s', deviceSubtypeName(deviceSubtype)), disabled: true}]
        },
    ),
)(Select)

class AddDevice extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        panelId: PropTypes.number.isRequired,
        isNeo: PropTypes.bool.isRequired,
        isLoading: PropTypes.bool,              // populated from withForm HOC
        reference: PropTypes.objectOf(
            PropTypes.shape({
                code: PropTypes.string,
                users: PropTypes.number,
                partitions: PropTypes.number,
            }),
        ),
    }

    static defaultProps = {
        isLoading: false,
    }

    state = {
        deviceId: '',
        showUser: false,
        showPartitions: false,
    }

    delim = ' — '

    onClose = () => {
        const {onClose} = this.props

        return onClose()
    }

    onSubmit = ({zoneId, deviceId, partitions, userId}) => {
        const {handle, panelId} = this.props
        return handle(panelId, zoneId, deviceId.replace(/\D/g, ''), partitions, userId)
    }

    getRules = () => {
        const {showUser, showPartitions} = this.state

        const rules = {
            deviceId: {
                presence: {
                    message: __('You should define enroll code of device'),
                },

                deviceId: {
                    invalid: __('This is not valid enrollment ID'),
                    notExists: __('This enrollment ID is not exists'),
                    existence: code => code && this.deviceSubtypeName(code.substr(0, 3)) !== false,
                },
            },

            zoneId: {
                presence: {
                    message: __('You should define zone to enroll device'),
                },
                numericality: {
                    strict: true,
                    greaterThan: 0,
                    message: __('Zone number must be a integer.'),
                },
            },
        }

        const neoRules = !showPartitions ? {} : {
            partitions: {
                presence: {
                    message: __('You should define partitions to enroll device'),
                },
            },
        }

        const userRules = !showUser ? {} : {
            userId: {
                presence: {
                    message: __('You should define user to enroll device'),
                },
            },
        }

        return {
            ...rules,
            ...neoRules,
            ...userRules,
        }
    }

    handleDeviceIdChange = (e) => {
        const digits = e.target.value.replace(/\D/g, '')
        const hasDelim = e.target.value.indexOf(this.delim) === 3

        let deviceId = digits.substr(0, 3)

        if (deviceId.length === 3) {
            this.setState({
                showUser: this.deviceSubtypeHasUser(deviceId),
                deviceSubtype: this.deviceSubtypeName(deviceId),
                showPartitions: this.deviceSubtypeHasPartitions(deviceId),
            })
        } else {
            this.setState({showUser: false, showPartitions: false, deviceSubtype: ''})
        }

        if (hasDelim || (digits.length === 3 && this.digitAdded) || digits.length > 3) {
            deviceId += this.delim
        }

        deviceId += digits.substr(3)

        this.setState({deviceId})
    }

    deviceSubtypeHasUser(deviceId) {
        const {reference} = this.props

        if (reference.hasOwnProperty(deviceId)) {
            return get(reference, [deviceId, 'users'], false)
        }

        return false
    }

    deviceSubtypeHasPartitions(deviceId) {
        const {reference, hasPartitions} = this.props

        if (!hasPartitions) {
            return false
        }

        if (reference.hasOwnProperty(deviceId)) {
            return get(reference, [deviceId, 'partitions'], false)
        }

        return false
    }

    deviceSubtypeName(deviceId) {
        const {reference} = this.props

        if (reference.hasOwnProperty(deviceId)) {
            return get(reference, [deviceId, 'subtype'], false)
        }

        return false
    }

    handleDeviceIdKeyDown = (e) => {
        this.digitAdded = e.keyCode >= 48 && e.keyCode <= 57
    }

    render() {
        const {isLoading, errors, error, panelId} = this.props
        const {showUser, deviceSubtype, showPartitions} = this.state

        return (
            <CardForm isLoading={isLoading}
                      header={__('Enroll device')}
                      submitLabel={__('Add')}
                      errors={errors}
                      error={error}
                      onClose={this.onClose}
                      onSubmit={this.onSubmit}
                      rules={this.getRules()}>

                <Input
                    label={__('Enrollment ID')}
                    name="deviceId"
                    type="text"
                    maxLength="10"
                    value={this.state.deviceId}
                    onChange={this.handleDeviceIdChange}
                    onKeyDown={this.handleDeviceIdKeyDown}
                />

                <Input
                    label={__('Zone Number')}
                    name="zoneId"
                    type="number"
                    min="1"
                />

                {!!showPartitions && <PartitionSelect
                    label={__('Partitions')}
                    name="partitions"
                    panelId={panelId}
                    hasSelectAll
                    maxSelectOptions={showPartitions}
                    maxSelectOptionsLabel={__n('Only one partition allowed to select for this device', 'Only %d partitions allowed for this device', showPartitions)}
                />}

                {!!showUser && <UserSelect
                    label={__('User')}
                    name="userId"
                    deviceSubtype={deviceSubtype}
                    panelId={panelId}
                />}
            </CardForm>
        )
    }
}

export default compose(
    connect(
        ({panels}, {panelId}) => {
            const {isNeo} = panels.store.byIds[panelId] || {}
            const {hasPartitions} = panels.state.byIds[panelId] || {}

            return {
                hasPartitions,
                isNeo,
                vendor: isNeo ? VENDOR_NEO : VENDOR_POWER_MASTER,
            }
        },
    ),
    withDeviceReference(),
    withLifeCycle({
        onMount({fetchReference}) {
            fetchReference()
        },
    }),
    withForm(addDevice),
)(AddDevice)
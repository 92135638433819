import {combineReducers} from 'redux'

import list from './list/reducer'
import manager from './manager/reducer'
import store from './store/reducer'

export default combineReducers({
    list,
    manager,
    store,
})
import withProps from 'containers/withProps'

import Page from 'ui/Page'

import PushBasicConfigurationGridBar from './PushBasicConfigurationGridBar'
import PushBasicConfigurationTable from './PushBasicConfigurationTable'
import PushBasicConfigurationSearch from './PushBasicConfigurationSearch'


export default withProps({
    Top: PushBasicConfigurationSearch,
    Bar: PushBasicConfigurationGridBar,
    Content: PushBasicConfigurationTable,
})(Page)
import {mapGroup as mapBasicGroup} from 'api/groups'
import {mapRole} from 'api/roles'
import {get, post} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'

export const mapGroup = (group) => ({
    ...mapBasicGroup(group),
    serverMessagingLanguageId: group.ugl_id,
    videoFormatId: group.vof_id,
})

export const mapFormData = (formData) => ({
    utg_id: formData.id,
    utg_name: formData.name,
    utg_description: formData.description,
    gprs_timer_disabler: formData.GPRSKeepAlive ? 'yes' : 'no',
    gprs_timer: formData.GPRSKeepAlivePeriod,
    gprs_offline_timer: formData.GPRSOfflineTimer,
    bba_timer: formData.BBAKeepAlivePeriod,
    bba_offline_timer: formData.BBAOfflineTimer,
    utg_time_sync: formData.timeSynchronization ? 'yes' : 'no',
    utg_upgrade_media: formData.upgradeMethod,
    utg_local_wakeup: formData.localWakeUp ? 'yes' : 'no',
    utg_secure_pnet: formData.BBAEncryption ? 'yes' : 'no',
    fibro_timer_seconds: formData.FibroKeepAlivePeriod,
    fibro_gprs_offline_timer: formData.FibroGPRSOfflineTimer,
    fibro_bba_offline_timer: formData.FibroBBAOfflineTimer,
    fibro_bba_timer_enabled: formData.FibroBBAKeepAlive ? 'yes' : 'no',
    fibro_gprs_timer_enabled: formData.FibroGPRSKeepAlive ? 'yes' : 'no',
    fibro53_bba_timer_enabled: formData.Fibro53BBAKeepAlive ? 'yes' : 'no',
    fibro53_bba_timer_seconds: formData.Fibro53BBAOfflinePeriod,
    fibro53_bba_offline_timer: formData.Fibro53BBAOfflineTimer,
    fibro53_gprs_timer_enabled: formData.Fibro53GPRSKeepAlive ? 'yes' : 'no',
    fibro53_gprs_timer_seconds: formData.Fibro53GPRSOfflinePeriod,
    fibro53_gprs_offline_timer: formData.Fibro53GPRSOfflineTimer,
    ugl_id: formData.serverMessagingLanguageId,
})

export const keysMap = {
    id: 'utg_id',
    name: 'utg_name',
    description: 'utg_description',
    GPRSKeepAlive: 'gprs_timer_disabler',
    GPRSKeepAlivePeriod: 'gprs_timer',
    GPRSOfflineTimer: 'gprs_offline_timer',
    BBAKeepAlivePeriod: 'bba_timer',
    BBAOfflineTimer: 'bba_offline_timer',
    timeSynchronization: 'utg_time_sync',
    upgradeMethod: 'utg_upgrade_media',
    localWakeUp: 'utg_local_wakeup',
    BBAEncryption: 'utg_secure_pnet',
    serverMessagingLanguageId: 'ugl_id',
    FibroKeepAlivePeriod: 'fibro_timer_seconds',
    FibroGPRSOfflineTimer: 'fibro_gprs_offline_timer',
    FibroBBAOfflineTimer: 'fibro_bba_offline_timer',
    FibroBBAKeepAlive: 'fibro_bba_timer_enabled',
    FibroGPRSKeepAlive: 'fibro_gprs_timer_enabled',
    Fibro53GPRSOfflineTimer: 'fibro53_gprs_offline_timer',
    Fibro53GPRSOfflinePeriod: 'fibro53_gprs_timer_seconds',
    Fibro53BBAOfflinePeriod: 'fibro53_bba_timer_seconds',
    Fibro53BBAOfflineTimer: 'fibro53_bba_offline_timer',
}

export function receiveGroup(id) {
    return get('/group/info', {id}).then((data) => mapGroup(data))
}

export function persistCentralStationLinks(groupId, centralStationId, profiles) {
    return post('/group/savecslinks', {
        form: {
            groupId,
            centralStationId,
            profiles,
        },
    }).catch(mapValidationErrors)
}

export function receiveGroupPermissions(groupIds) {
    return get('/group/getpermissions', {utg_id: groupIds})
        .then(data => Object.values(data).map(mapRole))
}
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Input from 'ui/Input'
import {__} from 'utils/i18n'

export default class StringValue extends PureComponent {

    static propTypes = {
        item: PropTypes.object.isRequired,
        value: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    handleChange = e => {
        const value = this.sanitize(e.target.value)
        this.props.onChange(value, !this.validate(value))
    }

    sanitize(value) {
        return value
    }

    validate(value) {
        const {min_length: minLength, max_length: maxLength} = this.props.item

        if (minLength && minLength === maxLength && value.length !== minLength) {
            return __('Should contain exactly %d characters', minLength)
        }

        if (minLength && value.length < minLength) {
            return __('Should contain more than or equal to %d characters', minLength)
        }

        if (maxLength && value.length > maxLength) {
            return __('Should contain less than or equal to %d characters', maxLength)
        }
    }

    isRequired() {
        const {item} = this.props

        if (!item.hasOwnProperty('required')) {
            // if nothing specified assume field is required
            return true
        }

        return item.required
    }

    getValue() {
        return this.props.value
    }

    getMaxLength() {
        return this.props.item.max_length
    }

    render() {
        const value = this.getValue()

        return (
            <Input
                placeholder={__('Not set')}
                value={value}
                maxLength={this.getMaxLength()}
                onChange={this.handleChange}
                error={this.validate(value)}/>
        )
    }

}
import {compose} from 'redux'

import {create} from 'permissions/roles/actions'

import withRoles from 'containers/withRoles'
import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'
import {withVisibility} from 'containers/withVisibility'

import Button from 'ui/Button'
import {__} from 'utils/i18n'
import IconAdd from 'icons/plus.svg'

export default compose(
    withPermission({isVisible: create}),
    withVisibility(),
    withRoles(),
    withProps(
        ({showAddRole}) => ({
            Icon: IconAdd,
            onClick: showAddRole,
            label: __('Add Role'),
        }),
    ),
)(Button)
import {warn} from 'utils/log'

import AwayIcon from 'icons/away.svg'
import NightIcon from 'icons/night.svg'
import HomeIcon from 'icons/home.svg'
import DisarmIcon from 'icons/disarm.svg'

import {__} from 'utils/i18n'

export const STATE_AWAY = 'AWAY'
export const STATE_NIGHT = 'NIGHT'
export const STATE_HOME = 'HOME'
export const STATE_STAY = 'STAY'
export const STATE_DISARM = 'DISARM'
export const STATE_ENTRY_DELAY = 'ENTRY_DELAY'
export const STATE_PROGRAMMING = 'PROGRAMMING'

export const SUB_STATE_EXIT = 'EXIT'
export const SUB_STATE_ENTRY = 'ENTRY'
export const SUB_STATE_HOLD = 'HOLD'

export const STATE_MODIFIER_NO_EXIT = 'NOEXIT'
export const STATE_MODIFIER_NO_ENTRY = 'NOENTRY'
export const STATE_MODIFIER_LATCHKEY = 'LATCHKEY'

const icons = {
    [STATE_AWAY]: AwayIcon,
    [STATE_NIGHT]: NightIcon,
    [STATE_HOME]: HomeIcon,
    [STATE_STAY]: HomeIcon,
    [STATE_DISARM]: DisarmIcon,
    [STATE_ENTRY_DELAY]: DisarmIcon,
    [STATE_PROGRAMMING]: DisarmIcon,
}

export function stateTitle(label) {
    switch (label && label.toUpperCase()) {
        case STATE_AWAY:
            return __('Away')
        case STATE_NIGHT:
            return __('Night')
        case STATE_HOME:
            return __('Home')
        case STATE_STAY:
            return __('Stay')
        case STATE_DISARM:
            return __('Disarm')
        case STATE_ENTRY_DELAY:
            return __('Entry delay')
        case STATE_PROGRAMMING:
            return __('Programming')
    }

    warn(`Unknown state ${label}`)

    return label
}

export function stateIcon(label) {
    label = label && label.toUpperCase()

    if (icons.hasOwnProperty(label)) {
        return icons[label]
    }

    warn(`No icon for state ${label}`)

    return null
}

export function subStateTitle(label) {
    switch (label && label.toUpperCase()) {
        case SUB_STATE_EXIT:
            return __('Exit Delay')
        case SUB_STATE_ENTRY:
            return __('Entry Delay')
        case SUB_STATE_HOLD:
            return __('On Hold')
    }

    warn(`Unknown substate ${label}`)

    return label
}

export function stateModifierTitle(modifier) {
    switch (modifier && modifier.toUpperCase()) {
        case STATE_MODIFIER_NO_ENTRY:
            return __('No entry')
        case STATE_MODIFIER_NO_EXIT:
            return __('No exit')
        case STATE_MODIFIER_LATCHKEY:
            return __('Latch key')
    }

    warn(`Unknown state modifier ${modifier}`)

    return modifier
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class Circle extends Component {

    static propTypes = {
        Icon: PropTypes.oneOfType([
            PropTypes.instanceOf(Component),
            PropTypes.func,
        ]).isRequired,
    }

    render() {
        const {Icon, className, ...props} = this.props

        return (
            <div className={classnames('circle', className)} {...props}>
                <Icon className="circle-icon"/>
            </div>
        )
    }

}
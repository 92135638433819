import withDeviceMeteo from 'containers/withDeviceMeteo'
import moment from 'moment'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import get from 'lodash-es/get'

import Bar, {BarSpace} from 'ui/Bar'

import Button from 'ui/Button'
import Buttons from 'ui/Buttons'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ui/Card'
import ChartComponent from 'components/Devices/Chart'
import Error from 'ui/Error'
import InputDatePicker from 'ui/InputDatePicker'
import Spinner from 'ui/Spinner'

import {__} from 'utils/i18n'

const Chart = withDeviceMeteo()(ChartComponent)

class ShowDeviceMeteoChart extends Component {

    state = {
        range: {
            from: moment().subtract(1, 'week').startOf('day'),
            to: moment(),
        },
        left: 0,
    }

    componentWillMount() {
        this.refreshPresets(this.props)
    }

    componentWillReceiveProps(props) {
        this.refreshPresets(props)
    }

    refreshPresets({isLoading, data}) {
        if (isLoading || !data || !data.length) {
            return
        }

        const min = data.reduce(
            (min, {timestamp}) => Math.min(min, timestamp),
            moment().unix(),
        )

        this.presets = [
            {
                from: moment().subtract(1, 'day').startOf('day'),
                to: moment(),
                label: __('Last day'),
            },
            {
                from: moment().subtract(1, 'week').startOf('day'),
                to: moment(),
                label: __('Last week'),
            },
            {
                from: moment().subtract(1, 'month').startOf('day'),
                to: moment(),
                label: __('Last month'),
            },
            {
                from: moment.unix(min),
                to: moment(),
                label: __('All'),
            },
        ]
    }

    getTitle() {
        const {type} = this.props

        switch (type) {
            case 'temperature':
                return __('Temperature')
            case 'brightness':
                return __('Brightness')
            default:
                return __('Unknown metric')
        }
    }

    handleRangeChange = (from, to) => {
        if (!from || !to) {
            return
        }

        this.setState({
            range: {
                from,
                to,
            },
        })
    }

    handlePreset = (from, to) => {
        this.setState({
            range: {
                from,
                to,
            },
        })
    }

    renderContent() {
        const {isLoading, data} = this.props
        const {range} = this.state

        if (isLoading) {
            return <Spinner/>
        }

        if (!data || !data.length) {
            return <Error title={__('No data')}/>
        }

        return (
            <div>
                <Bar>
                    <InputDatePicker
                        range
                        clearable={false}
                        value={range}
                        onChange={this.handleRangeChange}
                    />

                    <BarSpace/>

                    <Buttons flat>
                        {this.presets.map(({from, to, label}) => (
                            <Button
                                key={from.unix() + '$' + to.unix()}
                                active={from.isSame(range.from, 'day') && to.isSame(range.to, 'day')}
                                onClick={() => this.handlePreset(from, to)}>
                                {label}
                            </Button>
                        ))}
                    </Buttons>
                </Bar>

                <div className="chart">
                    <Chart range={range} {...this.props}/>
                </div>
            </div>
        )
    }

    render() {
        const {onClose} = this.props

        return (
            <Card className="card--wide-3 chartModal">
                <CardClose onClick={onClose}/>

                <CardHeader>{this.getTitle()}</CardHeader>

                <CardContent>
                    {this.renderContent()}
                </CardContent>

                <CardActions>
                    <Button borderless onClick={onClose}>
                        {__('Close')}
                    </Button>
                </CardActions>
            </Card>
        )
    }
}

export default connect(
    (state, {panelId, deviceId, type}) => get(state.devices.meteo, `${panelId}.${deviceId}.${type}`, {
        isLoading: true,
    }),
)(ShowDeviceMeteoChart)
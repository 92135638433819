import withProps from 'containers/withProps'

import FirmwareUpgradeGridBar from './Upgrade/FirmwareUpgradeGridBar'
import FirmwareUpgradeTable from './Upgrade/FirmwareUpgradeTable'
import FirmwareUpgradeSearch from './Upgrade/FirmwareUpgradeSearch'

import Page from 'ui/Page'

export default withProps({
    Top: FirmwareUpgradeSearch,
    Bar: FirmwareUpgradeGridBar,
    Content: FirmwareUpgradeTable,
})(Page)
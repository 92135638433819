import {put, call, select} from 'redux-saga/effects'
import groupBy from 'lodash-es/groupBy'
import concat from 'lodash-es/concat'
import uniqid from 'uniqid'
import {sequenced} from 'utils/saga-effects'

import * as actions from './actions'
import {snackShow} from 'modules/snacks'
import {__} from 'utils/i18n'
import timezones from 'utils/timezones'
import {PROCESS_TYPE_PMAXANONYMOUS} from 'constants/processTypes'

const makeProcessStub = (type, panelId, store) => ({
    key: uniqid(),
    webname: store && store[panelId] && store[panelId].webname,
    panelId,
    type,
    status: 'start',
    started: timezones.server(),
    percentage: 0,
})

const makeProcessStubs = (type, panelIds, store) => panelIds.map(
    panelId => makeProcessStub(type, panelId, store),
)

// WORKAROUND
// sometimes process handling went incredibly wrong
// despite process is created, server couldn't set right process type
// but we are smart enough to fix that behaviour
const fixAnonymousProcessType = (process, type) => {
    if (process && process.type === PROCESS_TYPE_PMAXANONYMOUS) {
        process.type = type
    }

    return process
}

const makeNotStartedProcess = (process, errorMessage) => ({
    ...process,
    status: 'notStarted',
    finished: timezones.server(),
    isFailed: true,
    isRunning: false,
    isFailedToStart: true,
    errorMessage,
})

export function* observeBatch(processes) {
    const key = uniqid()

    processes = Array.isArray(processes) ? processes : [processes]
    processes = processes.map(process => {
        if (process.key) {
            return process
        }

        return {
            key: uniqid(),
            ...process,
        }
    })
    yield put(actions.observe(processes, key))
    return key
}

const mergeStubsAndProcesses = (stubs, processes) => {
    const stubsByPanelId = groupBy(stubs, 'panelId')

    const receivedProcesses = processes.map(
        process => {
            const stub = stubsByPanelId[process.panelId] && stubsByPanelId[process.panelId].shift()

            if (!stub) {
                return process
            }

            process.key = stub.key

            return fixAnonymousProcessType(process, stub.type)
        },
    )

    const failedToStart = Object.values(stubsByPanelId)
        .reduce((acc, stubs) => acc.concat(stubs), [])
        .map(makeNotStartedProcess)

    return receivedProcesses.concat(failedToStart)
}

function createExecutor(type, batchKey, dataRetriever, processStubs, resultParser = ({processes}) => processes) {
    return function* executor(fn, ...arg) {
        try {
            const data = yield dataRetriever(fn, ...arg)
            const processes = mergeStubsAndProcesses(processStubs, resultParser(data))

            yield put(actions.observe(processes, batchKey))

            const failed = processes
                .filter(process => !process.id)
                .map(({panelId}) => panelId)

            if (failed.length > 0) {
                yield put(snackShow(__('1 process failed to start', '%d processes failed to start', failed.length)))
            }

            return failed
        } catch (error) {
            yield put(actions.observe(
                processStubs.map(makeNotStartedProcess),
                batchKey,
            ))

            throw error
        }
    }
}

function* simpleDataRetriever(fn, ...arg) {
    return yield call(fn, ...arg)
}

function* batchDataRetriever(fn, iterable, ...arg) {
    const data = yield sequenced(fn, iterable, ...arg)

    return data.reduce((acc, {lastError, processes}) => ({
        lastError: (lastError) ? lastError : acc.lastError,
        processes: concat(acc.processes, processes),
    }), {lastError: null, processes: []})
}

export function* generateBatchForPanel(type, panelId, count = 1) {
    return yield generateBatch(type, new Array(count).fill(panelId))
}

export function* generateBatch(type, panelIds) {
    const batchKey = uniqid()
    const store = yield select(state => state.panels.store.byIds)
    const processStubs = makeProcessStubs(type, panelIds, store)

    yield put(actions.observe(processStubs, batchKey))

    const keys = processStubs.reduce(
        (acc, {panelId, key}) => ({
            ...acc,
            [panelId]: key,
        }),
        {},
    )

    return {
        processes: keys,
        execute: createExecutor(type, batchKey, batchDataRetriever, processStubs),
    }
}

export default function* generateProcess(type, panelId) {
    const batchKey = uniqid()
    const store = yield select(state => state.panels.store.byIds)
    const process = makeProcessStub(type, panelId, store)

    yield put(actions.observe(process, batchKey))

    return {
        process: process.key,
        execute: createExecutor(type, batchKey, simpleDataRetriever, [process], process => [process]),
    }
}
import React, {Component} from 'react'
import {Provider} from 'react-redux'
import {Switch, Route, Redirect} from 'react-router-dom'
import {ConnectedRouter as Router} from 'react-router-redux'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {selectBatches} from 'modules/processes/manager/selectors'
import {hide} from 'modules/snacks'

import Button from 'ui/Button'
import Error from 'ui/Error'
import SnackBarComponent from 'ui/SnackBar'
import Logo from 'ui/Logo'

import {removeBatch, removeFinishedBatches} from 'modules/processes/manager/actions'
import {
    showEditProfileInfoModal,
    showChangePasswordModal,
    showEditSettingsModal,
    showBatchInfoModal, showAboutModal,
} from 'modules/modals/actions'
import {logout} from 'modules/auth/sign/actions'
import withLoader from 'containers/withLoader'
import {__} from 'utils/i18n'

import pages from './routes'
import Login from './Login'
import NavMain from 'components/Nav/NavMain'
import ModalsContainer from '../modals/ModalsContainer'
import UserInfoComponent from 'components/UserInfo'
import ProcessBatches from 'components/Processes/ProcessBatches'
import path from 'utils/path'

const Processes = connect(
    state => ({
        batches: selectBatches(state),
    }),
    dispatcher => bindActionCreators({
        untrack: removeBatch,
        untrackFinished: removeFinishedBatches,
        showInfo: showBatchInfoModal,
    }, dispatcher),
)(ProcessBatches)

const SnackBar = connect(
    ({snacks}) => ({snacks}),
    dispatch => bindActionCreators({
        hide,
        dispatch,
    }, dispatch)
)(SnackBarComponent)

const UserInfo = connect(
    (({auth: {sign: {user}}}) => ({user})),
    dispatch => bindActionCreators({
        showEditProfileInfoModal,
        showChangePasswordModal,
        showEditSettingsModal,
        logout: () => logout(),
    }, dispatch),
)(UserInfoComponent)

const Layout = ({showAboutModal, locale, isLogged, history}) => {
    if (!isLogged) {
        return <Login><SnackBar/></Login>
    }

    return (
        <Router history={history} key={locale}>
            <div className="app">
                <UserInfo/>

                <aside className="app-aside">
                    <div className="app-aside-logo" onClick={showAboutModal}>
                        <Logo multiline/>
                    </div>

                    <div className="app-aside-content">
                        <NavMain/>
                        <Processes/>
                    </div>
                </aside>

                <Switch>
                    <Route exact path={path('/')} render={() => (
                        <Redirect to={path('panels', {})}/>
                    )}/>
                    {pages.map((page, i) => <Route key={i} {...page}/>)}
                </Switch>

                <SnackBar/>

                <ModalsContainer/>
            </div>
        </Router>
    )
}

const ConnectedLayout = compose(
    connect(
        ({auth: {sign}, locales}) => ({
            locale: locales.locale,
            isLogged: !!sign.user,
            isLoading: !sign.checked,
        }),

        dispatch => bindActionCreators({showAboutModal}, dispatch),
    ),
    withLoader(),
)(Layout)

export default class App extends Component {
    state = {}

    componentDidCatch(error) {
        this.setState({hasError: true})
    }

    handleReload = () => window.location = '/'

    render() {
        if (this.state.hasError) {
            const message = (
                <div>
                    <p className="title">{__('Tab just crashed.')}</p>
                    {__('To continue, click button below.')}
                </div>
            )

            return (
                <Error
                    title={__('We are sorry')}
                    message={message}>
                    <Button primary onClick={this.handleReload}>{__('Reload tab')}</Button>
                </Error>
            )
        }

        const {store, history} = this.props

        return (
            <Provider store={store}>
                <ConnectedLayout history={history}/>
            </Provider>
        )
    }
}

import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import processType from 'constants/processType'
import IconCheck from 'icons/check.svg'
import Duration from 'ui/Duration'
import parseDate from 'api/base/parseDate'
import momentPropType from 'utils/momentPropType'

export default class ProcessBatch extends Component {

    static propTypes = {
        batch: PropTypes.shape({
            type: PropTypes.string.isRequired,
            started: PropTypes.oneOfType([PropTypes.string, momentPropType]).isRequired,
            finished: PropTypes.oneOfType([PropTypes.string, momentPropType]),
            counters: PropTypes.shape({
                succeeded: PropTypes.number.isRequired,
                handled: PropTypes.number.isRequired,
                failed: PropTypes.number.isRequired,
                start: PropTypes.number.isRequired,
            }).isRequired,
        }),
        untrack: PropTypes.func.isRequired,
        showInfo: PropTypes.func.isRequired,
    }

    handleShowInfo = e => {
        const {batch, showInfo} = this.props
        showInfo(batch.id)
    }

    handleUntrack = e => {
        const {batch, untrack} = this.props
        e.stopPropagation()
        untrack(batch.id)
    }

    renderCounter(count, className) {
        if (count <= 0) {
            return null
        }

        return (
            <div className={'process-counter process-counter--' + className} style={{width: count * 100}}>
                <span className="process-counter-label">
                    {count}
                </span>
            </div>
        )
    }

    renderDuration() {
        let {started, finished} = this.props.batch

        started = parseDate(started)
        finished = parseDate(finished)

        if (finished) {
            return (
                <div className="process-time">
                    {__('Finished in:')}
                    {' '}
                    <Duration from={started} to={finished}/>
                </div>
            )
        }


        return (
            <div className="process-time">
                <Duration from={started}/>
            </div>
        )
    }

    render() {
        const {batch, untrack} = this.props
        const {type, counters, panels, webname} = batch
        const {notStarted, succeeded, handled, failed, start} = counters

        return (
            <div className="process" onClick={this.handleShowInfo}>
                <div className="process-title">
                    <div className="hint">
                        {panels > 1
                            ? __('%d panels', panels)
                            : webname}
                    </div>

                    <div className="process-type">
                        {processType(type)}
                    </div>

                    {untrack && <IconCheck className="process-title-icon" onClick={this.handleUntrack}/>}
                </div>

                {this.renderDuration()}

                <div className="process-counters">
                    {this.renderCounter(notStarted, 'notStarted')}
                    {this.renderCounter(succeeded, 'succeeded')}
                    {this.renderCounter(handled, 'handled')}
                    {this.renderCounter(failed, 'failed')}
                    {this.renderCounter(start, 'start')}
                </div>
            </div>
        )
    }

}
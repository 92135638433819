import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {__} from 'utils/i18n'
import withSelectLoader from 'containers/withSelectLoader'

import CardForm from 'ui/CardForm'
import Autocomplete from 'ui/Autocomplete'

import MixedVendorsError from './PushBasicConfigurationError/MixedVendorsError'

import {fetch as fetchBasicConfig} from 'modules/basicConfiguration/select/actions'
import {pushBasicConfiguration} from 'modules/panels/store/actions'
import withLoader from 'containers/withLoader'
import withInterruption from 'containers/withInterruption'

const BasicConfigSelect = withSelectLoader(
    fetchBasicConfig,
    ({basicConfiguration}) => basicConfiguration.select.isLoading,
    ({basicConfiguration, modals: {args: {panelIds}}, panels: {store: {byIds}}}) => Object.values(basicConfiguration.store.byIds)
        .filter(({vendor}) => vendor === byIds[panelIds[0]].configModel)
        .map(
            ({id, name}) => ({label: name, value: id}),
        ),
    10,
    false,
)(Autocomplete)

export class PushBasicConfiguration extends Component {
    static propTypes = {
        handle: PropTypes.func.isRequired,
        panelIds: PropTypes.arrayOf(PropTypes.number),
    }

    rules = {
        basicConfigId: {
            presence: true,
        },
    }

    handle = ({basicConfigId}) => {
        const {panelIds, handle, onClose} = this.props
        handle(basicConfigId, panelIds)
        onClose()
    }

    render() {
        return (
            <CardForm
                onSubmit={this.handle}
                header={__('Push Basic Config')}
                rules={this.rules}
                submitLabel={__('Push')}
                {...this.props}
            >
                <BasicConfigSelect label={__('Basic Configurations')} name="basicConfigId"/>
            </CardForm>
        )
    }
}

export default compose(
    connect(
        ({panels, basicConfiguration}, {panelIds}) => {
            const selectedPanels = panelIds.map(id => panels.store.byIds[id])
            const selectedConfigModel = selectedPanels[0].configModel
            const configurations = Object.values(basicConfiguration.store.byIds).filter(({vendor}) => vendor === selectedConfigModel)

            return {
                selectedPanels,
                configurations,
                selectedConfigModel,
            }
        },
        (dispatch) => bindActionCreators({
            fetchBasicConfig,
            handle: pushBasicConfiguration,
        }, dispatch),
    ),
    withInterruption(
        ({selectedPanels, selectedConfigModel}) => !selectedPanels.every(panel => panel.configModel === selectedConfigModel),
        MixedVendorsError,
    ),
    withLoader(({fetchBasicConfig}) => fetchBasicConfig()),
)(PushBasicConfiguration)
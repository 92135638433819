import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

export class InteractiveBar extends Component {
    static propTypes = {
        primary: PropTypes.bool,
        danger: PropTypes.bool,
        warning: PropTypes.bool,
        success: PropTypes.bool,
        active: PropTypes.bool.isRequired,
    }

    render() {
        const {
            primary,
            danger,
            warning,
            success,
            children,
            active,
        } = this.props

        const className = classes('page-interactiveBar-content', {
            'page-interactiveBar-content--primary': primary,
            'page-interactiveBar-content--success': success,
            'page-interactiveBar-content--warning': warning,
            'page-interactiveBar-content--danger': danger,
        })

        if (active) {
            this.lastActiveChildren = children
        }

        return (
            <div className={classes('page-interactiveBar', {'page-interactiveBar--active': active})}>
                <div className={className}>
                    {(!active && this.lastActiveChildren)
                        ? this.lastActiveChildren
                        : children}
                </div>
            </div>
        )
    }
}
import {compose} from 'redux'

import {refreshRssi} from 'permissions/panel/devices/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withDevices from 'containers/withDevices'
import withProps from 'containers/withProps'

import RefreshButton from 'ui/RefreshButton'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: refreshRssi}),
    withVisibility(),
    withDevices(),
    withProps(({refreshRssi, panel: {rssiRefreshProcess}}) => ({
        label: __('Refresh RSSI'),
        onClick: refreshRssi,
        isRefreshing: !!rssiRefreshProcess,
    })),
)(RefreshButton)
import React from 'react'
import {compose} from 'redux'

import bar from 'permissions/basicConfiguration/bar'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Bar, {BarSpace} from 'ui/Bar'

import BasicConfigurationsPager from './GridBar/BasicConfigurationsPager'
import BasicConfigurationsSelectionDropDown from './GridBar/BasicConfigurationsSelectionDropDown'
import BasicConfigurationsRemoveButton from './GridBar/BasicConfigurationsRemoveButton'

const BasicConfigurationsGridBar = () => (
    <Bar>
        <BasicConfigurationsSelectionDropDown/>
        <BasicConfigurationsRemoveButton/>

        <BarSpace/>

        <BasicConfigurationsPager/>
    </Bar>
)

export default compose(
    withPermission({isVisible: bar}),
    withVisibility(),
)(BasicConfigurationsGridBar)
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {
    showMarkForServiceModal,
} from 'modules/modals/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withRouterPanelId} from 'containers/withPanel'

import {MenuItem} from 'ui/Menu'

import {__} from 'utils/i18n'
import {markForService} from 'permissions/panels/selection'

export default compose(
    withPermission({isVisible: markForService}),
    withVisibility(),
    withRouterPanelId(),
    connect(
        () => ({
            children: __('Mark For Service'),
        }),
        (dispatch, {panelId}) => bindActionCreators({
            onClick: () => showMarkForServiceModal([panelId]),
        }, dispatch),
    ),
)(MenuItem)

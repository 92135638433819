import {get, post} from 'api/http'
import dataMapper from 'utils/dataMapper'
import {mapProcess} from 'api/processes'

const map = dataMapper(appliance => ({
    number: parseInt(appliance.number, 10),
    type: appliance.type,
    currentVersion: appliance.current_version,
    packages: appliance.packages,
}))

export function fetchList(panelId) {
    return get('unit_firmware/list', {unt_id: panelId})
        .then(({appliances, process}) => ({
            process: process ? mapProcess(process) : null,
            appliances: appliances.map(map),
        }))
}

export function update(panelId, packages) {
    return post('unit_firmware/upgrade', {
        unt_id: panelId,
        packages: packages.map(
            ({type, packageName}) => ({
                device_type: type,
                package: packageName,
            }),
        ),
    }).then(mapProcess)
}
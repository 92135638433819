function purge(val) {
    val.forEach(item => {
        if (item.type === 'menu') {
            purge(item.val)
            return
        }

        if (item.type === 'code' || item.type === 'code_hex') {
            item.val = '*'.repeat(item.val.length)
        }
    })

    return val
}

// change stars to non-printable dots and ... don't trust anyone, even your backend!
export default function purgePasswords(item) {
    purge(item.val)
    return item
}
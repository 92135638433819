import React from 'react'
import PropTypes from 'prop-types'

import classes from 'classnames'

const List = ({className, twoCols, children}) => {
    const icons = !!React.Children.toArray(children).find(child => child.props && !!child.props.Icon)

    return (
        <ol className={classes('list', className, {
            'list--icons': icons,
            'list--twoCols': twoCols,
        })}>
            {children}
        </ol>
    )
}

List.propTypes = {
    className: PropTypes.any,
    twoCols: PropTypes.bool,
}

export default List

export const ListItem = ({className, Icon, children, ...props}) => (
    <li className={classes('list-item', className)} {...props}>
        {Icon && <Icon className="list-item-icon"/>}
        {children}
    </li>
)

ListItem.propTypes = {
    className: PropTypes.any,
    Icon: PropTypes.func,
}

export const ListHintItem = ({name, text, ...props}) => (
    <ListItem {...props}>
        <div className="hint">{name}</div>
        <strong>{text}</strong>
    </ListItem>
)

ListHintItem.propTypes = {
    className: PropTypes.any,
    Icon: PropTypes.func,
    name: PropTypes.node,
    text: PropTypes.node,
}

import mapValues from 'lodash-es/mapValues'

import {get, post} from 'api/http'
import dataMapper from 'utils/dataMapper'
import {mapProcess} from 'api/processes'

const mapPartitionStates = dataMapper(data => ({
    id: data.id,
    name: data.state,
    substate: data.status || null,
    ready: data.ready,
    modifiers: data.options,
}))

const mapStateSet = states => states.map(dataMapper(data => ({
    name: data.name,
    visible: data.settable,
    modifiers: data.options,
    substates: data.statuses,
    transitions: data.transitions,
})))

const mapPartition = dataMapper(({id, name, active, exit_delay_time, state_set}) => ({
    id: parseInt(id),
    name,
    isActive: active,
    exitDelay: parseInt(exit_delay_time),
    stateSet: state_set,
}))

export function getPartitionStates(panelId) {
    return get('unit_diagnostic/getstate', {unt_id: panelId}).then(data => {
        const partitionStates = data.state.map(mapPartitionStates)
        const partitions = data.partitions.map(mapPartition)
        const stateSets = mapValues(data.state_sets, mapStateSet)

        return {
            partitionStates,
            partitions,
            stateSets,
        }
    })
}

export function sendState(panelId, partition, state, option) {
    return post('unit_diagnostic/setstate', {
        unt_id: panelId,
        form: {
            partition,
            state,
            options: option ? [option] : [],
        },
    }).then(mapProcess)
}

import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import rainbow from 'utils/rainbow'

export default class Ava extends PureComponent {

    static propTypes = {
        name: PropTypes.string.isRequired,
        id: PropTypes.number.isRequired,
        className: PropTypes.string,
        large: PropTypes.bool,
        small: PropTypes.bool,
    }

    render() {
        const {className, large, small, name, id, ...props} = this.props
        const abbreviation = name.split(' ').map(n => n.trim().slice(0, 1)).slice(0, 2).filter(n => !!n).join('').toUpperCase()
        const color = id ? rainbow(id) : 'grey'

        return (
            <div
                {...props}
                className={classes('ava', className, {
                    'ava--small': small,
                    'ava--large': large,
                    'ava--interactive': props.onClick,
                })}
                style={{backgroundColor: color}}
                children={abbreviation}
            />
        )
    }
}
import {VENDOR_FIBRO_TRANSMITTER, VENDOR_NEO, VENDOR_POWER_MASTER} from 'constants/panelVendorType'
import {__} from 'utils/i18n'
import pickBy from 'lodash-es/pickBy'

const tabs = {
    devices: {
        label: () => __('Devices'),
        path: 'panel.devices',
        exact: true,
    },
    info: {
        label: () => __('Info'),
        path: 'panel.info',
    },
    state: {
        label: () => __('State'),
        path: 'panel.state',
    },
    configuration: {
        label: () => __('Configuration'),
        path: 'panel.configuration',
    },
    locations: {
        label: () => __('Locations'),
        path: 'panel.locations',
    },
    processes: {
        label: () => __('Processes'),
        path: 'panel.processes',
    },
    reports: {
        label: () => __('Reports'),
        path: 'panel.reports',
    },
    log: {
        label: () => __('Logs'),
        path: 'panel.log',
    },
    remoteInspections: {
        label: () => __('Remote Inspections'),
        path: 'panel.remoteInspections',
    },
    events: {
        label: () => __('Events'),
        path: 'panel.events',
    },
    firmware: {
        label: () => __('Firmware'),
        path: 'panel.firmware',
    },
    keypad: {
        label: () => __('Keypad'),
        path: 'panel.keypad',
    },
}

const allMenuItems = {
    devices: true,
    info: true,
    state: true,
    configuration: true,
    locations: true,
    processes: true,
    reports: true,
    log: true,
    remoteInspections: true,
    firmware: true,
    events: true,
    keypad: true,
}

const neoMenuItems = {
    devices: true,
    info: true,
    state: true,
    configuration: true,
    locations: false,
    processes: true,
    reports: true,
    log: true,
    remoteInspections: true,
    firmware: true,
    events: true,
    keypad: true,
}

const fibroMenuItems = {
    events: true,
}

export function getMenuItems(vendor) {
    switch (vendor) {
        case VENDOR_NEO:
            return neoMenuItems
        case VENDOR_FIBRO_TRANSMITTER:
            return fibroMenuItems
        case VENDOR_POWER_MASTER:
        default:
            return allMenuItems
    }
}

export function getItemsByKeys(keys) {
    return pickBy(tabs, (tab, key) => keys.indexOf(key) >= 0)
}
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {download} from 'permissions/panel/configuration/actions'

import {refresh} from 'modules/panels/configuration/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import {MenuItem} from 'ui/Menu'
import IconDownload from 'icons/download.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: download}),
    withVisibility(),
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: refresh,
        }, dispatch),
    ),
    withProps(() => ({
        Icon: IconDownload,
        children: __('Refresh configuration'),
    })),
    withSelectionHandler(),
)(MenuItem)

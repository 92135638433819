import React from 'react'
import {compose} from 'redux'

import {withRouter} from 'react-router-dom'

import BasicConfigurationsGridBar from './BasicConfigurationsGridBar'
import BasicConfigurationsTable from './BasicConfigurationsTable'
import BasicConfigurationsSearch from './BasicConfigurationsSearch'

import Page from 'ui/Page'

class BasicConfigurationsPage extends Page {
    renderTop = () => {
        return <BasicConfigurationsSearch/>
    }

    renderBar = () => {
        return <BasicConfigurationsGridBar/>
    }

    renderContent = () => {
        return <BasicConfigurationsTable/>
    }
}


export default compose(
    withRouter
)(BasicConfigurationsPage)
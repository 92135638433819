import React, {Component} from 'react'
import PropTypes from 'prop-types'

import ProgressBar from 'ui/ProgressBar'

import ResendEmail from './Actions/ResendEmail'
import Schedule from './Actions/Schedule'
import Initiate from './Actions/Initiate'
import Review from './Actions/Review'

export default class ActionsCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            id: PropTypes.number,
            progress: PropTypes.number,
            latest: PropTypes.object,
            next: PropTypes.object,
        }),
    }

    renderResendEmail() {
        const {row: {latest}} = this.props

        if (latest) {
            return (
                <ResendEmail result={latest}/>
            )
        }

        return null
    }

    renderMarkReviewed() {
        const {id, latest} = this.props.row

        if (!latest) {
            return null
        }

        return <Review id={id} result={latest}/>
    }

    renderSchedule() {
        const {row: {id, next}} = this.props

        return (
            <Schedule panelId={id} next={next}/>
        )
    }

    renderInitiate() {
        const {row: {progress, id}} = this.props

        if (progress !== 'progress') {
            return (
                <Initiate panelId={id}/>
            )
        }

        return null

    }

    renderProgress() {
        const {row: {progress}} = this.props

        return (
            <ProgressBar className="rri-progress" value={progress}/>
        )
    }

    render() {
        const {row: {progress}} = this.props

        if (progress !== null) {
            return this.renderProgress()
        }

        return (
            <div className="rri-actions">
                {this.renderResendEmail()}
                {this.renderMarkReviewed()}
                {this.renderSchedule()}
                {this.renderInitiate()}
            </div>
        )
    }
}
export const PROCESS_TYPE_FEATURES = 'Features'
export const PROCESS_TYPE_PLINKSOFTWAREUPGRADE = 'PLinkSoftwareUpgrade'
export const PROCESS_TYPE_PMAXANONYMOUS = 'PMaxAnonymous'
export const PROCESS_TYPE_PMAXCANCELSOAKZONE = 'PMaxCancelSoakZone'
export const PROCESS_TYPE_PMAXCLEARBYPASSZONE = 'PMaxClearByPassZone'
export const PROCESS_TYPE_PMAXCONFIGBACKUP = 'PMaxConfigBackup'
export const PROCESS_TYPE_PMAXCONFIGBASICUPLOAD = 'PMaxConfigBasicUpload'
export const PROCESS_TYPE_PMAXCONFIGDOWNLOAD = 'PMaxConfigDownload'
export const PROCESS_TYPE_PMAXCONFIGUPLOAD = 'PMaxConfigUpload'
export const PROCESS_TYPE_PMAXFILMONDEMAND = 'PMaxFilmOnDemand'
export const PROCESS_TYPE_PMAXLOGLEGACY = 'PMaxLogLegacy'
export const PROCESS_TYPE_PMAXLOGSTANDARD = 'PMaxLogStandard'
export const PROCESS_TYPE_NEOLIVELOG = 'NeoLiveLog'
export const PROCESS_TYPE_PMAXPYTHONUPGRADE = 'PMaxPythonUpgrade'
export const PROCESS_TYPE_PMAXSETBYPASSZONE = 'PMaxSetByPassZone'
export const PROCESS_TYPE_PMAXSETCUSTOMZONES = 'PMaxSetCustomZones'
export const PROCESS_TYPE_PMAXSETSOAKZONE = 'PMaxSetSoakZone'
export const PROCESS_TYPE_PMAXSOFTWAREUPGRADE = 'PMaxSoftwareUpgrade'
export const PROCESS_TYPE_PMAXSTATEGET = 'PMaxStateGet'
export const PROCESS_TYPE_PMAXSTATESET = 'PMaxStateSet'
export const PROCESS_TYPE_PMAXZONEADD = 'PMaxZoneAdd'
export const PROCESS_TYPE_PMAXZONEREMOVE = 'PMaxZoneRemove'
export const PROCESS_TYPE_PMAXACTIVATESIREN = 'PMaxActivateSiren'
export const PROCESS_TYPE_PMAXZONERSSI = 'PMaxZoneRssi'
export const PROCESS_TYPE_REFRESH_GSM = 'GsmSignalLevel'
export const PROCESS_TYPE_PMAXZONEWALKTEST = 'PMaxZoneWalkTest'
export const PROCESS_TYPE_RESETPASSWORD = 'ResetPassword'
export const PROCESS_TYPE_REMOTEINSPECTION = 'RemoteInspection'
export const PROCESS_TYPE_SOFTWAREREPOSYNC = 'SoftwareRepoSync'
export const PROCESS_TYPE_DISABLEX10 = 'DisableX10'
export const PROCESS_TYPE_ENABLEX10 = 'EnableX10'
export const PROCESS_TYPE_DIMMERX10 = 'DimmerX10'
export const PROCESS_TYPE_BRIGHTERX10 = 'BrighterX10'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL0 = 'PMaxSetPrivateReportsEmail0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL1 = 'PMaxSetPrivateReportsEmail1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL2 = 'PMaxSetPrivateReportsEmail2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAIL3 = 'PMaxSetPrivateReportsEmail3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE0 = 'PMaxSetPrivateReportsPhone0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE1 = 'PMaxSetPrivateReportsPhone1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE2 = 'PMaxSetPrivateReportsPhone2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSPHONE3 = 'PMaxSetPrivateReportsPhone3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER0 = 'PMaxSetPrivateReportsEmailFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER1 = 'PMaxSetPrivateReportsEmailFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER2 = 'PMaxSetPrivateReportsEmailFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSEMAILFILTER3 = 'PMaxSetPrivateReportsEmailFilter3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER0 = 'PMaxSetPrivateReportsSmsFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER1 = 'PMaxSetPrivateReportsSmsFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER2 = 'PMaxSetPrivateReportsSmsFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSSMSFILTER3 = 'PMaxSetPrivateReportsSmsFilter3'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER0 = 'PMaxSetPrivateReportsMmsFilter0'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER1 = 'PMaxSetPrivateReportsMmsFilter1'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER2 = 'PMaxSetPrivateReportsMmsFilter2'
export const PROCESS_TYPE_PMAXSETPRIVATEREPORTSMMSFILTER3 = 'PMaxSetPrivateReportsMmsFilter3'
export const PROCESS_TYPE_REPORT = 'Report'
export const PROCESS_TYPE_PMAXSETVODMODE = 'PMaxSetVODMode'
export const PROCESS_TYPE_PMAXRESETRECEIVERIP = 'PMaxResetReceiverIp'
export const PROCESS_TYPE_PMAXASSIGNPARTITIONS = 'PMaxAssignPartitions'
export const PROCESS_TYPE_PMAXDISABLESIREN = 'PMaxDisableSiren'
export const PROCESS_TYPE_NEOACTIVATION = 'NeoActivation'
export const PROCESS_TYPE_FIRMWARE_UPGRADE = 'FirmwareUpgrade'
export const PROCESS_TYPE_NEO_UPGRADE = 'NeoUpgrade'
export const PROCESS_TYPE_SET_LABEL = 'SetLabel'
import withForm from 'containers/withForm'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import {removeRole} from 'modules/forms/handlers'

import head from 'lodash-es/head'
import {compose} from 'redux'
import CardForm from 'ui/CardForm'
import decorate from 'utils/decorate'
import {__, __n} from 'utils/i18n'

class RemoveRole extends Component {
    static propTypes = {
        roles: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                name: PropTypes.string,
                isRemovable: PropTypes.bool,
                relatedUsers: PropTypes.number,
                relatedRoles: PropTypes.number,
            }),
        ),
        onClose: PropTypes.func,
        handle: PropTypes.func,
    }

    static defaultProps = {
        roles: [],
    }

    unRemovableRoles() {
        return this.props.roles.filter(({isRemovable}) => !isRemovable)
    }

    render() {
        const unRemovableRoles = this.unRemovableRoles()
        const {onClose, handle, roles} = this.props

        switch (unRemovableRoles.length) {
            case 0:
                return (
                    <CardForm onClose={onClose}
                              header={__n('Remove role', 'Remove roles', roles.length)}
                              submitLabel={__('Remove')}
                              onSubmit={handle}
                    >
                        {__n(
                            'Do you really want to remove selected role?',
                            'Do you really want to remove %s selected roles?',
                            roles.length,
                        )}
                    </CardForm>
                )

            //eslint-disable-next-line no-case-declarations
            case 1:
                const role = head(unRemovableRoles)

                let decorator

                if (!role.removable) {
                    decorator = decorate(
                        __('Role %s cannot be deleted, because it is not allowed.'),
                        role.name,
                    )
                } else {
                    decorator = decorate(
                        __('Role %s cannot be deleted, because it has %s child users and %s child roles. Remove them before delete this user'),
                        role.name,
                        role.relatedUsers,
                        role.relatedRoles,
                    )
                }

                return (
                    <CardForm header={__n('Remove role', 'Remove roles', roles.length)}
                              showDismiss={true}
                              onClose={onClose}
                              editable={false}
                              onSubmit={() => ({})}>
                        {decorator}
                    </CardForm>
                )

            default:
                return (
                    <CardForm showDismiss={true}
                              header={__n('Remove role', 'Remove roles', roles.length)}
                              onClose={onClose}
                              onSubmit={() => ({})}
                              editable={false}>
                        {decorate(
                            __('Roles %s has related users or roles and cannot be removed. Remove related users and roles before.'),
                            unRemovableRoles.map(({name}) => name).join(', '),
                        )}
                    </CardForm>
                )
        }
    }
}

export default compose(
    withForm(removeRole),
    connect(
        ({roles: {store: {byIds}}}, {roleIds, handle}) => ({
            roles: Object.values(byIds).filter(({id}) => roleIds.indexOf(id) !== -1),
            handle: () => handle(roleIds),
        }),
    )
)(RemoveRole)


import nl2br from 'react-nl2br'
import React, {PureComponent} from 'react'

import {severityTitle} from 'constants/severityType'
import deviceType from 'constants/deviceType'

import WarningIcon from 'components/Devices/WarningIcon'

import DropDown, {ALIGN_TOP, DropDownContent} from 'ui/DropDown'
import AutohideList from 'ui/AutohideList'
import Duration from 'ui/Duration'
import {__, __n} from 'utils/i18n'
import Definition from 'ui/Definition'

const FaultRow = ({fault}) => {
    const type = fault.deviceType ? deviceType(fault.deviceType) : ''
    const zone = fault.zone ? ' #' + fault.zone : ''

    return (
        <div className="worryDefinition">
            <WarningIcon warning={fault} showTooltip={false}/>

            <strong className="worryDefinition-name">
                {severityTitle(fault)}
            </strong>

            <p className="worryDefinition-description">
                {fault.comment
                    ? nl2br(fault.comment)
                    : (type + zone)
                }
            </p>
        </div>
    )
}

export default class FaultsCell extends PureComponent {
    render() {
        const {faultsSince, faults} = this.props

        if (!faults || faults.length === 0) {
            return <span className="empty">{__('No troubles')}</span>
        }

        const icons = faults.map(fault => <WarningIcon key={fault.id} warning={fault}/>)

        const trigger = <AutohideList className="worries">{icons}</AutohideList>

        const duration = faultsSince
            ? <Duration from={faultsSince} message={__('%s in fault state')}/>
            : null

        const dropDownTitle = (
            <Definition
                multiLine
                title={__n('Active fault', '%d active faults', faults.length)}
                detail={duration}
            />
        )

        return (
            <DropDown trigger={trigger} align={ALIGN_TOP}>
                <DropDownContent className="severity-list" title={dropDownTitle}>
                    {faults.map(fault => <FaultRow key={fault.id} fault={fault}/>)}
                </DropDownContent>
            </DropDown>
        )
    }
}
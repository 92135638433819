import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import withPerPage from './withPerPage'
import {fetch, reset, setStart/*,  startPoll, stopPoll */} from 'modules/basicConfiguration/push/actions'
import {selectUnitListForPushBasicConfigurationRows} from 'modules/basicConfiguration/push/selectors'

const mapStateToProps = () =>
    state => {
        const {
            isLoading,
            error,
            total,
            start,
        } = state.basicConfiguration.push

        return {
            rows: selectUnitListForPushBasicConfigurationRows(state),
            isLoading,
            error,
            total,
            start,
        }
    }

const mapDispatchToProps = (dispatch, {basicConfigId}) =>
    bindActionCreators({
        fetch: () => fetch(basicConfigId),
        reset,
        onPageChange: setStart,
    }, dispatch)

export default function withPushBasicConfigurations() {
    return compose(
        withPerPage('basicConfiguration'),
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
    )
}
import {compose} from 'redux'

import {edit} from 'permissions/panel/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withPanel from 'containers/withPanel'

import IconEdit from 'icons/edit.svg'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: edit}),
    withVisibility(),
    withPanel(),
    withProps(({panel, showEditPanelInfo}) => ({
        onClick: showEditPanelInfo,
        label: __('Edit'),
        Icon: IconEdit,
    })),
)(Button)
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'
import generateFilterString from 'api/base/generateFilterString'
import parseDate, {formatDate} from 'api/base/parseDate'
import {SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE} from 'constants/severityType'
import {TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE} from 'constants/troubleType'
import mapValidationErrors from './base/mapValidationErrors'
import {mapProcessBatch} from 'api/processes'
import dataMapper from 'utils/dataMapper'
import {get, post} from 'api/http'

import {
    DEVICE_TYPE_CONTROL_PANEL,
    DEVICE_TYPE_WIRED_KEYPAD,
    DEVICE_TYPE_ZONE_EXPANDER,
    DEVICE_TYPE_OUTPUT_EXPANDER,
    DEVICE_TYPE_POWER_SUPPLY,
    DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
    DEVICE_TYPE_PGH,
    DEVICE_TYPE_COMMUNICATOR,
    DEVICE_TYPE_AUDIO_VERIFICATION,
    DEVICE_TYPE_POWER_LINK,
} from 'constants/deviceType'
import {getVendor, isNeoSerial} from 'utils/panelType'

const mapFault = dataMapper((fault) => {
    return {
        id: parseInt(fault.utf_id),
        type: fault.iow_rest_name,
        severity: fault.iow_type && fault.iow_type.toUpperCase(),
        deviceType: fault.device_type,
        zone: fault.utz_zone_id ? parseInt(fault.utz_zone_id) : null,
        comment: fault.utf_comment,
        inMemory: fault.in_memory,
        isSuspended: fault.suspended,
        isMarkForService: fault.iow_rest_name === TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE,
    }
})

const warningsOrder = [SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE]

export const sortFaults = faults => faults.sort(
    (f1, f2) => {
        if (f1.isSuspended !== f2.isSuspended) {
            return f1.isSuspended - f2.isSuspended
        }

        const p1 = warningsOrder.indexOf(f1.severity)
        const p2 = warningsOrder.indexOf(f1.severity)

        if (p1 !== p2) {
            return p1 - p2
        }

        if (f1.name !== f2.name) {
            return f1.name > f2.name ? 1 : -1
        }

        return 0
    },
)

const mapFaults = (faults) => sortFaults(faults.map(mapFault))

export const mapPanel = dataMapper((data) => ({
    id: parseInt(data.unt_id),
    account: data.unt_account,
    webname: data.unt_name,
    model: data.unt_model,
    family: data.family,
    configModel: data.cgm_name,
    serial: data.unt_serial,
    isNeo: isNeoSerial(data.unt_serial),
    vendor: getVendor({model: data.unt_model, serial: data.unt_serial}),
    configuratorApp: data.unt_installer_remote_access === 'allow',
    userApp: data.unt_remote_access === 'allow',
    alerts: parseInt(data._unt_alerts),
    alarms: parseInt(data._unt_alarms),
    modules: data.modules,
    group: data._utg_name,
    groupId: parseInt(data.utg_id),
    basicConfigName: data.cgm_name,
    hasTroubles: !!data.faults && data.faults.length > 0,
    removing: false,                        // todo mark from database!!!
    rri: data.uri_last_result,
    rriDate: parseDate(data.urir_created_timestamp),
    user: data.usr_name,
    userId: data.usr_id ? parseInt(data.usr_id) : null,
    faults: data.faults ? mapFaults(data.faults) : [],
    faultsSince: parseDate(data.ufs_created_timestamp),
    isActivated: data.unt_activated === '1',
    isDiscoveryInProgress: data.unt_activated === null,
}))

const keysMap = {
    id: 'unt_id',
    account: 'unt_account',
    webname: 'unt_name',
    model: 'unt_model',
    configModel: 'cgm_name',
    serial: 'unt_serial',
    configuratorApp: 'unt_installer_remote_access',
    userApp: 'unt_remote_access',
    alerts: '_unt_alerts',
    alarms: '_unt_alarms',
    keepAlive: '_unt_gprs_ka_enabled',
    broadband: '_unt_module_bb',
    gprs: '_unt_module_gprs',
    group: '_utg_name',
    fault: 'iow_rest_name',
    user: 'usr_name',
    userId: 'usr_id',
    hasTroubles: 'has_troubles',
    isActivated: 'unt_activated',
    basicConfigName: 'cgm_name',
    [DEVICE_TYPE_CONTROL_PANEL]: `utz_firmware_version_${DEVICE_TYPE_CONTROL_PANEL}`,
    [DEVICE_TYPE_POWER_LINK]: `utz_firmware_version_${DEVICE_TYPE_POWER_LINK}`,
    [DEVICE_TYPE_WIRED_KEYPAD]: `utz_firmware_version_${DEVICE_TYPE_WIRED_KEYPAD}`,
    [DEVICE_TYPE_ZONE_EXPANDER]: `utz_firmware_version_${DEVICE_TYPE_ZONE_EXPANDER}`,
    [DEVICE_TYPE_OUTPUT_EXPANDER]: `utz_firmware_version_${DEVICE_TYPE_OUTPUT_EXPANDER}`,
    [DEVICE_TYPE_POWER_SUPPLY]: `utz_firmware_version_${DEVICE_TYPE_POWER_SUPPLY}`,
    [DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS]: `utz_firmware_version_${DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS}`,
    [DEVICE_TYPE_PGH]: `utz_firmware_version_${DEVICE_TYPE_PGH}`,
    [DEVICE_TYPE_COMMUNICATOR]: `utz_firmware_version_${DEVICE_TYPE_COMMUNICATOR}`,
    [DEVICE_TYPE_AUDIO_VERIFICATION]: `utz_firmware_version_${DEVICE_TYPE_AUDIO_VERIFICATION}`,
}

export function fetch({start = 0, perPage: count, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/units_all/getallunits', {start, count, filter, query})
        .then(createRowsResponseParser(mapPanel))
}

export function processUserApp(ids, state = true) {
    return processApp('/units_all/guirestriction', ids, state)
}

export function processConfiguratorApp(ids, state = true) {
    return processApp('/units_all/installerapprestriction', ids, state)
}

const processApp = (method, ids, state) => {
    return post(method, {
        form: {
            data: ids.map((id) => ({unitId: id, state})),
        },
    })
}

export function remove(ids) {
    return post('units_all/remove', {form: {unitId: ids}})
        .then(({processes}) => mapProcessBatch({processes}))
}

export function markForService(unitIds, comment, userId = null) {
    return post('units_all/markforservice', {
        form: {
            unitId: unitIds,
            userId: userId,
            comment: comment,
        },
    }).catch(mapValidationErrors)
}

export function reassign(unitIds, comment, userId = null) {
    return post('units_faulty/reassign', {
        form: {
            unitId: unitIds,
            userId: userId,
            comment: comment,
        },
    }).catch(mapValidationErrors)
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('units_all/suggestunits', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function panelMaintainers(groupIds) {
    return get('/units_faulty/getusersforassign', {
        'utg_id[]': groupIds,
    }).then(data => data.map(
        dataMapper(data => ({
            id: parseInt(data.usr_id),
            name: data.usr_name,
            isEnabled: data.usr_status === 'enabled',
        })),
    ))
}

export function changeGroup(unitIds, groupId) {
    return post('/units_all/changegroup', {
        form: {
            unitId: unitIds,
            groupId: groupId,
        },
    }).catch(mapValidationErrors)
}

export function refreshState(ids) {
    return post('/units_faulty/refresh', {unt_id: ids}).then(mapProcessBatch)
}

export function resolveFaults(faultIds) {
    return post('/units_faulty/resolve', {form: {faults: faultIds}})
        .catch(mapValidationErrors)
}

export function suspendFaults(faultIds, date) {
    return post('/units_faulty/suspend', {faults: faultIds, date: formatDate(date)})
        .catch(mapValidationErrors)
}

export function resumeFaults(faultIds) {
    return post('/units_faulty/resume', {faults: faultIds})
        .catch(mapValidationErrors)
}

export function pushBasicConfiguration(panelIds, basicConfigId) {
    return post('pmaxconfigbasic/upload', {unt_id: panelIds, pca_id: basicConfigId})
        .then(result => mapProcessBatch({processes: result}))
        .catch(mapValidationErrors)
}

export function fetchUpgradable({start = 0, perPage: count, applianceId: utd_id, upgradePackageId: uep_id, filters}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/units_all/getupgradableunits', {start, count, utd_id, uep_id, filter})
        .then(createRowsResponseParser(mapPanel))
}

export function suggestUpgradable(fields, start = 0, count = 5, {applianceId: utd_id, upgradePackageId: uep_id}) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('units_all/suggestupgradableunits', {utd_id, uep_id, suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function fetchConfigCompatible({basicConfigId: pca_id, start = 0, perPage: count = 10, filters}) {
    filters = generateFilterString(filters, keysMap)

    return get('units_all/getconfigcompatibleunits', {
        pca_id,
        start,
        count,
        filter: filters,
        sort: '-unt_id',
    }).then(createRowsResponseParser(mapPanel))
}

export function suggestConfigCompatible(fields, start = 0, count = 5, {basicConfigId: pca_id}) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('units_all/suggestconfigcompatibleunits', {pca_id, suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

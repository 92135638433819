import withProps from 'containers/withProps'

import BasicConfigurationGridBar from './BasicConfigurationGridBar'
import BasicConfigurationContent from './BasicConfigurationContent'

import Page from 'ui/Page'

export default withProps({
    Bar: BasicConfigurationGridBar,
    Content: BasicConfigurationContent,
})(Page)
import {compose} from 'redux'

import withForm from 'containers/withForm'
import withProps from 'containers/withProps'
import {addGroup} from 'modules/forms/handlers'

import GroupForm from './GroupForm'
import {__} from 'utils/i18n'

const defaultValues = {
    name: null,
    description: null,
    GPRSKeepAlive: true,
    GPRSKeepAlivePeriod: 120,
    GPRSOfflineTimer: 240,
    BBAEncryption: true,
    BBAKeepAlivePeriod: 5,
    BBAOfflineTimer: 60,
    localWakeUp: true,
    serverMessagingLanguage: null,
    serverMessagingLanguageId: null,
    timeSynchronization: false,
    upgradeMethod: 'gprs',
    FibroKeepAlivePeriod: 135,
    FibroBBAKeepAlive: true,
    FibroBBAOfflineTimer: 405,
    FibroGPRSKeepAlive: true,
    FibroGPRSOfflineTimer: 405,

    Fibro53BBAKeepAlive: true,
    Fibro53BBAOfflinePeriod: 135,
    Fibro53BBAOfflineTimer: 405,
    Fibro53GPRSKeepAlive: true,
    Fibro53GPRSOfflinePeriod: 135,
    Fibro53GPRSOfflineTimer: 405,
}

export default compose(
    withForm(addGroup),
    withProps(() => ({
        data: defaultValues,
        header: __('Add group'),
    }))
)(GroupForm)
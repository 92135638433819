import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import {withPermission, withRejection} from 'containers/withPermission'
import withDevices from 'containers/withDevices'

import page from 'permissions/panel/devices/page'

import Layout, {ScrollView} from 'ui/Layout'

import DevicesContent from './DevicesContent'
import DeviceSidebar from './Sidebar/DeviceSidebar'

import DefaultDevicesBar from './Bars/DefaultDevicesBar'
import WalktestDevicesBar from './Bars/WalktestDevicesBar'

export class Devices extends Component {
    static propTypes = {
        refreshRssi: PropTypes.func.isRequired,
        showAddDevice: PropTypes.func.isRequired,
        toggleWalktest: PropTypes.func,
        fetchWalktest: PropTypes.func,
        startWalktest: PropTypes.func,
        stopWalktest: PropTypes.func,
        showWalktest: PropTypes.bool,
        panel: PropTypes.object,
        configuration: PropTypes.object,
        changes: PropTypes.object,
        devices: PropTypes.object,
    }

    state = {}

    componentWillReceiveProps({byId}) {
        const {selectedDevice} = this.state

        if (!byId || !byId[selectedDevice] || byId[selectedDevice].removing) {
            this.handleCloseSidebar()
        }
    }

    handleSelect = (device, startTab = {}) => {
        if (!device || device.enrolling || device.removing) {
            return this.setState({selected: null})
        }
        // there is no way to see Control Panel on sidebar so far
        if (device.deviceType === DEVICE_TYPE_CONTROL_PANEL) {
            return this.setState({selected: null})
        }

        const {startWithVideoOnDemandTab, startWithMeteoTab} = startTab

        return this.setState({
            selectedDevice: device.id,
            startWithVideoOnDemandTab,
            startWithMeteoTab,
        })
    }

    handleCloseSidebar = () => {
        this.setState({
            selectedDevice: null,
            startWithVideoOnDemandTab: false,
        })
    }

    handleTroublesCheckboxChange = e => this.setState({filterTroubles: e.target.checked})

    render() {
        const {showWalktest, panel} = this.props
        const {
            selectedDevice,
            filterTroubles,
            startWithVideoOnDemandTab,
            startWithMeteoTab,
        } = this.state

        return (
            <Layout>
                <Layout vertical className="devices">
                    {showWalktest
                     ? <WalktestDevicesBar/>
                     : <DefaultDevicesBar onToggle={this.handleTroublesCheckboxChange}/>}

                    <ScrollView className="devices-content">
                        <DevicesContent
                            {...{
                                filterTroubles,
                                showWalktest,
                                selectedDevice,
                                onSelect: this.handleSelect,
                            }}
                        />
                    </ScrollView>
                </Layout>

                {selectedDevice && !showWalktest &&
                <DeviceSidebar
                    key={selectedDevice}
                    deviceId={selectedDevice}
                    panelId={panel.id}
                    isNeo={panel.isNeo}
                    onSelect={this.handleSelect}
                    onClose={this.handleCloseSidebar}
                    startWithVideoOnDemandTab={startWithVideoOnDemandTab}
                    startWithMeteoTab={startWithMeteoTab}
                />}
            </Layout>
        )
    }

}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withDevices(),
)(Devices)

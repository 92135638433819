import {RestoreConfigurationButton} from 'pages/Panel/Configuration/View/Header/RestoreConfigurationButton'
import React, {PureComponent, Fragment} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ui/Definition'
import Bar, {BarSpace} from 'ui/Bar'
import LinkButton from 'ui/LinkButton'
import IconBack from 'icons/go-back.svg'
import humanTime from 'utils/humanTime'
import path from 'utils/path'
import {__} from 'utils/i18n'

import {MakeBasicConfigurationButton} from './MakeBasicConfigurationButton'

export default class ConfigurationHeader extends PureComponent {

    static propTypes = {
        sections: PropTypes.array.isRequired,
        configuration: PropTypes.object.isRequired,
        isCompareWithBackup: PropTypes.bool,
        panelId: PropTypes.number.isRequired,
    }

    renderTableHeader = () => {
        const {isCompareWithBackup, sections} = this.props

        if (isCompareWithBackup && sections && sections.length) {
            return (
                <div className="table-header">
                    <div className="table-header-cell"/>
                    <div className="table-header-cell">{__('Current')}</div>
                    <div className="table-header-cell">{__('Saved')}</div>
                </div>
            )
        }

        return null
    }

    render() {
        const {makeBasic, isCompareWithBackup, panelId, backup, configuration} = this.props
        const {date, time} = configuration.timestamp ? humanTime(configuration.timestamp) : {}

        return (
            <Fragment>
                <Bar>
                    <LinkButton
                        Icon={IconBack}
                        to={path('panel.configuration', {id: panelId})}
                    />

                    <Definition title={date} detail={time}/>

                    <BarSpace/>

                    {!isCompareWithBackup && !makeBasic && (
                        <MakeBasicConfigurationButton panelId={panelId}/>
                    )}

                    {isCompareWithBackup && (
                        <RestoreConfigurationButton panelId={panelId} backup={backup}/>
                    )}
                </Bar>

                {this.renderTableHeader()}
            </Fragment>
        )
    }
}
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import timezones from 'utils/timezones'
import calendrical from 'calendrical'
import classes from 'classnames'

import IconArrowPrev from 'icons/arrow-prev.svg'
import IconArrowNext from 'icons/arrow-next.svg'

import {getValidMomentDate} from 'utils/dateParser'
import momentPropType from 'utils/momentPropType'

export default class DatePicker extends PureComponent {

    static propTypes = {
        onChange: PropTypes.func,
        range: PropTypes.bool,
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
            momentPropType,
        ]),
        from: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
            momentPropType,
        ]),
        to: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
            momentPropType,
        ]),
    }

    state = {
        current: timezones.server(),
    }

    constructor(props) {
        super()

        var current, value, from, to

        from = props.from ? getValidMomentDate(props.from) : undefined
        to = props.to ? getValidMomentDate(props.to) : undefined
        value = props.value ? getValidMomentDate(props.value) : undefined

        if (props.range) {
            current = getValidMomentDate(props.to ? props.to : props.from)
        } else {
            current = getValidMomentDate(props.value)
        }

        this.state = {current, value, from, to}
    }

    componentWillReceiveProps(props) {
        let {value, from, to} = this.props
        const state = {}

        if (from != props.from) {
            state.from = props.from ? getValidMomentDate(props.from) : undefined
        }

        if (to != props.to) {
            state.to = props.to ? getValidMomentDate(props.to) : undefined
        }

        if (value != props.value) {
            state.value = props.value ? getValidMomentDate(props.value) : undefined

            if (state.value && state.value.isValid()) {
                state.current = state.value
            }
        }

        if (Object.keys(state).length) {
            this.setState(state)
        }
    }

    isSelected(date) {
        const {value, from, to} = this.state

        if (!this.props.range) {
            return value && value.isSame(date, 'day')
        }

        if (!to) {
            return from && date.isSame(from, 'day')
        }

        return date.isBetween(from, to, null, '[]')
    }

    isDisabled(date) {
        const {min, max} = this.props

        if (min && date.isBefore(min)) {
            return true
        }

        if (max && date.isAfter(max)) {
            return true
        }

        return false
    }

    select(date) {
        if (this.isDisabled(date)) {
            return
        }

        if (this.props.range) {
            this.setState(({from, to}) => {
                if (to || !from) {
                    from = date
                    to = null
                } else {
                    to = moment.max(from, date)
                    from = moment.min(from, date)
                }

                this.props.onChange && this.props.onChange(from, to)

                return {from, to}
            })
        } else {
            this.setState({value: date})
            this.props.onChange && this.props.onChange(date)
        }
    }

    move(direction, step) {
        this.setState(({current}) => {
            current = current.clone().add(direction, step)

            if (this.props.min) {
                current = moment.max(current, this.props.min)
            }

            if (this.props.max) {
                current = moment.min(current, this.props.max)
            }

            return {current}
        })
    }

    prev = () => this.move(-1, 'month')

    next = () => this.move(1, 'month')

    prevYear = () => this.move(-1, 'year')

    nextYear = () => this.move(1, 'year')

    renderDay = ({date, inCurrentMonth, dayOfMonth}, key) => {
        var m = timezones.server(date)

        const className = classes('datepicker-day', {
            'datepicker--disabled': this.isDisabled(m),
            'datepicker-day--selected': this.isSelected(m),
            'datepicker-day--current': m.isSame(Date.now(), 'day'),
            'datepicker-day--outside': !inCurrentMonth,
        })

        return (
            <div key={key}
                 className={className}
                 onClick={() => this.select(m)}>
                {dayOfMonth}
            </div>
        )
    }

    renderWeek() {
        return calendrical
            .getWeeksInMonth(this.state.current.toDate(), {
                weekStartsWith: timezones.server().startOf('week').isoWeekday(),
            })
            .map((week, index) =>
                <div key={index} className="datepicker-week">
                    {week.map(this.renderDay)}
                </div>,
            )
    }

    renderWeekDays() {
        return (
            <div className="datepicker-week datepicker-week--dayNames">
                {new Array(7).fill(0).map((_, key) =>
                    <span className="datepicker-day" key={key}>
                        {timezones.server().weekday(key).format('dd')}
                    </span>,
                )}
            </div>
        )
    }

    renderYearNav() {
        const {min, max} = this.props
        const {current} = this.state
        const prevYear = timezones.server(current).subtract(1, 'year')
        const nextYear = timezones.server(current).add(1, 'year')

        const prevEnabled = !min || prevYear.isSameOrAfter(min, 'year')
        const nextEnabled = !max || nextYear.isSameOrBefore(max, 'year')

        return (
            <div className="datepicker-years">
                <a className={classes('datepicker-year', {
                    'datepicker--disabled': !prevEnabled,
                })}
                   onClick={this.prevYear}>
                    {prevYear.format('YYYY')}
                </a>

                <span className="datepicker-year datepicker-year--current">
                    {current.format('YYYY')}
                </span>

                <a className={classes('datepicker-year', {
                    'datepicker--disabled': !nextEnabled,
                })}
                   onClick={this.nextYear}>
                    {nextYear.format('YYYY')}
                </a>
            </div>
        )
    }

    renderMonthNav() {
        const {min, max} = this.props
        const {current} = this.state

        const prevMonth = timezones.server(current).startOf('month').subtract(1, 'day')
        const nextMonth = timezones.server(current).add(1, 'month').startOf('month')

        const prevEnabled = !min || prevMonth.isSameOrAfter(min, 'month')
        const nextEnabled = !max || nextMonth.isSameOrBefore(max, 'month')

        return (
            <div className="datepicker-nav">
                <a className={classes('datepicker-nav-item', {
                    'datepicker--disabled': !prevEnabled,
                })}
                   onClick={this.prev}>
                    <IconArrowPrev/>
                </a>

                <span className="datepicker-nav-title">
                    {current.format('MMMM')}
                </span>

                <a className={classes('datepicker-nav-item', {
                    'datepicker--disabled': !nextEnabled,
                })}
                   onClick={this.next}>
                    <IconArrowNext/>
                </a>
            </div>
        )
    }

    render() {
        return (
            <div className={classes('datepicker', this.props.className)}>
                {this.renderMonthNav()}

                <div className="datepicker-days">
                    {this.renderWeekDays()}
                    {this.renderWeek()}
                </div>

                {this.renderYearNav()}
            </div>
        )
    }

}
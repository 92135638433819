import React, {Component} from 'react'
import {__} from 'utils/i18n'
import {Tab} from 'ui/Tabs'

import configuration from 'permissions/panel/configuration/page'

import withCurrentConfiguration, {
    withConfigurationActions,
    withConfigurationPreloader,
} from 'containers/withConfiguration'
import {withPermission, withRejection} from 'containers/withPermission'
import {compose} from 'redux'
import DeviceConfiguration from 'components/Configuration/DeviceConfiguration'
import withLoader from 'containers/withLoader'

const DeviceConfigurationConnected = compose(
    withCurrentConfiguration(),
    withConfigurationActions(),
    withLoader(({configuration, fetch}) => !configuration && fetch()),
    withConfigurationPreloader({primary: true}),
)(DeviceConfiguration)

class ConfigurationTab extends Component {
    render() {
        return (
            <Tab name={__('Configuration')}>
                <DeviceConfigurationConnected {...this.props}/>
            </Tab>
        )
    }
}

export default compose(
    withPermission({isAllowed: configuration}),
    withRejection(),
)(ConfigurationTab)
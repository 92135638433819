import React from 'react'
import {compose} from 'redux'

import list from 'permissions/panel/remoteInspections/list'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'
import withLifeCycle from 'containers/withLifeCycle'
import {withPermission, withRejection} from 'containers/withPermission'

import ResultsCell from 'components/Cell/RemoteInspections/ResultsCell'
import CreatedCell from 'components/Cell/RemoteInspections/CreatedCell'
import ResultIconCell from 'components/Cell/RemoteInspections/ResultIconCell'

import TableGrouped from 'ui/Table/Grouped'

import {humanDate} from 'utils/humanTime'
import {__} from 'utils/i18n'
import ActionsCell from './ActionsCell'

const columns = [
    {
        width: 50,
        fixed: true,
        render: ({fails}) => (
            <ResultIconCell {...{isSucceeded: fails === 0}}/>
        ),
    },
    {
        render: ({result, state, created}) => (
            <div>
                <CreatedCell {...{created}} />
                <ResultsCell {...{results: result, result: 'success', progress: 100}} />
            </div>
        ),
    },
    {
        width: 80,
        fixed: true,
        render: (result) => (
            <ActionsCell result={result} panelId={result.panelId} progress={null}/>
        ),
    },
]

export default compose(
    withPermission({isAllowed: list}),
    withRejection(),
    withPanelRemoteInspection(),
    withLoader(({fetch}) => fetch()),
    withLifeCycle({
        onMount: ({startPoll}) => startPoll(),
        onUnmount: ({stopPoll}) => stopPoll(),
    }),
    withProps({
        separatorCallback: (row) => humanDate(row.created),
        columns,
        emptyMessage: () => __('No Remote Inspections'),
    }),
)(TableGrouped)
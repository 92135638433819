import {compose} from 'redux'

import {list} from 'permissions/firmware/upgradeStatus'

import withProps from 'containers/withProps'
import {withPermission, withRejection} from 'containers/withPermission'

import UpgradeStatusSearch from './UpgradeStatusSearch'
import UpgradeStatusTable from './UpgradeStatusTable'
import UpgradeStatusBar from './UpgradeStatusBar'

import Page from 'ui/Page'

export default compose(
    withPermission({isAllowed: list}),
    withRejection(),
    withProps({
        Top: UpgradeStatusSearch,
        Bar: UpgradeStatusBar,
        Content: UpgradeStatusTable,
    })
)(Page)

import pathToRegexp from 'path-to-regexp'

const cache = {}

export class Router {

    paths = {}

    get(name, ...args) {
        const path = this.paths[name]

        if (!path) {
            throw `Path ${name} not found`
        }

        if (!args.length) {
            return path
        }

        if (!cache.hasOwnProperty(path)) {
            cache[path] = pathToRegexp.compile(path)
        }

        return cache[path](...args)
    }

    add(name, path = name, routerCallback) {
        if (this.paths[name]) {
            throw new Error `Route ${name} already defined`
        }

        if (path[0] !== '/') {
            path = '/' + path
        }

        this.paths[name] = path

        if (routerCallback) {
            const subRouter = {
                add: (subname, subpath = subname, routerCallback) => {
                    if (subpath[0] !== '/') {
                        subpath = '/' + subpath
                    }

                    this.add(name + '.' + subname, path + subpath, routerCallback)
                    return subRouter
                },
            }

            routerCallback(subRouter)
        }

        return this
    }

}

const paths = new Router()

paths
    .add('/')
    .add('panels', '/panels/:scope?')
    .add('remoteInspections', '/remote-inspections/:scope?')
    .add('events', '/events/:scope([\\d\\w]{8})?/:serial?/:id(\\d+)?')
    .add('reports', '/reports/:scope?')
    .add('panel', '/panel/:id', router => {
        router
            .add('devices', '/')
            .add('info')
            .add('state')
            .add('configuration', 'configuration', router => {
                router
                    .add('list', '')
                    .add('current')
                    .add('view', 'view/:configId')
                    .add('compare', 'compare/:configId')
            })
            .add('locations')
            .add('processes')
            .add('reports')
            .add('log')
            .add('firmware')
            .add('remoteInspections')
            .add('keypad')
            .add('events')
    })
    .add('firmware', '/firmware', router => {
        router
            .add('upgrade', '/upgrade/:applianceId(\\d+)/:upgradePackageId(\\d+)')
            .add('select')
            .add('upgradeStatus')
    })
    .add('system', '/system', router => {
        router
            .add('processes')
            .add('users')
            .add('roles')
            .add('actionLog')
            .add('centralStations')
            .add('basicConfigurations')
            .add('basicConfiguration', '/basicConfiguration/:id', router => {
                router
                    .add('edit', '/')
                    .add('push')
            })
            .add('groups')
            .add('group', '/group/:id', router => {
                router
                    .add('general', '/')
                    .add('stations')
            })
            .add('installers')
            .add('interactiveUsers')
            .add('dashboard')
    })

export default function path(name, ...args) {
    return paths.get(name, ...args)
}

export function check(name, pathname) {
    return !!pathToRegexp(path(name)).exec(pathname)
}
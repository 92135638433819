import createListActions from 'modules/higherOrder/createListActions'

export const {
    init,
    reset,
    fetch,
    receive,
    setStart,
    setQuery,
    setFilters,
    addFilters,
    remove,
    revertRemove,
    removeFilters,
    clearFilters,
} = createListActions('GROUPS/LIST')
import __ from 'utils/i18n'
import {warn} from 'utils/log'

export const MODULE_TYPE_GPRS = 'gprs'
export const MODULE_TYPE_BBA = 'bba'

export const MODULE_STATE_ONLINE = 'online'
export const MODULE_STATE_OFFLINE = 'offline'
export const MODULE_STATE_DISABLED = 'disabled'

export default function moduleTitle(module) {
    if (!module) {
        return __('Not Installed')
    }

    if (module.connected) {
        return __('Connected')
    }

    if (module.hasOwnProperty('installed') && !module.installed) {
        return __('Not Installed')
    }

    switch (module.state) {
        case MODULE_STATE_ONLINE:
            return __('Online')

        case MODULE_STATE_OFFLINE:
            return __('Offline')

        case MODULE_STATE_DISABLED:
            return __('Disabled')

        case null:
            return __('Installed')
    }

    warn(`Unknown module state ${module.state}`)
    return module.state || __('Unknown')
}
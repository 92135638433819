import {createAction} from 'redux-actions'

export const observe = createAction('PROCESSES/MANAGER/OBSERVE',
    processes => Array.isArray(processes) ? processes : [processes],
    (_, batchId) => ({batchId}),
)

export const removeBatch = createAction('PROCESSES/MANAGER/REMOVE_BATCH', (batchId: number) => ({batchId}))

export const removeFinishedBatches = createAction('PROCESSES/MANAGER/REMOVE_FINISHED_BATCHES', () => null)

export const receive = createAction('PROCESSES/MANAGER/RECEIVE',
    processes => Array.isArray(processes) ? processes : [processes]
)

export const restore = createAction('PROCESSES/MANAGER/RESTORE')
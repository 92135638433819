import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Bar from 'ui/Bar'
import StateButton from 'components/Button/State'

export default class PartitionStateButtons extends Component {

    static propTypes = {
        state: PropTypes.shape({
            name: PropTypes.string,
            stateSet: PropTypes.objectOf(
                PropTypes.shape({
                    name: PropTypes.string,
                    visible: PropTypes.bool,
                }),
            ).isRequired,
        }).isRequired,

        partition: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string,
        }).isRequired,

        sendState: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    }

    isActive({name}) {
        const {state} = this.props
        return state ? state.name === name : false
    }

    handleSendState = (...args) => {
        const {sendState, partition} = this.props
        sendState(partition.id, ...args)
    }

    isTransitionAllowed({name}) {
        const {transitions} = this.props.state
        return transitions && transitions.includes(name)
    }

    render() {
        const {state, disabled} = this.props

        if (!state.stateSet) {
            return null
        }

        return (
            <Bar>
                {Object.values(state.stateSet).filter(({visible}) => visible).map(state => (
                    <StateButton
                        key={state.name}
                        name={state.name}
                        modifiers={state.modifiers}
                        active={this.isActive(state)}
                        disabled={disabled || !this.isTransitionAllowed(state)}
                        sendStateAction={this.handleSendState}
                    />
                ))}
            </Bar>
        )
    }
}

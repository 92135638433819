import React, {Component} from 'react'
import classes from 'classnames'

import {__} from 'utils/i18n'

export default class PanelStatusLine extends Component {

    state = {index: 0}

    constructor(props, context) {
        super(props, context)

        if (props.lines && props.lines.length > 0) {
            this.state.line = props.lines[0]
        }
    }

    componentWillMount() {
        const {lines} = this.props

        if (lines && lines.length > 0) {
            this.run()
        }
    }

    componentWillUnmount() {
        this.stop()
    }

    componentWillReceiveProps({lines}, nextContext) {
        if (lines && lines.length > 0) {
            if (!this.state.line) {
                this.setState({line: lines[0]})
            }

            this.run()
        } else {
            this.stop()
        }
    }

    run() {
        if (!this.iId) {
            this.iId = setInterval(this.nextLine, 1000)
        }
    }

    stop() {
        if (this.iId) {
            clearInterval(this.iId)
            this.iId = null
        }
    }

    nextLine = () => {
        const {lines} = this.props

        this.setState(({line, index}) => {
            index = (index + 1) % lines.length
            line = lines[index] || line
            return {line, index}
        })
    }

    render() {
        const {lines, hasTroubles} = this.props

        if (!lines || !lines.some(line => !!line)) {
            return null
        }

        return (
            <div className={classes('panelStatusLine', {'panelStatusLine--hasTroubles': hasTroubles})}>
                {this.state.line}
                <div className="panelStatusLine-message">{__('This information can be outdated!')}</div>
            </div>
        )
    }

}
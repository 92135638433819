import {compose} from 'redux'
import {parse} from 'query-string'

import withLoader from 'containers/withLoader'
import withLifeCycle from 'containers/withLifeCycle'
import withProps from 'containers/withProps'

export function withSearchScope(getFilters: ? (params: Object, query: Object) => Object[]) {
    const dispatchInit = ({init, scope, match, history}) => {
        const filters = getFilters
            ? getFilters(match.params, parse(history.location.search))
            : []

        init(scope, history.action, filters)
    }

    return compose(
        withProps(({match}) => ({
            scope: match && match.params && match.params.scope,
        })),

        withLifeCycle({
            onMount: dispatchInit,

            onReceiveProps(props, {scope}) {
                if (props.scope !== scope) {
                    dispatchInit(props)
                }
            },
        }),
    )
}

export default function withSearchScopeLoader(getFilters: ? (params: Object, query: Object) => Object[]) {
    return compose(
        withSearchScope(...arguments),
        withLoader(),
    )
}
import parseDate from 'api/base/parseDate'
import {post} from 'api/http'
import {mapProcess, mapProcessBatch} from 'api/processes'
import {get} from 'api/http'
import mapValidationErrors from 'api/base/mapValidationErrors'
import purgePasswords from 'utils/configuration/purgePasswords'
import dataMapper from 'utils/dataMapper'

const mapConfigurationRow = dataMapper(
    ({id, date, backup, current, verified_at: verified}) => ({
        id: parseInt(id),
        date: parseDate(date),
        verified: parseDate(verified),
        backup: !!backup,
        current: !!current,
    }),
)

export function one(unitId, id) {
    return get('pmaxconfig/one', {unt_id: unitId, id}).then(config => {
        purgePasswords(config)
        return config
    })
}

export function list(panelId) {
    return get('pmaxconfig/list', {unt_id: panelId}).then(
        ({rows, current, process}) => ({
            process: process ? mapProcess(process) : null,
            rows: rows.map(mapConfigurationRow),
        }),
    )
}

export function refresh(panelId) {
    return post('pmaxconfig/refresh', {unt_id: panelId}).then(mapProcess)
}

export function refreshAll(panelIds) {
    return post('pmaxconfig/refreshall', {unt_id: panelIds}).then(mapProcessBatch)
}

export function backup(panelId, id) {
    return post('pmaxconfig/backup', {unt_id: panelId, id})
}

export function upload(panelId, changes, version) {
    const diff = Object.entries(changes)
    return post('pmaxconfig/upload', {unt_id: panelId, diff, version}).then(mapProcess)
}

export function makeBasic(panelId, name, version, values) {
    const diff = Object.entries(values)
    return post('pmaxconfig/addbasic', {unt_id: panelId, diff, version, name})
        .catch(mapValidationErrors)
}
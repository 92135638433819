import ProcessStatus from 'components/Processes/ProcessStatus'
import React from 'react'
import {compose} from 'redux'

import {stop} from 'permissions/processes/actions'

import withProps from 'containers/withProps'
import Table from 'ui/Table'
import Duration from 'ui/Duration'
import ProcessStopButton from 'components/Processes/ProcessStopButton.js'

import withTableColumnsPermission from 'containers/withTableColumnsPermission'

const empty = <span className="empty">—</span>

const columns = [
    {
        maxWidth: 150,
        render: ({webname}) => webname,
    },
    {
        render: ProcessStatus,
    },
    {
        render: ({description}) => description || empty,
    },
    {
        maxWidth: 150,
        render: ({started, finished}) => <Duration from={started} to={finished}/>,
    },
    {
        tooltip: false,
        permission: stop,
        width: 50,
        align: 'right',
        fixed: true,
        render: ({id, isStoppable, panelId}, {onStop}) => (
            <ProcessStopButton onClick={() => onStop(id)} disabled={!isStoppable}/>
        ),
    },
]

export default compose(
    withProps({
        columns,
    }),
    withTableColumnsPermission(),
)(Table)
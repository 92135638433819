import React, {Component} from 'react'
import PropTypes from 'prop-types'

import ViewedRRI from './Actions/ViewedRRI'
import ResendEmail from './Actions/ResendEmail'
import ScheduleOrCancelRRI from './Actions/ScheduleOrCancelRRI'
import InitRRI from './Actions/InitRRI'

import ProgressBar from 'ui/ProgressBar'

export default class ActionsCell extends Component {

    static propTypes = {
        panelId: PropTypes.number.isRequired,

        markAsViewed: PropTypes.func,
        sendEmail: PropTypes.func,
        schedule: PropTypes.func,
        initiateNow: PropTypes.func,

        isEmailSent: PropTypes.bool,
        isReviewed: PropTypes.bool,

        id: PropTypes.number,
        next: PropTypes.object,
        result: PropTypes.object,
        progress: PropTypes.number,
    }

    render() {
        const {panelId, result, markAsViewed, sendEmail, initiateNow, schedule, progress, next} = this.props

        if (progress !== null) {
            return (<ProgressBar className="rri-progress" value={progress}/>)
        }

        return (
            <div className="rri-actions">
                {sendEmail && <ResendEmail {...{result, sendEmail}}/>}
                {markAsViewed && <ViewedRRI {...{result, markAsViewed}}/>}
                {schedule && <ScheduleOrCancelRRI {...{panelId, schedule, next}}/>}
                {initiateNow && result !== 'progress' && <InitRRI {...{panelId, initiateNow}}/>}
            </div>
        )
    }
}
import {compose} from 'redux'

import {remove} from 'permissions/users/selection'

import {withSelectionHandler} from 'containers/withSelection'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withUsers from 'containers/withUsers'

import RemoveIcon from 'icons/remove.svg'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: remove}),
    withVisibility(),
    withUsers(),
    withProps(({showRemoveUser: onClick}) => ({
        onClick,
        label: __('Remove'),
        Icon: RemoveIcon,
    })),
    withSelectionHandler(),
)(Button)
import {deselectAll} from 'modules/selection/actions'
import {takeEvery, takeLatest, all, call, put, select, throttle} from 'redux-saga/effects'

import * as selection from 'modules/selection/actions'
import {snackShow} from 'modules/snacks'
import {SCOPE_NOT_FOUND} from 'constants/errorType'

import toIds from 'utils/toIds'
import {err} from 'utils/log'

export const ERROR_SCOPE_NOT_FOUND = new Error(SCOPE_NOT_FOUND)

let lastList
let lastScope

export function* createListFetchSaga(fetch, actions, updateActionCreator, selector) {
    if (!fetch) {
        return err(`Api method fetch not found for action: ${actions.fetch}`)
    }

    if (!actions.init) {
        return
    }

    yield all([
        takeEvery(actions.init, function* watchInit({payload: {scope, filters, action}}) {
            const sameList = lastList === actions.init.toString()
            const sameScope = lastScope === scope

            lastList = actions.init.toString()
            lastScope = scope

            if (!sameList) {
                yield put(deselectAll())
            }

            if (sameList && sameScope && action !== 'PUSH') {
                return
            }

            if (scope) {
                const {scopes} = yield select(selector)

                if (!scopes || !scopes.hasOwnProperty(scope)) {
                    yield put(actions.receive(ERROR_SCOPE_NOT_FOUND))
                    return
                }

                filters = scopes[scope].filters || []
            }

            yield put(actions.reset(filters))
        }),

        takeLatest([
            actions.reset,
            actions.fetch,
            actions.setStart,
        ], function* watchFetch() {
            const state = yield select(selector)

            try {
                const {rows, count} = yield call(fetch, state)

                const action = yield updateActionCreator(rows)

                if (action) {
                    yield put(action)
                }

                yield put(actions.receive(toIds(rows), count))
            } catch (error) {
                yield put(actions.receive(error))
            }
        }),
    ])
}

export function* createListSuggestSaga(suggest, actions, selector) {
    if (!suggest) {
        return err(`Api method suggest not found for action: ${actions.fetchSuggests}`)
    }

    if (!actions.hasOwnProperty('receiveSuggests')) {
        return err(`There is no receiveSuggests action as pair for action: ${actions.fetchSuggests}`)
    }

    yield throttle(500, [
        actions.fetchSuggests,
    ], function* watchSuggests({type, payload: {fields, prefix}}) {
        try {
            const state = yield select(selector)

            const data = fields.reduce((acc, key) => ({
                ...acc,
                [key]: prefix,
            }), {})

            const result = yield call(suggest, data, 0, 10, state)

            yield put(actions.receiveSuggests(result, prefix))
        } catch (error) {
            // ignore?
        }
    })
}

export function* createListRemoveSaga(remove, actions) {
    if (!remove) {
        return err(`Api method remove not found for action: ${actions.remove}`)
    }

    yield takeEvery([
        actions.remove,
    ], function* watchRemove({type, payload: ids}) {
        yield put(selection.deselectAll())

        try {
            yield call(remove, ids)
        } catch (error) {
            yield put(snackShow(error.message))

            if (actions.hasOwnProperty('revertRemove')) {
                yield put(actions.revertRemove(error))
            } else {
                yield put(actions.fetch())
            }

            const isEmpty = yield select(state => state.selection.isEmpty())

            if (isEmpty) {
                yield put(selection.select(ids))
            }
        }
    })
}

export default function* (api, actions, updateActionCreator, selector) {
    const sagas = []

    sagas.push(
        createListFetchSaga(api.fetch, actions, updateActionCreator, selector),
    )

    if (actions.hasOwnProperty('fetchSuggests')) {
        sagas.push(
            createListSuggestSaga(api.suggest, actions, selector),
        )
    }

    if (actions.hasOwnProperty('remove')) {
        sagas.push(
            createListRemoveSaga(api.remove, actions),
        )
    }

    yield all(sagas)
}


import React, {Component} from 'react'
import PropTypes from 'prop-types'

import CardForm from 'ui/CardForm'
import Input from 'ui/Input'
import {__} from 'utils/i18n'
import Select from 'ui/Select'
import MultiSelect from 'ui/MultiSelect'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchAssignableRoles} from 'modules/roles/assignableRoles/actions'
import {fetch as fetchGroups} from 'modules/groups/select/actions'

const ParentRoleSelect = withSelectLoader(
    fetchAssignableRoles,
    ({roles}) => roles.assignableRoles.isLoading,
    ({roles}) => roles.assignableRoles.ids
        .map(id => roles.store.byIds[id])
        .map(({id, name}) => ({value: id, label: name})),
)(Select)

const GroupsMultiSelect = withSelectLoader(
    fetchGroups,
    ({groups}) => groups.select.isLoading,
    ({groups}) => Object.values(groups.store.byIds).map(
        ({id, name}) => ({value: id, label: name}),
    ),
    10,
)(MultiSelect)

export default class RoleForm extends Component {

    static propTypes = {
        isNew: PropTypes.bool,
        handle: PropTypes.func,
        data: PropTypes.shape({
            name: PropTypes.string,
            groups: PropTypes.arrayOf(
                PropTypes.shape({
                    id: PropTypes.number.isRequired,
                    name: PropTypes.string.isRequired,
                }),
            ),
        }),
    }

    rules = {
        name: {
            presence: true,
            length: {maximum: 25},
        },
        groups: {
            presence: true,
        },
    }

    getRules(isNew) {
        if (!isNew) {
            return this.rules
        }

        return {
            ...this.rules,
            parentId: {
                presence: true,
            },
        }
    }

    render() {
        const {isNew, data, handle, ...props} = this.props

        return (
            <CardForm confirmOnDismiss rules={this.getRules(isNew)} onSubmit={handle} {...props}>
                <Input
                    autoFocus
                    label={__('Name')}
                    name="name"
                    defaultValue={data.name}
                />

                {isNew && <ParentRoleSelect
                    label={__('Parent Role')}
                    name="parentId"
                />}

                <GroupsMultiSelect
                    label={__('Unit Groups')}
                    name="groups"
                    defaultValues={data.groups && data.groups.map(
                        ({id, name}) => ({value: id, label: name}),
                    )}
                />
            </CardForm>
        )
    }
}
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import classes from 'classnames'

export const BarSpace = () => <div className="bar-space"></div>

export default class Bar extends PureComponent {
    static propTypes = {
        childrenRight: PropTypes.bool,
        divider: PropTypes.bool,
    }

    render() {
        const {className, childrenRight, divider, ...props} = this.props

        return (
            <div className={classes('bar', className, {
                'bar--children-right': childrenRight,
                'bar--divider': divider,
            })} {...props}></div>
        )
    }
}
import withEvents from 'containers/withEvents'
import {compose} from 'redux'

import {markAsViewed} from 'permissions/events/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import Button from 'ui/Button'
import __ from 'utils/i18n'
import withProps from 'containers/withProps'

export default compose(
    withPermission({isVisible: markAsViewed}),
    withVisibility(),
    withEvents(),
    withProps(({markAsViewed}) => ({
        onClick: markAsViewed,
        label: __('Mark As Viewed'),
    })),
    withSelectionHandler(),
)(Button)
import StringValue from 'components/Configuration/Row/StringValue'
import {__} from 'utils/i18n'

const regexp = /^\+?[0-9]*$/

export default class PhoneValue extends StringValue {

    getMaxLength() {
        return 16
    }

    sanitize(value) {
        return value.toUpperCase()
    }

    validate(value) {
        if (!value) {
            return
        }

        const {characters} = this.props.item

        if (characters) {
            const incorrectChar = Array.from(value).find(char => characters.indexOf(char) === -1)

            if (incorrectChar) {
                return __('Incorrect character. Should contains only %s', characters)
            }
        } else if (!regexp.test(value)) {
            return __('Incorrect characters')
        }
    }

}
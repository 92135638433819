import React, {PureComponent} from 'react'
import classnames from 'classnames'
import {stringify} from 'query-string'

import IconAlarm from 'icons/alarm.svg'
import IconAlert from 'icons/alert.svg'
import IconCheck from 'icons/check.svg'
import {Link} from 'react-router-dom'
import Tooltip from 'ui/Tooltip'
import {__, __n} from 'utils/i18n'
import path from 'utils/path'

export default class UnhandledEventsCell extends PureComponent {

    getPath(severity) {
        const {serial} = this.props

        return path('events', {}) + '?' + stringify({
            serial,
            severity,
        })
    }

    renderAlerts() {
        const {alerts} = this.props

        const tooltip = alerts
            ? __n('One unhandled alert', '%d unhandled alerts', alerts)
            : __('All alerts handled')

        return (
            <Tooltip tooltip={tooltip} className={classnames('severity', {
                'severity--warning': alerts,
                'empty': !alerts,
            })}>
                <Link to={this.getPath('alert')}>
                    <IconAlert className="severity-icon"/>
                    {alerts}
                </Link>
            </Tooltip>
        )
    }

    renderAlarms() {
        const {alarms} = this.props

        const tooltip = alarms
            ? __n('One unhandled alarm', '%d unhandled alarms', alarms)
            : __('All alarms handled')

        return (
            <Tooltip tooltip={tooltip} className={classnames('severity', {
                'severity--danger': alarms,
                'empty': !alarms,
            })}>
                <Link to={this.getPath('alarm')}>
                    <IconAlarm className="severity-icon"/>
                    {alarms}
                </Link>
            </Tooltip>
        )
    }

    render() {
        const {alerts, alarms} = this.props

        if (!alerts && !alarms) {
            return (
                <div className="severity-row">
                    <Tooltip tooltip={__('No unhandled alarms or alerts')}>
                        <IconCheck className="success"/>
                    </Tooltip>
                </div>
            )
        }

        return (
            <div className="severity-row">
                {this.renderAlarms()}
                {this.renderAlerts()}
            </div>
        )
    }
}

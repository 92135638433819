import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateSuggestString from 'api/base/generateSuggestString'
import generateFilterString from 'api/base/generateFilterString'
import parseDate from 'api/base/parseDate'
import mapSuggests from 'api/base/mapSuggests'

import {
    mapRemoteInspectionResult,
} from 'api/remoteInspectionResult'

import mapValidationErrors from 'api/base/mapValidationErrors'
import {mapProcess, mapProcessBatch} from 'api/processes'
import dataMapper from 'utils/dataMapper'
import {get, post} from 'api/http'

export const mapRemoteInspection = dataMapper(data => {
    const latest = (data.hasOwnProperty('last') && data.last && Object.keys(data.last).length)
        ? mapRemoteInspectionResult(data.last)
        : null

    const process = data.hasOwnProperty('process') && data.process
        ? mapProcess(data.process)
        : null

    const resultId = data.hasOwnProperty('urir_id') && data.urir_id
        ? parseInt(data.urir_id)
        : null

    const isEmailSent = data.hasOwnProperty('urir_email_sent') && data.urir_email_sent
        ? data.urir_email_sent === 1
        : null

    return {
        id: parseInt(data.unt_id),
        resultId,
        serial: data.unt_serial,
        account: data.unt_account,
        webname: data.unt_name,
        result: data.uri_last_result,
        last: parseDate(data.uri_last_timestamp),
        next: parseDate(data.uri_next_timestamp),
        progress: data.uri_last_result === 'progress' ? parseInt(data.uri_progress) : null,
        repetition: parseInt(data.uri_repeat),
        isEmailSent,
        group: data._utg_name,
        latest,
        latestId: latest ? latest.id : null,
        process,
    }
})

const keysMap = {
    id: 'unt_id',
    account: 'unt_account',
    webname: 'unt_name',
    serial: 'unt_serial',
    last: 'uri_last_timestamp',
    next: 'uri_next_timestamp',
    isEmailSent: 'urir_email_sent',
    isReviewed: 'urir_reviewed',
    group: '_utg_name',
}

const valuesMap = {
    isEmailSent: {
        true: '1',
        false: '0',
    },
    isReviewed: {
        true: 'yes',
        false: 'no',
    },
}

export function fetch({start = 0, perPage: count = 10, filters, query}) {
    const filter = generateFilterString(filters, keysMap, valuesMap)

    return get('/units_remoteinspection/getallunits', {start, count, filter, query})
        .then(createRowsResponseParser(mapRemoteInspection))
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('units_remoteinspection/suggestunits', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function markAsViewed(resultIds) {
    return post('unit_remoteinspection_results/review', {
        'urir_id': resultIds,
    })
}

export function sendEmail(resultId) {
    return post('unit_remoteinspection_results/email', {
        urir_id: resultId,
    })
}

export function initiateInspection(panelIds) {
    return post('units_remoteinspection/initiateinspection', {
        unt_id: panelIds,
    }).then(mapProcessBatch)
}

export function scheduleInspection(unitIds, date, repetition) {
    return post('units_remoteinspection/schedule', {
        unitId: unitIds,
        schedule: 'set',
        date: date.format('YYYY-MM-DD'),
        repetition,
    }).catch(mapValidationErrors)
}

export function cancelInspection(unitIds) {
    return post('units_remoteinspection/schedule', {
        unitId: unitIds,
        schedule: 'clear',
    }).catch(mapValidationErrors)
}

export function getValues() {
    return get('/system_remoteinspection_options/index')
}

export function setValues(data) {
    return post('/system_remoteinspection_options/edit', {...data})
        .catch(mapValidationErrors)
}

export function scheduleInspectionByCsv(data) {
    return post('units_remoteinspection/schedulecsv', {data})
        .catch(e => mapValidationErrors(e, keysMap))
}
import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import Radiobox from 'ui/Radiobox'
import {__} from 'utils/i18n'

export default class PermissionRow extends Component {

    static propTypes = {
        onChange: PropTypes.func.isRequired,
        title: PropTypes.string.isRequired,
        name: PropTypes.string.isRequired,
        isAllowByDefault: PropTypes.bool,
        isEditable: PropTypes.bool,
        isAllowed: PropTypes.bool,
        isDenied: PropTypes.bool,
    }

    handleAllow = () => {
        const {name, onChange, isAllowDefault} = this.props
        onChange(name, isAllowDefault ? null : true)
    }

    handleDeny = () => {
        const {name, onChange, isAllowByDefault} = this.props
        onChange(name, isAllowByDefault ? false : null)
    }

    renderContent() {
        const {name, isAllowByDefault, isAllowed, isDenied, isEditable} = this.props

        const allowed = isAllowByDefault ? !isDenied : isAllowed

        return <Fragment>
            <div className="form-row-content">
                <Radiobox
                    name={name}
                    label={__('Allow')}
                    checked={allowed}
                    readOnly={!isEditable}
                    disabled={isAllowByDefault && !isDenied}
                    onChange={isEditable ? this.handleAllow : null}
                />
            </div>

            <div className="form-row-content">
                <Radiobox
                    name={name}
                    label={__('Deny')}
                    checked={!allowed}
                    readOnly={!isEditable}
                    disabled={!isAllowByDefault && !isAllowed}
                    onChange={isEditable ? this.handleDeny : null}
                />
            </div>
        </Fragment>
    }

    render() {
        return (
            <div className="form-row">
                <div className="form-row-label">
                    {this.props.title}
                </div>

                {this.renderContent()}
            </div>
        )
    }
}
import {
    PROCESS_TYPE_PMAXCONFIGDOWNLOAD,
    PROCESS_TYPE_PMAXCONFIGUPLOAD,
    PROCESS_TYPE_PMAXZONEADD, PROCESS_TYPE_PMAXZONEREMOVE,
} from 'constants/processTypes'
import {selectReadyChanges} from 'modules/panels/configuration/selectors'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import generateProcess, {generateBatch} from 'modules/processes/manager/generateProcess'
import {takeEveryProcessCompleteSuccessful} from 'modules/processes/manager/takeProcess'
import {snackShow} from 'modules/snacks'
import {select, all, call, takeEvery, put} from 'redux-saga/effects'
import {createBasicConfiguration} from 'modules/forms/handlers'
import {__n} from 'utils/i18n'

import * as actions from './actions'
import * as api from 'api/panel/configuration'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.markAsBackup, watchMarkAsBackup),
        takeEvery(actions.fetchOne, watchFetchOne),
        takeEvery(actions.refresh, watchRefresh),
        takeEvery(actions.upload, watchUpload),
        takeEvery(createBasicConfiguration.SUCCESS, watchMakeBasicComplete),
        takeEveryProcessCompleteSuccessful([
            PROCESS_TYPE_PMAXCONFIGDOWNLOAD,
            PROCESS_TYPE_PMAXCONFIGUPLOAD,
            PROCESS_TYPE_PMAXZONEADD,
            PROCESS_TYPE_PMAXZONEREMOVE,
        ], watchProcessFinished),
    ])
}

function* watchFetch({payload: panelId}) {
    try {
        const {rows, process} = yield call(api.list, panelId)

        const current = rows.find(row => row.current) || {}
        const {currentConfigId} = yield select(state => state.panels.configuration[panelId] || {})

        if (current.id >= 0 && current.id !== currentConfigId) {
            const configuration = yield call(api.one, panelId, current.id)
            yield put(actions.receiveCurrent(configuration, panelId))
        }

        yield put(actions.receive({
            process: yield ensureProcess(process),
            rows,
            currentConfigId: current.id,
        }, panelId))
    } catch (error) {
        yield put(actions.receive(error, panelId))
    }
}

function* watchFetchOne({payload: {panelId, configId}}) {
    try {
        const configuration = yield call(api.one, panelId, configId)
        yield put(actions.receiveOne(configuration, panelId, configId))
    } catch (error) {
        yield put(actions.receiveOne(error, panelId, configId))
    }
}

function* watchMarkAsBackup({payload: {id, panelId}}) {
    try {
        yield call(api.backup, panelId, id)
    } catch (error) {
        yield put(snackShow(error))
        yield put(actions.revertBackup(panelId))
    }
}

function* watchRefresh({payload: panelIds}) {
    if (panelIds.length === 1) {
        yield refreshPanel(panelIds.pop())
    } else {
        yield refreshPanels(panelIds)
    }
}

function* refreshPanel(panelId) {
    const {process, execute} = yield generateProcess(PROCESS_TYPE_PMAXCONFIGDOWNLOAD, panelId)
    yield put(actions.update({process}, panelId))

    try {
        yield execute(api.refresh, panelId)
    } catch (error) {
        yield put(actions.update({process: null}, panelId))
        yield put(snackShow(error.message))
    }
}

function* refreshPanels(panelIds) {
    const {execute} = yield generateBatch(PROCESS_TYPE_PMAXCONFIGDOWNLOAD, panelIds)
    try {
        yield execute(api.refreshAll, panelIds)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchUpload({payload: {panelId}}) {
    const {changes, configuration, errors} = yield select(state => state.panels.configuration[panelId] || {})
    const readyChanges = yield select(selectReadyChanges, {panelId})
    const errorsCount = errors ? Object.keys(errors).length : 0

    if (errorsCount > 0) {
        const message = __n('You have an error in configuration', 'You have %d errors in configuration', errorsCount)
        yield put(snackShow(message))
        return
    }

    const {process, execute} = yield generateProcess(PROCESS_TYPE_PMAXCONFIGUPLOAD, panelId)

    yield put(actions.update({
        process,
        changes: {},
    }, panelId))

    try {
        yield execute(api.upload, panelId, readyChanges, configuration && configuration.version)
    } catch (error) {
        yield put(actions.update({process: null, changes}, panelId))
        yield put(snackShow(error.message))
    }
}

function* watchMakeBasicComplete({meta}) {
    const {panelId} = meta
    yield put(actions.dismissChanges(panelId))
}

function* watchProcessFinished(process) {
    yield put(actions.outdated(process.panelId))
}
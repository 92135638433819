import React, {Component} from 'react'
import PropTypes from 'prop-types'

import deviceType from 'constants/deviceType'

import Definition from 'ui/Definition'
import DropDown, {DropDownContent} from 'ui/DropDown'

import {__} from 'utils/i18n'

export default class UpgradeStatusTableCellModules extends Component {
    static propTypes = {
        row: PropTypes.shape({
            upgrades: PropTypes.arrayOf(
                PropTypes.shape({
                    module: PropTypes.string,
                    version: PropTypes.string,
                }),
            ).isRequired,
        }).isRequired,
    }

    renderOne({module, version}) {
        return (
            <Definition
                multiLine
                key={module}
                title={deviceType(module)}
                detail={version}
            />
        )
    }

    render() {
        const {upgrades} = this.props.row

        const Trigger = (
            <span className="upgraded-modules">
                {upgrades.length === 1
                    ? deviceType(upgrades[0].module)
                    : __('%d modules', upgrades.length)
                }
            </span>
        )

        return (
            <DropDown trigger={Trigger}>
                <DropDownContent>
                    {upgrades.map(this.renderOne)}
                </DropDownContent>
            </DropDown>
        )
    }
}

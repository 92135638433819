import React, {Component} from 'react'
import {compose} from 'redux'

import page from 'permissions/panel/remoteInspections/page'

import {withPermission, withRejection} from 'containers/withPermission'

import Layout from 'ui/Layout'

import RemoteInspectionBar from './RemoteInspectionBar'
import RemoteInspectionsContent from './RemoteInspectionsContent'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'

class RemoteInspectionsPage extends Component {
    state = {}

    constructor(props, context) {
        super(props, context)
        this.state.active = props.rows && props.rows[0]
    }

    componentWillReceiveProps({rows}) {
        if (!rows || !rows.length) {
            this.setState({active: null})
            return
        }

        let active

        if (this.state.active) {
            active = rows.find(({id}) => id == this.state.active.id)
        }

        this.setState({active: active || rows[0]})
    }

    onRowClick = (active) => {
        this.setState({active})
    }

    render() {
        return (
            <Layout vertical>
                <RemoteInspectionBar/>
                <RemoteInspectionsContent onRowClick={this.onRowClick} active={this.state.active}/>
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withPanelRemoteInspection(),
)(RemoteInspectionsPage)
import {post, get} from 'api/http'

export function receive(panelId, from, to) {
    return get('/unit_report_results/getall', {
        unt_id: panelId,
        from,
        to,
    })
}

export function review(reportResultId) {
    return post('/unit_report_results/review', {
        urr_id: reportResultId,
    })
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Bar from 'ui/Bar'
import Buttons from 'ui/Buttons'

import {__} from 'utils/i18n'

import DisableLogButton from './PlinkDebugButtons/DisableLogButton'
import DisableSSHButton from './PlinkDebugButtons/DisableSSHButton'
import EnableLogButton from './PlinkDebugButtons/EnableLogButton'
import EnableSSHButton from './PlinkDebugButtons/EnableSSHButton'
import SendLogButton from './PlinkDebugButtons/SendLogButton'
import RebootButton from './PlinkDebugButtons/RebootButton'

export default class PlinkDebug extends Component {
    static propTypes = {
        panelId: PropTypes.number.isRequired,
    }

    render() {
        const {panelId} = this.props

        return (
            <div className="block plinkDebug">
                <h4 className="title">
                    {__('Debug')}
                </h4>

                <Bar>
                    <span className="hint">
                        {__('Log')}
                    </span>

                    <Buttons small flat>
                        <EnableLogButton panelId={panelId}>
                            {__('Enable')}
                        </EnableLogButton>

                        <DisableLogButton panelId={panelId}>
                            {__('Disable')}
                        </DisableLogButton>
                    </Buttons>

                    <SendLogButton panelId={panelId} small flat>
                        {__('Send')}
                    </SendLogButton>
                </Bar>

                <Bar>
                    <span className="hint">
                        {__('SSH')}
                    </span>

                    <Buttons small flat>
                        <EnableSSHButton panelId={panelId}>
                            {__('Enable')}
                        </EnableSSHButton>

                        <DisableSSHButton panelId={panelId}>
                            {__('Disable')}
                        </DisableSSHButton>
                    </Buttons>
                </Bar>

                <RebootButton panelId={panelId} primary>
                    {__('Reboot')}
                </RebootButton>
            </div>
        )
    }
}
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import CardForm from 'ui/CardForm'
import Input from 'ui/Input'

import withForm from 'containers/withForm'
import {rebootPlink} from 'modules/forms/handlers'

const rules = {
    seconds: {
        presence: true,
        numericality: true,
    },
}

class RebootPlink extends PureComponent {
    static propTypes = {
        panelId: PropTypes.number,
        handle: PropTypes.func,
    }

    handle = (form) => {
        const {handle, panelId} = this.props

        handle(panelId, form)
    }

    render() {
        return (
            <CardForm
                onSubmit={this.handle}
                header={__('Reboot Plink')}
                rules={rules}
                submitLabel={__('Reboot')}
                {...this.props}>
                <Input
                    label={__('Seconds')}
                    name="seconds"
                    defaultValue={3}/>
            </CardForm>
        )
    }
}

export default withForm(rebootPlink)(RebootPlink)
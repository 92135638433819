import {selectPerPage} from 'modules/settings/selectors'
import {call, all, put, select, takeEvery} from 'redux-saga/effects'

import * as actions from './actions'
import {update} from '../store/actions'
import * as api from 'api/reports'
import {POLL_REPORTS} from 'configs/pollers'
import toIds from 'utils/toIds'

import {snackShow} from 'modules/snacks'
import {createReport} from 'modules/forms/handlers'
import listSaga from 'modules/higherOrder/createListSaga'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'

import {__} from 'utils/i18n'

const selector = state => ({
    ...state.reports.list,
    scopes: state.settings.scopes.reports,
    perPage: selectPerPage(state, 'reports'),
})

export default function* () {
    yield all([
        listSaga(api, actions, update, selector),
        createPollerSaga(actions.startPoll, actions.stopPoll, POLL_REPORTS, pollList),
        takeEvery(createReport.SUCCESS, watchAddReport),
    ])
}

function* watchAddReport() {
    yield put(actions.fetch())
    yield put(snackShow(__('Report was created successfully')))
}

export function* pollList() {
    const state = yield select(selector)

    try {
        const {rows, count} = yield call(api.fetch, state)

        yield put(update(rows))

        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
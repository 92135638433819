import React from 'react'
import {compose} from 'redux'

import {list, retry} from 'permissions/firmware/upgradeStatus'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withLifeCycle from 'containers/withLifeCycle'
import withSelection from 'containers/withSelection'
import withUpgradeStatus from 'containers/withUpgradeStatus'
import {withPermission, withRejection} from 'containers/withPermission'

import Table from 'ui/Table'
import Duration from 'ui/Duration'
import DateTime from 'ui/DateTime'

import ProcessStatus from 'components/Processes/ProcessStatus'
import PanelCell from 'components/TableCells/PanelCell'

import UpgradeStatusTableCellModules from './Table/Cell/UpgradeStatusTableCellModules'
import UpgradeStatusRetryButton from './Table/UpgradeStatusRetryButton'

import {__} from 'utils/i18n'

const columns = [
    {
        width: 150,
        fixed: true,
        name: () => __('Time'),
        render: ({started}) => (
            <DateTime timeFirst datetime={started}/>
        ),
    },
    {
        maxWidth: 180,
        name: () => __('Panel'),
        render: ({panelId, serial, webname}) => (
            <PanelCell id={panelId} serial={serial} webname={webname}/>
        ),
    },
    {
        tooltip: false,
        name: () => __('Modules'),
        render: UpgradeStatusTableCellModules,
    },
    {
        width: 150,
        name: () => __('Status'),
        render: ProcessStatus,
    },
    {
        width: 90,
        name: () => __('User'),
        render: ({user}) => user,
    },
    {
        maxWidth: 100,
        name: () => __('Duration'),
        render: ({started, finished}) => <Duration from={started} to={finished}/>,
    },
    {
        width: 50,
        fixed: true,
        tooltip: false,
        align: 'right',
        render: ({isFailed, id}) => {
            if (isFailed) {
                return (
                    <UpgradeStatusRetryButton id={id}/>
                )
            }

            return null
        },
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: retry,
    }),
    withRejection(),
    withUpgradeStatus(),
    withSelection(),
    withLifeCycle({
        onMount({init, startPoll}) {
            init()
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No upgrade processes'),
    }),
)(Table)

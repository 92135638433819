import React, {Component} from 'react'

const findOverboundElement = (children, right) => {
    const index = Array.from(children).findIndex(child => {
        const rect = child.getBoundingClientRect()
        return rect.left + rect.width > right
    })

    return index >= 0 ? index : null
}

export default class AutohideList extends Component {

    state = {}

    componentWillMount() {
        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    resize = () => {
        this.setState({count: null})
        this.element && this.calc(this.element)
    }

    wrapper = (element) => {
        this.element = element
        this.resize()
    }

    calc(element) {
        const rect = element.parentNode.getBoundingClientRect()
        const index = findOverboundElement(element.children, rect.right - 50)
        this.setState({count: index})
    }

    renderChildren(count) {
        return React.Children.toArray(this.props.children)
            .map((element, index) => {
                if (index >= count) {
                    return <div className="hidden" key={'hidden$' + index}>{element}</div>
                }

                return element
            })
    }

    render() {
        const {children, className} = this.props
        const length = children.length
        const count = this.state.count || length

        return (
            <div className={className}>
                <span ref={this.wrapper}>
                    {this.renderChildren(count)}
                </span>

                {count < length
                    ? <span className="more">{`...${length - count}`}</span>
                    : null
                }
            </div>
        )
    }

}
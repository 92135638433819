import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ui/Definition'
import PanelLink from 'components/PanelLink'

export default class PanelCell extends PureComponent {

    static propTypes = {
        id: PropTypes.number,
        serial: PropTypes.string,
        webname: PropTypes.string,
        account: PropTypes.string,
        className: PropTypes.string,
        to: PropTypes.string,
    }

    static defaultProps = {
        to: 'devices',
    }

    render() {
        const {id, webname, account, to, className, ...other} = this.props

        const props = {
            ...other,
            title: webname,
            detail: account,
        }

        return (
            <PanelLink className={className} to={to} id={id} onClick={e => e.stopPropagation()}>
                <Definition {...props}/>
            </PanelLink>
        )
    }
}
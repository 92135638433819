import {compose} from 'redux'

import withProps from 'containers/withProps'
import withUpgradeStatus from 'containers/withUpgradeStatus'

import Button from 'ui/Button'
import IconRetry from 'icons/refresh.svg'

export default compose(
    withUpgradeStatus(),
    withProps(({retry, id}) => ({
        primary: true,
        flat: true,
        small: true,
        Icon: IconRetry,
        onClick: () => retry(id),
    }))
)(Button)

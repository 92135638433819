import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import {Link} from 'react-router-dom'
import classes from 'classnames'

import DropDownIcon from 'icons/drop-down.svg'
import withRoute from 'containers/withRoute'

export class NavLinkGroup extends Component {

    static propTypes = {
        Icon: PropTypes.oneOfType([
            PropTypes.instanceOf(Component),
            PropTypes.func,
        ]),
        to: PropTypes.string,
        name: PropTypes.string.isRequired,
        activate: PropTypes.func,
        Coin: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.instanceOf(Component),
        ]),
        childrenPermissions: PropTypes.array,
    }

    state = {active: false}

    toggle = e => {
        e.stopPropagation()
        e.preventDefault()

        this.setState(({active}) => ({active: !active}))
    }

    componentWillMount() {
        const {match} = this.props

        if (match) {
            this.setState({active: true})
        }
    }

    componentWillReceiveProps({match}) {
        if (match && !this.state.active) {
            this.setState({active: true})
        }

        if (!match && this.state.active) {
            this.setState({active: false})
        }
    }

    hasChildren() {
        return React.Children.count(this.props.children) > 0
    }

    renderToggle() {
        if (!this.hasChildren()) {
            return
        }

        const props = {
            className: classes('nav-link-expand', {'nav-link-expand--active': this.state.active}),
            onClick: this.toggle,
        }

        return <div {...props}><DropDownIcon className="nav-link-expand-icon"/></div>
    }

    renderChildren() {
        if (!this.hasChildren() || !this.state.active) {
            return
        }

        return <div className="nav-submenu">{this.props.children}</div>
    }

    showNavigate = () => {
        const {to, isPermitted} = this.props

        if (isPermitted) {
            return to
        } else {
            return false
        }
    }

    hasPermitedChildren = () => {
        const {childrenPermissions} = this.props

        if (childrenPermissions) {
            return childrenPermissions.every(permission => permission)
        } else {
            return false
        }
    }

    renderContent() {
        const {Icon, name, Coin} = this.props

        return (
            <Fragment>
                {Coin && <Coin/>}

                {this.renderToggle()}

                {Icon && <Icon className="nav-link-icon"/>}

                <div className="nav-link-content">
                    {name}
                </div>
            </Fragment>
        )
    }

    render() {
        const {activate, match, to, children, isPermitted} = this.props

        if (!isPermitted && !this.hasPermitedChildren()) {
            return null
        }

        const active = activate
            ? activate(match)
            : match && (!children || children && match.isExact)

        return (
            <div className="nav-link-wrapper">
                {this.showNavigate() &&
                <Link className={classes('nav-link', {'nav-link--active': active})} to={to}>
                    {this.renderContent()}
                </Link>}

                {!this.showNavigate() &&
                <div className="nav-link" onClick={this.toggle}>
                    {this.renderContent()}
                </div>}

                {this.renderChildren()}
            </div>
        )
    }
}

export default withRoute()(NavLinkGroup)
import withProps from 'containers/withProps'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {addFilters, clearFilters, fetch, removeFilters, fetchSuggests} from 'modules/upgradeStatus/list/actions'

import {list} from 'permissions/firmware/upgradeStatus'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'
import Search from 'components/Search/Search'

import __ from 'utils/i18n'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('user', __('User')),
            new TranslateSuggestFilter('status', __('Status'), {
                succeeded: __('Succeeded'),
                failed: __('Failed'),
                handled: __('Handled'),
                started: __('Started'),
            }),
            new DateFilter('started', __('Started at')),
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('webname', __('Panel Name')),
        ],
    })),
    connect(
        ({upgradeStatus: {list}}) => ({
            selected: list.filters,
            suggests: list.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
        }, dispatch),
    ),
)(Search)

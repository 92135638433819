import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import CalendarIcon from 'icons/calendar.svg'

import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import Circle from 'ui/Circle'

import Definition from 'ui/Definition'
import ProgressBar from 'ui/ProgressBar'
import Spinner from 'ui/Spinner'

import humanTime from 'utils/humanTime'
import {__, __n} from 'utils/i18n'

class RemoteInspectionNext extends Component {

    static propTypes = {
        remoteInspection: PropTypes.object.isRequired,
    }

    renderProgress(progress) {
        return (
            <div className="rri-next rri-next--progress">
                <Circle Icon={Spinner} className="rri-status"/>

                <div>
                    {__('Running')}
                </div>

                <ProgressBar className="rri-progress" value={progress}/>
            </div>
        )
    }

    render() {
        const {next, progress, repetition} = this.props.remoteInspection

        if (progress != null) {
            return this.renderProgress(progress)
        }

        const d = humanTime(next)

        return (
            <div className="rri-next">
                <Circle Icon={CalendarIcon} className="rri-status"/>

                <div>
                    {__('Next RI:')}

                    <span className="rri-next-date">
                        {next
                            ? <Definition title={d.date} detail={d.time}/>
                            : __('Not scheduled')}
                    </span>
                </div>

                {next && <span className="rri-next-repetition">
                    {!!repetition && __n('Every month', 'Every %d months', repetition)}
                </span>}
            </div>
        )
    }
}

export default compose(
    withPanelRemoteInspection(),
    withProps(({remoteInspection}) => ({
        isVisible: !!remoteInspection,
    })),
    withVisibility(),
)(RemoteInspectionNext)
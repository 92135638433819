import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import Toggle from 'ui/Toggle'

export default class Togglebox extends PureComponent {

    static propTypes = {
        label: PropTypes.node,
    }

    state = {}

    constructor(props, context) {
        super(props, context)
        this.state.checked = props.defaultChecked || props.checked
    }

    componentWillReceiveProps(props, context) {
        if (props.hasOwnProperty('checked')) {
            this.setState({checked: props.checked})
        }
    }

    onChange = (e) => {
        if (!this.props.hasOwnProperty('checked')) {
            this.setState({checked: e.target.checked})
        }

        this.props.onChange && this.props.onChange(e)
    }

    render() {
        const {label, labelOff, disabled, ...props} = this.props

        return (
            <label className="form-field form-field--togglebox">
                <Toggle {...{...props, disabled}} onChange={this.onChange}/>

                <span className={classes('checkbox-label', {
                    'checkbox-label--disabled': disabled,
                    'checkbox-label--readOnly': props.readOnly,
                })}>
                    {this.state.checked || !labelOff ? label : labelOff}
                </span>
            </label>
        )
    }
}

import React from 'react'
import {compose} from 'redux'

import withProps from 'containers/withProps'
import withRemoteInspectionsResult from 'containers/withRemoteInspectionsResult'

import ArrowPrev from 'icons/arrow-prev.svg'
import ArrowNext from 'icons/arrow-next.svg'

import Button from 'ui/Button'

export const PreviousButton = compose(
    withRemoteInspectionsResult(),
    withProps(({selectPrevious, hasPrev}) => ({
        onClick: () => hasPrev && selectPrevious(),
        disabled: !hasPrev,
        borderless: true,
        className: 'rri-result-nav rri-result-nav--prev',
        children: <ArrowPrev/>,
    })),
)(Button)

export const NextButton = compose(
    withRemoteInspectionsResult(),
    withProps(({selectNext, hasNext}) => ({
        onClick: () => hasNext && selectNext(),
        disabled: !hasNext,
        borderless: true,
        className: 'rri-result-nav rri-result-nav--next',
        children: <ArrowNext/>,
    })),
)(Button)
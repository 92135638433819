import {Component} from 'react'
import PropTypes from 'prop-types'

import * as ReportTypes from 'constants/reportTypes'
import {__, __n} from 'utils/i18n'

export default class ScheduleReportCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            schedule: PropTypes.shape({
                pattern: PropTypes.string,
                every: PropTypes.number,
            }),
        }),
    }

    render() {
        const {row: {schedule}} = this.props

        switch (schedule.pattern) {
            case ReportTypes.REPORT_ONE_TIME:
                return __('One time report')
            case ReportTypes.REPORT_DAILY:
                return __n('Every day', 'Every %d days', schedule.every)
            case ReportTypes.REPORT_WEEKLY:
                return __n('Every week', 'Every %d weeks', schedule.every)
            case ReportTypes.REPORT_MONTHLY:
                return __n('Every month', 'Every %d months', schedule.every)
            case ReportTypes.REPORT_YEARLY:
                return __n('Every year', 'Every %d years', schedule.every)
        }

        return __('Unknown')
    }
}
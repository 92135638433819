import React, {PureComponent} from 'react'
import {connect} from 'react-redux'
import CardForm from 'ui/CardForm'
import {__, __n} from 'utils/i18n'
import InputDatePicker from 'ui/InputDatePicker'
import Select, {Option} from 'ui/Select'
import Radiobox from 'ui/Radiobox'
import {compose} from 'redux'
import withForm from 'containers/withForm'
import {scheduleRri} from 'modules/forms/handlers'
import {parseSingleValue} from 'utils/dateParser'

class ScheduleRRI extends PureComponent {

    state = {}

    rules = {
        next: {
            presence: (value, attributes) => attributes.mode !== 'cancel',
        },

        repetition: {
            presence: {allowEmpty: true},
        },
    }

    handle = ({mode, next, repetition}) => {
        const {handle, panelIds} = this.props
        const date = parseSingleValue(next)

        if (mode === 'cancel') {
            handle(panelIds, null, null)
        } else {
            handle(panelIds, date, parseInt(repetition))
        }
    }

    componentWillMount = () => {
        if (this.props.next === null) {
            this.checkDisabled()
        } else {
            this.checkEnabled()
        }
    }

    checkEnabled = () => {
        const {next, repetition} = this.props

        this.setState({
            disabledChecked: false,
            enabledChecked: true,
            next,
            repetition,
        })
    }

    checkDisabled = () => {
        this.setState({
            disabledChecked: true,
            enabledChecked: false,
            next: null,
            repetition: undefined,
        })
    }

    render() {
        const {panelIds, serial, ...props} = this.props
        const {next, repetition, disabledChecked, enabledChecked} = this.state

        const single = panelIds.length === 1

        const header = single
            ? __('Schedule %s remote inspection', serial)
            : __('Schedule %d panels remote inspection', panelIds.length)

        return (
            <CardForm
                confirmOnDismiss
                header={header}
                onSubmit={this.handle}
                rules={this.rules}
                {...props}
            >

                {single && <Radiobox {...{
                    name: 'mode',
                    value: 'cancel',
                    checked: disabledChecked,
                    onChange: this.checkDisabled,
                    label: __('Disabled'),
                }} />}

                {single && <Radiobox {...{
                    name: 'mode',
                    value: 'schedule',
                    checked: enabledChecked,
                    onChange: this.checkEnabled,
                    label: __('Enabled'),
                }} />}

                <InputDatePicker {...{
                    name: 'next',
                    label: __('Next Inspection'),
                    range: false,
                    onFocus: this.checkEnabled,
                    onChange: this.checkEnabled,
                    defaultValue: next,
                }}/>

                <Select {...{
                    name: 'repetition',
                    label: __('Choose repetition'),
                    onFocus: this.checkEnabled,
                    defaultValue: repetition,
                }}>
                    <Option value={0} label={__('Once')}/>

                    {[1, 3, 6, 9].map(key => <Option {...{
                        key,
                        value: key,
                        label: __n('%d month', '%d months', key),
                    }}/>)}
                </Select>

            </CardForm>
        )
    }
}

export default compose(
    connect(
        ({remoteInspections}, {panelIds}) => {
            if (panelIds.length === 1) {
                return remoteInspections.store[panelIds[0]]
            }

            return {}
        },
    ),
    withForm(scheduleRri),
)(ScheduleRRI)
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import humanTime from 'utils/humanTime'

export default class extends Component
{
    static propTypes = {
        created: PropTypes.object.isRequired,
    }

    render() {
        return (
            <div>
                {humanTime(this.props.created).time}
            </div>
        )
    }
}
import {selectPerPage} from 'modules/settings/selectors'
import {all} from 'redux-saga/effects'

import * as actions from './actions'
import {update} from '../store/actions'
import * as api from 'api/basicConfiguration'

import listSaga from 'modules/higherOrder/createListSaga'

export default function* () {
    yield all([
        listSaga(api, actions, update, (state) => ({
            ...state.basicConfiguration.list,
            perPage: selectPerPage(state, 'basicConfiguration'),
        })),
    ])
}

import {compose} from 'redux'

import {pushBasic} from 'permissions/panels/selection'

import {withSelectionHandler} from 'containers/withSelection'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withBasicConfigurations from 'containers/withBasicConfigurations'

import IconUpload from 'icons/upload.svg'
import {MenuItem} from 'ui/Menu'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: pushBasic}),
    withVisibility(),
    withBasicConfigurations(),
    withProps(({showPushBasicConfigurationModal: onClick}) => ({
        onClick,
        message: __('Do you really want to push basic configurations for selected panels?'),
        title: __('Push basic configurations'),
        children: __('Push Basic Configuration'),
        Icon: IconUpload,
    })),
    withSelectionHandler(),
)(MenuItem)
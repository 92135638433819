import ReadOnlyValue from 'components/Configuration/Row/ReadOnlyValue'
import StringValue from 'components/Configuration/Row/StringValue'
import AccountValue from 'components/Configuration/Row/AccountValue'
import CodeValue from 'components/Configuration/Row/CodeValue'
import DateValue from 'components/Configuration/Row/DateValue'
import CodeHexValue from 'components/Configuration/Row/CodeHexValue'
import IntValue from 'components/Configuration/Row/IntValue'
import IpValue from 'components/Configuration/Row/IpValue'
import ListValue from 'components/Configuration/Row/ListValue'
import PhoneValue from 'components/Configuration/Row/PhoneValue'
import PinValue from 'components/Configuration/Row/PinValue'
import TimeValue from 'components/Configuration/Row/TimeValue'
import MultilineValue from 'components/Configuration/Row/MultilineValue'
import {err} from 'utils/log'

const components = {
    'time': TimeValue,
    'ip': IpValue,
    'account': AccountValue,
    'pin': PinValue,
    'phone': PhoneValue,
    'code': CodeValue,
    'code_hex': CodeHexValue,
    'string': StringValue,
    'int': IntValue,
    'list': ListValue,
    'date': DateValue,
    'multiline': MultilineValue,
}

export default function configurationFactory(type) {
    if (components.hasOwnProperty(type)) {
        return components[type]
    }

    err(`Unknown configuration item type: ${type}`)

    return ReadOnlyValue
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {
    SEVERITY_ALARM,
    SEVERITY_ALERT,
    SEVERITY_TROUBLE,
    severityTitle,
    severityIcon,
} from 'constants/severityType'

import Tooltip from 'ui/Tooltip'

export default class WarningIcon extends Component {
    static propTypes = {
        warning: PropTypes.shape({
            severity: PropTypes.oneOf([SEVERITY_ALARM, SEVERITY_ALERT, SEVERITY_TROUBLE]).isRequired,
            type: PropTypes.string.isRequired,
            zoneType: PropTypes.string,
            inMemory: PropTypes.bool,
            isSuspended: PropTypes.bool,
        }).isRequired,

        showTooltip: PropTypes.bool,
    }

    static defaultProps = {
        showTooltip: true,
    }

    render() {
        const {warning, showTooltip} = this.props
        const {isSuspended, severity} = warning

        const Icon = severityIcon(warning)

        const className = classes('worry', {
            'worry--alarm': severity === SEVERITY_ALARM,
            'worry--alert': severity === SEVERITY_ALERT,
            'worry--suspended': isSuspended,
        })

        if (!showTooltip) {
            return (
                <span className={className}>
                    <Icon className="worry-icon"/>
                </span>
            )
        }

        const title = severityTitle(warning)

        return (
            <Tooltip tooltip={title} className={className}>
                <Icon className="worry-icon"/>
            </Tooltip>
        )
    }
}
import React, {Component} from 'react'

import IconCompare from 'icons/compare.svg'
import Tooltip from 'ui/Tooltip'
import {__} from 'utils/i18n'

export default class CompareCell extends Component {
    handleClick = (e) => {
        e.stopPropagation()
        const {row, compare} = this.props
        compare(row)
    }

    render() {
        const {current} = this.props.row

        if (current) {
            return null
        }

        return (
            <div onClick={this.handleClick}>
                <Tooltip tooltip={__('Compare with current configuration')}>
                    <IconCompare className="configurations-icon"/>
                </Tooltip>
            </div>
        )
    }
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import CardForm from 'ui/CardForm'

import decorate from 'utils/decorate'
import {__} from 'utils/i18n'

export default class extends Component {
    static propTypes = {
        user: PropTypes.shape({
            name: PropTypes.string,
            relatedUsers: PropTypes.number,
            relatedRoles: PropTypes.number,
        }),
        onClose: PropTypes.func,
        header: PropTypes.string,
    }

    render() {
        const {user, onClose, header} = this.props

        return (
            <CardForm header={header}
                      showDismiss={true}
                      onClose={onClose}
                      editable={false}
                      onSubmit={() => ({})}>
                {decorate(
                    __('User %s cannot be deleted, because it has %s child users and %s child roles. Remove them before delete this user'),
                    user.name,
                    user.relatedUsers,
                    user.relatedRoles,
                )}
            </CardForm>
        )
    }
}
import {createAction} from 'redux-actions'

export const setError = createAction('DEVICES/METEO/SET_ERROR', (panelId, deviceId, type, error) => ({
    panelId,
    deviceId,
    type,
    error,
}))

export const setLoading = createAction('DEVICES/METEO/SET_LOADING', (panelId, deviceId, type = 'temperature', isLoading = true) => ({
    panelId,
    deviceId,
    type,
    isLoading,
}))

export const purge = createAction('DEVICES/METEO/PURGE', (panelId) => ({
    panelId,
}))

export const fetch = createAction('DEVICES/METEO/FETCH', (panelId, deviceId, type = 'temperature') => ({
    panelId,
    deviceId,
    type,
}))

export const receive = createAction('DEVICES/METEO/RECEIVE', (panelId, deviceId, type = 'temperature', data) => ({
    panelId,
    deviceId,
    type,
    data,
}))

import React from 'react'
import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'

import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ui/Card'
import Button from 'ui/Button'
import {__} from 'utils/i18n'

const CardMessage = ({className, title, positive, negative, negativeShortcut, positiveShortcut, message, onPositive, onNegative, onClose}) => (
    <Card className={className}>
        {onClose && <CardClose onClick={onClose}/>}
        <CardHeader>{title}</CardHeader>

        {message && <CardContent>{nl2br(message)}</CardContent>}

        <CardActions>
            {onNegative &&
            <Button
                borderless
                shortcut={negativeShortcut || 'esc'}
                onClick={onNegative}
                label={negative || __('Dismiss')}
            />}

            <Button
                primary
                shortcut={positiveShortcut || 'enter'}
                onClick={onPositive}
                label={positive || __('Ok')}
            />
        </CardActions>
    </Card>
)

CardMessage.propTypes = {
    title: PropTypes.node.isRequired,
    message: PropTypes.node,
    positive: PropTypes.node,
    negative: PropTypes.node,
    onPositive: PropTypes.func.isRequired,
    onNegative: PropTypes.func,
    onClose: PropTypes.func,
    className: PropTypes.string,
}

export default CardMessage
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {info} from 'permissions/panel/info/page'

import withRouterProps from 'containers/withRouterProps'
import {withPermission} from 'containers/withPermission'

import {
    fetch,
    refreshState,
    remove,
    startPollPanelData,
    stopPollPanelData,
} from 'modules/panels/one/actions'
import {update} from 'modules/panels/store/actions'
import {selectPendingChanges} from 'modules/panels/configuration/selectors'
import {
    showMakeBasicConfigurationModal,
    showEditPanelCustomerInfoModal,
    showEditPanelInfoModal,
} from 'modules/modals/actions'
import {dismissChanges, upload} from 'modules/panels/configuration/actions'
import {remember} from 'modules/panels/recent/actions'
import {fetch as fetchEvents, fetchOlder, fetchNewer, checkNewer, reset} from 'modules/panels/events/actions'

export function withRouterPanelId() {
    return withRouterProps(
        ({id}) => ({panelId: parseInt(id)}),
    )
}

export default function withPanel() {
    return compose(
        withRouterPanelId(),
        withPermission({
            isFetchAllowed: info,
        }),
        connect(
            (state, {panelId}) => {
                const panel = state.panels.store.byIds[panelId]
                const one = state.panels.one
                const pending = selectPendingChanges(state, {panelId})

                return {
                    panel,
                    isLoading: !panel && !one.error,
                    error: one.error,
                    ...pending,
                }
            },

            dispatch => bindActionCreators({
                remove,
                fetch,
                upload,
                update,
                refreshState,
                showEditPanelInfoModal,
                showEditPanelCustomerInfoModal,
                showMakeBasicConfigurationModal,
                commitChanges: upload,
                dismissChanges,
                remember,
                startPollPanelData,
                stopPollPanelData,
            }, dispatch),

            ({panel, ...state}, dispatch, {panelId, isFetchAllowed, ...props}) => {
                const id = panel ? panel.id : panelId

                return {
                    ...state,
                    panel,
                    remove: () => dispatch.remove([panel]),
                    fetch: () => isFetchAllowed ? dispatch.fetch(id) : dispatch.update({id}),
                    refreshState: () => dispatch.refreshState(id),
                    showEditPanelInfo: () => dispatch.showEditPanelInfoModal(id),
                    showEditPanelCustomerInfo: () => dispatch.showEditPanelCustomerInfoModal(id),
                    commitBasic: () => dispatch.showMakeBasicConfigurationModal(id),
                    commitChanges: () => dispatch.upload(id),
                    resetChanges: () => dispatch.dismissChanges(id),
                    remember: (path) => dispatch.remember(panel, path),
                    startPollPanelData: () => dispatch.startPollPanelData(id),
                    stopPollPanelData: () => dispatch.stopPollPanelData(id),
                    ...props,
                }
            },
        ),
    )
}

export function withPanelEvents() {
    return connect(
        ({panels}, {match}) => {
            const panelId = parseInt(match.params.id)
            const {rows, isLoading, error, hasOlder, newerCount, isNewerLoading, isOlderLoading, isPristine} = panels.events

            return {
                panelId,
                error,
                rows,
                isLoading,
                isOlderLoading,
                isNewerLoading,
                hasOlder,
                newerCount,
                isPristine,
            }
        },
        (dispatch, {match}) => bindActionCreators({
            fetch: () => fetchEvents(match.params.id),
            reset,
            fetchOlder,
            fetchNewer,
            checkNewer,
        }, dispatch),
    )
}
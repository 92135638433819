const circle = 211
const generator = 17
const offset = 50

/** generate 211 * 6 = 1266 different colors */
export default function rainbow(step = 1) {
    let h = offset

    for (let i = 0; i < step % circle; i++) {
        h = (h + generator) % circle
    }

    h = Math.round(h / circle * 360)

    let a = Math.cos((h - 60) / 180 * Math.PI)

    const round = (3 + Math.floor(step / circle)) % 6
    const b = 50 + 5 * a - 5 * (round - 3)
    const s = 100 - 30 * a

    return `hsl(${h}, ${s}%, ${b}%)`
}
import {compose} from 'redux'

import list from 'permissions/firmware/list'

import {withSelectionHandler} from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'
import withConfirmation from 'containers/withConfirmation'
import withSelection from 'containers/withSelection'

import Button from 'ui/Button'

import {__} from 'utils/i18n'
import withUpgradablePanels from 'containers/withUpgradablePanels'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withSelection(),
    withUpgradablePanels(),
    withProps(({upgrade}) => ({
        onClick: upgrade,
        title: __('Firmware upgrade'),
        message: __('Do you really want to upgrade firmware on selected panels?'),
        children: __('Upgrade'),
    })),
    withSelectionHandler(),
    withConfirmation(),
)(Button)
import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import remarks, {add, remove} from 'permissions/panel/info/remarks'

import * as actions from 'modules/panels/remarks/actions'

import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import IconSend from 'icons/send.svg'
import Hotkey from 'ui/Hotkeys'

import Textarea from 'ui/Textarea'
import Fab from 'ui/Fab'
import {__} from 'utils/i18n'
import Comment from 'ui/Comment'
import {MenuItem} from 'ui/Menu'

import {humanDate} from 'utils/humanTime'

const REMARKS_PER_PAGE = 25

export class PanelRemarks extends Component {

    state = {value: '', size: REMARKS_PER_PAGE}

    handleRemove = key => {
        const {remove} = this.props
        remove && remove(key)
    }

    createRemark = () => {
        const {create} = this.props
        const value = this.state.value.trim()

        value && create && create(value)
        this.setState({value: ''})
    }

    handleChange = e => {
        this.setState({value: e.target.value}, this.handleScroll)
    }

    componentWillReceiveProps(nextProps) {
        const {remarks} = this.props

        if (remarks && nextProps.remarks.length > remarks.length) {
            this.needScroll = true
        }
    }

    componentDidUpdate() {
        if (this.needScroll) {
            this.needScroll = false
            this.scrollToBottom()
        }
    }

    scrollToBottom() {
        if (this.scrollElement) {
            this.scrollElement.scrollTop = this.scrollElement.scrollHeight
        }
    }

    handleRef = el => {
        this.scrollElement = el
        this.scrollToBottom()
    }

    handleScroll = () => {
        if (!this.scrollElement) {
            return
        }

        const {scrollTop, scrollHeight, clientHeight} = this.scrollElement
        const {remarks} = this.props
        let {size} = this.state

        if (scrollTop < 200 && remarks.length > size) {
            size = Math.min(remarks.length, size + REMARKS_PER_PAGE)

            this.setState({size}, () => {
                // add new content offset to scroll position
                this.scrollElement.scrollTop += this.scrollElement.scrollHeight - scrollHeight
            })
        }

        const hasScroll = (scrollHeight - scrollTop - clientHeight) > 0
        this.state.hasScroll !== hasScroll && this.setState({hasScroll})
    }

    renderRemark = remark => (
        <Comment {...remark} isMine={remark.user.id === this.props.user.id}>
            {this.props.isRemoveAllowed &&
            <MenuItem onClick={() => this.handleRemove(remark.key)}>{__('Remove')}</MenuItem>}
        </Comment>
    )

    renderRemarks() {
        const {remarks} = this.props
        const {size} = this.state

        if (!remarks) {
            return null
        }

        const begin = Math.max(0, remarks.length - size)

        const days = remarks.slice(begin).reduce(
            (acc, remark) => {
                const date = humanDate(remark.time)

                if (!acc[date]) {
                    acc[date] = []
                }
                acc[date].push(remark)
                return acc
            }, {},
        )

        const list = Object.keys(days)
            .reduce((acc, day) => {
                return [
                    ...acc,
                    <div className="hint hint--gray panelInfo-remarks-title" key={day}>{day}</div>,
                    days[day].map(this.renderRemark),
                ]
            }, [])

        return (
            <div className="scroll" ref={this.handleRef} onScroll={this.handleScroll}>
                {list}
            </div>
        )
    }

    render() {
        const {isCreateAllowed} = this.props

        const className = 'panelInfo-addRemark' + (this.state.hasScroll ? ' panelInfo-addRemark--separated' : '')

        return (
            <Fragment>
                {this.renderRemarks()}

                {isCreateAllowed &&
                <div className={className}>
                    <Textarea
                        onChange={this.handleChange}
                        value={this.state.value}
                        placeholder={__('Add note')}
                        maxLength={1000}
                    />

                    <Hotkey
                        shortcut="⌘+enter, ctrl+enter"
                        action={this.createRemark}
                        scope="input"
                    />

                    <Fab mini primary Icon={IconSend} onClick={this.createRemark}/>
                </div>
                }
            </Fragment>
        )
    }
}

export default compose(
    withPermission({
        isVisible: remarks,
        isCreateAllowed: add,
        isRemoveAllowed: remove,
    }),
    withVisibility(),
    connect(
        ({panels, auth: {sign: {user}}}, {panelId}) => {
            const remarks = panels.remarks[panelId]
            return {
                user: user,
                isLoading: !remarks,
                remarks: remarks && remarks.page.map(id => remarks.rows[id]),
            }
        },
        (dispatch, {panelId}) => {
            return bindActionCreators({
                fetch: () => actions.fetch(panelId),
                create: text => actions.create(text, panelId),
                remove: key => actions.remove(key, panelId),
            }, dispatch)
        },
    ),
    withLoader(({fetch}) => fetch()),
    withPoller(5000, ({fetch}) => fetch()),
)(PanelRemarks)
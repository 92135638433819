import {compose} from 'redux'

import {initiate} from 'permissions/remoteInspections/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withConfirmation from 'containers/withConfirmation'
import withRemoteInspectionsResult from 'containers/withRemoteInspectionsResult'

import Button from 'ui/Button'
import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: initiate}),
    withVisibility(),
    withRemoteInspectionsResult(),
    withProps(({inspection, initiate}) => ({
        disabled: inspection && inspection.result === 'progress' && inspection.progress === 0,
        label: (inspection && inspection.result === 'progress' && inspection.progress === 0) ? __('Pending...') : __('Run'),
        title: __('Initiate Inspection'),
        message: __('Do you want to initiate remote inspection now?'),
        positive: __('Begin now'),
        borderless: true,
    })),
    withConfirmation(({initiate}) => initiate()),
)(Button)
import {selectPerPage} from 'modules/settings/selectors'
import {all} from 'redux-saga/effects'

import * as api from 'api/interactiveUsers'
import * as actions from './actions'

import {update} from '../store/actions'

import listSaga from 'modules/higherOrder/createListSaga'

export default function* () {
    yield all([
        listSaga(api, actions, update, (state) => ({
            ...state.interactiveUsers.list,
            perPage: selectPerPage(state, 'interactiveUsers'),
        })),
    ])
}

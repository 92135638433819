import React from 'react'
import {compose} from 'redux'

import Bar, {BarSpace} from 'ui/Bar'

import bar from 'permissions/panel/remoteInspections/bar'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import InitiateNowRemoteInspectionButton from './RemoteInspectionBar/InitiateNowRemoteInspectionButton'
import ScheduleRemoteInspectionButton from './RemoteInspectionBar/ScheduleRemoteInspectionButton'
import FilterRemoteInspectionsList from './RemoteInspectionBar/FilterRemoteInspectionsList'

const RemoteInspectionBar = () => (
    <Bar className="rri-bar">
        <FilterRemoteInspectionsList/>

        <BarSpace/>

        <InitiateNowRemoteInspectionButton/>
        <ScheduleRemoteInspectionButton/>
    </Bar>
)

export default compose(
    withPermission({isVisible: bar}),
    withVisibility(),
)(RemoteInspectionBar)
import React, {Component} from 'react'
import {compose} from 'redux'
import {withRouter} from 'react-router-dom'

import withBasicConfiguration from 'containers/withBasicConfiguration'
import {withPermission} from 'containers/withPermission'
import withLoader from 'containers/withLoader'
import withConfigurationFilter from 'containers/withConfigurationFilter'

import {edit} from 'permissions/basicConfiguration/actions'
import page from 'permissions/basicConfiguration/page'

import ConfigurationLayout from 'components/Configuration/ConfigurationLayout'
import BasicConfigurationQuickSearch from './Filters/BasicConfigurationQuickSearch'

class BasicConfiguration extends Component {

    handleIsChange = (item) => {
        const {exporting, changes, basicValues} = this.props

        if (changes && changes.hasOwnProperty(item.key)) {
            return true
        }

        const toExport = exporting && exporting.hasOwnProperty(item.key)
        const wasExport = basicValues && basicValues.hasOwnProperty(item.key)

        return toExport !== wasExport
    }

    render() {
        const filters = <BasicConfigurationQuickSearch/>

        return (
            <ConfigurationLayout
                {...this.props}
                isShowExportCheckbox
                filters={filters}
                isChanged={this.handleIsChange}
            />
        )
    }
}

export default compose(
    withPermission({
        isAllowed: page,
        isEditable: edit,
    }),
    withRouter,
    withBasicConfiguration(),
    withLoader(
        ({fetch, match}) => fetch({basicConfigId: match.params.id}),
    ),
    withConfigurationFilter(),
)(BasicConfiguration)
import Promise from 'promise-polyfill'
import {createStore, applyMiddleware, compose} from 'redux'
import createSagaMiddleware from 'redux-saga'
import thunk from 'redux-thunk'
import promiseMiddleware from 'redux-promise'
import {routerMiddleware} from 'react-router-redux'

import instance from 'api/http'

import saga from 'modules/saga'
import reducer from 'modules/reducer'
import {logout} from 'modules/auth/sign/actions'

export default function createAppStore(history, defaultState = {}) {
    const composeEnhancers =
        typeof window === 'object' &&
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
        }) : compose

    const sagaMiddleware = createSagaMiddleware()

    const middlewares = [
        thunk,
        promiseMiddleware,
        sagaMiddleware,
        routerMiddleware(history),
    ]

    if (DEVELOPMENT) {
        const freeze = require('redux-freeze')
        middlewares.push(freeze)
    }

    const enhancer = composeEnhancers(applyMiddleware(...middlewares))

    const store = createStore(
        reducer,
        defaultState,
        enhancer,
    )

    let sagaTask = sagaMiddleware.run(saga)

    if (module.hot) {
        module.hot.accept('../modules/saga', () => {
            const newSaga = require('../modules/saga').default
            sagaTask.cancel()
            sagaTask.done.then(() => {
                sagaTask = sagaMiddleware.run(newSaga)
                console.warn('Sagas replaced')
            })
        })

        module.hot.accept('../modules/reducer', () => {
            const nextRootReducer = require('../modules/reducer').default
            store.replaceReducer(nextRootReducer)
            console.warn('Reducers replaced')
        })
    }

    instance.interceptors.response.use(null, (error) => {
        const {status} = error

        if (status === 401) {
            store.dispatch(logout())
        }

        return Promise.reject(error)
    })

    return store
}
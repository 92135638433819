import {handleActions} from 'redux-actions'
import {update, clear} from './actions'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [clear]() {
        return defaultState
    },

    [update](state, {payload}) {
        const byIds = payload.reduce((acc, row) => {
            const old = acc[row.id]

            return {
                ...acc,
                [row.id]: {
                    ...old,
                    ...row,
                },
            }
        }, state.byIds || {})

        return {
            ...state,
            byIds,
        }
    },
}, defaultState)
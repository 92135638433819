import panelVendorType from 'constants/panelVendorType'
import {VENDOR_FIBRO_TRANSMITTER} from 'constants/panelVendorType'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Bar, {BarSpace} from 'ui/Bar'
import Buttons from 'ui/Buttons'

import PanelStatus from 'components/PanelStatus'
import PanelCell from 'components/TableCells/PanelCell'
import ApplicationInteractiveDropdown from 'components/DropDown/ApplicationInteractiveDropdown'
import PanelStatusLine from './PanelPageTop/PanelStatusLine'

import EditPanelButton from './PanelPageTop/Buttons/EditPanelButton'
import RefreshPanelButton from './PanelPageTop/Buttons/RefreshPanelButton'
import RemovePanelButton from './PanelPageTop/Buttons/RemovePanelButton'
import ServiceDropDown from './PanelPageTop/DropDowns/ServiceDropDown'

import classes from 'classnames'

const PanelStatusLineStubber = ({panel: {vendor}}) => {
    if (vendor !== VENDOR_FIBRO_TRANSMITTER) {
        return null
    }

    return (
        <div className={classes('panelStatusLine')}>
            {panelVendorType(VENDOR_FIBRO_TRANSMITTER)}
        </div>
    )
}

export default class PanelPageTop extends Component {

    static propTypes = {
        panel: PropTypes.shape({
            webname: PropTypes.string,
            serial: PropTypes.string,
            account: PropTypes.string,
        }).isRequired,
    }

    render() {
        const {panel} = this.props
        const {webname, serial, account} = panel

        return (
            <Bar>
                <PanelStatus panel={panel}/>
                <PanelCell multiLine className="title title--large" {...{webname, serial, account}}/>
                <PanelStatusLine panel={panel}/>
                <PanelStatusLineStubber panel={panel}/>

                <BarSpace/>


                <ServiceDropDown/>
                <Buttons>
                    <ApplicationInteractiveDropdown panel={panel}/>
                </Buttons>
                <Buttons>
                    <RefreshPanelButton panel={panel}/>
                    <EditPanelButton panel={panel}/>
                    <RemovePanelButton panel={panel}/>
                </Buttons>
            </Bar>
        )
    }
}
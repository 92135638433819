import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import {connect} from 'react-redux'
import moment from 'moment'

import {suspendFaults as suspendFaultsHandler} from 'modules/forms/handlers'

import withForm from 'containers/withForm'
import withLoader from 'containers/withLoader'
import withLifeCycle from 'containers/withLifeCycle'
import {withPanelsRefresh} from 'containers/withPanels'

import FaultChecklist from 'components/List/FaultChecklist'

import CardForm from 'ui/CardForm'
import CardMessage from 'ui/CardMessage'
import {FormValidationGroup} from 'ui/Form'
import InputDatePicker from 'ui/InputDatePicker'

import {__} from 'utils/i18n'

class SuspendFaults extends PureComponent {
    static propTypes = {
        panels: PropTypes.arrayOf(
            PropTypes.shape({
                serial: PropTypes.string,
                account: PropTypes.string,
                faults: PropTypes.arrayOf(
                    PropTypes.shape({
                        id: PropTypes.number,
                        name: PropTypes.string,
                        zone: PropTypes.number,
                        comment: PropTypes.string,
                        isSuspended: PropTypes.bool,
                    }),
                ),
            }),
        ),
        isLoading: PropTypes.bool,
        onClose: PropTypes.func,
        handle: PropTypes.func.isRequired,
    }

    rules = {
        faultId: {
            presence: {
                message: __('You must select at least one fault to suspend'),
            },
        },

        until: {
            presence: true,
            datetime: true,
        },
    }

    handle = ({faultId, until}) => {
        const {handle} = this.props
        const date = moment(until, moment.localeData().longDateFormat('L'))

        handle(
            faultId.map(id => parseInt(id)),
            date.startOf('day'),
        )
    }

    filterFault = fault => !fault.isSuspended

    render() {
        const {panels, isLoading, onClose} = this.props

        if (!panels.some(panel => panel.faults && panel.faults.find(this.filterFault))) {
            return (
                <CardMessage
                    title={__('Suspend Faults')}
                    message={__('No suspendable faults found')}
                    onClose={onClose}
                    onPositive={onClose}
                />
            )
        }

        return (
            <CardForm
                onClose={onClose}
                onSubmit={this.handle}
                header={__('Suspend Faults')}
                rules={this.rules}
                isLoading={isLoading}
            >
                <FormValidationGroup name="faultId">
                    <FaultChecklist
                        panels={panels}
                        filter={this.filterFault}
                        emptyMessage={__('There are no suspendable faults')}
                    />
                </FormValidationGroup>

                <InputDatePicker {...{
                    name: 'until',
                    label: __('Suspend until'),
                    defaultValue: moment().add(1, 'week'),
                    range: false,
                    min: moment(),
                }}/>
            </CardForm>
        )
    }
}

export default compose(
    withPanelsRefresh(),
    withLifeCycle({
        onMount({refresh, panelIds}) {
            refresh(panelIds)
        },
    }),
    connect((state, props) => ({
        isLoading: state.panels.store.isRefreshing,
        panels: props.panelIds.map(panelId => state.panels.store.byIds[panelId]),
    })),
    withForm(suspendFaultsHandler),
    withLoader(),
)(SuspendFaults)

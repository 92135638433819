import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import Check from 'ui/Check'
import Spinner from 'ui/Spinner'

export default class Checkbox extends PureComponent {

    static propTypes = {
        label: PropTypes.node,
        name: PropTypes.string,
        className: PropTypes.string,
        greyscale: PropTypes.bool,
        indeterminate: PropTypes.bool,
        checked: PropTypes.bool,
        progress: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onMouseDown: PropTypes.func,
        onMouseUp: PropTypes.func,
        onMouseMov: PropTypes.func,
    }

    render() {
        const {label, children, progress, ...props} = this.props

        return (
            <label className="form-field form-field--checkbox">
                {progress
                    ? <Spinner className="btn-icon"/>
                    : <Check {...props}/>}
                <span className={classes('checkbox-label', {
                    'checkbox-label--disabled': props.disabled,
                })}>{children || label}</span>
            </label>
        )
    }
}

import {compose} from 'redux'

import selection from 'permissions/groups/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withSelection from 'containers/withSelection'
import withGroups from 'containers/withGroups'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withPermission({isVisible: selection}),
    withVisibility(),
    withGroups(),
    withSelection(),
)(SelectionDropDown)
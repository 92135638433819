import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'ui/Checkbox'

import {__} from 'utils/i18n'
import {FormSection} from 'ui/Form'
import getContentName from 'constants/reportContentOption'

export default class extends Component {
    static propTypes = {
        contentOptions: PropTypes.object,
    }

    state = {
        csvEnabled: true,
        pdfEnabled: false,
    }

    changeFormatPdf = () => {
        return this.setState(({csvEnabled, pdfEnabled}) => ({
            csvEnabled: (!csvEnabled && pdfEnabled) ? true : csvEnabled,
            pdfEnabled: !pdfEnabled,
        }))
    }

    changeFormatCsv = () => {
        // PDF is disabled, so csv can't be unchecked
        if (!this.props.panelIds) {
            return
        }

        return this.setState(({csvEnabled, pdfEnabled}) => ({
            pdfEnabled: (!pdfEnabled && csvEnabled) ? true : pdfEnabled,
            csvEnabled: !csvEnabled,
        }))
    }

    // TBD: hardcoded export formats ids
    render() {
        const {pdfEnabled, csvEnabled} = this.state
        const {contentOptions, panelIds} = this.props

        return (
            <div>
                <Checkbox {...{checked: csvEnabled, onChange: this.changeFormatCsv, value: 2, name: 'export'}}>
                    {__('CSV')}
                </Checkbox>

                <Checkbox {...{disabled: !panelIds, checked: pdfEnabled, onChange: this.changeFormatPdf, value: 1, name: 'export'}}>
                    {!panelIds
                    ? <Fragment>{__('PDF')} <small>{__('It is not possible to use for all panels')}</small></Fragment>
                    : __('PDF')}
                </Checkbox>

                {
                    pdfEnabled &&
                    <FormSection title={__('Content Options')}>
                        {
                            Object.keys(contentOptions).map((key) => (
                                <Checkbox {...{key, value: key, name: 'contentOptions'}}>
                                    {getContentName(contentOptions[key].name)}
                                </Checkbox>
                            ))
                        }
                    </FormSection>
                }
            </div>
        )
    }
}
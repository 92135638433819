import chunk from 'lodash-es/chunk'
import concat from 'lodash-es/concat'

import {all, call} from 'redux-saga/effects'

const PARALLEL_CHUNK_SIZE = 100
const CONCURRENCY = 3

export function* sequenced(fn, array, ...arg) {
    const sequences = chunk(array, PARALLEL_CHUNK_SIZE * CONCURRENCY).map(part => chunk(part, PARALLEL_CHUNK_SIZE))

    let data = []

    for (let sequenceId = 0; sequenceId < sequences.length; sequenceId++) {
        const sequence = sequences[sequenceId]
        const sequenceResponse = yield all(
            sequence.map(parallels => call(fn, parallels, ...arg)),
        )

        data = concat(data, sequenceResponse)
    }

    return data
}

import React, {Component} from 'react'
import Layout from 'ui/Layout'

export default class Charts extends Component {
    render() {
        return (
            <Layout className="dashboard">
                {this.props.children}
            </Layout>
        )
    }
}
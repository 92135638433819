import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import CardMessage from 'ui/CardMessage'

class Confirmation extends Component {

    static propTypes = {
        action: PropTypes.arrayOf(PropTypes.shape({
            type: PropTypes.string.isRequired,
        })),
        dispatch: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,

        messages: PropTypes.shape({
            title: PropTypes.string.isRequired,
            message: PropTypes.string.isRequired,
            positive: PropTypes.string,
            negative: PropTypes.string,
        }).isRequired,
    }

    handle = () => {
        const {onClose, action, dispatch} = this.props

        onClose()
        action.forEach(dispatch)
    }

    render() {
        const {messages, onClose} = this.props

        return (
            <CardMessage
                title={messages.title}
                message={messages.message}
                positive={messages.positive}
                negative={messages.negative}
                onClose={onClose}
                onNegative={onClose}
                onPositive={this.handle}
            />
        )
    }
}

export default connect()(Confirmation)
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import Ava from 'ui/Ava'
import {__} from 'utils/i18n'
import Menu, {MenuItem} from 'ui/Menu'
import IconPassword from 'icons/password.svg'
import IconProfile from 'icons/user.svg'
import IconLogout from 'icons/logout.svg'
import IconHelp from 'icons/help.svg'
import IconSettings from 'icons/settings.svg'
import DropDown, {ALIGN_RIGHT, ALIGN_TOP} from 'ui/DropDown'

export default class UserInfo extends PureComponent {

    static propTypes = {
        showEditProfileInfoModal: PropTypes.func,
        showChangePasswordModal: PropTypes.func,
        showEditSettingsModal: PropTypes.func,
        logout: PropTypes.func,
        user: PropTypes.object,
    }

    handleChangePasswordClick = () => {
        this.props.showChangePasswordModal && this.props.showChangePasswordModal()
    }

    handleHelpClick = () => {
        window.open('http://help.tycomonitor.com/4.4/', '_blank')
    }

    handleProfileEdit = () => {
        this.props.showEditProfileInfoModal && this.props.showEditProfileInfoModal()
    }

    handleSettingsEdit = () => {
        this.props.showEditSettingsModal && this.props.showEditSettingsModal()
    }

    render() {
        const {user, logout} = this.props
        const trigger = <Ava name={user.name} id={user.id}/>

        return (
            <DropDown
                trigger={trigger}
                align={ALIGN_RIGHT | ALIGN_TOP}
                className="userInfo">

                <div className="card">
                    <div className="userInfo-user">
                        <Ava
                            className="userInfo-ava"
                            large
                            name={user.name}
                            id={user.id}/>

                        <div>
                            <h1 className="userInfo-name">{user.name}</h1>
                            <p className="userInfo-details">{user.email}</p>
                            <p className="userInfo-details">{user.countryName}</p>
                            <p className="userInfo-details">{user.phone}</p>
                        </div>
                    </div>

                    <Menu className="userInfo-menu">
                        <MenuItem Icon={IconSettings} onClick={this.handleSettingsEdit}>{__('Settings')}</MenuItem>
                        <MenuItem Icon={IconProfile} onClick={this.handleProfileEdit}>{__('Edit Profile')}</MenuItem>
                        <MenuItem Icon={IconPassword}
                                  onClick={this.handleChangePasswordClick}>{__('Change Password')}</MenuItem>
                        <MenuItem Icon={IconHelp} onClick={this.handleHelpClick}>{__('Help')}</MenuItem>
                        <MenuItem Icon={IconLogout} onClick={logout}>{__('Logout')}</MenuItem>
                    </Menu>
                </div>
            </DropDown>
        )
    }
}

import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class ProgressBar extends Component {

    static propTypes = {
        max: PropTypes.number,
        value: PropTypes.number,
        className: PropTypes.string,
        big: PropTypes.bool,
        indeterminate: PropTypes.bool,
        inline: PropTypes.bool,
    }

    static defaultProps = {
        max: 100,
    }

    renderPercentage() {
        const {max, value} = this.props
        const percentage = 100 * value / max

        const valueStyle = {width: 10000 / percentage + '%'}
        const text = Math.round(percentage) + '%'

        return (
            <Fragment>
                <div className="progress-bar" style={{width: percentage + '%'}}>
                    {percentage > 0 && (
                        <span className="progress-value" style={valueStyle}>{text}</span>
                    )}
                </div>
                <div className="progress-value">{text}</div>
            </Fragment>
        )
    }

    render() {
        const {className, big, indeterminate, inline} = this.props

        return (
            <div className={classNames('progress', {
                'progress--big': big,
                'progress--indeterminate': indeterminate,
                'progress--inline': inline,
            }, className)}>
                {!indeterminate && this.renderPercentage()}
            </div>
        )
    }
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import list from 'permissions/panel/devices/list'

import {withPermission} from 'containers/withPermission'

import Bar, {BarSpace} from 'ui/Bar'
import Buttons from 'ui/Buttons'
import Checkbox from 'ui/Checkbox'

import RefreshRssiButton from './Buttons/RefreshRssiButton'
import RefreshGsmButton from './Buttons/RefreshGSMButton'
import AddDeviceButton from './Buttons/AddDeviceButton'
import ToggleWalktestButton from './Buttons/ToggleWalktestButton'

import {__} from 'utils/i18n'

class DefaultDevicesBar extends Component {

    static propTypes = {
        onToggle: PropTypes.func.isRequired,
        hasList: PropTypes.bool,
    }

    render() {
        const {onToggle, hasList} = this.props

        return (
            <Bar>
                {hasList && <Checkbox onChange={onToggle} label={__('Show devices with troubles')}/>}

                <BarSpace/>

                <Buttons>
                    <RefreshGsmButton/>
                    <RefreshRssiButton/>
                    <AddDeviceButton/>
                    <ToggleWalktestButton/>
                </Buttons>
            </Bar>
        )
    }
}

export default withPermission({
    hasList: list,
})(DefaultDevicesBar)
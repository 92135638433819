import {handleActions} from 'redux-actions'
import {update} from './actions'
import {set} from 'immutable-modify'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [update](state, {payload}) {
        const byIds = payload.reduce((byIds, row) => {
            return set(byIds, [row.id], {
                ...byIds[row.id],
                ...row,
            })
        }, state.byIds)

        return {
            ...state,
            byIds,
        }
    },
}, defaultState)
import React, {PureComponent} from 'react'
import {FormRow, FormSection} from 'ui/Form'
import Checkbox from 'ui/Checkbox'
import Select, {Option} from 'ui/Select'
import {__} from 'utils/i18n'
import Input from 'ui/Input'
import Togglebox from 'ui/Togglebox'
import Textarea from 'ui/Textarea'
import CardForm from 'ui/CardForm'
import withSelectLoader from 'containers/withSelectLoader'
import {fetch as fetchLanguages} from 'modules/languages/actions'

const LanguageSelect = withSelectLoader(
    fetchLanguages,
    store => store.languages.isLoading,
    store => Object.values(store.languages.byIds).map(({id, name}) => ({value: id, label: name})),
)(Select)

export default class GroupFieldset extends PureComponent {

    state = {}

    constructor(props, context) {
        super(props, context)
        this.state.gprsKeepAlive = props.data && props.data.GPRSKeepAlive
        this.state.fibroGprsKeepAlive = props.data && props.data.FibroGPRSKeepAlive
        this.state.fibroBbaKeepAlive = props.data && props.data.FibroBBAKeepAlive
        this.state.fibro53GprsKeepAlive = props.data && props.data.Fibro53GPRSKeepAlive
        this.state.fibro53BbaKeepAlive = props.data && props.data.Fibro53BBAKeepAlive
    }

    rules = {
        name: {
            presence: true,
            length: {
                maximum: 24,
                tooLong: 'needs to have less then %{count} characters',
            },
        },

        description: {
            length: {
                maximum: 1024,
            },
        },

        GPRSKeepAlivePeriod: {
            presence: (value, attributes) => attributes.GPRSKeepAlive !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        GPRSOfflineTimer: {
            presence: (value, attributes) => attributes.GPRSKeepAlive !== null,
            numericality: (value, {GPRSKeepAlivePeriod}) => ({
                onlyInteger: true,
                greaterThan: parseInt(GPRSKeepAlivePeriod),
            }),
        },

        BBAOfflineTimer: {
            presence: true,
            numericality: (value, {BBAKeepAlivePeriod}) => ({
                onlyInteger: true,
                greaterThan: parseInt(BBAKeepAlivePeriod),
            }),
        },

        BBAKeepAlivePeriod: {
            presence: true,
            numericality: {
                onlyInteger: true,
            },
        },

        FibroKeepAlivePeriod: {
            presence: (value, attributes) => attributes.FibroGPRSKeepAlive !== null || attributes.FibroBBAKeepAlive !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        FibroBBAOfflineTimer: {
            presence: (value, attributes) => attributes.FibroBBAKeepAlive !== null,
            numericality: (value, {FibroKeepAlivePeriod}) => ({
                onlyInteger: true,
                greaterThan: parseInt(FibroKeepAlivePeriod),
            }),
        },

        FibroGPRSOfflineTimer: {
            presence: (value, attributes) => attributes.FibroGPRSKeepAlive !== null,
            numericality: (value, {FibroKeepAlivePeriod}) => ({
                onlyInteger: true,
                greaterThan: parseInt(FibroKeepAlivePeriod),
            }),
        },

        Fibro53BBAOfflinePeriod: {
            presence: (value, attributes) => attributes.Fibro53BBAKeepAlive !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        Fibro53BBAOfflineTimer: {
            presence: (value, attributes) => attributes.Fibro53BBAKeepAlive !== null,
            numericality: (value, {Fibro53BBAOfflinePeriod}) => ({
                onlyInteger: true,
                greaterThan: parseInt(Fibro53BBAOfflinePeriod),
            }),
        },

        Fibro53GPRSOfflinePeriod: {
            presence: (value, attributes) => attributes.Fibro53GPRSKeepAlive !== null,
            numericality: {
                onlyInteger: true,
            },
        },

        Fibro53GPRSOfflineTimer: {
            presence: (value, attributes) => attributes.Fibro53GPRSKeepAlive !== null,
            numericality: (value, {Fibro53GPRSOfflinePeriod}) => ({
                onlyInteger: true,
                greaterThan: parseInt(Fibro53GPRSOfflinePeriod),
            }),
        },

        serverMessagingLanguageId: {
            presence: true,
        },
    }

    handleGprsKeepAliveChange = (e) => {
        this.setState({gprsKeepAlive: e.target.checked})
    }

    handleFibroGprsKeepAliveChange = (e) => {
        this.setState({fibroGprsKeepAlive: e.target.checked})
    }

    handleFibroBBAKeepAliveChange = (e) => {
        this.setState({fibroBbaKeepAlive: e.target.checked})
    }

    handleFibro53GprsKeepAliveChange = (e) => {
        this.setState({fibro53GprsKeepAlive: e.target.checked})
    }

    handleFibro53BBAKeepAliveChange = (e) => {
        this.setState({fibro53BbaKeepAlive: e.target.checked})
    }


    render() {
        const {handle, data, ...props} = this.props
        const {gprsKeepAlive, fibroGprsKeepAlive, fibroBbaKeepAlive, fibro53GprsKeepAlive, fibro53BbaKeepAlive} = this.state

        return (
            <CardForm
                wide
                confirmOnDismiss
                onSubmit={handle}
                rules={this.rules}
                {...props}
            >

                <Input
                    autoFocus
                    name="name"
                    maxLength="24"
                    defaultValue={data.name}
                    label={__('Name')}/>

                <Textarea
                    name="description"
                    defaultValue={data.description}
                    label={__('Description')}/>

                <h3 className="title title--cols">{__('PowerMaster/PowerMax')}</h3>

                <div className="cols cols--stretch">
                    <FormSection className="col" title={__('Cellular')}>
                        <Togglebox
                            name="GPRSKeepAlive"
                            label={__('Supervision enabled')}
                            labelOff={__('Supervision disabled')}
                            defaultChecked={data.GPRSKeepAlive}
                            onChange={this.handleGprsKeepAliveChange}
                        />

                        <FormRow>
                            <Input
                                name="GPRSKeepAlivePeriod"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={data.GPRSKeepAlivePeriod}
                                disabled={!gprsKeepAlive}
                            />

                            <Input
                                name="GPRSOfflineTimer"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={data.GPRSOfflineTimer}
                                disabled={!gprsKeepAlive}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection className="col" title={__('Broadband')}>
                        <Togglebox
                            name="BBAEncryption"
                            label={__('Encryption enabled')}
                            labelOff={__('Encryption disabled')}
                            defaultChecked={data.BBAEncryption}
                        />

                        <FormRow>
                            <Input
                                name="BBAKeepAlivePeriod"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={data.BBAKeepAlivePeriod}
                            />

                            <Input
                                name="BBAOfflineTimer"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={data.BBAOfflineTimer}
                            />
                        </FormRow>
                    </FormSection>
                </div>

                <h3 className="title title--cols">{__('PowerSeries NEO/Pro, Quazar <5.3')}</h3>

                <div className="cols cols--stretch">
                    <FormSection className="col">
                        <Togglebox
                            name="FibroGPRSKeepAlive"
                            label={__('Cellular Supervision enabled')}
                            labelOff={__('Cellular Supervision disabled')}
                            defaultChecked={data.FibroGPRSKeepAlive}
                            onChange={this.handleFibroGprsKeepAliveChange}
                        />

                        <FormRow>
                            <Input
                                name="FibroKeepAlivePeriod"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={data.FibroKeepAlivePeriod}
                                disabled={!(fibroBbaKeepAlive || fibroGprsKeepAlive)}
                            />

                            <Input
                                name="FibroGPRSOfflineTimer"
                                label={__('Cellular Offline Timer')}
                                type="number"
                                defaultValue={data.FibroGPRSOfflineTimer}
                                disabled={!fibroGprsKeepAlive}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection className="col">
                        <Togglebox
                            name="FibroBBAKeepAlive"
                            label={__('Broadband Supervision enabled')}
                            labelOff={__('Broadband Supervision disabled')}
                            defaultChecked={data.FibroBBAKeepAlive}
                            onChange={this.handleFibroBBAKeepAliveChange}
                        />

                        <FormRow>
                            <Input
                                name="FibroBBAOfflineTimer"
                                label={__('Broadband Offline Timer')}
                                type="number"
                                defaultValue={data.FibroBBAOfflineTimer}
                                disabled={!fibroBbaKeepAlive}
                            />
                        </FormRow>
                    </FormSection>
                </div>

                <div className="title title--cols">
                    {__('PowerSeries NEO, Quazar 5.3 and higher')}
                </div>

                <div className="cols cols--stretch">
                    <FormSection className="col" title={__('Cellular')}>
                        <Togglebox
                            name="Fibro53GPRSKeepAlive"
                            label={__('Supervision enabled')}
                            labelOff={__('Supervision disabled')}
                            defaultChecked={data.Fibro53GPRSKeepAlive}
                            onChange={this.handleFibro53GprsKeepAliveChange}
                        />

                        <FormRow>
                            <Input
                                name="Fibro53GPRSOfflinePeriod"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={data.Fibro53GPRSOfflinePeriod}
                                disabled={!fibro53GprsKeepAlive}
                            />

                            <Input
                                name="Fibro53GPRSOfflineTimer"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={data.Fibro53GPRSOfflineTimer}
                                disabled={!fibro53GprsKeepAlive}
                            />
                        </FormRow>
                    </FormSection>

                    <FormSection className="col" title={__('Broadband')}>
                        <Togglebox
                            name="Fibro53BBAKeepAlive"
                            label={__('Supervision enabled')}
                            labelOff={__('Supervision disabled')}
                            defaultChecked={data.Fibro53BBAKeepAlive}
                            onChange={this.handleFibro53BBAKeepAliveChange}
                        />

                        <FormRow>
                            <Input
                                name="Fibro53BBAOfflinePeriod"
                                label={__('Supervision Period')}
                                type="number"
                                defaultValue={data.Fibro53BBAOfflinePeriod}
                                disabled={!fibro53BbaKeepAlive}
                            />

                            <Input
                                name="Fibro53BBAOfflineTimer"
                                label={__('Offline Timer')}
                                type="number"
                                defaultValue={data.Fibro53BBAOfflineTimer}
                                disabled={!fibro53BbaKeepAlive}
                            />
                        </FormRow>
                    </FormSection>
                </div>

                <div className="title title--cols">
                    {__('General')}
                </div>

                <div className="cols cols--stretch">
                    <FormSection className="col" title={__('Interactive')}>
                        <Checkbox
                            name="localWakeUp"
                            label={__('Local wake up')}
                            defaultChecked={data.localWakeUp}
                        />

                        <LanguageSelect
                            name="serverMessagingLanguageId"
                            label={__('Server Messaging Language')}
                            defaultValue={data.serverMessagingLanguageId}
                            defaultLabel={data.serverMessagingLanguage}
                        />
                    </FormSection>

                    <FormSection className="col" title={__('Other')}>
                        <Checkbox
                            name="timeSynchronization"
                            label={__('Panel\'s time synchronization')}
                            defaultChecked={data.timeSynchronization}
                        />

                        <Select
                            name="upgradeMethod"
                            label={__('Power Master upgrade method')}
                            defaultValue={data.upgradeMethod}
                        >
                            <Option value="gprs" label={__('GPRS')}/>
                            <Option value="bba" label={__('Broadband')}/>
                        </Select>
                    </FormSection>
                </div>
            </CardForm>
        )
    }

}
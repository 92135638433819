import {compose} from 'redux'

import withProps from 'containers/withProps'
import withForm from 'containers/withForm'

import MaintainForm from './MaintainForm'
import {markForService} from 'modules/forms/handlers'

import {__n} from 'utils/i18n'

export default compose(
    withForm(markForService),
    withProps(({panelIds}) => ({
        header: __n('Mark panel for service', 'Mark %s panels for service', panelIds.length),
    })),
)(MaintainForm)
import {takeEvery, call, put, fork, take, select, cancel, cancelled} from 'redux-saga/effects'
import {eventChannel} from 'redux-saga'
import keyBy from 'lodash-es/keyBy'

import {getProcesses, getStorageKey, setProcesses} from 'storage/processes'
import * as actions from 'modules/processes/manager/actions'
import {loggedOut} from 'modules/auth/sign/actions'

export function* persistProcesses() {
    const userId = yield select(({auth}) => auth.sign.user.id)

    const savedData = yield call(getProcesses, userId)

    if (savedData) {
        yield restore(savedData)
    }

    const restoreTask = yield fork(storeChangeWatcher, userId)
    const persistTask = yield takeEvery([
        actions.receive,
        actions.observe,
        actions.removeBatch,
        actions.removeFinishedBatches,
    ], persist, userId)

    yield take(loggedOut)
    yield cancel(restoreTask)
    yield cancel(persistTask)
}

function* storeChangeWatcher(userId) {
    const storageChannel = yield call(createProcessesTabPersistChannel, userId)

    try {
        while (true) {
            const data = yield take(storageChannel)
            yield restore(data)
        }
    } finally {
        if (yield cancelled()) {
            storageChannel.close()
        }
    }
}

function createProcessesTabPersistChannel(userId) {
    return eventChannel(emit => {
        const storagePersister = (e) => {
            if (e.key === getStorageKey(userId)) {
                const serializedData = e.newValue

                try {
                    emit(JSON.parse(serializedData))
                } catch (error) {
                    // Oh no!
                }
            }
        }

        window.addEventListener('storage', storagePersister)

        return () => {
            window.removeEventListener('storage', storagePersister)
        }
    })
}

function* restore(data) {
    if (!data) {
        return
    }

    const ids = Object.values(data.byKeys).reduce(
        (acc, process) => {
            acc[process.id] = process.key
            return acc
        },
        {},
    )

    yield put(actions.restore({
        ...data,
        ids,
    }))
}

function* persist(userId) {
    const {batches, byKeys} = yield select(({processes}) => processes.manager)

    const activeProcesses = Object.values(batches)
        .map(batch => batch.processes.map(key => byKeys[key]))
        .reduce((acc, processes) => acc.concat(processes), [])

    setProcesses(userId, {
        batches,
        byKeys: keyBy(activeProcesses, 'key'),
    })
}
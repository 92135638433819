import React, {Component} from 'react'
import classes from 'classnames'
import PropTypes from 'prop-types'
import calendarical from 'calendrical'
import timezones from 'utils/timezones'

import Definition from 'ui/Definition'
import Bar from 'ui/Bar'
import Button from 'ui/Button'
import Buttons from 'ui/Buttons'

import IconPrev from 'icons/arrow-prev.svg'
import IconNext from 'icons/arrow-next.svg'
import {__} from 'utils/i18n'
import {ScrollView} from 'ui/Layout'
import Spinner from 'ui/Spinner'
import DropDown, {ALIGN_TOP} from 'ui/DropDown'
import humanTime from 'utils/humanTime'
import momentPropType from 'utils/momentPropType'

export class CalendarItem extends Component {

    static propTypes = {
        children: PropTypes.node,

        title: PropTypes.node.isRequired,

        color: PropTypes.string,

        date: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            momentPropType
        ]).isRequired,

        Icon: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.instanceOf(Component)
        ]),

        onIconClick: PropTypes.func,
    }

    handleIconClick = e => {
        e.stopPropagation()
        e.preventDefault()
        this.props.onIconClick()
    }

    render() {
        const {className, date, Icon, color, title, children} = this.props

        const trigger = (
            <div className="calendar-item-box" style={{backgroundColor: color}}>
                <p className="calendar-item-content">
                    <span className="calendar-item-time">
                        {humanTime(date).time}
                    </span>
                    <span className="calendar-item-title">
                        {title}
                    </span>
                    {Icon && <span className="calendar-item-icon"><Icon onClick={this.handleIconClick}/></span>}
                </p>
            </div>
        )

        if (!children) {
            return <div className={classes('calendar-item', className)}>{trigger}</div>
        }

        return (
            <DropDown
                className={classes('calendar-item', 'calendar-item--interactive', className)}
                trigger={trigger}
                align={ALIGN_TOP}
                children={children}
            />
        )
    }

}

export default class Calendar extends Component {

    static propTypes = {
        date: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            momentPropType
        ]),

        isLoading: PropTypes.bool,

        onSelectDate: PropTypes.func.isRequired,
    }

    static defaultProps = {
        date: new Date(),
    }

    today = timezones.server()

    renderDay = ({date, inCurrentMonth}, index) => {
        if (!inCurrentMonth) {
            return <div className="calendar-day" key={index}></div>
        }

        date = timezones.server(date)

        const children = React.Children.toArray(this.props.children)
            .filter(child => child && child.props && date.isSame(child.props.date, 'day'))
            .sort((left, right) => timezones.server(left.props.date).isAfter(right.props.date) ? 1 : -1)
            .map((child, key) => React.cloneElement(child, {key}))

        return (
            <div className="calendar-day" key={index}>
                <div className={classes('calendar-day-number', {
                    'calendar-day-number--today': this.today.isSame(date, 'day'),
                })}>{date.format('D')}</div>
                {children}
            </div>
        )
    }

    renderWeek = (week, index) => {
        return (
            <div className="calendar-week" key={index}>
                {week.map(this.renderDay)}
            </div>
        )
    }

    handlePrev = () => {
        const {date, onSelectDate} = this.props
        onSelectDate(timezones.server(date).subtract(1, 'month').toDate())
    }

    handleToday = () => {
        const {onSelectDate} = this.props
        onSelectDate(new Date())
    }

    handleNext = () => {
        const {date, onSelectDate} = this.props
        onSelectDate(timezones.server(date).add(1, 'month').toDate())
    }

    renderDayNames() {
        return (
            <div className="calendar-week calendar-week--dayNames">
                {new Array(7).fill(0).map((_, key) =>
                    <span className="calendar-day" key={key}>
                    {timezones.server().weekday(key).format('dd')}
                    </span>
                )}
            </div>
        )
    }

    renderMonth() {
        const {date} = this.props
        const options = {
            weekStartsWith: timezones.server().localeData().firstDayOfWeek(),
        }

        const month = calendarical.getWeeksInMonth(date, options)

        return (
            <ScrollView>
                <div className="calendar-month">
                    {month.map(this.renderWeek)}
                </div>
            </ScrollView>
        )
    }

    render() {
        const {isLoading, date} = this.props

        return (
            <div className="calendar">
                <Bar className="calendar-header">
                    <Button onClick={this.handleToday}>{__('Today')}</Button>

                    <Buttons>
                        <Button onClick={this.handlePrev} Icon={IconPrev}/>
                        <Button onClick={this.handleNext} Icon={IconNext}/>
                    </Buttons>

                    <Definition
                        title={timezones.server(date).format('MMMM')}
                        detail={timezones.server(date).format('YYYY')}
                    />
                </Bar>

                {isLoading
                    ? <Spinner/>
                    : this.renderDayNames()
                }

                {!isLoading && this.renderMonth()}
            </div>
        )
    }

}
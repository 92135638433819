import React, {Component} from 'react'
import {compose} from 'redux'

import list from 'permissions/panel/remoteInspections/list'

import Layout, {ScrollView} from 'ui/Layout'

import {withPermission, withRejection} from 'containers/withPermission'

import RemoteInspectionTable from './RemoteInspectionsContent/RemoteInspectionTable'
import RemoteInspectionNext from './RemoteInspectionsContent/RemoteInspectionNext'
import LoadMoreButton from './RemoteInspectionsContent/LoadMoreButton'
import RemoteInspectionSideBar from './RemoteInspectionsContent/RemoteInspectionSideBar'

class RemoteInspectionsContent extends Component {
    render() {
        const {active, onRowClick} = this.props

        return (
            <Layout className="rri">
                <ScrollView className="rri-list">
                    <RemoteInspectionNext/>
                    <RemoteInspectionTable {...{onRowClick, active}}/>

                    <div className="btnHolder">
                        <LoadMoreButton/>
                    </div>
                </ScrollView>

                <RemoteInspectionSideBar {...{remoteInspectionResult: active}}/>
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: list}),
    withRejection(),
)(RemoteInspectionsContent)
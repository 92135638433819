import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import {status} from 'permissions/panel/devices/walktest'

import withDevices from 'containers/withDevices'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'

import Definition from 'ui/Definition'

import {__, __n} from 'utils/i18n'
import humanTime from 'utils/humanTime'

class WalktestStatus extends Component {

    static propTypes = {
        walktest: PropTypes.shape({
            isStopping: PropTypes.bool,
            isRunning: PropTypes.bool,
            process: PropTypes.shape({
                status: PropTypes.string,
            }),
            status: PropTypes.object,
        }),
    }

    render() {
        const {walktest} = this.props

        if (!walktest) {
            return null
        }

        const {isRunning, isStopping, status, process} = walktest

        if (!status) {
            return null
        }

        const zones = Object.values(status)

        if (!process) {
            return <Definition title={__('Walktest was never initiated')}/>
        }

        if (process.status === 'start' || !process.status) {
            return <Definition title={__('Starting...')}/>
        }

        const passed = zones.filter(s => s == 'ok').length

        if (isRunning) {
            return (
                <Definition
                    title={isStopping ? __('Stopping...') : __('Running...')}
                    detail={__('%d of %d devices passed', passed, zones.length)}/>
            )
        }

        const title = process.status === 'failed'
            ? __('Failed')
            : __('Finished')

        const detail = passed === zones.length
            ? __('All devices passed')
            : __n('1 device fail to pass', '%d devices fail to pass', zones.length - passed)

        const finished = process.finished ? humanTime(process.finished) : ''

        return <Definition title={title + ' ' + finished} detail={detail}/>
    }
}

export default compose(
    withPermission({isVisible: status}),
    withVisibility(),
    withDevices(),
)(WalktestStatus)
import {takeEvery, all, call, put} from 'redux-saga/effects'
import uniqBy from 'lodash-es/uniqBy'

import * as api from 'api/panel/firmware'
import {fetch, receive, upgrade} from './actions'
import {PROCESS_TYPE_NEO_UPGRADE} from 'constants/processTypes'
import generateProcess from 'modules/processes/manager/generateProcess'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import {takeEveryProcessComplete} from 'modules/processes/manager/takeProcess'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(fetch, watchFetch),
        takeEvery(upgrade, watchUpgrade),
        takeEveryProcessComplete(PROCESS_TYPE_NEO_UPGRADE, watchProcessComplete),
    ])
}

function* watchFetch({payload: {panelId}}) {
    try {
        const {appliances, process} = yield call(api.fetchList, panelId)

        yield put(receive({
            appliances,
            process: yield ensureProcess(process),
        }, panelId))
    } catch (error) {
        yield put(receive(error, panelId))
    }
}

function* watchUpgrade({payload: {panelId, packages}}) {
    const {execute, process} = yield generateProcess(PROCESS_TYPE_NEO_UPGRADE, panelId)
    yield put(receive({process}, panelId))

    try {
        yield execute(
            api.update,
            panelId,
            uniqBy(packages, 'packageName'),
        )
    } catch ({message}) {
        yield put(receive({process: null}, panelId))
        yield put(snackShow(message))
    }
}

function* watchProcessComplete({panelId}) {
    yield put(fetch(panelId))
}
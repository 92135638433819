import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Button from 'ui/Button'
import withConfirmation from 'containers/withConfirmation'
import {stop} from 'modules/processes/store/actions'
import {__} from 'utils/i18n'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withPermission} from 'containers/withPermission'
import {stop as stopPermission} from 'permissions/processes/actions'
import {withVisibility} from 'containers/withVisibility'

const ConfirmButton = withConfirmation()(Button)

class StopButton extends Component {
    static propTypes = {
        processId: PropTypes.number.isRequired,
        stop: PropTypes.func.isRequired,
    }

    handleClick = () => {
        const {processId, stop} = this.props

        stop(processId)
    }

    render() {
        return (
            <ConfirmButton
                onClick={this.handleClick}
                title={__('Firmware Upgrade')}
                message={__('Stop Firmware Upgrade?')}
                label={__('Stop Upgrade')}
            />
        )
    }
}

export default compose(
    withPermission({
        isVisible: stopPermission,
    }),
    withVisibility(),
    connect(
        null,
        dispatch => bindActionCreators({stop}, dispatch)
    )
)(StopButton)
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import IconMagnifier from 'icons/search.svg'
import IconCross from 'icons/cross.svg'
import Hotkey from 'ui/Hotkeys'

export default class SearchBar extends Component {

    static propTypes = {
        input: PropTypes.node,
        children: PropTypes.node,
        className: PropTypes.string,
        onChange: PropTypes.func,
        placeholder: PropTypes.string,
    }

    state = {
        value: '',
    }

    handleOnChange = e => {
        this.setState({
            value: e.target.value,
        })

        this.props.onChange && this.props.onChange(e.target.value, e)
    }

    handleClear = e => {
        this.setState({
            value: '',
        })

        this.props.onChange && this.props.onChange('', e)
    }

    render() {
        const {input, children, className, ...props} = this.props
        const {value} = this.state

        return (
            <div className="search-bar">
                <div className={classnames('search-box', className)}>
                    <div className="search-box-icon"><IconMagnifier/></div>

                    {input || <input className="search-box-input" value={value} {...props} onChange={this.handleOnChange}/>}

                    {value && <IconCross className="search-autoComplete-clear" onClick={this.handleClear}/>}

                    {!input && <Hotkey shortcut="esc" action={this.handleClear} scope="input"/>}
                </div>

                {children}
            </div>
        )
    }
}
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {
    showCreateReportModal,
} from 'modules/modals/actions'

import {withPermission} from 'containers/withPermission'
import {withSelectionHandler} from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import Button from 'ui/Button'
import IconFile from 'icons/file.svg'

import {__} from 'utils/i18n'
import {createReport} from 'permissions/panels/selection'

export default compose(
    withProps(() => ({
        Icon: IconFile,
        children: __('Create Report'),
    })),
    withPermission({isVisible: createReport}),
    withVisibility(),
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: showCreateReportModal,
        }, dispatch),
    ),
    withSelectionHandler(),
)(Button)

import withProps from 'containers/withProps'
import Page from 'ui/Page'

import CentralStationsTable from './CentralStationsTable'
import CentralStationsGridBar from './CentralStationsGridBar'
import CentralStationsSearch from './CentralStationsSearch'

export default withProps({
    Top: CentralStationsSearch,
    Bar: CentralStationsGridBar,
    Content: CentralStationsTable,
})(Page)
import {POLL_PANEL_LOCATIONS} from 'configs/pollers'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'
import {delay} from 'redux-saga'
import {takeEvery, select, all, call, put, race} from 'redux-saga/effects'
import * as actions from './actions'
import * as api from 'api/panel/locations'
import {snackShow} from 'modules/snacks'
import generateProcess from 'modules/processes/manager/generateProcess'
import {PROCESS_TYPE_PMAXSETCUSTOMZONES} from 'constants/processTypes'
import ensureProcess from 'modules/processes/manager/ensureProcess'
import {takeEveryProcessCompleteSuccessful} from 'modules/processes/manager/takeProcess'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.persist, watchPersist),
        takeEveryProcessCompleteSuccessful(PROCESS_TYPE_PMAXSETCUSTOMZONES, watchProcessCompleteSuccessful),
        createPollerSaga(
            actions.startPolling,
            actions.stopPolling,
            locationsDelay,
            watchFetch,
            true
        ),
    ])
}

function* locationsDelay() {
    return yield race({
        delay: delay(POLL_PANEL_LOCATIONS),
        // here can be some special conditions for invalidating locations.
    })
}

function* watchFetch({payload: panelId}) {
    try {
        const data = yield call(api.fetch, panelId)

        yield put(actions.receive({
            ...data,
            process: yield ensureProcess(data.process),
        }, panelId))
    } catch (error) {
        yield put(actions.receive(error, panelId))
        snackShow(error.message)
    }
}

function* watchPersist({payload: panelId}) {
    const {values} = yield select(state => state.panels.locations[panelId] || {})

    if (!values || Object.keys(values).length === 0) {
        yield put(actions.update({process: null}, panelId))
        return
    }

    const {execute, process} = yield generateProcess(PROCESS_TYPE_PMAXSETCUSTOMZONES, panelId)
    yield put(actions.update({process}, panelId))

    try {
        yield execute(api.save, panelId, values)
    } catch (error) {
        yield put(actions.update({process: null}, panelId))
        yield put(snackShow(error.message))
    }
}

function* watchProcessCompleteSuccessful({panelId}) {
    const {values, locations} = yield select(state => state.panels.locations[panelId] || {})

    yield put(actions.update({
        values: {},
        locations: locations.map(({id, custom, ...rest}) => ({
            id,
            custom: values.hasOwnProperty(id) ? values[id] : custom,
            ...rest,
        })),
    }, panelId))
}
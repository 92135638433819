import React, {Component} from 'react'
import {compose} from 'redux'
import PropTypes from 'prop-types'

import {general} from 'permissions/group/navigation'

import {ScrollView} from 'ui/Layout'
import List, {ListHintItem} from 'ui/List'

import {
    withPermission,
    withRejection,
} from 'containers/withPermission'

import {__, __n} from 'utils/i18n'

export class General extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
    }

    render() {
        const {group} = this.props
        const fibroEnabled = group.FibroGPRSKeepAlive || group.FibroBBAKeepAlive

        const empty = (
            <span className="empty">
                {__('not set')}
            </span>
        )

        return (
            <div className="groupInfo">
                <div className="card">
                    <ScrollView>
                        <List className="card-content" twoCols>
                            <ListHintItem name={__('Group Name')} text={group.name}/>
                            <ListHintItem name={__('Description')} text={group.description || empty}/>


                            <ListHintItem name={__('Server Messaging Language')}
                                          text={group.serverMessagingLanguage}/>
                            <ListHintItem name={__('Local Wake up')}
                                          text={group.localWakeUp ? __('Enabled') : __('Disabled')}/>

                            <ListHintItem name={__('Video Format')}
                                          text={group.videoFormat}/>


                            <ListHintItem name={__('Upgrade Method')}
                                          text={group.upgradeMethod === 'gprs' ? __('GPRS') : __('Broadband')}/>
                            <ListHintItem name={__('Time Synchronization')}
                                          text={group.timeSynchronization ? __('Enabled') : __('Disabled')}/>
                        </List>

                        <h1 className="card-header">
                            {__('PowerMaster/PowerMax')}
                        </h1>

                        <List className="card-content" twoCols>
                            <ListHintItem name={__('Broadband Supervision')}
                                          text={__n('%d second', '%d seconds', group.BBAKeepAlivePeriod)}/>

                            <ListHintItem name={__('Broadband Offline Timer')}
                                          text={`${group.BBAOfflineTimer} seconds`}/>

                            <ListHintItem name={__('Broadband Encryption')}
                                          text={group.BBAEncryption ? __('Enabled') : __('Disabled')}/>

                            {group.GPRSKeepAlive &&
                            <ListHintItem name={__('Cellular Supervision')}
                                          text={__n('%d second', '%d seconds', group.GPRSKeepAlivePeriod)}/>}

                            {group.GPRSKeepAlive &&
                            <ListHintItem name={__('Cellular Offline Timer')}
                                          text={__n('%d second', '%d seconds', group.GPRSOfflineTimer)}/>}

                            {!group.GPRSKeepAlive &&
                            <ListHintItem name={__('PowerMaster GPRS')} text={__('Disabled')}/>}


                        </List>

                        <h3 className="card-header">
                            {__('PowerSeries NEO/Pro, Quazar <5.3')}
                        </h3>

                        <List className="card-content" twoCols>
                            {fibroEnabled &&
                            <ListHintItem name={__('Supervision period')}
                                          text={`${group.FibroKeepAlivePeriod} seconds`}/>}

                            {group.FibroGPRSKeepAlive &&
                            <ListHintItem name={__('Broadband Offline Timer')}
                                          text={__n('%d second', '%d seconds', group.FibroBBAOfflineTimer)}/>}

                            {group.FibroBBAKeepAlive &&
                            <ListHintItem name={__('Cellular Offline Timer')}
                                          text={__n('%d second', '%d seconds', group.FibroGPRSOfflineTimer)}/>}

                            {!fibroEnabled && <ListHintItem name={__('Supervision')} text={__('Disabled')}/>}
                        </List>

                        <h3 className="card-header">
                            {__('PowerSeries NEO, Quazar 5.3 and higher')}
                        </h3>

                        <List className="card-content" twoCols>
                            {!group.Fibro53BBAKeepAlive && <ListHintItem name={__('Broadband Supervision')} text={__('Disabled')}/>}

                            {group.Fibro53BBAKeepAlive &&
                            <ListHintItem name={__('Broadband Supervision Period')}
                                          text={__n('%d second', '%d seconds', group.Fibro53BBAOfflinePeriod)}/>
                            }

                            {group.Fibro53BBAKeepAlive &&
                            <ListHintItem name={__('Broadband Offline Timer')}
                                          text={__n('%d second', '%d seconds', group.Fibro53BBAOfflineTimer)}/>
                            }

                            {!group.Fibro53GPRSKeepAlive && <ListHintItem name={__('Cellular Supervision')} text={__('Disabled')}/>}

                            {group.Fibro53GPRSKeepAlive &&
                            <ListHintItem name={__('Cellular Supervision Period')}
                                          text={__n('%d second', '%d seconds', group.Fibro53GPRSOfflinePeriod)}/>
                            }

                            {group.Fibro53GPRSKeepAlive &&
                            <ListHintItem name={__('Cellular Offline Timer')}
                                          text={__n('%d second', '%d seconds', group.Fibro53GPRSOfflineTimer)}/>
                            }
                        </List>
                    </ScrollView>
                </div>
            </div>
        )
    }
}

export default compose(
    withPermission({isAllowed: general}),
    withRejection(),
)(General)
import {TIMEOUTS_AUTOLOGOUT} from 'configs/timeouts'
import {getCountryName} from 'constants/ISO3166-1'
import React, {Component} from 'react'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'
import PropTypes from 'prop-types'

import {getNativeLanguageName} from 'constants/ISO639-1'

import {
    setLanguage,
    setAutologout,
    setAlarmsEnabled,
    setAlarmsSoundEnabled,
    setAlarmsNotificationsEnabled,
} from 'modules/settings/actions'
import {fetch, changeLocale} from 'modules/locales/actions'

import withLoader from 'containers/withLoader'

import CardForm from 'ui/CardForm'
import Togglebox from 'ui/Togglebox'
import Select, {Option} from 'ui/Select'
import {humanDuration} from 'utils/humanTime'
import {__} from 'utils/i18n'

class EditSettings extends Component {

    static propTypes = {
        setLanguage: PropTypes.func.isRequired,
        changeLocale: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
        timeout: PropTypes.number,
        locale: PropTypes.string,
        isAlarmsEnabled: PropTypes.bool,
        isAlarmSoundEnabled: PropTypes.bool,
        setAlarmsEnabled: PropTypes.func.isRequired,
        setAlarmsSoundEnabled: PropTypes.func.isRequired,
        setAlarmsNotificationsEnabled: PropTypes.func.isRequired,
    }

    state = {}

    handleEnableSupervisionChange = (e) => {
        const {setAlarmsEnabled} = this.props
        setAlarmsEnabled(e.target.checked)
    }

    handleEnableSoundChange = (e) => {
        const {setAlarmsSoundEnabled} = this.props
        setAlarmsSoundEnabled(e.target.checked)
    }

    handleEnableNotificationsChange = (e) => {
        const {setAlarmsNotificationsEnabled} = this.props
        setAlarmsNotificationsEnabled(e.target.checked)
    }

    handleChangeLocale = (e, value) => {
        const {setLanguage} = this.props
        this.setState({locale: value})
        setLanguage(value)
    }

    handleChangeAutologout = (e, value) => {
        const {setAutologout} = this.props
        setAutologout(value)
    }

    handleClose = () => {
        const {locale} = this.state
        const {changeLocale, onClose} = this.props

        if (locale) {
            changeLocale(locale)
        } else {
            onClose()
        }
    }

    renderLocaleOptions() {
        const {locales} = this.props

        const languages = locales
            .map(locale => locale.substr(0, 2))
            .filter((locale, index, array) => array.indexOf(locale) !== index)

        return locales.map(locale => {
            const [langCode, countryCode] = locale.split('-')
            const showCountry = languages.includes(langCode)

            let label = getNativeLanguageName(langCode)

            if (showCountry && countryCode) {
                const country = getCountryName(countryCode, langCode)
                label += ' - ' + (country || countryCode)
            }

            return (
                <Option
                    key={locale}
                    label={label}
                    value={locale}/>
            )
        })
    }

    render() {
        const {
            locale,
            autologout,
            isAlarmsEnabled,
            isAlarmSoundEnabled,
            isAlarmNotificationsEnabled,
        } = this.props

        return (
            <CardForm
                onClose={this.handleClose}
                onSubmit={this.handleClose}
                showDismiss={false}
                submitLabel={__('OK')}
                header={__('Edit Settings')}>

                <Select
                    label={__('Language')}
                    onChange={this.handleChangeLocale}
                    defaultValue={locale}>
                    {this.renderLocaleOptions()}
                </Select>

                <Select
                    label={__('Auto logout timeout')}
                    onChange={this.handleChangeAutologout}
                    defaultValue={autologout || TIMEOUTS_AUTOLOGOUT[0]}>
                    {TIMEOUTS_AUTOLOGOUT.map(
                        value => (
                            <Option key={value} value={value} label={humanDuration(value, 'minutes')}/>
                        ),
                    )}
                </Select>

                <h3 className="form-section-header">{__('Alarms supervision')}</h3>

                <Togglebox
                    label={__('Enable alarms supervision')}
                    onChange={this.handleEnableSupervisionChange}
                    checked={isAlarmsEnabled}
                />

                <br/>
                <p className={isAlarmsEnabled ? '' : 'empty'}>{__('When alarm occurred:')}</p>

                <Togglebox
                    label={__('Show notification')}
                    onChange={this.handleEnableNotificationsChange}
                    checked={isAlarmNotificationsEnabled}
                    disabled={!isAlarmsEnabled}
                />

                <Togglebox
                    label={__('Play sound')}
                    onChange={this.handleEnableSoundChange}
                    checked={isAlarmSoundEnabled}
                    disabled={!isAlarmsEnabled}
                />
            </CardForm>
        )
    }
}

export default compose(
    connect(
        ({locales, events, settings}) => {
            const {isLoading, list, error, locale} = locales
            const {autologout, alarms} = settings

            return {
                autologout,
                isLoading,
                error,
                locales: list,
                locale,
                isAlarmsEnabled: alarms.isEnabled,
                isAlarmSoundEnabled: alarms.isSoundEnabled,
                isAlarmNotificationsEnabled: alarms.isNotificationsEnabled,
            }
        },
        (dispatch) => bindActionCreators({
            setLanguage,
            setAutologout,
            changeLocale,
            fetchLocalesList: fetch,
            setAlarmsEnabled,
            setAlarmsSoundEnabled,
            setAlarmsNotificationsEnabled,
        }, dispatch),
    ),
    withLoader(({fetchLocalesList}) => fetchLocalesList()),
)(EditSettings)
import React from 'react'
import PropTypes from 'prop-types'

import humanTime from 'utils/humanTime'
import momentPropType from 'utils/momentPropType'

const empty = <span className="empty">—</span>

const Time = ({datetime, withSeconds}) => {
    if (!datetime) {
        return empty
    }

    const {time} = humanTime(datetime, withSeconds)
    return time || empty
}

Time.propTypes = {
    datetime: PropTypes.oneOfType([
        PropTypes.string,
        momentPropType,
        PropTypes.instanceOf(Date),
    ]).isRequired,
    withSeconds: PropTypes.bool,
}

export default Time
import {createAction} from 'redux-actions'

export default function createListActions(actionPrefix: string) {
    return {
        init: createAction(actionPrefix + '/INIT', (scope, action, filters) => ({scope, action, filters})),
        reset: createAction(actionPrefix + '/RESET', (filters = []) => ({filters})),
        fetch: createAction(actionPrefix + '/FETCH'),
        receive: createAction(actionPrefix + '/RECEIVE', (page, total) => ({page, total})),

        remove: createAction(actionPrefix + '/REMOVE'),
        revertRemove: createAction(actionPrefix + '/REVERT_REMOVE'),

        setStart: createAction(actionPrefix + '/SET_START'),
        setQuery: createAction(actionPrefix + '/SET_QUERY'),
        setSort: createAction(actionPrefix + '/SET_SORT', (sort, desc) => ({sort, desc})),
        setFilters: createAction(actionPrefix + '/SET_FILTERS'),
        addFilters: createAction(actionPrefix + '/ADD_FILTERS', (...filters) => filters),
        removeFilters: createAction(actionPrefix + '/REMOVE_FILTERS', (...filters) => filters.map(({$}) => $)),
        clearFilters: createAction(actionPrefix + '/CLEAR_FILTERS'),

        fetchSuggests: createAction(actionPrefix + '/FETCH_SUGGESTS', (fields, prefix) => ({
            fields: Array.isArray(fields) ? fields : [fields],
            prefix,
        })),
        receiveSuggests: createAction(actionPrefix + '/RECEIVE_SUGGESTS', (results, prefix) => ({results, prefix})),

        startPoll: createAction(actionPrefix + '/POLL/START'),
        stopPoll: createAction(actionPrefix + '/POLL/STOP'),
    }
}
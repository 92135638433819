import {withSearchScope} from 'containers/withSearchScopeLoader'
import React from 'react'
import {compose} from 'redux'

import list from 'permissions/events/list'
import selection from 'permissions/events/selection'

import withEvents from 'containers/withEvents'
import withSelection from 'containers/withSelection'
import {withPermission, withRejection} from 'containers/withPermission'

import EventsBySerialPage from './EventsBySerialPage'
import EventsListPage from './EventsListPage'

const EventsPage = (props) => {
    if (props.match.params.id) {
        return <EventsBySerialPage {...props}/>
    }

    return <EventsListPage {...props}/>
}

export default compose(
    withPermission({isAllowed: list, hasSelection: selection}),
    withRejection(),
    withEvents(),
    withSelection(),
    withSearchScope(
        (params, search) => {
            // That's not pretty cool, but very useful.
            // Good candidate to refactor
            // Filters should know about filters, not page :)

            const filters = []

            if (search.serial) {
                filters.push({
                    $: 'serial' + search.serial,
                    name: 'serial',
                    value: search.serial,
                })
            }

            if (search.severity) {
                filters.push({
                    $: 'severity$' + search.severity,
                    name: 'severity',
                    value: search.severity,
                })
            }

            return filters
        },
    ),
)(EventsPage)
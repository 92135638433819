import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'
import reduce from 'lodash-es/reduce'

import {withPermission, withRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'
import withDevices from 'containers/withDevices'

import list from 'permissions/panel/devices/list'

import Diagnostic from './List/Diagnostic'
import Walktest from './List/Walktest'

export class Devices extends Component {

    static propTypes = {
        devices: PropTypes.object.isRequired,
        showWalktest: PropTypes.bool.isRequired,
        selectedDevice: PropTypes.number,
        isPanelSelected: PropTypes.bool,
        onSelect: PropTypes.func,
        onSelectPanel: PropTypes.func,
    }

    getDevices() {
        const {filterTroubles, devices} = this.props

        if (!filterTroubles) {
            return devices
        }

        return reduce(devices, (acc, categoryDevices, category) => {
            categoryDevices = categoryDevices.filter(device => device.hasTroubles)

            if (categoryDevices.length === 0) {
                return acc
            }

            return {
                ...acc,
                [category]: categoryDevices,
            }
        }, {})
    }

    render() {
        const {
            showWalktest,
            selectedDevice,
            isPanelSelected,
            onSelect,
            onSelectPanel,
        } = this.props

        if (showWalktest) {
            return <Walktest/>
        }

        return (
            <Diagnostic {...{
                devices: this.getDevices(),
                selectedDevice,
                isPanelSelected,
                onSelect,
                onSelectPanel,
            }}/>
        )
    }
}

export default compose(
    withPermission({isAllowed: list}),
    withRejection(),
    withDevices(),
    withLoader(({fetch}) => fetch()),
)(Devices)

import React, {Component} from 'react'

import withEvents from 'containers/withEvents'
import Togglebox from 'ui/Togglebox'
import __ from 'utils/i18n'

class StreamNewEventsButton extends Component {

    handleChange = e => {
        const {setFirstPageStreamable} = this.props
        setFirstPageStreamable(e.target.checked)
    }

    render() {
        const {isFirstPageStreamable} = this.props

        return (
            <div className="bar-item">
                <Togglebox
                    label={__('Stream new events')}
                    checked={isFirstPageStreamable}
                    onChange={this.handleChange}
                />
            </div>
        )
    }
}

export default withEvents()(StreamNewEventsButton)
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import withLifeCycle from 'containers/withLifeCycle'
import withOptions from 'containers/withOptions'

export default function withSelectLoader(fetchActionCreator: (prefix: String, maxOptionsToShow: Number, props: Object) => Object,
                                         mapStateToIsLoading: (state: Object, props: Object) => Boolean,
                                         mapStateToOptions: (state: Object, props: Object) => Object,
                                         maxOptionsToShow ?: Number,
                                         fetchOnMount ?: Boolean) {
    const prefixes = []

    return compose(
        connect(
            (state, props) => ({
                isLoading: mapStateToIsLoading(state, props),
                maxOptionsToShow,
            }),
            (dispatch, props) => bindActionCreators({
                fetch: (prefix = '') => fetchActionCreator(
                    prefix,
                    maxOptionsToShow ? maxOptionsToShow + 1 : undefined,
                    props,
                ),
            }, dispatch),
        ),
        withLifeCycle(
            {
                onMount({fetch}) {
                    if (typeof fetchOnMount === 'boolean') {
                        fetchOnMount && fetch()
                    } else {
                        fetch()
                    }
                },
            },
            ({fetch, ...props}) => {
                if (!maxOptionsToShow) {
                    return props
                }

                return {
                    ...props,
                    onType(e, prefix) {
                        if (!prefixes.includes(prefix)) {
                            prefixes.push(prefix)
                            fetch(prefix)
                        }
                    },
                }
            },
        ),
        withOptions(mapStateToOptions),
    )
}
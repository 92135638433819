import withProps from 'containers/withProps'

import Page from 'ui/Page'
import path from 'utils/path'

import EventsSearch from './List/EventsSearch'
import EventsBySerialBar from './BySerial/EventsBySerialBar'
import EventsBySerialContent from './BySerial/EventsBySerialContent'

const EventsBySerialSearch = withProps(
    ({history}) => ({
        apply: () => history.push(path('events')),
    }),
)(EventsSearch)

export default withProps({
    Top: EventsBySerialSearch,
    Bar: EventsBySerialBar,
    Content: EventsBySerialContent,
})(Page)
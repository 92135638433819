import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import withLoader from 'containers/withLoader'
import {fetchGroupRoles} from 'modules/groups/one/actions'

import DropDownButton from 'ui/DropDownButton'
import List, {ListItem} from 'ui/List'
import KeyIcon from 'icons/key.svg'
import GroupIcon from 'icons/group.svg'
import {ALIGN_RIGHT} from 'ui/DropDown'

class GroupRolesListComponent extends Component {
    static propTypes = {
        roles: PropTypes.arrayOf(
            PropTypes.shape({
                name: PropTypes.string,
            }),
        ),
    }

    render() {
        const {roles} = this.props

        return (
            <List>
                {roles.map((role, i) => <ListItem key={i} Icon={GroupIcon}>{role.name}</ListItem>)}
            </List>
        )
    }
}

const GroupRolesList = compose(
    connect(
        ({groups}, {groupId}) => ({
            roles: groups.one.groupRoles[groupId] || [],
            isLoading: groups.one.isLoading,
        }),
        dispatch => bindActionCreators({
            fetchGroupRoles,
        }, dispatch),
    ),
    withLoader(({fetchGroupRoles, groupId}) => fetchGroupRoles(groupId)),
)(GroupRolesListComponent)

export default class GroupRoles extends Component {
    static propTypes = {
        groupId: PropTypes.number.isRequired,
    }

    render() {
        const {groupId} = this.props

        return (
            <DropDownButton Icon={KeyIcon} small flat align={ALIGN_RIGHT}>
                <div className="dropDown-content">
                    <GroupRolesList groupId={groupId}/>
                </div>
            </DropDownButton>
        )
    }
}
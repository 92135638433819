import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {matchPath} from 'react-router'

import * as permissions from 'permissions/navigation'

import NavLink from 'components/Nav/NavLink'
import NavLinkGroup from 'components/Nav/NavLinkGroup'
import RecentPanelLink from 'components/Nav/RecentPanelLink'
import NewEventsCounter from 'components/Nav/NewEventsCounter'

import __ from 'utils/i18n'
import path from 'utils/path'
import {withPermission} from 'containers/withPermission'
import NavLinkScopesGroup from 'components/Nav/NavLinkScopesGroup'
import {removeSearchScope} from 'modules/settings/actions'

import IconPanels from 'icons/panel.svg'
import IconInspection from 'icons/wrench.svg'
import IconEvents from 'icons/events.svg'
import IconReports from 'icons/report.svg'
import IconFirmware from 'icons/chip.svg'
import IconSettings from 'icons/settings.svg'

class NavMain extends PureComponent {
    static propTypes = {
        onSearchScopeRemove: PropTypes.func.isRequired,
        scopes: PropTypes.objectOf(
            PropTypes.objectOf(
                PropTypes.shape({
                    name: PropTypes.string.isRequired,
                }),
            ),
        ),
    }

    handleSearchScopeRemove = (page, scope) => {
        const {onSearchScopeRemove, history, location} = this.props

        const match = matchPath(location.pathname, {
            path: path(page, {scope}),
        })

        if (match) {
            history.push(path(page, {}))
        }

        onSearchScopeRemove(page, scope)
    }

    render() {
        const {permissions, scopes} = this.props

        return (
            <nav className="nav">
                <RecentPanelLink/>

                <NavLinkScopesGroup
                    Icon={IconPanels}
                    link={'panels'}
                    name={__('Panels')}
                    isPermitted={permissions.panels}
                    scopes={scopes.panels}
                    onRemove={this.handleSearchScopeRemove}
                />

                <NavLinkScopesGroup
                    Icon={IconInspection}
                    link={'remoteInspections'}
                    name={__('Remote Inspection')}
                    isPermitted={permissions.remoteInspections}
                    scopes={scopes.remoteInspections}
                    onRemove={this.handleSearchScopeRemove}
                />

                <NavLinkScopesGroup
                    Icon={IconEvents}
                    link={'events'}
                    name={__('Events')}
                    isPermitted={permissions.events}
                    scopes={scopes.events}
                    onRemove={this.handleSearchScopeRemove}
                    Coin={NewEventsCounter}
                />

                <NavLinkScopesGroup
                    Icon={IconReports}
                    link={'reports'}
                    name={__('Reports')}
                    isPermitted={permissions.reports}
                    scopes={scopes.reports}
                    onRemove={this.handleSearchScopeRemove}
                />

                <NavLinkGroup
                    Icon={IconFirmware}
                    path={path('firmware', {})}
                    to={path('firmware', {})}
                    name={__('Firmware')}
                    childrenPermissions={[permissions.firmwareUpgradeStatus]}
                    isPermitted={permissions.firmware}>
                    <NavLink
                        to={path('firmware.upgradeStatus', {})}
                        name={__('Upgrade Status')}
                        isPermitted={permissions.firmwareUpgradeStatus}/>
                </NavLinkGroup>

                <NavLinkGroup
                    Icon={IconSettings}
                    path={path('system', {})}
                    name={__('System')}
                    isPermitted={permissions.system}>

                    <NavLink
                        to={path('system.groups', {})}
                        name={__('Groups')}
                        isPermitted={permissions.groups}/>

                    <NavLink to={path('system.processes', {})}
                             name={__('Processes')}
                             isPermitted={permissions.processes}/>

                    <NavLink to={path('system.users', {})}
                             name={__('Users')}
                             isPermitted={permissions.users}/>

                    <NavLink to={path('system.roles', {})}
                             name={__('Roles')}
                             isPermitted={permissions.roles}/>

                    <NavLink to={path('system.actionLog', {})}
                             name={__('Action Log')}
                             isPermitted={permissions.actionLog}/>

                    <NavLink to={path('system.centralStations', {})}
                             name={__('Central Stations')}
                            isPermitted={permissions.centralStations}/>

                    <NavLink to={path('system.basicConfigurations', {})}
                            name={__('Basic Configurations')}
                            isPermitted={permissions.basicConfiguration}/>

                    <NavLink to={path('system.installers', {})}
                             name={__('Installers')}
                             isPermitted={permissions.installers}/>

                    <NavLink to={path('system.interactiveUsers', {})}
                             name={__('Interactive Users')}
                             isPermitted={permissions.interactiveUsers}/>

                    <NavLink to={path('system.dashboard', {})}
                             name={__('Dashboard')}
                             isPermitted={permissions.dashboard} />
                </NavLinkGroup>
            </nav>
        )
    }
}

export default compose(
    withRouter,
    connect(
        ({settings, panels}) => ({
            recentPanel: panels.recent || undefined,
            scopes: settings.scopes,
        }),
        dispatch => bindActionCreators({
            onSearchScopeRemove: removeSearchScope,
        }, dispatch),
    ),
    withPermission({permissions}),
)(NavMain)
import React, {Component} from 'react'

export default class Bar extends Component {
    render() {
        let {children} = this.props

        return (
            <div className="table-title">
                {children}
            </div>
        )
    }
}
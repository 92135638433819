import {PureComponent} from 'react'
import PropTypes from 'prop-types'
import {sprintf} from 'sprintf-js'

import {humanDiff} from 'utils/humanTime'
import momentPropType from 'utils/momentPropType'

export default class Duration extends PureComponent {

    static propTypes = {
        from: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            momentPropType,
        ]).isRequired,

        to: PropTypes.oneOfType([
            PropTypes.instanceOf(Date),
            momentPropType,
        ]),

        message: PropTypes.string,
    }

    run() {
        if (!this.tId) {
            this.setUpdateTimeout()
        }
    }

    stop() {
        if (this.tId) {
            clearTimeout(this.tId)
            this.tId = null
        }
    }

    setUpdateTimeout() {
        const diff = (new Date() - this.props.from) / 1000

        const timeout = diff >= 60
            ? Math.ceil(diff / 60) * 60 - diff // update on next minute
            : 15 // update every 15 seconds

        this.tId = setTimeout(this.tick, timeout * 1000)
    }

    tick = () => {
        this.forceUpdate()

        if (!this.props.to) {
            this.setUpdateTimeout()
        }
    }

    componentDidMount() {
        if (!this.props.to) {
            this.run()
        }
    }

    componentWillUnmount() {
        this.stop()
    }

    componentWillReceiveProps(props, context) {
        const {from, to} = this.props

        if (from === props.from && to === props.to) {
            return
        }

        this.stop()

        if (!props.to) {
            this.run()
        }
    }

    render() {
        const {message, from, to} = this.props

        const duration = humanDiff(from, to)

        if (!message) {
            return duration
        }

        return sprintf(message, duration)
    }

}
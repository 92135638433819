import {PROCESS_STORAGE_KEY} from 'constants/storageKeys'

/**
 * Returns storage key for user with current id, like 'prcesses_1'
 *
 * @param {Number} userId
 * @returns {String}
 *
 */
export function getStorageKey(userId) {
    return `${PROCESS_STORAGE_KEY}_${userId}`
}

/**
 * Returns cached active processes
 *
 * @param {Number} userId
 * @returns {Array}
 */
export function getProcesses(userId) {
    try {
        const serializedProcesses = localStorage.getItem(getStorageKey(userId))

        if (serializedProcesses === null) {
            return undefined
        }

        return JSON.parse(serializedProcesses)
    } catch (error) {
        console.log('get processes from storage error:', error)
        return undefined
    }
}

/**
 * Cache active process
 *
 * @param {Number} userId
 * @param {Array|Number} processes process manager state
 */
export function setProcesses(userId, processes) {
    try {
        const serializedProcesses = JSON.stringify(processes)
        localStorage.setItem(getStorageKey(userId), serializedProcesses)
    } catch (error) {
        console.error('set processes from storage error:', error)
    }
}
import Pager from 'ui/Pager'
import {compose} from 'redux'

import list from 'permissions/basicConfiguration/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withBasicConfigurations from 'containers/withBasicConfigurations'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withBasicConfigurations(),
)(Pager)
import BoardDateFilter from 'components/Search/Board/BoardDateFilter'
import Filter from 'components/Search/Filters/Filter'

import parse, {stringify} from 'utils/dateParser'

import __ from 'utils/i18n'

export default class DateFilter extends Filter {

    Component = BoardDateFilter

    range = true

    constructor(name, title, period = 'past', range = true) {
        super(name, title)

        this.range = range

        if (period === 'past') {
            this.items = {
                '{0day}': __('Today'),
                '{-1day:-1day}': __('Yesterday'),
                '{-7day}': __('Last Week'),
                '{-1month}': __('Last Month'),
            }
        } else {
            this.items = {
                '{0day}': __('Today'),
                '{+1day:+1day}': __('Tomorrow'),
                '{+7day}': __('Next Week'),
                '{+1month}': __('Next Month'),
            }
        }
    }

    digest(value) {
        if (value instanceof Object) {
            return this.name + '$custom'
        }

        return value
    }

    getItems() {
        return Object.keys(this.items)
            .map(this.createItem.bind(this))
    }

    getItemLabel(value) {
        if (this.items.hasOwnProperty(value)) {
            return this.items[value]
        }

        return stringify(value)
    }

    createItemByPrefix(prefix) {
        const parsed = parse(prefix)

        if (parsed && parsed.from) {
            return this.createItem(parsed)
        }

        return null
    }

}
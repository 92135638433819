import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'


import withEvents from 'containers/withEvents'
import withLifeCycle from 'containers/withLifeCycle'

import PanelCell from 'components/TableCells/PanelCell'

import Pager from 'ui/Pager'
import Bar, {BarSpace} from 'ui/Bar'
import Button from 'ui/Button'
import IconGoBack from 'icons/go-back.svg'

import path from 'utils/path'

class EventsBySerialBar extends Component {

    static propTypes = {
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        match: PropTypes.shape({
            params: PropTypes.shape({
                scope: PropTypes.array,
            }).isRequired,
        }).isRequired,
        rows: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                panelId: PropTypes.number,
                serial: PropTypes.string,
                account: PropTypes.string,
                webname: PropTypes.string,
            }),
        ).isRequired,
        row: PropTypes.shape({
            id: PropTypes.number,
            panelId: PropTypes.number,
            serial: PropTypes.string,
            account: PropTypes.string,
            webname: PropTypes.string,
        }).isRequired,
        start: PropTypes.number.isRequired,
        total: PropTypes.number.isRequired,
        perPage: PropTypes.number.isRequired,
        overbound: PropTypes.shape({
            next: PropTypes.object,
            prev: PropTypes.object,
        }).isRequired,
        nextOverbound: PropTypes.func.isRequired,
        prevOverbound: PropTypes.func.isRequired,
        disabled: PropTypes.bool,
    }

    handleGoBack = () => {
        const {history, match} = this.props
        history.push(path('events', {scope: match.params.scope}))
    }

    navigateTo({serial, id}) {
        const {history, match} = this.props

        history.push(path('events', {
            scope: match.params.scope,
            serial,
            id,
        }))
    }

    handlePageChange = (newStart) => {
        const {rows, start, perPage, overbound, nextOverbound, prevOverbound} = this.props
        const offset = newStart - start

        if (rows[offset]) {
            return this.navigateTo(rows[offset])
        }

        if (offset >= perPage && overbound.next) {
            nextOverbound()
            return this.navigateTo(overbound.next)
        }

        if (offset < 0 && overbound.prev) {
            prevOverbound()
            return this.navigateTo(overbound.prev)
        }
    }

    render() {
        const {rows, row, total, start, disabled} = this.props
        const {panelId, serial, account, webname} = row

        const index = rows && rows.indexOf(row)
        const offset = Math.max(0, index)

        return (
            <Bar>
                <Button
                    shortcut="backspace"
                    onClick={this.handleGoBack}
                    className="btn--goBack">
                    <IconGoBack/>
                </Button>

                <PanelCell
                    id={panelId}
                    className="title"
                    multiLine
                    {...{serial, account, webname}}
                />

                <BarSpace/>

                <Pager {...{
                    onPageChange: this.handlePageChange,
                    total,
                    perPage: 1,
                    start: start + offset,
                    disabled,
                }}/>
            </Bar>
        )
    }
}

export default compose(
    withEvents(),
    withLifeCycle({
        onMount({fetchOverbound}) {
            fetchOverbound()
        },
    }),
)(EventsBySerialBar)
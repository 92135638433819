import React, {Component} from 'react'
import {compose} from 'redux'

import list from 'permissions/remoteInspections/list'

import withSelection from 'containers/withSelection'
import {withPermission, withRejection} from 'containers/withPermission'
import withRemoteInspections from 'containers/withRemoteInspections'

import {TableSelection} from 'ui/Table'
import Layout, {ScrollView, SideBar} from 'ui/Layout'

import RemoteInspectionsTable from './Content/RemoteInspectionsTable'
import RemoteInspectionsResultHeader from './Content/Result/RemoteInspectionsResultHeader'
import RemoteInspectionsResultContent from './Content/Result/RemoteInspectionsResultContent'
import withSearchScope from 'containers/withSearchScopeLoader'

class RemoteInspectionContent extends Component {
    render() {
        const {selection} = this.props

        return (
            <TableSelection selection={selection} className="rri">
                <Layout>
                    <ScrollView className="rri-list">
                        <RemoteInspectionsTable/>
                    </ScrollView>

                    <SideBar>
                        <RemoteInspectionsResultHeader/>
                        <RemoteInspectionsResultContent/>
                    </SideBar>
                </Layout>
            </TableSelection>
        )
    }
}

export default compose(
    withPermission({isAllowed: list}),
    withRejection(),
    withSelection(),
    withRemoteInspections(),
    withSearchScope(),
)(RemoteInspectionContent)
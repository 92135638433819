import React, {Fragment, Component} from 'react'

import processErrorMessage from 'constants/processError'

import IconAlert from 'icons/alert.svg'
import IconCompleted from 'icons/check.svg'

import Definition from 'ui/Definition'
import ProgressBar from 'ui/ProgressBar'
import Spinner from 'ui/Spinner'
import {__} from 'utils/i18n'

export default class ProcessStatus extends Component {

    renderNotStarted() {
        const {isFailedToStart, errorMessage} = this.props.row

        if (isFailedToStart) {
            return (
                <Definition
                    className="danger"
                    Icon={IconAlert}
                    title={errorMessage || __('Failed to start')}/>
            )
        }

        return (
            <Definition
                Icon={Spinner}
                title={__('Starting…')}/>
        )
    }

    renderFailed() {
        const {error, errorMessage} = this.props.row

        return (
            <Definition
                className="danger"
                Icon={IconAlert}
                title={processErrorMessage(error, errorMessage)}/>
        )
    }

    renderRunning() {
        const {details, percentage} = this.props.row

        if (!details) {
            return <ProgressBar max={100} value={percentage || 0} indeterminate={percentage === 0}/>
        }

        return (
            <Fragment>
                <ProgressBar max={100} value={percentage || 0} indeterminate={percentage === 0} inline/>
                {details}
            </Fragment>
        )
    }

    renderSuccessful() {
        return (
            <Definition
                className="success"
                Icon={IconCompleted}
                title={__('Succeeded')}
            />
        )
    }

    render() {
        const {id, status, isRunning, isFailed, isSuccessful} = this.props.row

        switch (true) {
            case !id:
                return this.renderNotStarted()

            case isFailed:
                return this.renderFailed()

            case isRunning:
                return this.renderRunning()

            case isSuccessful:
                return this.renderSuccessful()

            default:
                return status
        }
    }
}
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {changeGroup} from 'permissions/panels/selection'

import {
    showChangePanelGroupModal,
} from 'modules/modals/actions'

import {withSelectionHandler} from 'containers/withSelection'
import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withProps from 'containers/withProps'

import {MenuItem} from 'ui/Menu'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: changeGroup}),
    withVisibility(),
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: showChangePanelGroupModal,
        }, dispatch),
    ),
    withProps(() => ({
        children: __('Change Group'),
    })),
    withSelectionHandler(),
)(MenuItem)
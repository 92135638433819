import withConfirmation from 'containers/withConfirmation'
import {__} from 'utils/i18n'
import Button from 'ui/Button'
import {compose} from 'redux'
import withProps from 'containers/withProps'

import IconStop from 'icons/cross.svg'

export default compose(
    withProps(({disabled}) => ({
        small: true,
        flat: true,
        primary: !disabled,
        Icon: IconStop,
        title: __('Stop process'),
        message: __('Do you want to stop process?'),
    })),
    withConfirmation()
)(Button)
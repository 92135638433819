import {takeEvery, all, call, put} from 'redux-saga/effects'

import * as actions from './actions'
import * as api from 'api/panel/state'

import {renamePartition} from 'modules/forms/handlers'
import generateProcess, {observeBatch} from 'modules/processes/manager/generateProcess'
import {snackShow} from 'modules/snacks'

import {PROCESS_TYPE_PMAXSTATESET} from 'constants/processTypes'

export default function* () {
    yield all([
        takeEvery(actions.fetch, watchFetch),
        takeEvery(actions.sendState, watchSendState),
        takeEvery(renamePartition.SUCCESS, watchRenamePartition),
    ])
}

export function* watchFetch({payload: {panelId}}) {
    try {
        const state = yield call(api.getPartitionStates, panelId)
        yield put(actions.receive(state, panelId))
    } catch (error) {
        yield put(actions.receive(error, panelId))
    }
}

function* watchSendState(action) {
    const {panelId, partition, state, modifier} = action.payload
    try {
        const {execute} = yield generateProcess(PROCESS_TYPE_PMAXSTATESET, panelId)

        yield execute(api.sendState, panelId, partition, state, modifier)
    } catch (e) {
        yield put(snackShow(e.message))
    }
    yield put(actions.fetch(panelId))
}

function* watchRenamePartition({meta: {panelId, partitionId, name}, payload: {result: process}}) {
    yield observeBatch(process)

    if (!process.isFailed) {
        yield put(actions.renamePartition(panelId, partitionId, name))
    }
    else {
        yield put(snackShow(process.errorMessage))
    }
}
import createPermission, {any} from 'permissions/createPermission'

export {default as panels} from './panels/page'
export {default as remoteInspections} from './remoteInspections/page'
export {default as events} from './events/page'
export {default as reports} from './reports/page'
export {default as panel} from './panel/page'
export {default as firmware} from './firmware/page'
export {default as firmwareUpgradeStatus} from './firmware/status'

import processes from './processes/page'
import users from './users/page'
import groups from './groups/page'
import roles from './roles/page'
import actionLog from './actionLog/page'
import installers from './installers/page'
import interactiveUsers from './interactiveUsers/page'
import centralStations from './centralStations/page'
import basicConfiguration from './basicConfiguration/page'
import dashboard from './dashboard/page'

export {
    processes,
    groups,
    users,
    roles,
    actionLog,
    installers,
    interactiveUsers,
    centralStations,
    basicConfiguration,
    dashboard
}

export const system = createPermission(
    any(
        processes,
        groups,
        users,
        roles,
        actionLog,
        installers,
        interactiveUsers,
        centralStations,
        basicConfiguration,
        dashboard,
    ),
)

import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {withRouter} from 'react-router-dom'

import Button from 'ui/Button'

import {__} from 'utils/i18n'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {edit} from 'permissions/basicConfiguration/actions'

import {save} from 'modules/basicConfiguration/one/actions'

export default compose(
    withRouter,
    withPermission({isVisible: edit}),
    withVisibility(),
    connect(
        () => ({
            primary: true,
            label: __('Save'),
        }),
        (dispatch, {match}) => bindActionCreators({
            onClick: () => save({basicConfigId: match.params.id}),
        }, dispatch),
    ),
)(Button)
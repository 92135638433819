import {
    get
} from 'api/http'

import {
    mapRemoteInspection
} from 'api/remoteInspection'

import {
    mapRemoteInspectionResult
} from 'api/remoteInspectionResult'

export function fetch(panelId, from, to, start, count = 10) {
    return get('/unit_remoteinspection_results/getall', {
        unt_id: panelId,
        from: from ? from.format('YYYY-MM-DD') : null,
        to: to ? to.format('YYYY-MM-DD') : null,
        start,
        count,
    }).then(({inspection, rows, count}) => ({
        inspection: mapRemoteInspection(inspection),
        results: rows.map(mapRemoteInspectionResult),
        count,
    }))
}
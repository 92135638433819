import React from 'react'
import PropTypes from 'prop-types'

import Button from 'ui/Button'
import IconRefresh from 'icons/refresh.svg'
import {__} from 'utils/i18n'
import Spinner from 'ui/Spinner'

const RefreshButton = ({isRefreshing, Icon, children, disabled, ...props}) => (
    <Button Icon={isRefreshing ? Spinner : Icon} disabled={isRefreshing || disabled} {...props}>
        {children || __('Refresh')}
    </Button>
)

RefreshButton.propTypes = {
    disabled: PropTypes.bool,
    Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    isRefreshing: PropTypes.bool,
}

RefreshButton.defaultProps = {
    Icon: IconRefresh,
}

export default RefreshButton
import moduleTitle from 'constants/moduleTypes'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {MODULE_TYPE_GPRS, MODULE_TYPE_BBA, MODULE_STATE_ONLINE, MODULE_STATE_OFFLINE} from 'constants/moduleTypes'
import {PANEL_TYPE_POWER_MASTER_360, PANEL_TYPE_POWER_MASTER_360R} from 'constants/panelType'
import Tooltip from 'ui/Tooltip'

export default class Communication extends Component {

    static propTypes = {
        moduleType: PropTypes.oneOf([MODULE_TYPE_GPRS, MODULE_TYPE_BBA]).isRequired,
        module: PropTypes.shape({
            connected: PropTypes.bool,
            state: PropTypes.string,
        }),
        showTooltip: PropTypes.bool,
    }

    static defaultProps = {
        showTooltip: true,
    }

    getLetter = () => {
        const {moduleType, model} = this.props

        switch (moduleType) {
            case MODULE_TYPE_GPRS:
                return 'G'
            case MODULE_TYPE_BBA:
            default:
                switch (model) {
                    case PANEL_TYPE_POWER_MASTER_360:
                    case PANEL_TYPE_POWER_MASTER_360R:
                        return (
                            <span>B<sup className="communication--alt">G</sup></span>
                        )

                    default:
                        return 'B'
                }


        }
    }

    getCommunicationStatusClassName() {
        const {module} = this.props

        if (!module) {
            return 'communication--none'
        }

        if (module.hasOwnProperty('installed') && !module.installed) {
            return 'communication--none'
        }

        switch (true) {
            case module.connected:
                return 'communication--connected'
            case module.state === MODULE_STATE_OFFLINE:
                return 'communication--offline'
            case module.state === MODULE_STATE_ONLINE:
                return 'communication--online'
        }

        return null
    }

    render() {
        const {module, showTooltip} = this.props

        const classes = classnames('communication', this.getCommunicationStatusClassName())

        if (showTooltip) {
            return (
                <Tooltip className={classes} tooltip={moduleTitle(module)}>
                    {this.getLetter()}
                </Tooltip>
            )
        }

        return (
            <div className={classes}>
                {this.getLetter()}
            </div>
        )
    }
}
import React, {PureComponent} from 'react'
import RRIFailedIcon from 'icons/rri-failed.svg'
import RRISucceededIcon from 'icons/rri-succeeded.svg'
import RRIUnknownIcon from 'icons/rri-unknown.svg'
import PanelLink from 'components/PanelLink'

export default class RRICell extends PureComponent {

    getIcon() {
        switch (this.props.rri) {
            case 'success':
                return <RRISucceededIcon/>
            case 'fail':
                return <RRIFailedIcon className="remoteInspection-icon--fail"/>
            default:
                return <RRIUnknownIcon className="remoteInspection-icon--unknown"/>
        }
    }

    render() {
        const {id} = this.props

        return (
            <PanelLink to="remoteInspections" id={id}>
                {this.getIcon()}
            </PanelLink>
        )
    }
}
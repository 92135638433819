import {withLocationChange} from 'containers/withLocationChange'
import ActivatePanelInteractiveBar from 'pages/Panel/InteractiveBar/ActivatePanelInteractiveBar'
import ConfigurationChangesInteractiveBar from 'pages/Panel/InteractiveBar/ConfigurationChangesInteractiveBar'
import MakeBasicConfigurationInteractiveBar from 'pages/Panel/InteractiveBar/MakeBasicConfigurationInteractiveBar'
import {getItemsByKeys, getMenuItems} from 'pages/Panel/PanelPageRouting'
import React from 'react'
import {compose} from 'redux'
import {content} from 'permissions/panel/page'
import * as tabs from 'permissions/panel/navigation'

import withPanel from 'containers/withPanel'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import withProps from 'containers/withProps'
import ProcessLoader from 'ui/ProcessLoader'
import get from 'lodash-es/get'
import reduce from 'lodash-es/reduce'
import {__} from 'utils/i18n'

import PanelPageTop from './PanelPageTop'
import PanelPageBar from './PanelPageBar'
import PanelPageContent from './PanelPageContent'

import Page from 'ui/Page'

class PanelPage extends Page {

    renderTop() {
        return <PanelPageTop {...this.props}/>
    }

    renderBar() {
        if (this.props.panel.isDiscoveryInProgress) {
            return null
        }

        return <PanelPageBar menu={this.props.items}/>
    }

    renderContent() {
        if (this.props.panel.isDiscoveryInProgress) {
            return <ProcessLoader message={__('Discovery in progress')}/>
        }

        return <PanelPageContent routes={this.props.items}/>
    }

    renderInteractiveBar() {
        const {id, isDiscoveryInProgress} = this.props.panel

        if (isDiscoveryInProgress) {
            return null
        }

        return [
            <ActivatePanelInteractiveBar id={id} key="activate-panel"/>,
            <ConfigurationChangesInteractiveBar key="configuration"/>,
            <MakeBasicConfigurationInteractiveBar key="make-basic-configuration"/>,
        ]
    }
}

export default compose(
    withPermission({
        isAllowed: content,
        allowedItems: {
            ...tabs,
        },
    }),
    withRejection(),
    withProps(({match}) => ({
        key: match.params.id,
    })),
    withPanel(),
    withLoader(({fetch}) => {
        fetch()
    }),
    withLocationChange(
        ({location, match}, {remember}) => {
            remember(location.pathname)
        },
    ),
    withLifeCycle({
        onMount({startPollPanelData}) {
            startPollPanelData()
        },

        onUnmount({stopPollPanelData}) {
            stopPollPanelData()
        },
    }),
    withProps(({allowedItems, panel: {vendor, info}}) => {
        const vendorItems = getMenuItems(vendor)
        let mergedItems = reduce(
            allowedItems,
            (res, isAllowed, item) => {
                if (isAllowed && get(vendorItems, item, false)) {
                    res.push(item)
                }
                return res
            },
            [],
        )

        if (!info.isVirtualKeypad) {
            mergedItems = mergedItems.filter(item => item !== 'keypad')
        }

        return {
            items: getItemsByKeys(mergedItems),
        }
    }),
)(PanelPage)

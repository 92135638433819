import * as api from 'api/panels'
import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'
import {SEVERITY_TROUBLE} from 'constants/severityType'
import {TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE} from 'constants/troubleType'
import {update} from 'modules/panels/store/actions'
import {takeEvery, all, put, select, call} from 'redux-saga/effects'

import {sortFaults, refreshState, pushBasicConfiguration} from 'api/panels'
import * as actions from './actions'

import {PROCESS_TYPE_PMAXANONYMOUS, PROCESS_TYPE_PMAXSTATEGET} from 'constants/processTypes'

import {
    changeGroup,
    editPanelInfo,
    editPanelCustomerInfo,
    suspendFaults,
    resumeFaults,
    resolveFaults,
    markForService, reassignService,
} from 'modules/forms/handlers'

import {fetch} from 'modules/panels/one/actions'
import {generateBatch} from 'modules/processes/manager/generateProcess'
import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        takeEvery(actions.refreshState, watchRefreshState),
        takeEvery(actions.refresh, watchRefresh),
        takeEvery(changeGroup.SUCCESS, watchChangeGroup),
        takeEvery(editPanelInfo.SUCCESS, watchEditPanelInfo),
        takeEvery(editPanelCustomerInfo.SUCCESS, watchEditPanelCustomerInfo),
        takeEvery(suspendFaults.SUCCESS, watchSuspendFaults),
        takeEvery(markForService.SUCCESS, watchMarkForService),
        takeEvery(reassignService.SUCCESS, watchReassignService),
        takeEvery(resumeFaults.SUCCESS, watchResumeFaults),
        takeEvery(resolveFaults.SUCCESS, watchResolveFaults),
        takeEvery(actions.pushBasicConfiguration, watchPushBasicConfiguration),
    ])
}

function* watchEditPanelInfo({meta}) {
    const id = meta.panelId
    const {type, ...data} = meta.data
    const panel = yield select(({panels}) => panels.store.byIds[id] || {})

    const modules = Object.keys(panel.modules).reduce((acc, key) => ({
        ...acc,
        key: type.includes(key)
            ? panel.modules[key] || 'offline'
            : false,
    }), {})

    yield put(actions.update({
        id,
        ...data,
        modules,
    }))

    yield put(fetch(id))
}

function* watchEditPanelCustomerInfo({meta}) {
    const {panelId, data} = meta

    yield put(actions.update({
        id: panelId,
        contact: data,
    }))
}

function* watchChangeGroup({meta}) {
    const {ids, groupId} = meta
    const group = yield select(state => state.groups.store.byIds[groupId])
    yield put(actions.update(ids.map(id => ({id, group: group.name}))))
}

function* watchRefreshState({payload: panelIds}) {
    const {execute} = yield generateBatch(PROCESS_TYPE_PMAXSTATEGET, panelIds)

    try {
        yield execute(refreshState, panelIds)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function* watchRefresh({payload: panelIds}) {
    try {
        yield put(actions.setRefreshing())
        const data = yield call(api.fetch, {filters: {id: panelIds}})
        yield put(update(data.rows))
        yield put(actions.setRefreshing(false))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

function updatePanelFaults(panels, faultIds, patch) {
    const updater = typeof patch === 'function'
        ? patch
        : faults => faults.map(fault => {
            if (!faultIds.includes(fault.id)) {
                return fault
            }

            return {
                ...fault,
                ...patch,
            }
        })

    return Object.values(panels).reduce(
        (acc, panel) => {
            if (!panel.faults || panel.faults.every(fault => !faultIds.includes(fault.id))) {
                return acc
            }

            acc.push({
                id: panel.id,
                faults: sortFaults(updater(panel.faults)),
            })

            return acc
        },
        [],
    )
}

function* watchSuspendFaults({meta: {faultIds}}) {
    const panels = yield select(state => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(panels, faultIds, {isSuspended: true})

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}

function* watchResumeFaults({meta: {faultIds}}) {
    const panels = yield select(state => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(panels, faultIds, {isSuspended: false})

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}

function* watchResolveFaults({meta: {faultIds}}) {
    const panels = yield select(state => state.panels.store.byIds)
    const panelsPatch = updatePanelFaults(
        panels,
        faultIds,
        faults => faults.filter(fault => !faultIds.includes(fault.id)),
    )

    if (panelsPatch.length > 0) {
        yield put(actions.update(panelsPatch))
    }
}

function* watchMarkForService({meta: {ids, comment, userId, user}, payload: {result}}) {
    const byIds = yield select(state => state.panels.store.byIds)

    const fault = {
        severity: SEVERITY_TROUBLE,
        type: TROUBLE_TYPE_PANEL_MARK_FOR_SERVICE,
        deviceType: DEVICE_TYPE_CONTROL_PANEL,
        isMarkForService: true,
        isSuspended: false,
        zone: null,
        comment,
    }

    const panels = ids
        .map(id => byIds[id])
        .filter(panel => panel)
        .map(({id, faults}) => ({
            id,
            user,
            userId,
            faults: sortFaults((faults || []).concat({
                id: result[id],
                ...fault,
            })),
        }))

    if (panels.length > 0) {
        yield put(actions.update(panels))
    }
}

function* watchReassignService({meta: {ids, userId, user}}) {
    const panels = ids
        .map(id => ({
            id,
            user,
            userId,
        }))

    if (panels.length > 0) {
        yield put(actions.update(panels))
    }
}

function *watchPushBasicConfiguration({payload: {basicConfigId, panelIds}}) {
    const {execute} = yield generateBatch(PROCESS_TYPE_PMAXANONYMOUS, panelIds)

    try {
        yield execute(pushBasicConfiguration, panelIds, basicConfigId)
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
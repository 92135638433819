import React, {Component} from 'react'

import UpgradeButton from './GridBar/Buttons/UpgradeButton'

import FirmwarePager from './GridBar/FirmwarePager'
import FirmwareDropDown from './GridBar/FirmwareDropDown'

import Bar, {BarSpace} from 'ui/Bar'
import Buttons from 'ui/Buttons'

export default class FirmwareUpgradeGridBar extends Component {
    render() {
        return (
            <Bar>
                <FirmwareDropDown/>

                <Buttons>
                    <UpgradeButton/>
                </Buttons>

                <BarSpace/>

                <FirmwarePager/>
            </Bar>
        )
    }
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import {CardActions, CardContent, CardHeader} from 'ui/Card'
import DismissButton from 'ui/CardForm/DismissButton'
import CardClose from 'ui/CardForm/Close'
import Form from 'ui/Form'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default class CardForm extends Component {

    static propTypes = {
        wide: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.oneOf([2, 3]),
        ]),
        className: PropTypes.string,
        header: PropTypes.node,
        isLoading: PropTypes.bool,
        editable: PropTypes.bool,
        showDismiss: PropTypes.bool,
        submitLabel: PropTypes.string,
        dissmissLabel: PropTypes.string,
        onSubmit: PropTypes.func.isRequired,
        onClose: PropTypes.func,
        confirmOnDismiss: PropTypes.bool,
    }

    static defaultProps = {
        editable: true,
        showDismiss: true,
        confirmOnDismiss: false,
    }

    state = {
        changed: false,
    }

    dismiss = (e) => {
        e && e.preventDefault()
        this.props.onClose && this.props.onClose(e)
    }

    onChange = (...args) => {
        this.setState({
            changed: true,
        })

        this.props.onChange && this.props.onChange(...args)
    }

    isNeedConfirmation() {
        const {confirmOnDismiss} = this.props
        return confirmOnDismiss && this.state.changed
    }

    render() {
        const {
            header,
            onClose,
            className,
            children,
            submitLabel,
            dissmissLabel,
            showDismiss,
            wide,
            editable,
            ...props
        } = this.props

        delete props.confirmOnDismiss

        return (
            <Form {...props} onChange={this.onChange} className={classes('card', className, {
                'card--wide': wide === true,
                'card--wide-2': wide === 2,
                'card--wide-3': wide === 3,
            })}>
                {onClose && <CardClose confirmOnDismiss={this.isNeedConfirmation()} onClick={onClose}/>}
                {header && <CardHeader>{header}</CardHeader>}

                <CardContent>
                    {children}
                </CardContent>

                <CardActions>
                    {onClose && showDismiss &&
                    <DismissButton borderless shortcut="esc" onClick={this.dismiss} confirmOnDismiss={this.isNeedConfirmation()}>
                        {dissmissLabel || __('Dismiss')}
                    </DismissButton>}

                    {editable &&
                    <Button primary disabled={props.isLoading} type="submit">
                        {submitLabel || __('Save')}
                    </Button>}
                </CardActions>
            </Form>
        )
    }
}
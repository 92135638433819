import React, {Component} from 'react'
import {compose} from 'redux'

import list from 'permissions/panel/remoteInspections/list'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'

import InputDatePicker from 'ui/InputDatePicker'

import {__} from 'utils/i18n'

class FilterRemoteInspectionsList extends Component {
    render() {
        const {defaultValue, onChange} = this.props

        return (
            <span>
                 <InputDatePicker range placeholder={__('Filter by date:')} {...{defaultValue, onChange}}/>
            </span>
        )
    }
}

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withPanelRemoteInspection(),
    withProps(({from, to, setRange}) => ({
        defaultValue: {from, to},
        onChange: (from, to) => setRange(from, to),
    })),
)(FilterRemoteInspectionsList)
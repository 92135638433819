import {handleActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'

import * as actions from './actions'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [actions.update]({byIds}, {payload}) {
        return {
            byIds: {
                ...byIds,
                ...keyBy(payload, 'id'),
            },
        }
    },
}, defaultState)

import mapKeys from 'lodash-es/mapKeys'

import {post, get} from 'api/http'
import {mapProcess} from 'api/processes'
import mapValidationErrors from 'api/base/mapValidationErrors'

import mapper from 'utils/dataMapper'
import {getVendor, isNeoSerial} from 'utils/panelType'
import {PMAX_VIRTUAL_KEYPAD_SUPPORT_VLM_VERSION} from 'constants/virtualKeypad'

const map = mapper(data => ({
    id: parseInt(data.unt_id),
    groupId: parseInt(data.utg_id),
    userId: parseInt(data.usr_id),
    serial: data.unt_serial,
    isNeo: isNeoSerial(data.unt_serial),
    vendor: getVendor({model: data.unt_model, serial: data.unt_serial}),
    account: data.unt_account,
    webname: data.unt_name,
    group: data._utg_name,
    simNumber: data.unt_sim_number,
    contact: {
        name: data.unt_contact_name,
        email: data.unt_contact_email,
        phone: data.unt_contact_phone,
        address: data.unt_contact_address,
        remark: data.unt_remark,
    },
    model: data.unt_model,
    family: data.family,
    info: {
        ip: data.unt_ip_addr,
        model: data.unt_model,
        isVirtualKeypad: parseInt(data.unt_vlm_version) > PMAX_VIRTUAL_KEYPAD_SUPPORT_VLM_VERSION,
    },
    isActivated: data.unt_activated === '1',
    isDiscoveryInProgress: data.unt_activated === null,
}))

const mapOnline = mapper(data => ({
    id: parseInt(data.unt_id),
    connected: data.connected,
    online: data.online,
    refreshProcess: data.refreshProcess ? mapProcess(data.refreshProcess) : null,
    isActivated: data.activated,
    isDiscoveryInProgress: data.activated === null,
}))

const keysMap = {
    id: 'unt_id',
    serial: 'unt_serial',
    name: 'unt_contact_name',
    email: 'unt_contact_email',
    phone: 'unt_contact_phone',
    address: 'unt_contact_address',
    remark: 'unt_remark',
    groupId: 'utg_id',
    account: 'unt_account',
    simNumber: 'unt_sim_number',
    webname: 'unt_name',
}

const mapModules = ({type, ...data}) => {
    if (typeof type === 'undefined') {
        return data
    }

    return {
        ...data,
        _unt_module_gprs: type.includes('gprs') ? 'offline' : 'none',
        _unt_module_bb: type.includes('bba') ? 'offline' : 'none',
    }
}

const mapUsers = mapper(({max_users_count, user_names}) => ({
    maxUsersCount: max_users_count,
    userNames: user_names,
}))

export function retrieveData(panelId) {
    return get('/unit_general/form', {unt_id: panelId}).then(map)
}

export function isOnline(panelId) {
    return get('/unit_general/isonline', {unt_id: panelId}).then(mapOnline)
}

export function updateData(panelId, form) {
    return post('/units_all/edit', {
        unt_id: panelId,
        form: mapKeys(mapModules(form), (value, key) => keysMap[key] || key),
    })
        .catch(e => mapValidationErrors(e, keysMap))
}

export function refreshState(panelId) {
    return post('/pmaxstate/getstate', {unt_id: panelId}).then(mapProcess)
}

export function createNew(data) {
    let form = mapKeys(mapModules(data), (value, key) => keysMap[key] || key)
    return post('/units_all/add', {form})
        .catch(e => mapValidationErrors(e, keysMap))
}

export function activatePanel(unitId, installerCode) {
    return post('/unit_general/activate', {unt_id: unitId, installerCode})
        .then(mapProcess)
        .catch(mapValidationErrors)
}

export function getUsers(panelId) {
    return get('/unit_general/getusers', {unt_id: panelId}).then(mapUsers)
}

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import nl2br from 'react-nl2br'
import classes from 'classnames'

import IconMore from 'icons/more-vert.svg'

import Ava from 'ui/Ava'
import humanTime from 'utils/humanTime'
import {MenuIcon} from 'ui/Menu'
import Tooltip from 'ui/Tooltip'

export default class Comment extends Component {

    static propTypes = {
        user: PropTypes.shape({
            name: PropTypes.string.isRequired,
            id: PropTypes.number.isRequired,
        }),
        text: PropTypes.node.isRequired,
        isMine: PropTypes.bool,
        isNew: PropTypes.bool,
        className: PropTypes.string,
        time: PropTypes.any,
    }

    render() {
        const {children, className, isMine, isNew, user, text, time} = this.props

        return (
            <div className={classes('comment', className, {'comment--isNew': isNew, 'comment--mine': isMine})}>
                {!isMine &&
                <Tooltip tooltip={user.name}>
                    <Ava name={user.name} id={user.id}/>
                </Tooltip>
                }

                <div className="comment-content">
                    <div className="comment-text">
                        {nl2br(text)}
                    </div>

                    <div className="comment-date">
                        {humanTime(time).time}
                    </div>
                </div>

                {children && <MenuIcon Icon={IconMore} className="comment-more">{children}</MenuIcon>}
            </div>
        )
    }

}
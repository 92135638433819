import {get, post} from 'api/http'
import {mapProcess} from 'api/processes'
import dataMapper from 'utils/dataMapper'

const mapVideoOnDemandInfo = ({video, process}) => ({
    video: mapVideoOnDemand(video),
    process: process && mapProcess(process),
})

const mapVideoOnDemand = dataMapper(({time, videos, frames}) => ({
    time: time,
    frames: frames ? frames : [],
    video: videos ? videos : {},
}))

const mapVideo = dataMapper(video => ({
    time: video.arrived,
    frames: video.frames,
    location: video.location,
    preview: video.preview,
    isReady: video.ready,
    videoPath: video.video,
    zone: video.zone,
}))

export function getVideo(unitId, unitZoneId) {
    return get('/unit_diagnostic/video', {
        unt_id: unitId,
        utz_id: unitZoneId,
    }).then(mapVideo)
}

export function getVideoOnDemandInfo(unitId, unitZoneId) {
    return get('/unit_diagnostic/videoondemandinfo', {
        unt_id: unitId,
        utz_id: unitZoneId,
    }).then(mapVideoOnDemandInfo)
}

export function requestVideo(unitId, unitZoneId) {
    return post('/unit_diagnostic/requestvideo', {
        unt_id: unitId,
        utz_id: unitZoneId,
    }).then(mapProcess)
}

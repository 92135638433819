import React, {Component} from 'react'
import PropTypes from 'prop-types'
import ProcessBatch from 'components/Processes/ProcessBatch'
import {__} from 'utils/i18n'

import IconDoneAll from 'icons/check-all.svg'

export default class ProcessBatches extends Component {

    static propTypes = {
        batches: PropTypes.array.isRequired,
        untrack: PropTypes.func.isRequired,
        untrackFinished: PropTypes.func.isRequired,
        showInfo: PropTypes.func.isRequired,
    }

    render() {
        const {batches, untrack, untrackFinished, showInfo} = this.props

        if (batches.length === 0) {
            return null
        }

        return (
            <div className="processes">
                <h3 className="app-aside-title">
                    <span className="app-aside-title-content">
                        {__('My processes')}
                    </span>

                    <IconDoneAll onClick={untrackFinished} className="app-aside-title-icon"/>
                </h3>

                <div className="processes-content">
                    {batches.map(batch => (
                        <ProcessBatch
                            key={batch.id}
                            batch={batch}
                            untrack={untrack}
                            showInfo={showInfo}
                        />
                    ))}
                </div>
            </div>
        )
    }

}
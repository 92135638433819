import React from 'react'
import {__, __n} from 'utils/i18n'
import humanTime from 'utils/humanTime'
import Definition from 'ui/Definition'

const NextRICell = ({next, repetition}) => {
    const d = humanTime(next)
    let detail = d && d.time
    if (next && repetition) {
        detail += ' | ' + __n('Every month', 'Every %d months', repetition)
    }

    return (
        <div className="rri-nearest">
            <div>
                {__('Next RI:')}

                <span className="rri-next-date">
                        {next
                            ? <Definition title={d.date} detail={detail}/>
                            : __('Not scheduled')}
                    </span>
            </div>
        </div>
    )
}

export default NextRICell
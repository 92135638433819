import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const SIGNAL_LEVEL_BAD = 'bad'
export const SIGNAL_LEVEL_POOR = 'poor'
export const SIGNAL_LEVEL_OK = 'ok'
export const SIGNAL_LEVEL_GOOD = 'good'
export const SIGNAL_LEVEL_STRONG = 'strong'
export const SIGNAL_LEVEL_1_WAY = '1 way'
export const SIGNAL_LEVEL_NO_CONNECTION = 'no connection'
export const SIGNAL_LEVEL_NO_RESULT = 'no result'
export const SIGNAL_LEVEL_NO_TEST = 'no test'
export const SIGNAL_LEVEL_PREENROLL = 'preenroll'
export const SIGNAL_LEVEL_TOO_EARLY = 'too early'
export const SIGNAL_LEVEL_UNDEFINED = 'undefined'

const unknown = require('icons/signal-unknown.svg')
const level0 = require('icons/signal-level-0.svg')
const level1 = require('icons/signal-level-1.svg')
const level2 = require('icons/signal-level-2.svg')
const level3 = require('icons/signal-level-3.svg')
const level4 = require('icons/signal-level-4.svg')

const deviceSignalIcons = {
    [SIGNAL_LEVEL_BAD]: level1,
    [SIGNAL_LEVEL_POOR]: level2,
    [SIGNAL_LEVEL_GOOD]: level3,
    [SIGNAL_LEVEL_STRONG]: level4,
    [SIGNAL_LEVEL_1_WAY]: require('icons/signal-1way.svg'),
    [SIGNAL_LEVEL_NO_CONNECTION]: require('icons/signal-no-connection.svg'),
    [SIGNAL_LEVEL_PREENROLL]: require('icons/signal-no-connection.svg'),
    [SIGNAL_LEVEL_NO_RESULT]: unknown,
    [SIGNAL_LEVEL_NO_TEST]: unknown,
    [SIGNAL_LEVEL_TOO_EARLY]: unknown,
    [SIGNAL_LEVEL_UNDEFINED]: unknown,
}

const gsmModuleSignal = {
    [SIGNAL_LEVEL_BAD]: level0,
    [SIGNAL_LEVEL_POOR]: level1,
    [SIGNAL_LEVEL_OK]: level2,
    [SIGNAL_LEVEL_GOOD]: level3,
    [SIGNAL_LEVEL_STRONG]: level4,
}

export function deviceSignalLevelIcon(signalLevel) {
    if (deviceSignalIcons.hasOwnProperty(signalLevel)) {
        return deviceSignalIcons[signalLevel]
    }

    warn(`Unknown device signal level ${signalLevel}`)

    return unknown
}

export function gsmSignalLevelIcon(signalLevel) {
    if (gsmModuleSignal.hasOwnProperty(signalLevel)) {
        return gsmModuleSignal[signalLevel]
    }

    warn(`Unknown gsm signal level ${signalLevel}`)

    return unknown
}

export function signalLevelTitle(signalLevel) {
    if (!signalLevel) {
        return __('No measurements')
    }

    switch (signalLevel) {
        case SIGNAL_LEVEL_BAD: return __('Bad')
        case SIGNAL_LEVEL_POOR: return __('Poor')
        case SIGNAL_LEVEL_OK: return __('Ok')
        case SIGNAL_LEVEL_GOOD: return __('Good')
        case SIGNAL_LEVEL_STRONG: return __('Strong')
        case SIGNAL_LEVEL_1_WAY: return __('One way')
        case SIGNAL_LEVEL_NO_CONNECTION: return __('No connection')
        case SIGNAL_LEVEL_NO_RESULT: return __('No result')
        case SIGNAL_LEVEL_NO_TEST: return __('No test')
        case SIGNAL_LEVEL_PREENROLL: return __('Preenroll')
        case SIGNAL_LEVEL_TOO_EARLY: return __('Too early')
        case SIGNAL_LEVEL_UNDEFINED: return __('Undefined')
    }

    warn(`Unknown signal level ${signalLevel}`)

    return signalLevel
}
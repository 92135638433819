import React from 'react'
import classnames from 'classnames'

import CheckIcon from 'icons/check.svg'
import CrossIcon from 'icons/cross.svg'
import QuestionIcon from 'icons/question.svg'
import Circle from 'ui/Circle'

const getIcon = status => {
    switch (status) {
        case 'ok':
            return CheckIcon
        case 'fail':
            return CrossIcon
        default:
            return QuestionIcon
    }
}

export default function WalktestMark({status}) {
    return (
        <Circle
            Icon={getIcon(status)}
            className={classnames('device-walktestMark', {
                'success': status === 'ok',
                'danger': status === 'fail',
            })}
        />
    )
}
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateFilterString from 'api/base/generateFilterString'
import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'

import {get} from 'api/http'
import {keysMap, mapProcess} from 'api/processes'
import mapper from 'utils/dataMapper'

const mapUpgrades = (versions, modules) => {
    let upgrades = []

    versions = versions ? versions.split(',') : []
    modules = modules ? modules.split(',') : []

    for (let idx = 0; idx < modules.length; idx++) {
        upgrades.push({
            module: modules[idx],
            version: versions[idx],
        })
    }

    return upgrades
}
const mapUpgradeRow = mapper(({versions, modules, ...process}) => ({
    ...mapProcess(process),
    upgrades: mapUpgrades(versions, modules),
}))

export function fetch({start = 0, perPage: count, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/firmware/upgradestatus', {
        start,
        count,
        filter,
        query,
    }).then(createRowsResponseParser(mapUpgradeRow))
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('/firmware/suggest', {suggest, start, count}).then(mapSuggests(fields, keysMap))
}
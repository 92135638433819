import {compose} from 'redux'

import {remove} from 'permissions/basicConfiguration/selection'

import {withSelectionHandler} from 'containers/withSelection'
import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'
import withBasicConfigurations from 'containers/withBasicConfigurations'

import RemoveIcon from 'icons/remove.svg'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: remove}),
    withVisibility(),
    withBasicConfigurations(),
    withProps(({remove: onClick}) => ({
        onClick,
        message: __('Do you really want to remove selected basic configurations?'),
        title: __('Remove basic configurations'),
        label: __('Remove'),
        Icon: RemoveIcon,
    })),
    withSelectionHandler(),
    withConfirmation(),
)(Button)
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateFilterString from 'api/base/generateFilterString'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {mapFormData, keysMap} from 'api/group'
import {get, post} from 'api/http'

import dataMapper from 'utils/dataMapper'

export const mapGroupCSLink = dataMapper((link) => ({
    profileId: parseInt(link.etp_id),
    centralStationId: parseInt(link.cls_id),
}))

export const mapLanguage = dataMapper((data) => ({
    id: parseInt(data.ugl_id),
    name: data.ugl_name,
    short: data.ugl_short,
    locale: data.ugl_locale,
    isDefault: data.ugl_default === 'y',
}))

export const mapGroup = dataMapper((group) => ({
    id: parseInt(group.utg_id),
    name: group.utg_name,
    description: group.utg_description,
    total: parseInt(group.total_cnt),
    online: parseInt(group.online_cnt),
    BBAOfflineTimer: parseInt(group.bba_offline_timer),
    BBAKeepAlivePeriod: parseInt(group.bba_timer_seconds),
    GPRSKeepAlive: group.gprs_timer_enabled === 'yes',
    GPRSKeepAlivePeriod: parseInt(group.gprs_timer_seconds),
    GPRSOfflineTimer: parseInt(group.gprs_offline_timer),
    FibroKeepAlivePeriod: parseInt(group.fibro_timer_seconds),
    FibroGPRSOfflineTimer: parseInt(group.fibro_gprs_offline_timer),
    FibroBBAOfflineTimer: parseInt(group.fibro_bba_offline_timer),
    FibroBBAKeepAlive: group.fibro_bba_timer_enabled === 'yes',
    FibroGPRSKeepAlive: group.fibro_gprs_timer_enabled === 'yes',

    Fibro53BBAOfflineTimer: parseInt(group.fibro_53_bba_offline_timer),
    Fibro53BBAKeepAlive: group.fibro_53_bba_timer_enabled === 'yes',
    Fibro53BBAOfflinePeriod: parseInt(group.fibro_53_bba_timer_seconds),
    Fibro53GPRSOfflineTimer: parseInt(group.fibro_53_gprs_offline_timer),
    Fibro53GPRSKeepAlive: group.fibro_53_gprs_timer_enabled === 'yes',
    Fibro53GPRSOfflinePeriod: parseInt(group.fibro_53_gprs_timer_seconds),

    serverMessagingLanguage: group.ugl_name,
    localWakeUp: group.utg_local_wakeup === 'yes',
    videoFormat: group.vof_name,
    BBAEncryption: group.utg_secure_pnet === 'yes',
    timeSynchronization: group.utg_time_sync === 'yes',
    upgradeMethod: group.utg_upgrade_media,
    links: group.hasOwnProperty('links') && group.links ? group.links.map(link => mapGroupCSLink(link)) : [],
}))

export function fetch({start = 0, perPage: count = 10, filters, query, sort, sortDesc}) {
    const filter = generateFilterString(filters, keysMap)

    if (sort) {
        sort = keysMap[sort] || sort

        if (sortDesc) {
            sort = '-' + sort
        }
    }

    return get('/group/getallgroups', {start, count, filter, query, sort})
        .then(createRowsResponseParser(mapGroup))
}

export function remove(groupIds) {
    return post('/group/remove', {groupIds})
}

export function add(data) {
    return post('/group/add', {form: mapFormData(data)})
        .catch(e => mapValidationErrors(e, keysMap))
}

export function edit(id, data) {
    return post('/group/edit', {
        form: mapFormData({id, ...data}),
    }).catch(e => mapValidationErrors(e, keysMap))
}

export function fetchGroupMessagingLanguages() {
    return get('/group/languages')
        .then(({count, rows}) => ({
            count,
            rows: rows.map(mapLanguage),
        }))
}
import React from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import page from 'permissions/group/page'

import {
    withPermission,
    withRejection,
} from 'containers/withPermission'
import withLoader from 'containers/withLoader'
import withGroup from 'containers/withGroup'

import Page from 'ui/Page'

import GroupBar from './GroupBar'
import GroupContent from './GroupContent'

class GroupPage extends Page {

    static propTypes = {
        group: PropTypes.object,
    }

    renderTop() {
        const {group} = this.props

        return (
            <GroupBar group={group}/>
        )
    }

    renderContent() {
        const {group} = this.props

        return (
            <GroupContent group={group}/>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withGroup(),
    withLoader(({fetch}) => fetch()),
)(GroupPage)
import {get, post} from 'api/http'
import {mapProcessBatch} from 'api/processes'
import mapper from 'utils/dataMapper'

const mapPackage = mapper(data => ({
    id: parseInt(data.uep_id),
    type: data.uep_type,
    name: data.uep_name,
    version: data.uep_version,
    description: data.uep_description,
}))

const map = mapper(data => ({
    id: parseInt(data.utd_id),
    type: data.utd_rest_name,
    name: data.utd_name,
    groupAbbr: data.utd_group_abbr,
    upgradePackages: data.upgrade_packages && data.upgrade_packages.map(mapPackage) || [],
}))

export function listAppliance() {
    return get('/firmware/listappliance').then(appliances => appliances.map(map))
}

export function upgrade(panelIds, applianceId, upgradePackageId) {
    return post('firmware/upgrade', {
        unt_id: panelIds,
        utd_id: applianceId,
        uep_id: upgradePackageId,
    }).then(mapProcessBatch)
}

export function retry(ids) {
    return post('/firmware/retry', {prs_id: ids}).then(mapProcessBatch)
}

import {warn} from 'utils/log'

import * as DeviceTypes from 'constants/deviceType'
import {__} from 'utils/i18n'

export const DEVICE_CATEGORY_ZONES = 'ZONES'
export const DEVICE_CATEGORY_MODULES = 'MODULES'
export const DEVICE_CATEGORY_ACCESSORIES = 'ACCESSORIES'
export const DEVICE_CATEGORY_SMART = 'SMART'
export const DEVICE_CATEGORY_EXTENDERS = 'EXTENDERS'
export const DEVICE_CATEGORY_SIRENS = 'SIRENS'
export const DEVICE_CATEGORY_OUTPUTS = 'OUTPUTS'
export const DEVICE_CATEGORY_UNKNOWN = 'UNKNOWN'

export default function deviceCategory(category) {
    switch (category) {
        case DEVICE_CATEGORY_ZONES: return __('Zones')
        case DEVICE_CATEGORY_MODULES: return __('Modules')
        case DEVICE_CATEGORY_ACCESSORIES: return __('Accessories')
        case DEVICE_CATEGORY_SMART: return __('Smart devices')
        case DEVICE_CATEGORY_EXTENDERS: return __('Extenders')
        case DEVICE_CATEGORY_SIRENS: return __('Sirens')
        case DEVICE_CATEGORY_OUTPUTS: return __('Outputs')
        case DEVICE_CATEGORY_UNKNOWN: return __('Other')
    }

    warn(`Unknown category ${category}`)

    return deviceCategory(DEVICE_CATEGORY_UNKNOWN)
}

const categories = {
    [DeviceTypes.DEVICE_TYPE_UNKNOWN]: DEVICE_CATEGORY_UNKNOWN,

    [DeviceTypes.DEVICE_TYPE_ZONE]: DEVICE_CATEGORY_ZONES,
    [DeviceTypes.DEVICE_TYPE_CAMERA]: DEVICE_CATEGORY_ZONES,

    [DeviceTypes.DEVICE_TYPE_POWER_LINK]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_COMMUNICATOR]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_PGH]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_AUDIO_VERIFICATION]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_IO_EXPANDER_HSM3408]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_BUS_REPEATER_HSM3204CX]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS_HSM2204]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY_MODULE_HSM2300]: DEVICE_CATEGORY_MODULES,
    [DeviceTypes.DEVICE_TYPE_POWER_SUPPLY_3A]: DEVICE_CATEGORY_MODULES,

    [DeviceTypes.DEVICE_TYPE_KEYFOB]: DEVICE_CATEGORY_ACCESSORIES,
    [DeviceTypes.DEVICE_TYPE_GUARD_KEY]: DEVICE_CATEGORY_ACCESSORIES,
    [DeviceTypes.DEVICE_TYPE_WIRELESS_COMMANDER]: DEVICE_CATEGORY_ACCESSORIES,
    [DeviceTypes.DEVICE_TYPE_WIRED_KEYPAD]: DEVICE_CATEGORY_ACCESSORIES,
    [DeviceTypes.DEVICE_TYPE_2_WAY_WIRELESS_KEYPAD]: DEVICE_CATEGORY_ACCESSORIES,
    [DeviceTypes.DEVICE_TYPE_PENDANT]: DEVICE_CATEGORY_ACCESSORIES,
    [DeviceTypes.DEVICE_TYPE_PROXY_TAG]: DEVICE_CATEGORY_ACCESSORIES,

    [DeviceTypes.DEVICE_TYPE_X10]: DEVICE_CATEGORY_SMART,

    [DeviceTypes.DEVICE_TYPE_REPEATER]: DEVICE_CATEGORY_EXTENDERS,
    [DeviceTypes.DEVICE_TYPE_EXPANDER_33]: DEVICE_CATEGORY_EXTENDERS,
    [DeviceTypes.DEVICE_TYPE_OUTPUT_EXPANDER]: DEVICE_CATEGORY_EXTENDERS,
    [DeviceTypes.DEVICE_TYPE_ZONE_EXPANDER]: DEVICE_CATEGORY_EXTENDERS,

    [DeviceTypes.DEVICE_TYPE_WL_SIREN]: DEVICE_CATEGORY_SIRENS,

    [DeviceTypes.DEVICE_TYPE_PGM]: DEVICE_CATEGORY_OUTPUTS,
}

export function categoryByDeviceType(deviceType) {
    if (categories.hasOwnProperty(deviceType)) {
        return categories[deviceType]
    }

    warn(`Unknown category for deviceType ${deviceType}`)

    return DEVICE_CATEGORY_UNKNOWN
}
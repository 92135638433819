import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'
import {troubleTitle} from 'constants/troubleType'
import withProps from 'containers/withProps'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import list from 'permissions/panels/list'

import {fetchSuggests, fetch, addFilters, removeFilters, clearFilters, setQuery} from 'modules/panels/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import __ from 'utils/i18n'
import {showSaveSearchModal} from 'modules/modals/actions'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new ValuesFilter('faults', __('Faulty'), {
                'active': __('Active'),
                'suspended': __('Suspended'),
                'any': __('Any'),
            }),
            new SuggestFilter('webname', __('Name')),
            new SuggestFilter('account', __('Account')),
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('group', __('Group')),
            new SuggestFilter('model', __('Model')),
            new SuggestFilter('user', __('Operator')),
            new TranslateSuggestFilter('fault', __('Fault'), troubleTitle),
            new ValuesFilter('isActivated', __('Activated'), {
                '1': __('Yes'),
                '{!=1}': __('No'),
            }),
            new ValuesFilter('userApp', __('User App'), {
                'allow': __('Allow'),
                'disallow': __('Disallow'),
            }),
            new ValuesFilter('configuratorApp', __('Configurator App'), {
                'allow': __('Allow'),
                'disallow': __('Disallow'),
            }),
            new ValuesFilter('broadband', __('BBA'), {
                'online': __('Online'),
                'offline': __('Offline'),
                'none': __('None'),
            }),
            new ValuesFilter('gprs', __('GPRS'), {
                'online': __('Online'),
                'offline': __('Offline'),
                'none': __('None'),
            }),
            new SuggestFilter('basicConfigName', __('Basic Configuration')),
        ],
    })),
    connect(
        ({panels: {list}}, {match}) => ({
            key: match.params.scope,
            selected: list.filters,
            query: list.query,
            suggests: list.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSuggest: fetchSuggests,
            onQuery: setQuery,
            onSelect: addFilters,
            onClear: clearFilters,
            onDeselect: removeFilters,
            onSaveSearch: filters => showSaveSearchModal('panels', filters),
        }, dispatch),
    ),
)(Search)
import withForm from 'containers/withForm'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import CardForm from 'ui/CardForm'

import head from 'lodash-es/head'
import {__, __n} from 'utils/i18n'

import {removeUser} from 'modules/forms/handlers'

import RemoveUsersReject from './RemoveUser/RemoveUsersReject'
import RemoveUserReject from './RemoveUser/RemoveUserReject'

class RemoveUser extends Component {
    static propTypes = {
        userIds: PropTypes.arrayOf(
            PropTypes.number,
        ),
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                isRemovable: PropTypes.bool,
            }),
        ),
        onClose: PropTypes.func,
        handle: PropTypes.func,
    }

    static defaultProps = {
        users: [],
    }

    unRemovableUsers() {
        return this.props.users.filter(({isRemovable}) => !isRemovable)
    }

    render() {
        const unRemovableUsers = this.unRemovableUsers()
        const {onClose, handle, users} = this.props

        switch (unRemovableUsers.length) {
            case 0:
                return (
                    <CardForm onClose={onClose}
                              header={__n('Remove user', 'Remove users', users.length)}
                              submitLabel={__('Remove')}
                              onSubmit={handle}
                    >
                        {__n(
                            'Do you really want to remove user?',
                            'Do you really want to remove %d users?',
                            users.length,
                        )}
                    </CardForm>
                )

            case 1:
                return (
                    <RemoveUserReject onClose={onClose}
                                      header={__n('Remove user', 'Remove users', users.length)}
                                      user={head(unRemovableUsers)}/>
                )

            default:
                return (
                    <RemoveUsersReject onClose={onClose}
                                       header={__n('Remove user', 'Remove users', users.length)}
                                       users={unRemovableUsers}/>
                )
        }
    }
}

export default compose(
    withForm(removeUser),
    connect(
        ({users: {store: {byIds}}}, {userIds, handle}) => ({
            users: Object.values(byIds).filter(({id}) => userIds.indexOf(id) !== -1),
            handle: () => handle(userIds),
        }),
    ),
)(RemoveUser)
import React, {Component, PureComponent} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export default class Tags extends Component {
    static propTypes = {
        tags: PropTypes.arrayOf(PropTypes.object),
        onRemove: PropTypes.func,
    }

    render() {
        const {className, items, onRemove, ...props} = this.props

        const children = props.children || items.map((item, key) => (
            <Tag {...{key, ...item, onClick: onRemove}}>
                {item.label}
            </Tag>
        ))

        return (
            <div className={classNames('tags', className)}>
                {children}
            </div>
        )
    }
}

export class Tag extends PureComponent {

    static propTypes = {
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        label: PropTypes.string,
        onIconClick: PropTypes.func,
        Icon: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.instanceOf(Component)
        ]),
    }

    render() {
        const {className, label, children, value, onIconClick, Icon, ...props} = this.props

        return (
            <div
                {...props}
                className={classNames('tag', className, {
                    'tag--icon': !!Icon,
                })}
            >
                <div className="tag-content">{label || children || value}</div>
                {Icon && <Icon onClick={onIconClick} className="tag-icon"/>}
            </div>
        )
    }
}
import {UNKNOWN_DEVICE} from 'constants/device'
import {SEVERITY_TROUBLE} from 'constants/severityType'
import {set} from 'immutable-modify'
import {createSelector} from 'reselect'
import get from 'lodash-es/get'
import sortBy from 'lodash-es/sortBy'
import groupBy from 'lodash-es/groupBy'
import mapValues from 'lodash-es/mapValues'

import {PARTITION_ALL} from 'constants/partitions'
import {DEVICE_TYPE_CONTROL_PANEL} from 'constants/deviceType'

export const selectDevicesByIds = createSelector(
    (state, panelId) => state.devices.list[panelId],
    state => {
        const progress = state && state.progress && state.progress || {}

        return mapValues(
            state && state.byIds || {},
            device => {
                Object.keys(progress).forEach(
                    type => {
                        if (progress[type].hasOwnProperty(device.id)) {
                            device = set(device, ['traits', type], {
                                enabled: progress[type][device.id],
                                progress: true,
                            })
                        }
                    },
                )

                return device
            },
        )
    },
)

export const selectDeviceInfo = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (state, {deviceId}) => deviceId,
    (devicesByIds, deviceId) => {
        const device = devicesByIds[deviceId]
        const parent = device.traits && device.traits.parent && devicesByIds[device.traits.parent.id]
        const childDevices = Object.values(devicesByIds).filter(
            d => get(d, 'traits.parent.id') === device.id,
        )

        return {
            device,
            parent,
            childDevices,
        }
    },
)

export const selectDeviceByCategories = createSelector(
    selectDevicesByIds,
    (devicesByIds) => {
        const devices = sortBy(
            Object.values(devicesByIds),
            ({zone}) => zone || Infinity,
        )

        return groupBy(
            devices.filter(({deviceType}) => deviceType !== DEVICE_TYPE_CONTROL_PANEL),
            'category',
        )
    },
)

export const selectDeviceByZoneAndType = createSelector(
    (state, {panelId}) => selectDevicesByIds(state, panelId),
    (state, {panelId}) => get(state, [panelId, 'isLoading'], true),
    (state, {deviceType}) => deviceType,
    (state, {zone}) => zone,
    (devicesByIds, isLoading, deviceType, zone) => {
        const device = Object.values(devicesByIds).find(
            device => device.deviceType === deviceType && device.zone === zone,
        )

        if (device) {
            return device
        }

        return isLoading ? null : UNKNOWN_DEVICE
    },
)

export const selectBypassableDevices = createSelector(
    selectDevicesByIds,
    devicesById => Object.values(devicesById).filter(({traits}) => traits && traits.hasOwnProperty('bypass')),
)

export const selectBypassableDevicesByPartition = createSelector(
    selectBypassableDevices,
    (_, __, partitionId) => partitionId,
    (devices, partitionId) => devices
        .filter(({partitions}) => partitions.includes(partitionId) || partitions.includes(PARTITION_ALL)),
)

export const selectWarningsByPanelId = createSelector(
    selectDevicesByIds,
    (state, panelId) => get(state, ['panels', 'store', 'byIds', panelId], {warnings: []}),
    (devices, {warnings}) => {
        const combined = [
            ...formatDeviceWarnings(devices),
            ...formatPanelWarnings(warnings),
        ]

        return combined.filter((value, index, self) => self.indexOf(value) === index)
    },
)

function formatDeviceWarnings(devices) {
    return Object.values(devices).reduce((lines, {warnings}) => {
        if (warnings.length) {
            return [
                ...lines,
                ...warnings,
            ]
        }

        return lines
    }, [])
}

function formatPanelWarnings(warnings) {
    if (!warnings) {
        return []
    }

    return warnings
        .filter(({severity}) => severity === SEVERITY_TROUBLE)
}
import {compose} from 'redux'

import list from 'permissions/reports/list'

import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'
import withReports from 'containers/withReports'

import Pager from 'ui/Pager'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withReports(),
)(Pager)
import {selectPerPage} from 'modules/settings/selectors'
import {takeEvery, put, all} from 'redux-saga/effects'

import * as api from 'api/centralStations'
import * as actions from './actions'
import {update} from '../store/actions'

import {snackShow} from 'modules/snacks'
import {addCentralStation} from 'modules/forms/handlers'
import createListSaga from 'modules/higherOrder/createListSaga'

import {__} from 'utils/i18n'

export default function* () {
    yield all([
        createListSaga(api, actions, update, state => ({
            ...state.centralStations.list,
            perPage: selectPerPage(state, 'centralStations'),
        })),

        takeEvery(addCentralStation.SUCCESS, watchAddCentralStation),
    ])
}

function* watchAddCentralStation() {
    yield put(actions.fetch())
    yield put(snackShow(__('Central station was created successfully')))
}
import React from 'react'
import PropTypes from 'prop-types'
import Button from 'ui/Button'
import classes from 'classnames'

const Fab = ({className, mini, ...props}) => (
    <Button {...props} className={classes('fab', className, {
        'fab--mini': mini,
    })}/>
)

Fab.propTypes = {
    mini: PropTypes.bool,
    ...Button.propTypes,
}

export default Fab
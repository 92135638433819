import {compose} from 'redux'

import disable from 'permissions/panel/devices/plink/log/disable'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withPlinkDebugCommands} from 'containers/withDevices'

import Button from 'ui/Button'

export default compose(
    withPermission({
        isVisible: disable,
    }),
    withVisibility(),
    withPlinkDebugCommands(),
    withProps(({disableLogging}) => ({
        onClick: disableLogging,
    })),
)(Button)
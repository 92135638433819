import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {Line} from 'react-chartjs-2'

import __ from 'utils/i18n'
import {timeXAxes, timeTitleCallback} from 'utils/chartsOption'

export default class OfflineFaultyPanelsChart extends Component {
    static propTypes = {
        faultyData: PropTypes.array,
        offlaneData: PropTypes.array,
        from: PropTypes.instanceOf(Date),
        to: PropTypes.instanceOf(Date),
        isLoading: PropTypes.bool,
        error: PropTypes.object,
    }

    get data() {
        const {faultyData, offlaneData} = this.props

        return {
            datasets: [{
                label: __('Faulty'),
                data: faultyData,
                borderColor: 'rgba(45,114,186,1)',
                backgroundColor: 'rgba(45,114,186,.65)',
                spanGaps: true,
            }, {
                label: __('Offline'),
                borderColor: 'rgba(202,35,57,1)',
                backgroundColor: 'rgba(202,35,57,0.65)',
                data: offlaneData,
            }],
        }
    }

    get options() {
        const {from, to} = this.props

        return {
            animation: false,
            maintainAspectRatio: false,
            tooltips: {
                callbacks: {
                    title: timeTitleCallback,
                },
            },
            scales: {
                yAxes: [{
                    ticks: {
                        beginAtZero: true,
                        callback: (value, index, values) => (value * 100).toFixed(0), // percentage
                    },
                }],
                xAxes: timeXAxes(from, to),
            },
        }
    }

    render() {
        return (
            <div className="dashboard-secondary">
                <h3 className="dashboard-chartTitle">{__('Offline/Faulty Panels')}</h3>
                <Line data={this.data}
                    options={this.options}/>
            </div>
        )
    }
}
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {edit} from 'permissions/group/actions'

import {showEditGroup} from 'modules/modals/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import EditIcon from 'icons/edit.svg'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: edit}),
    withVisibility(),
    connect(
        null,
        (dispatch, {group: {id: groupId}}) => bindActionCreators({
            onClick: () => showEditGroup(groupId),
        }, dispatch),
    ),
    withProps(() => ({
        Icon: EditIcon,
        label: __('Edit Group'),
    })),
)(Button)
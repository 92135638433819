import plink from 'permissions/panel/devices/plink'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import * as signals from 'constants/signalLevelType'
import {signalLevelTitle} from 'constants/signalLevelType'
import {DEVICE_TYPE_POWER_LINK} from 'constants/deviceType'

import {soak, rarelyUsed, bypass, remove} from 'permissions/panel/devices/actions'
import {setLabel} from 'permissions/panel/labels'

import {showRenameDeviceModal} from 'modules/modals/actions'
import {setBypass, setRarelyUsed, setSoak, removeDevice} from 'modules/devices/list/actions'

import {withPermission} from 'containers/withPermission'
import withConfirmation from 'containers/withConfirmation'

import List, {ListHintItem} from 'ui/List'
import Definition from 'ui/Definition'
import Checkbox from 'ui/Checkbox'
import Button from 'ui/Button'

import PlinkDebug from './PlinkDebug'

import humanTime from 'utils/humanTime'
import {__} from 'utils/i18n'

const ConfirmationButton = withConfirmation()(Button)

export class GeneralInfoTab extends Component {

    static propTypes = {
        device: PropTypes.shape({
            traits: PropTypes.shape({
                rarely_used: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                bypass: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                soak: PropTypes.shape({
                    progress: PropTypes.bool,
                    enabled: PropTypes.bool.isRequired,
                }),
                rssi: PropTypes.shape({
                    average: PropTypes.oneOf(Object.values(signals)),
                    current: PropTypes.oneOf(Object.values(signals)),
                    channel: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
                    repeater: PropTypes.number,
                    triggeredAt: PropTypes.string,
                }),
            }),
        }).isRequired,
        onSetBypass: PropTypes.func.isRequired,
        onSetSoak: PropTypes.func.isRequired,
        onSetRarelyUsed: PropTypes.func.isRequired,
        onRemove: PropTypes.func.isRequired,
        onRename: PropTypes.func.isRequired,
        isBypassAllowed: PropTypes.bool,
        isSoakAllowed: PropTypes.bool,
        isRarelyUsedAllowed: PropTypes.bool,
        isRemoveAllowed: PropTypes.bool,
        isRenameDevices: PropTypes.bool,
    }

    static defaultProps = {
        isBypassAllowed: true,
        isSoakAllowed: true,
        isRarelyUsedAllowed: true,
        isRemoveAllowed: true,
    }

    setBypass = (e) => {
        this.props.onSetBypass(e.target.checked)
    }

    setSoak = (e) => {
        this.props.onSetSoak(e.target.checked)
    }

    setRarelyUsed = (e) => {
        this.props.onSetRarelyUsed(e.target.checked)
    }

    remove = (e) => {
        this.props.onRemove()
    }

    rename = (e) => {
        this.props.onRename()
    }

    renderRssi({average, current, channel, repeater, last_updated: updated}) {
        const empty = <span className="empty">{__('not set')}</span>

        const info = (
            <List twoCols>
                <ListHintItem name={__('Current')} text={current ? signalLevelTitle(current) : empty}/>
                <ListHintItem name={__('Average')} text={average ? signalLevelTitle(average) : empty}/>
                <ListHintItem name={__('Channels')} text={channel || empty}/>
                <ListHintItem name={__('Repeater')} text={repeater !== null ? repeater : empty}/>
            </List>
        )

        return (
            <div className="block rssi">
                <Definition
                    className="title"
                    multiLine
                    title={__('RSSI')}
                    detail={updated && __('Updated: %s', humanTime(updated))}
                />

                {updated
                    ? info
                    : (
                        <div className="rssi-noMeasurements">
                            <h6 className="title title--medium">{__('No measurements')}</h6>
                            {__('Click Refresh RSSI button to reveal device rssi info')}
                        </div>
                    )}
            </div>
        )
    }

    render() {
        const {
            panelId,
            isPlinkAllowed,
            isBypassAllowed,
            isSoakAllowed,
            isRarelyUsedAllowed,
            isRemoveAllowed,
            isRenameDevices,
        } = this.props
        const {traits, isRemovable, deviceType} = this.props.device || {}
        const {rarely_used: rarelyused, soak, rssi, bypass} = traits || {}

        return (
            <div className="blocks">
                {rssi && this.renderRssi(rssi)}

                {isPlinkAllowed && deviceType === DEVICE_TYPE_POWER_LINK && <PlinkDebug panelId={panelId}/>}

                <div className="block">
                    <h4 className="title">{__('Diagnostic')}</h4>

                    {bypass &&
                    <Checkbox
                        disabled={!isBypassAllowed}
                        onChange={this.setBypass}
                        label={__('Bypass')}
                        progress={bypass.progress}
                        checked={bypass.enabled}/>}

                    {soak &&
                    <Checkbox
                        disabled={!isSoakAllowed}
                        onChange={this.setSoak}
                        label={__('Soak')}
                        progress={soak.progress}
                        checked={soak.enabled}/>}

                    {rarelyused &&
                    <Checkbox
                        disabled={!isRarelyUsedAllowed}
                        onChange={this.setRarelyUsed}
                        label={__('Rarely Triggered')}
                        progress={rarelyused.progress}
                        checked={rarelyused.enabled}/>}

                    <br/>

                    {isRenameDevices &&
                    <Button primary
                            className="devices-sidebar-button"
                            onClick={this.rename}
                            label={__('Rename')}
                    />}

                    {isRemoveAllowed && isRemovable &&
                    <ConfirmationButton
                        primary
                        className="devices-sidebar-button"
                        onClick={this.remove}
                        title={__('Remove device')}
                        message={__('Do you really want to remove device')}
                        positive={__('Remove')}
                        label={__('Remove')}
                    />}
                </div>
            </div>
        )
    }
}

export default compose(
    withPermission({
        isPlinkAllowed: plink,
        isBypassAllowed: bypass,
        isSoakAllowed: soak,
        isRarelyUsedAllowed: rarelyUsed,
        isRemoveAllowed: remove,
        isRenameDevices: setLabel,
    }),
    connect(
        null,
        (dispatch, {panelId, device}) => bindActionCreators({
            onSetBypass: enabled => setBypass(panelId, {[device.id]: enabled}),
            onSetSoak: enabled => setSoak(panelId, device.id, enabled),
            onSetRarelyUsed: enabled => setRarelyUsed(panelId, device.id, enabled),
            onRemove: () => removeDevice(panelId, device.id),
            onRename: () => showRenameDeviceModal(panelId, device),
        }, dispatch),
    ),
)(GeneralInfoTab)
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Definition from 'ui/Definition'
import DateTime from 'ui/DateTime'
import ProgressBar from 'ui/ProgressBar'

import IconAlert from 'icons/alert.svg'
import IconCompleted from 'icons/check.svg'
import IconEmpty from 'icons/hourglass-empty.svg'

import {__} from 'utils/i18n'

export default class StatusReportCell extends Component {
    static propTypes = {
        row: PropTypes.shape({
            status: PropTypes.string,
            percentage: PropTypes.number,
            finishedAt: PropTypes.object,
        }),
    }

    render() {
        const {row: {status, percentage, finishedAt}} = this.props

        switch (status) {
            case 'failed':
                return <DateTime className="danger" Icon={IconAlert} datetime={finishedAt || {}}/>
            case 'start':
            case 'handled':
                return <ProgressBar max={100} value={percentage || 0}/>
            case 'succeeded':
                return <DateTime Icon={IconCompleted} datetime={finishedAt || {}}/>
            case 'new':
            case null:
                return <Definition className="empty" Icon={IconEmpty} title={__('No results yet')}/>
            default:
                return null
        }
    }
}
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {
    init,
    reset,
    setStart,
    remove,
    startPoll,
    stopPoll,
} from 'modules/roles/list/actions'
import {showAddRole, showRemoveRole} from 'modules/modals/actions'

const mapStateToProps = () =>
    ({roles}) => {
        const {start, total, page, error, isLoading} = roles.list

        return {
            error,
            isLoading,
            start,
            total,
            rows: page.map(id => roles.store.byIds[id]),
        }
    }

const mapDispatchToProps = () => dispatch =>
    bindActionCreators({
        init,
        reset,
        onPageChange: setStart,
        remove,
        startPoll,
        stopPoll,
        showAddRole,
        showRemoveRole,
    }, dispatch)

export default function withRoles() {
    return compose(
        withPerPage('roles'),
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
    )
}
import createListActions from 'modules/higherOrder/createListActions'
import {createAction} from 'redux-actions'

export const {
    init,
    reset,
    fetch,
    receive,
    setStart,
    setQuery,
    setFilters,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
} = createListActions('REMOTE_INSPECTIONS/LIST')

export const invalidate = createAction('REMOTE_INSPECTIONS/LIST/INVALIDATE')
export const markAsViewed = createAction('REMOTE_INSPECTIONS/LIST/MARK_AS_VIEWED', ids => ({ids}))
export const select = createAction('REMOTE_INSPECTIONS/LIST/SELECT')
export const selectNext = createAction('REMOTE_INSPECTIONS/LIST/NEXT')
export const selectPrevious = createAction('REMOTE_INSPECTIONS/LIST/PREVIOUS')

import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Icon from 'icons/drop-down.svg'
import Menu, {MenuDelimiter, MenuHeader, MenuItem} from 'ui/Menu'
import DropDown, {ALIGN_LEFT, ALIGN_TOP, ALIGN_WIDTH} from 'ui/DropDown'
import {InputComponent} from 'ui/Input'
import {withNamedError} from 'ui/Form'
import {__} from 'utils/i18n'
import prepareInputName from 'utils/prepareInputName'
import Spinner from 'ui/Spinner'

export class Option extends MenuItem {
    static propTypes = {
        disabled: PropTypes.bool,
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        value: PropTypes.any,
    }

    render() {
        const props = {
            ...this.props,
            children: this.props.children || this.props.label,
        }

        return <MenuItem className="menu-item--option" {...props}/>
    }
}

export const Separator = ({label}) => {
    if (label) {
        return <MenuHeader>{label}</MenuHeader>
    }

    return <MenuDelimiter/>
}

export class SelectComponent extends PureComponent {

    static propTypes = {
        ...InputComponent.propTypes,
        optional: PropTypes.oneOfType([
            PropTypes.bool,
            PropTypes.string,
        ]),
        defaultLabel: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
        ]),
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
            PropTypes.bool,
        ]),
        isLoading: PropTypes.bool,
    }

    state = {}

    labels = {}

    align = ALIGN_LEFT | ALIGN_TOP | ALIGN_WIDTH

    constructor(props, context) {
        super(props, context)
        this.labels = this.createLabels(props.children)

        this.controlled = props.hasOwnProperty('value')

        this.state = {
            value: this.controlled ? props.value : props.defaultValue,
            input: props.defaultLabel,
        }
    }

    componentWillReceiveProps(props, context) {
        this.labels = this.createLabels(props.children)

        if (this.controlled) {
            this.setState({value: props.value})
        }
    }

    createLabels(children) {
        const {defaultValue, defaultLabel} = this.props
        const acc = defaultValue
            ? {[defaultValue]: defaultLabel}
            : {}

        return React.Children.toArray(children)
            .reduce((acc, {props}) => {
                if (!props || !props.hasOwnProperty('label')) {
                    return acc
                }

                const value = props.hasOwnProperty('value') ? props.value : props.label

                return {
                    ...acc,
                    [value]: props.label,
                }
            }, acc)
    }

    set = (e, value) => {
        const label = this.labels[value] || value
        this.props.onChange && this.props.onChange(e, value, label)

        if (!this.controlled) {
            this.setState({value, input: label})
        }
    }

    onChange = () => {
    }

    getValueLabel() {
        return this.labels[this.state.value] || this.state.value || ''
    }

    getValue() {
        const {value} = this.state

        if (value || value === '0' || value === 0) {
            return value
        }

        return ''
    }

    getOptionalMessage() {
        const {optional} = this.props

        if (optional === true) {
            return __('Empty')
        }

        return optional || null
    }

    getChildrenArray() {
        const children = React.Children.toArray(this.props.children)

        if (this.props.optional) {
            children.unshift(
                <Option key="" label="" onClick={this.set}>
                    <span className="empty">
                        {this.getOptionalMessage()}
                    </span>
                </Option>,
            )
        }

        return children
    }

    renderItems() {
        if (this.props.isLoading) {
            return <Spinner/>
        }

        return this.getChildrenArray()
            .filter(element => !!element)
            .map(element => React.cloneElement(element, {
                onClick: (e) => {
                    this.set(e,
                        element.props.hasOwnProperty('value')
                            ? element.props.value
                            : element.props.label,
                    )
                },
            }))
    }

    handleInputRef = input => this.input = input

    handleDropDownRef = dropDown => this.dropDown = dropDown

    onDropDownShow = () => {
        this.setState({focus: true})
    }

    onDropDownHide = () => {
        this.setState({focus: false})
        this.input && this.input.blur()
    }

    onFocus = (e) => {
        this.props.onFocus && this.props.onFocus(e)
        this.dropDown && this.dropDown.show()
    }

    onBlur = (e) => {
        this.props.onBlur && this.props.onBlur(e)
        this.dropDown && this.dropDown.hide()
    }

    handleMenuMouseDown = e => {
        // prevent blur event to be fired
        e.preventDefault()
    }

    render() {
        const {name, align, onIconClick, readOnly, label, placeholder, error, disabled} = this.props

        const input = <div>
            <InputComponent
                autoComplete="some-fake-id"
                disabled={disabled}
                Icon={Icon}
                ref={this.handleInputRef}
                onChange={this.onChange}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onIconClick={onIconClick}
                readOnly={readOnly}
                label={label}
                error={error}
                placeholder={label ? null : (placeholder || this.getOptionalMessage())}
                hasFocus={this.state.focus}
                value={this.getValueLabel()}
            />

            {/* hack for disable browser mail/account/password autocomplete */}
            <input name="fakeinputfield" type="password" style={{display: 'none'}}/>

            {name && <input type="hidden" value={this.getValue()} name={prepareInputName(name)}/>}
        </div>

        return (
            <DropDown
                className="form-field"
                ref={this.handleDropDownRef}
                trigger={input}
                align={align || this.align}
                onShow={this.onDropDownShow}
                onHide={this.onDropDownHide}
            >
                {!disabled &&
                <Menu scope="input" onMouseDown={this.handleMenuMouseDown}>
                    {this.renderItems()}
                </Menu>
                }
            </DropDown>
        )
    }
}

export default withNamedError(SelectComponent)
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import Search from 'components/Search/Search'
import __ from 'utils/i18n'

import list from 'permissions/events/list'

import {addFilters, clearFilters, fetch, removeFilters, fetchSuggests, setQuery} from 'modules/events/list/actions'
import {showSaveSearchModal} from 'modules/modals/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import DateFilter from 'components/Search/Filters/DateFilter'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'
import ValuesFilter from 'components/Search/Filters/ValuesFilter'
import TranslateSuggestFilter from 'components/Search/Filters/FullSuggestsFilter'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new TranslateSuggestFilter('severity', __('Severity'), {
                alarm: __('Alarm'),
                alert: __('Alert'),
                offline: __('Offline'),
                online: __('Online'),
                notice: __('Notice'),
            }),

            new SuggestFilter('serial', __('Panel Id')),

            new SuggestFilter('webname', __('Panel Name')),

            new SuggestFilter('account', __('Account')),

            new ValuesFilter('camera', __('Has Video'), {
                '{>=1}': __('Yes'),
                '0': __('No'),
            }),

            new DateFilter('datetime', __('Date')),

            new SuggestFilter('description', __('Type')),
        ],
    })),
    connect(
        ({events: {list}}, {match}) => ({
            key: match.params.scope,
            selected: list.filters,
            suggests: list.suggests,
            query: list.query,
        }),

        (dispatch, {apply}) => bindActionCreators({
            apply: (...args) => {
                apply && apply(...args)
                return fetch(...args)
            },
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onQuery: setQuery,
            onSuggest: fetchSuggests,
            onSaveSearch: filters => showSaveSearchModal('events', filters),
        }, dispatch),
    ),
)(Search)
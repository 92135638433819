import {createAction} from 'redux-actions'

export const update = createAction('PANELS/STORE/UPDATE', data => Array.isArray(data) ? data : [data])
export const clear = createAction('PANELS/STORE/CLEAR')
export const setActivationStatus = createAction('PANELS/STORE/SET_ACTIVATION_STATUS')
export const setActivationFailed = createAction('PANELS/STORE/SET_ACTIVATION_FAILED')
export const setActivationSuccess = createAction('PANELS/STORE/SET_ACTIVATION_SUCCESS')
export const refreshState = createAction('PANELS/LIST/REFRESH_STATE', panelIds => Array.isArray(panelIds)
    ? panelIds
    : [panelIds])
export const refresh = createAction('PANELS/STORE/REFRESH', panelIds => Array.isArray(panelIds) ? panelIds : [panelIds])
export const setRefreshing = createAction('PANELS/STORE/SET_REFRESH', (isRefreshing = true) => ({isRefreshing}))
export const pushBasicConfiguration = createAction('PANELS/STORE/PUSH', (basicConfigId, panelIds) => ({basicConfigId, panelIds}))

import React from 'react'
import {compose} from 'redux'

import bar from 'permissions/reports/bar'

import {withVisibility} from 'containers/withVisibility'
import {withPermission} from 'containers/withPermission'

import Bar, {BarSpace} from 'ui/Bar'

import StopReportsButton from './Bar/StopReportsButton'
import RemoveReportsButton from './Bar/RemoveReportsButton'
import ReportsSelectionDropDown from './Bar/ReportsSelectionDropDown'
import ReportsPager from './Bar/ReportsPager'
import CreateReportButton from './Bar/CreateReportButton'

const ReportsGridBar = () => (
    <Bar>
        <ReportsSelectionDropDown/>
        <StopReportsButton/>
        <RemoveReportsButton/>
        <BarSpace/>
        <ReportsPager/>
        <CreateReportButton/>
    </Bar>
)

export default compose(
    withPermission({isVisible: bar}),
    withVisibility(),
)(ReportsGridBar)
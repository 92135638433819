import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import withProps from 'containers/withProps'
import {withVisibility} from 'containers/withVisibility'
import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'

import RemoteInspectionResults from 'components/RemoteInspection/Results'

import {SideBar} from 'ui/Layout'

class RemoteInspectionSideBar extends Component {
    static propTypes = {
        remoteInspectionResult: PropTypes.object,
    }

    render() {
        const {remoteInspectionResult} = this.props

        return (
            <SideBar>
                <RemoteInspectionResults {...{remoteInspectionResult}}/>
            </SideBar>
        )
    }
}

export default compose(
    withPanelRemoteInspection(),
    withProps(({rows, remoteInspectionResult}) => ({
        isVisible: rows && rows.length > 0 && remoteInspectionResult,
        key: remoteInspectionResult && remoteInspectionResult.id,
    })),
    withVisibility(),
)(RemoteInspectionSideBar)
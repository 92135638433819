import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose} from 'redux'

import * as DeviceCategory from 'constants/deviceCategory'
import deviceCategory from 'constants/deviceCategory'

import withPanel from 'containers/withPanel'
import {selectChangedDeviceSections} from 'modules/panels/configuration/selectors'

import DevicesList from 'components/Devices/DevicesList'
import deviceSectionName from 'utils/configuration/deviceSectionName'

const groups = [
    DeviceCategory.DEVICE_CATEGORY_MODULES,
    DeviceCategory.DEVICE_CATEGORY_ZONES,
    DeviceCategory.DEVICE_CATEGORY_ACCESSORIES,
    DeviceCategory.DEVICE_CATEGORY_SMART,
    DeviceCategory.DEVICE_CATEGORY_EXTENDERS,
    DeviceCategory.DEVICE_CATEGORY_SIRENS,
    DeviceCategory.DEVICE_CATEGORY_OUTPUTS,
    DeviceCategory.DEVICE_CATEGORY_UNKNOWN,
]

export class Diagnostic extends Component {

    static propTypes = {
        devices: PropTypes.object.isRequired,
        panel: PropTypes.shape({
            isNeo: PropTypes.bool,
        }),
        selectedDevice: PropTypes.number,
        onSelect: PropTypes.func,
        onSelectPanel: PropTypes.func,
        configuration: PropTypes.object,
        changes: PropTypes.object,
    }

    constructor(props, context) {
        super(props, context)

        this.groups = groups.reduce((acc, category) => ({
            ...acc,
            [category]: deviceCategory(category),
        }), {})
    }

    isChanged = device => {
        const {changes} = this.props
        const sectionName = deviceSectionName(device)
        return sectionName && changes.hasOwnProperty(sectionName)
    }

    render() {
        return (
            <DevicesList
                groups={this.groups}
                isChanged={this.isChanged}
                {...this.props}
            />
        )
    }
}

export default compose(
    withPanel(),
    connect(
        (state, {panel}) => ({
            changes: selectChangedDeviceSections(state, {panelId: panel.id}),
        }),
    ),
)(Diagnostic)
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import beep from 'assets/keypad-sounds/beep.wav'
import beep1 from 'assets/keypad-sounds/beep1.wav'
import beep2 from 'assets/keypad-sounds/beep2.wav'
import beep3 from 'assets/keypad-sounds/beep3.wav'
import beep4 from 'assets/keypad-sounds/beep4.wav'
import beep5 from 'assets/keypad-sounds/beep5.wav'
import beep6 from 'assets/keypad-sounds/beep6.wav'
import beep7 from 'assets/keypad-sounds/beep7.wav'
import beep8 from 'assets/keypad-sounds/beep8.wav'
import beep9 from 'assets/keypad-sounds/beep9.wav'
import beep10 from 'assets/keypad-sounds/beep10.wav'
import beep11 from 'assets/keypad-sounds/beep11.wav'
import beep12 from 'assets/keypad-sounds/beep12.wav'
import siren from 'assets/keypad-sounds/siren.wav'
import squack from 'assets/keypad-sounds/squack.wav'

import {__} from 'utils/i18n'
import {
    disable,
    cleanBeep,
    cleanSiren,
    cleanSquack,
} from 'modules/panels/keypad/actions'

import Bar, {BarSpace} from 'ui/Bar'
import Button from 'ui/Button'
import Togglebox from 'ui/Togglebox'

class KeypadBar extends Component {

    static propTypes = {
        disable: PropTypes.func.isRequired,
        cleanBeep: PropTypes.func.isRequired,
        cleanSiren: PropTypes.func.isRequired,
        cleanSquack: PropTypes.func.isRequired,
        siren: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        beep: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        squack: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }

    state = {
        isMute: true,
        sounds: {
            siren: new Audio(siren),
            beep: new Audio(beep),
            beep1: new Audio(beep1),
            beep2: new Audio(beep2),
            beep3: new Audio(beep3),
            beep4: new Audio(beep4),
            beep5: new Audio(beep5),
            beep6: new Audio(beep6),
            beep7: new Audio(beep7),
            beep8: new Audio(beep8),
            beep9: new Audio(beep9),
            beep10: new Audio(beep10),
            beep11: new Audio(beep11),
            beep12: new Audio(beep12),
            squack: new Audio(squack),
        },
    }

    muteToggle = () => {
        const {sounds} = this.state

        for (const audio in sounds) {
            if (sounds.hasOwnProperty(audio)) {
                sounds[audio].muted = this.state.isMute
            }
        }

        this.setState({isMute: !this.state.isMute})
    }

    componentWillReceiveProps(nextProps) {
        const {siren, beep, squack} = nextProps
        const {siren: oldSiren, squack: oldSquack} = this.props

        this.watcher(siren, oldSiren, 'siren')
        this.watcher(squack, oldSquack, 'squack')
        this.watchBeeps(beep)
    }

    watchBeeps = (beepVal) => {
        const {cleanBeep, beep: oldBeep} = this.props

        if (typeof beepVal === 'undefined') {return}
        if (beepVal === oldBeep) {return}

        if (this.state.sounds.hasOwnProperty(`beep${beepVal}`)) {
            this.state.sounds[`beep${beepVal}`].play()
        }

        setTimeout(() => {
            cleanBeep()
        }, 1000)
    }

    watcher = (val, oldVal, type) => {
        const {sounds} = this.state

        if (typeof val === 'undefined') {return}
        if (val === oldVal) {return}
        if (!sounds.hasOwnProperty(type)) {return}

        if (val === '0') {
            sounds[type].pause()
            sounds[type].currentTime = 0
        } else {
            /**
             * For pmax siren:
             * 1 - Fire Alarm
             * 2 - Panic
             */
            sounds[type].play()
            this.cleanState(type)
        }
    }

    cleanState = type => {
        const {cleanSiren, cleanSquack} = this.props

        switch (type) {
            case 'siren':
                setTimeout(() => {
                    cleanSiren()
                }, 3000)
                break

            case 'squack':
                setTimeout(() => {
                    cleanSquack()
                }, 1000)
                break
        }
    }

    render() {
        const {disable, enabled} = this.props
        const {isMute} = this.state

        return (
            <Bar>
                <Togglebox
                    onChange={this.muteToggle}
                    label={__('Sound enabled')}
                    checked={isMute}
                    labelOff={__('Sound disbled')}/>
                <BarSpace/>

                <Button disabled={!enabled} onClick={disable}>{__('Disconnect')}</Button>
            </Bar>
        )
    }
}

export default connect(
    ({panels}, {panelId}) => panels.keypad[panelId] || {},
    (dispatch, {panelId}) => bindActionCreators({
        disable: () => disable(panelId),
        cleanBeep: () => cleanBeep(panelId),
        cleanSiren: () => cleanSiren(panelId),
        cleanSquack: () =>cleanSquack(panelId),
    }, dispatch)
)(KeypadBar)
import React, {Component} from 'react'
import {compose} from 'redux'
import {connect} from 'react-redux'

import {selectDeviceInfo} from 'modules/devices/list/selectors'

import configuration from 'permissions/panel/configuration/page'
import video from 'permissions/panel/devices/video/tab'
import meteo from 'permissions/panel/devices/meteo/tab'

import {withPermission} from 'containers/withPermission'
import {deviceSubtype} from 'constants/deviceSubtype'

import GeneralInfoTab from './Tabs/GeneralInfoTab'
import ParentTab from './Tabs/ParentTab'
import ChildrenTab from './Tabs/ChildrenTab'
import ConfigurationTab from './Tabs/ConfigurationTab'
import ChartsTab from './Tabs/ChartsTab'
import VideoOnDemandTab from './Tabs/VideoOnDemandTab'

import Tabs from 'ui/Tabs'
import {ScrollView, SideBar} from 'ui/Layout'
import {__} from 'utils/i18n'
import deviceSectionName from 'utils/configuration/deviceSectionName'

export class DeviceSidebar extends Component {

    layout = ({tab, nav}) => {
        const {device, onClose} = this.props

        return (
            <SideBar className="devices-sidebar">
                <div className="sidebar-header">
                    <a onClick={onClose} className="sidebar-close">×</a>

                    <h1 className="sidebar-title">
                        {deviceSubtype(device.subtype, device.deviceType)}
                    </h1>

                    {nav}
                </div>

                <ScrollView className="sidebar-content">
                    {tab}
                </ScrollView>
            </SideBar>
        )
    }

    render() {
        const {
            isConfigurationAllowed,
            isVideoOnDemandAllowed,
            device,
            childDevices,
            parent,
            onSelect,
            panelId,
            hasMeteo,
            hasTemperature,
            hasBrightness,
            hasVideoOnDemand,
            startWithVideoOnDemandTab,
            startWithMeteoTab,
            isMeteoAllowed,
        } = this.props

        const configurationSectionName = deviceSectionName(device)

        return (
            <Tabs Layout={this.layout}>
                <GeneralInfoTab name={__('General')} device={device} panelId={panelId} parent={parent}/>

                {parent && <ParentTab
                    name={__('Parent')}
                    device={parent}
                    onSelect={onSelect}/>
                }

                {childDevices && childDevices.length > 0 && <ChildrenTab
                    name={__('Children')}
                    devices={childDevices}
                    onSelect={onSelect}/>
                }

                {isConfigurationAllowed && configurationSectionName && <ConfigurationTab
                    name={__('Configuration')}
                    sectionName={configurationSectionName}
                    panelId={panelId}/>
                }

                {hasMeteo && isMeteoAllowed && <ChartsTab
                    name={__('Meteo')}
                    panelId={panelId}
                    device={device}
                    isDefaultTab={startWithMeteoTab}
                    hasTemperature={hasTemperature}
                    hasBrightness={hasBrightness}/>
                }

                {isVideoOnDemandAllowed && hasVideoOnDemand && !device.preenroll && <VideoOnDemandTab
                    name={__('Video on demand')}
                    panelId={panelId}
                    device={device}
                    isDefaultTab={startWithVideoOnDemandTab}/>
                }
            </Tabs>
        )
    }
}

export default compose(
    withPermission({
        isConfigurationAllowed: configuration,
        isVideoOnDemandAllowed: video,
        isMeteoAllowed: meteo,
    }),
    connect(
        (state, {deviceId, panelId, isNeo}) => {
            const {device, parent, childDevices} = selectDeviceInfo(state, {panelId, deviceId})

            const hasMeteo = device.traits && !!device.traits.meteo_info
            const hasTemperature = hasMeteo && !!device.traits.meteo_info.temperature
            const hasBrightness = hasMeteo && !!device.traits.meteo_info.brightness
            const hasVideoOnDemand = !isNeo && device.traits && device.traits.vod

            return {
                device,
                parent,
                childDevices,
                hasMeteo,
                hasTemperature,
                hasBrightness,
                hasVideoOnDemand,
            }
        },
    ),
)(DeviceSidebar)

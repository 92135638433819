import React from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import IconBack from 'icons/arrow-back.svg'
import Fab from 'ui/Fab'

const Card = ({className, ...props}) => <div className={classes('card', className)} {...props}/>

export const CardContent = ({className, ...props}) => (
    <div className={classes('card-content', className)} {...props}/>
)

export const CardActions = ({className, justify, ...props}) => (
    <div className={classes('card-actions', {'card-actions--justify': justify}, className)} {...props}/>
)

export const CardHeader = ({className, children, onBack, ...props}) => (
    <div
        className={classes('card-header', className, {'card-header--withBack': onBack})}
        {...props}>

        {onBack && <Fab mini borderless Icon={IconBack} onClick={onBack} className="card-header-back"/>}
        {children}
    </div>
)

CardHeader.propTypes = {
    className: PropTypes.string,
    onBack: PropTypes.func,
}

export const CardClose = ({className, circle, ...props}) =>
    <a className={classes('card-close', className, {
        'card-close--circle': circle,
    })} {...props}>×</a>

export default Card
import {
    handleActions,
} from 'redux-actions'

import {
    clear,
    update,
} from './actions'

export default handleActions({
    [clear]() {
        return {}
    },

    [update](state, {payload}) {
        return payload.reduce((carrier, row) => ({
            ...carrier,
            [row.id]: {
                ...carrier[row.id],
                ...row,
            },
        }), state)
    },
}, {})
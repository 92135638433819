import React from 'react'
import PropTypes from 'prop-types'

import Check from 'ui/Check'

import DropDownIcon from 'icons/drop-down.svg'
import DropDown from 'ui/DropDown'
import classes from 'classnames'
import Hotkey from 'ui/Hotkeys'

export default class DropDownButton extends DropDown {

    static PropTypes = {
        label: PropTypes.node,
        Icon: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
        indeterminate: PropTypes.bool,
        checked: PropTypes.bool,
        checkbox: PropTypes.bool,
        className: PropTypes.string,
        shortcut: PropTypes.string,
        flat: PropTypes.bool,
        primary: PropTypes.bool,
        small: PropTypes.bool,
        borderless: PropTypes.bool,
        noUpcase: PropTypes.bool,
        disabled: PropTypes.bool,
        onChange: PropTypes.func,
    }

    preventOpen = (e) => {
        e.stopPropagation()
    }

    onChange = (e) => {
        this.hide()
        this.props.onChange && this.props.onChange(e)
    }

    render() {
        const {active} = this.state

        const {
            className,
            label,
            Icon,
            indeterminate,
            checked,
            checkbox,
            flat,
            primary,
            small,
            borderless,
            noUpcase,
            disabled,
            shortcut,
            ...props
        } = this.props

        delete props.align

        return (
            <div className={classes('btn', 'btn--dropDown', className, {
                'btn--flat': flat,
                'btn--primary': primary,
                'btn--small': small,
                'btn--borderless': borderless,
                'btn--disabled': disabled,
                'btn--noUpcase': noUpcase,
                'btn--active': active,
            })} ref="element" onClick={this.handleClick}>
                <span className="btn-content">
                    {Icon && <Icon className={'btn-icon ' + (label ? 'btn-icon--offset' : '')}/>}

                    {checkbox
                        ? <Check greyscale noInk
                                 onClick={this.preventOpen}
                                 onChange={this.onChange}
                                 checked={checked}
                                 indeterminate={indeterminate}/>
                        : label && <span>{label}</span>
                    }

                    <DropDownIcon className="dropDown-icon"/>
                </span>

                {shortcut && <Hotkey shortcut={shortcut} action={this.onChange}/>}

                {this.renderDropDown()}
            </div>
        )
    }

}
import {createSelector} from 'reselect'

export const selectBatchProcesses = createSelector(
    (state, batchId) => state.processes.manager.batches[batchId],
    state => state.processes.manager.ids,
    state => state.processes.store.byIds,
    state => state.processes.manager.byKeys,
    (batch, ids, store, byKeys) => {
        ids = Object.keys(ids).reduce((acc, id) => {
            acc[ids[id]] = id
            return acc
        }, {})

        const processes = batch.processes
            .map(key => {
                const id = ids[key]
                return store[id] || byKeys[key]
            })

        return {
            batch,
            processes,
        }
    },
)

export const selectBatches = createSelector(
    state => state.processes.manager.batches,
    batches => Object.values(batches).reverse(),
)
import {selectPerPage} from 'modules/settings/selectors'
import {all, call, put, select} from 'redux-saga/effects'

import {POLL_UPGRADE_STATUS} from 'configs/pollers'

import createListSaga from 'modules/higherOrder/createListSaga'
import createPollerSaga from 'modules/higherOrder/createPollerSaga'

import toIds from 'utils/toIds'
import {update} from '../store/actions'

import * as actions from './actions'
import * as api from 'api/upgradeStatus'

const selector = (state) => ({
    ...state.upgradeStatus.list,
    perPage: selectPerPage(state, 'upgrades'),
})

export default function* () {
    yield all([
        createListSaga(api, actions, update, selector),
        createPollerSaga(actions.startPoll, actions.startPoll, POLL_UPGRADE_STATUS, invalidateUpgradeStatus)
    ])
}

function* invalidateUpgradeStatus() {
    const state = yield select(selector)

    try {
        const {rows, count} = yield call(api.fetch, state)
        yield put(update(rows))
        yield put(actions.receive(toIds(rows), count))
    } catch (error) {
        yield put(actions.receive(error))
    }
}

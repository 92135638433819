import {__} from 'utils/i18n'
import {warn} from 'utils/log'

import * as ZoneTypes from './zoneType'

export const ALARM_PANIC = 'PANIC'
export const ALARM_EMERGENCY = 'EMERGENCY'
export const ALARM_FIRE = 'FIRE'
export const ALARM_SMOKE_MEMORY = 'SMOKE_MEMORY'
export const ALARM_HEAT_MEMORY = 'HEAT_MEMORY'
export const ALARM_TAMPER_MEMORY = 'TAMPER_MEMORY'
export const ALARM_ALARM_IN_MEMORY = 'ALARM_IN_MEMORY'
export const ALARM_DURESS = 'DURESS'
export const ALARM_ALARM_DURESS = 'ALARM_DURESS'
export const ALARM_BURGLARY = 'BURGLARY'
export const ALARM_HR24_SUPERVISORY = 'HR24_SUPERVISORY'
export const ALARM_CO_ALARM = 'CO_ALARM'
export const ALARM_GAS_ALARM = 'GAS_ALARM'
export const ALARM_HIGH_TEMPERATURE = 'HIGH_TEMPERATURE'
export const ALARM_LOW_TEMPERATURE = 'LOW_TEMPERATURE'
export const ALARM_WATERFLOW = 'WATERFLOW'
export const ALARM_WATER_LEAKAGE = 'WATER_LEAKAGE'
export const ALARM_HOLD_UP = 'HOLD_UP'
export const ALARM_SPRINKLER = 'SPRINKLER'
export const ALARM_BURGLARY_NO_VERIFIED = 'BURGLARY_NO_VERIFIED'
export const ALARM_HOLD_UP_NO_VERIFIED = 'HOLD_UP_NO_VERIFIED'
export const ALARM_HIGH_TEMPERATURE_WARNING = 'HIGH_TEMPERATURE_WARNING'
export const ALARM_LOW_TEMPERATURE_WARNING = 'LOW_TEMPERATURE_WARNING'
export const ALARM_RF_JAM_ALARM = 'RF_JAM_ALARM'
export const ALARM_HARDWARE_FAULT = 'HARDWARE_FAULT'
export const ALARM_QUICK_BYPASS = 'QUICK_BYPASS'
export const ALARM_UNKNOWN = 'UNKNOWN'
export const ALARM_GENERAL = 'GENERAL'
export const ALARM_GAS = 'GAS'
export const ALARM_CO = 'CO'

const icons = {
    [ALARM_PANIC]: require('icons/worries/panic.svg'),
    [ALARM_EMERGENCY]: require('icons/worries/emergency.svg'),
    [ALARM_FIRE]: require('icons/worries/fire.svg'),
    [ALARM_SMOKE_MEMORY]: require('icons/worries/flood.svg'),
    [ALARM_HEAT_MEMORY]: require('icons/worries/heat.svg'),
    [ALARM_TAMPER_MEMORY]: require('icons/worries/tamper.svg'),
    [ALARM_CO_ALARM]: require('icons/worries/co.svg'),
    [ALARM_BURGLARY]: require('icons/worries/burglary.svg'),
    [ALARM_HR24_SUPERVISORY]: require('icons/worries/h24.svg'),
    [ALARM_CO_ALARM]: require('icons/worries/co.svg'),
    [ALARM_GAS_ALARM]: require('icons/worries/gas.svg'),
    [ALARM_HIGH_TEMPERATURE]: require('icons/worries/heat.svg'),
    [ALARM_LOW_TEMPERATURE]: require('icons/worries/cold.svg'),
    [ALARM_WATERFLOW]: require('icons/worries/flood.svg'),
    [ALARM_WATER_LEAKAGE]: require('icons/worries/flood.svg'),
    [ALARM_SPRINKLER]: require('icons/worries/flood.svg'),
    [ALARM_BURGLARY_NO_VERIFIED]: require('icons/worries/burglary.svg'),
    [ALARM_HIGH_TEMPERATURE_WARNING]: require('icons/worries/heat.svg'),
    [ALARM_LOW_TEMPERATURE_WARNING]: require('icons/worries/cold.svg'),
    [ALARM_RF_JAM_ALARM]: require('icons/worries/jammed.svg'),
    [ALARM_HARDWARE_FAULT]: require('icons/worries/tamper.svg'),
    [ALARM_QUICK_BYPASS]: require('icons/worries/bypass.svg'),
    [ALARM_GAS]: require('icons/worries/gas.svg'),
    [ALARM_CO]: require('icons/worries/co.svg'),
}

const iconsByZone = {
    [ZoneTypes.ZONE_TYPE_PUSH_BUTTON]: require('icons/worries/panic.svg'),
    [ZoneTypes.ZONE_TYPE_KEYFOB]: require('icons/worries/panic.svg'),
    [ZoneTypes.ZONE_TYPE_SMOKE_OR_HEAT]: require('icons/worries/fire.svg'),
    [ZoneTypes.ZONE_TYPE_FIRE]: require('icons/worries/fire.svg'),
    [ZoneTypes.ZONE_TYPE_SMOKE]: require('icons/worries/fire.svg'),
    [ZoneTypes.ZONE_TYPE_GAS]: require('icons/worries/gas.svg'),
    [ZoneTypes.ZONE_TYPE_FLOOD]: require('icons/worries/flood.svg'),
    [ZoneTypes.ZONE_TYPE_EMERGENCY]: require('icons/worries/emergency.svg'),
    [ZoneTypes.ZONE_TYPE_DELAY_1]: require('icons/worries/burglary.svg'),
    [ZoneTypes.ZONE_TYPE_DELAY_2]: require('icons/worries/burglary.svg'),
    [ZoneTypes.ZONE_TYPE_HOME_DELAY]: require('icons/worries/burglary.svg'),
    [ZoneTypes.ZONE_TYPE_GUARD_KEYBOX]: require('icons/worries/guard.svg'),
    [ZoneTypes.ZONE_TYPE_INTERIOR]: require('icons/worries/interior.svg'),
    [ZoneTypes.ZONE_TYPE_INTERIOR_FOLLOW]: require('icons/worries/interior.svg'),
    [ZoneTypes.ZONE_TYPE_PERIMETER]: require('icons/worries/perimeter.svg'),
    [ZoneTypes.ZONE_TYPE_PERIMETER_FOLLOW]: require('icons/worries/perimeter.svg'),
    [ZoneTypes.ZONE_TYPE_H24_SILENT]: require('icons/worries/h24.svg'),
    [ZoneTypes.ZONE_TYPE_H24_AUDIBLE]: require('icons/worries/h24.svg'),
    [ZoneTypes.ZONE_TYPE_CO]: require('icons/worries/co.svg'),
}

const unknown = require('icons/worries/trouble.svg')

function alarmTitleByZone(zoneType) {
    switch (zoneType) {
        case ZoneTypes.ZONE_TYPE_PUSH_BUTTON: return __('Push_button')
        case ZoneTypes.ZONE_TYPE_KEYFOB: return __('Keyfob')
        case ZoneTypes.ZONE_TYPE_SMOKE: return __('Smoke')
        case ZoneTypes.ZONE_TYPE_GAS: return __('Gas')
        case ZoneTypes.ZONE_TYPE_FLOOD: return __('Flood')
        case ZoneTypes.ZONE_TYPE_SMOKE_OR_HEAT: return __('Smoke or heat')
        case ZoneTypes.ZONE_TYPE_FIRE: return __('Fire')
        case ZoneTypes.ZONE_TYPE_EMERGENCY: return __('Emergency')
        case ZoneTypes.ZONE_TYPE_DELAY_1: return __('Delay 1')
        case ZoneTypes.ZONE_TYPE_DELAY_2: return __('Delay 2')
        case ZoneTypes.ZONE_TYPE_HOME_DELAY: return __('Home_delay')
        case ZoneTypes.ZONE_TYPE_GUARD_KEYBOX: return __('Guard keybox')
        case ZoneTypes.ZONE_TYPE_INTERIOR: return __('Interior')
        case ZoneTypes.ZONE_TYPE_INTERIOR_FOLLOW: return __('Interior follow')
        case ZoneTypes.ZONE_TYPE_PERIMETER: return __('Perimeter')
        case ZoneTypes.ZONE_TYPE_PERIMETER_FOLLOW: return __('Perimeter follow')
        case ZoneTypes.ZONE_TYPE_H24_SILENT: return __('24H silent')
        case ZoneTypes.ZONE_TYPE_H24_AUDIBLE: return __('24H audible')
        case ZoneTypes.ZONE_TYPE_OUTDOOR: return __('Outdoor')
        case ZoneTypes.ZONE_TYPE_CO: return __('Co')
        case ZoneTypes.ZONE_TYPE_NO_ZONE_TYPE: return __('No zone type')
    }

    warn(`Unknown alarm in memory zone type ${zoneType}`)

    return zoneType || __('Alarm in memory')
}

export function alarmTitle(alarm, zoneType) {
    if (alarm === ALARM_ALARM_IN_MEMORY) {
        return alarmTitleByZone(zoneType)
    }

    switch (alarm) {
        case ALARM_PANIC: return __('Panic alarm')
        case ALARM_EMERGENCY: return __('Emergency alarm')
        case ALARM_FIRE: return __('Fire alarm')
        case ALARM_SMOKE_MEMORY: return __('Smoke alarm')
        case ALARM_HEAT_MEMORY: return __('Heat alarm')
        case ALARM_TAMPER_MEMORY: return __('Tamper alarm')
        case ALARM_ALARM_DURESS: return __('Duress')
        case ALARM_BURGLARY: return __('Burglary')
        case ALARM_HR24_SUPERVISORY: return __('HR24 supervisory')
        case ALARM_CO_ALARM: return __('CO alarm')
        case ALARM_GAS_ALARM: return __('Gas alarm')
        case ALARM_HIGH_TEMPERATURE: return __('High temperature')
        case ALARM_LOW_TEMPERATURE: return __('Low temperature')
        case ALARM_WATERFLOW: return __('Waterflow')
        case ALARM_WATER_LEAKAGE: return __('Water leakage')
        case ALARM_HOLD_UP: return __('Hold up')
        case ALARM_SPRINKLER: return __('Sprinkler')
        case ALARM_BURGLARY_NO_VERIFIED: return __('Burglary no verified')
        case ALARM_HOLD_UP_NO_VERIFIED: return __('Hold up no verified')
        case ALARM_HIGH_TEMPERATURE_WARNING: return __('High temperature warning')
        case ALARM_LOW_TEMPERATURE_WARNING: return __('Low temperature warning')
        case ALARM_RF_JAM_ALARM: return __('RF jam alarm')
        case ALARM_HARDWARE_FAULT: return __('Hardware fault')
        case ALARM_QUICK_BYPASS: return __('Quick bypass')
        case ALARM_DURESS: return __('Duress')
        case ALARM_GENERAL: return __('General')
        case ALARM_GAS: return __('Gas')
        case ALARM_CO: return __('CO')
        case ALARM_UNKNOWN: return __('Unknown')
    }

    warn(`Unknown alarm ${alarm}`)

    return alarm
}

function alarmIconByZone(zoneType) {
    if (iconsByZone.hasOwnProperty(zoneType)) {
        return iconsByZone[zoneType]
    }

    warn(`Unknown icon for alarm in memory zone type ${zoneType}`)

    return unknown
}

export function alarmIcon(alarm, zoneType) {
    if (alarm === ALARM_ALARM_IN_MEMORY) {
        return alarmIconByZone(zoneType)
    }

    if (icons.hasOwnProperty(alarm)) {
        return icons[alarm]
    }

    warn(`Unknown icon for alarm ${alarm}`)

    return unknown
}
import timezones from 'utils/timezones'
import isEmpty from 'lodash-es/isEmpty'

const prepareParams = parameters => {
    const settings = formatSettings(parameters)

    return {
        name: parameters.name,
        ...formatPanels(parameters),
        schedule: {
            ...formatPattern(parameters),
            ...formatSchedule(parameters),
            ...formatRange(parameters),
            settings: isEmpty(settings) ? null : settings,
        },
        ...formatTransmission(parameters),
        ...formatExport(parameters),
    }
}

const formatPanels = ({panelIds}) => ({panelIds})

const formatPattern = ({schedule: {pattern}}) => ({pattern})

const formatSchedule = ({schedule}) => {
    const scheduleMoment = (schedule.schedule ? timezones.server(`${schedule.schedule} ${schedule.schedule_time}`, 'L LT') : timezones.server())

    return {schedule: scheduleMoment.format('YYYY-MM-DD HH:mm:ss')}
}

const formatRange = ({_rangeEndAfter, _rangeEndDateEnabled, range, schedule}) => {
    if (range) {
        const {end_date, times} = range
        const scheduleMoment = (schedule.schedule ? timezones.server(`${schedule.schedule} ${schedule.schedule_time}`, 'L LT') : timezones.server())
        const start = scheduleMoment.add('1', 'day').format('YYYY-MM-DD')

        if (_rangeEndAfter) {
            return {
                range: {
                    start,
                    times: parseInt(times),
                },
            }
        }

        if (_rangeEndDateEnabled) {
            return {
                range: {
                    start,
                    end_date: timezones.server(end_date, 'L').format('YYYY-MM-DD'),
                },
            }
        }
    }

    return {}
}

const formatSettings = (formData) => {
    switch (formData.schedule.pattern) {
        case 'daily':
            return formatDailySettings(formData)
        case 'weekly':
            return formatWeeklySettings(formData)
        case 'monthly':
            return formatMonthlySettings(formData)
        case 'yearly':
            return formatYearlySettings(formData)
        default:
            return {}
    }
}

const formatDailySettings = ({_recurEvery, settings: {every, everyWeekDay}}) => {
    if (_recurEvery) {
        return {every: parseInt(every)}
    }

    if (everyWeekDay) {
        return {'every-week-day': true}
    }

    return {}
}

const formatWeeklySettings = ({weeklyDay, weeklyEvery}) => {
    weeklyDay = weeklyDay.map((number) => parseInt(number))

    return {
        every: parseInt(weeklyEvery),
        day: new Array(7).fill(0).map((_, idx) => (weeklyDay.indexOf(idx + 1) >= 0) ? 1 : 0),
    }
}

const formatMonthlySettings = ({monthlyEvery, _everyDayOfMonth, _everyDayOfWeek, dayOfMonth, numberOfDay, dayOfWeek}) => {
    if (_everyDayOfMonth) {
        return {
            'every': parseInt(monthlyEvery),
            'day-month': {
                day: parseInt(dayOfMonth),
            },
        }
    }

    if (_everyDayOfWeek) {
        return {
            'every': parseInt(monthlyEvery),
            'day-week': {
                num: parseInt(numberOfDay),
                dow: parseInt(dayOfWeek),
            },
        }
    }

    return {}
}

const formatYearlySettings = ({_yearlyDayMonth, _yearlyDayWeekMonth, yearlyDay, yearlyMonth, every, yearlyWeekNumber, yearlyWeekDay, yearlyWeekMonth}) => {
    if (_yearlyDayMonth) {
        return {
            every: parseInt(every),
            'day-month': {
                day: parseInt(yearlyDay),
                month: parseInt(yearlyMonth),
            },
        }
    }

    if (_yearlyDayWeekMonth) {
        return {
            every: parseInt(every),
            'day-week-month': {
                num: parseInt(yearlyWeekNumber),
                dow: parseInt(yearlyWeekDay),
                month: parseInt(yearlyWeekMonth),
            },
        }
    }

    return {}
}

const formatTransmission = ({sendTo, destinationEmails, destinationFtp}) => {
    if (!sendTo) {
        return {
            sendTo: null,
        }
    }

    if (!Array.isArray(sendTo)) {
        sendTo = [sendTo]
    }

    let transmission = {sendTo}

    if (sendTo.indexOf('email') >= 0) {
        destinationEmails = normalizeArray(destinationEmails)

        if (destinationEmails.length) {
            transmission = {
                ...transmission,
                destinationEmails,
            }
        }
    }

    if (sendTo.indexOf('ftp') >= 0) {
        transmission = {
            ...transmission,
            destinationFtp,
        }
    }

    return transmission
}

const formatExport = (formData) => {
    if (!formData.export) {
        return null
    }

    const exports = Array.isArray(formData.export) ? formData.export : [formData.export]
    let exporting = {
        'export': exports,
    }

    // pdf
    if (exports.includes('1')) {
        let {contentOptions, pmaxIndexes} = formData

        if (contentOptions) {
            contentOptions = normalizeArray(contentOptions)
            if (contentOptions.length) {
                exporting.contentOptions = contentOptions
            }
        }

        pmaxIndexes = normalizeArray(pmaxIndexes).map(pmaxIndex => parseInt(pmaxIndex))
        if (pmaxIndexes.length) {
            exporting.configOptions = pmaxIndexes
        }
    }

    return exporting
}

const normalizeArray = (parameter) => {
    parameter = Array.isArray(parameter) ? parameter : [parameter]
    parameter.filter((item) => item && item.length)

    return parameter
}

export default prepareParams
import withConfirmation from 'containers/withConfirmation'
import {compose} from 'redux'

import {makeBasic} from 'permissions/panel/configuration/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withCurrentConfiguration, {withConfigurationActions} from 'containers/withConfiguration'
import withProps from 'containers/withProps'

import Button from 'ui/Button'

import {__} from 'utils/i18n'

export const RestoreConfigurationButton = compose(
    withPermission({isVisible: makeBasic}),
    withVisibility(),
    withCurrentConfiguration(),
    withConfigurationActions(),
    withProps(({values, changes, restoreBackup, backup}) => ({
        onClick: () => restoreBackup(backup),
        label: __('Restore'),
        title: __('Not submitted changes'),
        message: __('You will lose all configuration changes'),
        isConfirmationEnabled: !!changes && Object.keys(changes).length > 0,
        isVisible: values && Object.keys(values).some(
            key => changes.hasOwnProperty(key)
                ? changes[key] !== backup[key]
                : values[key] !== backup[key],
        ),
    })),
    withConfirmation(),
    withVisibility(),
)(Button)
import {withVisibility} from 'containers/withVisibility'
import {selectDeviceByZoneAndType} from 'modules/devices/list/selectors'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {videoOnDemand} from 'permissions/events/actions'
import devicesList from 'permissions/panel/devices/list'

import {fetchVideo, recordVideo} from 'modules/events/one/actions'
import {fetch as fetchDevices} from 'modules/devices/list/actions'
import {setBypass} from 'modules/devices/list/actions'
import withPoller from 'containers/withPoller'
import withLoader from 'containers/withLoader'
import {withPermission} from 'containers/withPermission'

import VideoTabsComonent from './VideoTabs'
import EventDevice from 'components/Devices/EventDevice'
import Panel from 'components/Devices/Panel'
import {SideBar} from 'ui/Layout'
import {isNeoSerial} from 'utils/panelType'

const VideoTabs = compose(
    withPermission({hasLive: videoOnDemand}),
    connect((state, {hasLive, isNeo}) => ({
        hasLive: hasLive && !isNeo,
    })),
    withLoader(({fetch}) => fetch()),
    withPoller(1000, ({fetch}) => fetch()),
)(VideoTabsComonent)

const DeviceCard = compose(
    withPermission({isVisible: devicesList}),
    withVisibility(),
    connect((state, {panelId, event: {zone, deviceType}}) => {
        const device = selectDeviceByZoneAndType(state, {panelId, deviceType, zone})

        return {
            isLoading: !device,
            device,
        }
    },
    (dispatch, {panelId}) => bindActionCreators({
        fetchDevices: () => fetchDevices(panelId),
        setBypass: (deviceId, state) => setBypass(panelId, {[deviceId]: state}),
    }, dispatch)),
    withLoader(({fetchDevices}) => fetchDevices()),
    withPoller(
        5000,
        ({fetchDevices}) => fetchDevices(),
    ),
)(EventDevice)

const PanelCard = compose(
    withPermission({isVisible: devicesList}),
    withVisibility(),
    connect(({panels}, {panelId}) => ({
            panel: panels.store.byIds[panelId],
            isLoading: !panels.store.byIds[panelId],
        }),
        (dispatch, {panelId}) => bindActionCreators({
            fetchDevices: () => fetchDevices(panelId),
        }, dispatch)),
    withLoader(({fetchDevices}) => fetchDevices()),
)(Panel)


class EventsBySerialSidebar extends Component {
    static propTypes: {
        event: PropTypes.object,
        device: PropTypes.object,
        panel: PropTypes.object,
        hasDevice: PropTypes.bool,
        hasPanel: PropTypes.bool,
        fetch: PropTypes.func,
        startRecordVideo: PropTypes.func,
        fetchDevices: PropTypes.func,
        fetchPanel: PropTypes.func,
    }

    renderDeviceCard() {
        const {event, panelId} = this.props
        const {hasDevice, hasPanel} = event

        if (hasDevice) {
            return <DeviceCard {...{event, panelId}} />
        }

        if (hasPanel) {
            return <PanelCard panelId={panelId} />
        }
    }

    render() {
        const {event, ...props} = this.props

        if (!event.hasVideo) {
            return (
                <SideBar>
                    <div className="sidebar-content">
                        {this.renderDeviceCard()}
                    </div>
                </SideBar>
            )
        }

        return (
            <SideBar>
                <VideoTabs video={event.video} isLoading={!event.video} isNeo={isNeoSerial(event.serial)} {...props}>
                    {this.renderDeviceCard()}
                </VideoTabs>
            </SideBar>
        )
    }
}

export default compose(
    connect(
        ({events}, {eventId}) => ({
            event: events.store.byIds[eventId] || {},
        }),
        (dispatch, {eventId, panelId}) => bindActionCreators({
            fetch: () => fetchVideo(eventId),
            startRecordVideo: (zoneId) => recordVideo(eventId, panelId, zoneId),
        }, dispatch)
    )
)(EventsBySerialSidebar)

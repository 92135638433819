import {compose} from 'redux'

import {refresh} from 'permissions/panel/actions'

import withPanel from 'containers/withPanel'
import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import RefreshButton from 'ui/RefreshButton'

export default compose(
    withPermission({isVisible: refresh}),
    withVisibility(),
    withPanel(),
    withProps(({panel: {refreshProcess}, refreshState}) => ({
        onClick: refreshState,
        isRefreshing: !!refreshProcess,
    })),
)(RefreshButton)
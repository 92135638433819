import {take, call, put, select, all} from 'redux-saga/effects'

import * as api from 'api/auth'
import * as actions from './actions'

import {snackShow} from 'modules/snacks'

export default function* () {
    yield all([
        persistSettings(),
    ])
}

function* persistSettings() {
    let settings = yield select(state => state.settings)

    while (true) {
        const action = yield take(Object.values(actions))

        if (action.type === actions.update.toString() || action.type === actions.revert.toString()) {
            settings = yield select(state => state.settings)
            continue
        }

        const settingsToPersist = yield select(state => state.settings)

        try {
            yield call(api.setSettings, settingsToPersist)
            settings = settingsToPersist
        } catch (error) {
            yield put(snackShow(error.message))
            yield put(actions.revert(settings))
        }
    }
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import Hotkey from 'ui/Hotkeys'

import {withRouter} from 'react-router-dom'
import DropDown, {ALIGN_RIGHT, ALIGN_TOP} from 'ui/DropDown'

export class MenuItem extends Component {

    static contextTypes = {
        hidePopup: PropTypes.func,
    }

    static propTypes = {
        selectable: PropTypes.bool,
        disabled: PropTypes.bool,
        shortcut: PropTypes.string,
        onClick(props, propName, componentName) {
            const test = props.disabled ? PropTypes.func : PropTypes.func.isRequired
            return test.apply(this, arguments)
        },
    }

    static defaultProps = {
        selectable: true,
    }

    onClick = (e) => {
        e.preventDefault()

        if (this.props.disabled) {
            return
        }

        this.props.onClick && this.props.onClick(e)

        if (this.context.hidePopup) {
            this.context.hidePopup()
        }
    }

    render() {
        const {
            className,
            Icon,
            shortcut,
            scope,
            onMouseEnter,
            onMouseLeave,
            disabled,
            selectable,
            children,
            isHovered,
        } = this.props

        return (
            <div
                onClick={this.onClick}
                className={classes('menu-item', className, {
                    'menu-item--disabled': disabled,
                    'menu-item--selectable': selectable,
                    'menu-item--hover': !disabled && isHovered,
                })}
                onMouseEnter={onMouseEnter}
                onMouseLeave={onMouseLeave}
            >
                {isHovered && <Hotkey shortcut="enter" action={this.onClick} scope={scope}/>}
                {shortcut && <Hotkey shortcut={shortcut} action={this.onClick} scope={scope}/>}
                {Icon && <Icon className="menu-item-icon"/>}
                {children}
            </div>
        )
    }
}

class MenuLinkComponent extends MenuItem {

    static propTypes = {
        selectable: PropTypes.bool,
        disabled: PropTypes.bool,
    }

    onClick = (e) => {
        if (this.props.disabled) {
            return
        }

        this.props.history.push(this.props.to)

        if (this.context.hidePopup) {
            this.context.hidePopup()
        }
    }
}

export const MenuLink = withRouter(MenuLinkComponent)

export const MenuDelimiter = () => <div className="menu-delimiter"></div>

export const MenuHeader = ({children}) => <div className="menu-header">{children}</div>

export const MenuIcon = ({Icon, className, align = ALIGN_TOP | ALIGN_RIGHT, ...props}) => (
    <DropDown trigger={<Icon/>} align={align} className={className}>
        <Menu {...props}/>
    </DropDown>
)

export default class Menu extends Component {

    static propTypes = {
        className: PropTypes.string,
        scope: PropTypes.string,
    }

    static contextTypes = {
        hidePopup: PropTypes.func,
    }

    state = {index: -1}

    componentWillReceiveProps(props) {
        const {children} = this.props

        if (props.children !== children) {
            const child = React.Children.toArray(this.props.children)[this.state.index]

            if (child) {
                const index = React.Children.toArray(props.children)
                    .findIndex(({key}) => key === child.key)

                this.setState({index})
            }
        }
    }

    setNext = e => {
        e.stopPropagation()
        e.preventDefault()

        this.setState(({index}) => {
            const children = React.Children.toArray(this.props.children)

            for (index = index + 1; index < children.length; index++) {
                if (this.isEnabled(children[index])) {
                    return {index}
                }
            }
        })
    }

    cancel = e => {
        if (this.context.hidePopup) {
            this.context.hidePopup()
            e.stopPropagation()
            e.preventDefault()
        }
    }

    select = (index) => {
        this.setState({index})
    }

    setPrev = e => {
        e.stopPropagation()
        e.preventDefault()

        this.setState(({index}) => {
            const children = React.Children.toArray(this.props.children)

            for (index = index - 1; index >= 0; index--) {
                if (this.isEnabled(children[index])) {
                    return {index}
                }
            }
        })
    }

    isEnabled = (child) => child && child.props && !child.props.disabled

    renderItem = (child, index) => {
        const props = {
            key: index || child.key,
            isHovered: this.state.index === index,
            scope: this.props.scope,
        }

        if (this.isEnabled(child)) {
            props.onMouseEnter = () => this.setState({index})
            props.onMouseLeave = () => this.setState({index: -1})
        }

        return React.cloneElement(child, props)
    }

    render() {
        const {className, children, scope, ...props} = this.props

        return (
            <div className={classes('menu', className)} {...props}>
                <Hotkey shortcut="up" action={this.setPrev} scope={scope}/>
                <Hotkey shortcut="down" action={this.setNext} scope={scope}/>
                <Hotkey shortcut="esc" action={this.cancel} scope={scope}/>

                {React.Children.toArray(children).map(this.renderItem)}
            </div>
        )
    }
}
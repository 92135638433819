import React, {Component} from 'react'
import {compose} from 'redux'
import get from 'lodash-es/get'

import withPanelInfo from 'containers/withPanelInfo'

import List, {ListHintItem} from 'ui/List'
import {__} from 'utils/i18n'
import {getPanelSoftwareVersionNumber} from 'utils/panelInfo'
import panelInfo, {PANEL_SW_VERSION} from 'constants/panelInfo'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {info} from 'permissions/panel/info/page'

import EditPanelInfoButton from './Buttons/EditPanelInfoButton'


class PanelInfo extends Component {
    getFirmavareValue(key) {
        const {panel} = this.props

        if (key === PANEL_SW_VERSION) {
            return panel.isNeo
                ? get(panel, ['firmware', key])
                : getPanelSoftwareVersionNumber(get(panel, ['firmware', key]))
        }

        return get(panel, ['firmware', key])
    }

    render() {
        const {panel} = this.props
        const firmware = panel.firmware || {}

        const empty = <span className="empty">{__('not set')}</span>

        return (
            <div>
                <div className="card-header">
                    {__('Panel Info')}
                    <EditPanelInfoButton/>
                </div>

                <List className="card-content" twoCols>
                    {panel.simNumber && <ListHintItem name={__('Sim number')} text={panel.simNumber}/>}

                    {Object.keys(firmware).map(key => (
                        <ListHintItem
                            key={key}
                            name={panelInfo(key)}
                            text={this.getFirmavareValue(key) || empty}
                        />
                    ))}
                </List>
            </div>
        )
    }
}

export default compose(
    withPermission({isVisible: info}),
    withVisibility(),
    withPanelInfo(),
)(PanelInfo)
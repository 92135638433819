import React from 'react'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import page from 'permissions/firmware/page'

import {fetch} from 'modules/firmware/applianceSelection/actions'
import {withPermission, withRejection} from 'containers/withPermission'
import withLoader from 'containers/withLoader'

import SearchBar from 'components/Search/SearchBar'
import Bar from 'ui/Bar'
import Page from 'ui/Page'
import Table from 'ui/Table'
import Layout from 'ui/Layout'
import Button from 'ui/Button'
import IconGoBack from 'icons/go-back.svg'

import path from 'utils/path'
import {__} from 'utils/i18n'
import deviceType from 'constants/deviceType'

const appliancesColumns = [
    {
        render: ({type}) => deviceType(type),
    },
]

const packagesColumns = [
    {
        width: 150,
        render: ({name}) => name,
    },
    {
        width: 50,
        render: ({version}) => version,
    },
]

const contains = (value, query) => value && (value + '').toLowerCase().indexOf(query) !== -1

export class FirmwarePage extends Page {
    static propTypes = {
        appliances: PropTypes.arrayOf(PropTypes.shape({
            id: PropTypes.number.isRequired,
            type: PropTypes.string.isRequired,
            name: PropTypes.string,
            groupAbbr: PropTypes.string,
            upgradePackages: PropTypes.arrayOf(PropTypes.shape({
                id: PropTypes.number.isRequired,
                version: PropTypes.string.isRequired,
                description: PropTypes.string,
            })),
        })),
    }

    state = {
        value: '',
    }

    handleSearchChange = value => this.setState({
        value,
        query: value.trim().toLowerCase(),
    })

    handleGoBack = () => this.setState({appliance: null, query: '', value: ''})

    renderTop() {
        const {appliance, value} = this.state

        return (
            <Bar>
                {appliance && (
                    <Button
                        shortcut="backspace"
                        onClick={this.handleGoBack}
                        className="btn--goBack">
                        <IconGoBack/>
                    </Button>
                )}

                <SearchBar
                    value={value}
                    onChange={this.handleSearchChange}
                    placeholder={__('Quick search')}
                />
            </Bar>
        )
    }

    selectAppliance = appliance => this.setState({appliance, query: '', value: ''})

    selectPackage = ({id}) => {
        const {history} = this.props
        const {appliance} = this.state

        history.push(path('firmware.upgrade', {
            upgradePackageId: id,
            applianceId: appliance.id,
        }))
    }

    getVisiblePackages() {
        const packages = this.state.appliance.upgradePackages
        const {query} = this.state

        if (!query) {
            return packages
        }

        return packages.filter(({version, name}) => contains(version, query) || contains(name, query))
    }

    renderPackagesTable() {
        const {appliance} = this.state

        return (
            <Table
                fullHeight
                emptyMessage={__('No packages found')}
                title={__('Upgrade %s to version:', appliance.name)}
                rows={this.getVisiblePackages()}
                columns={packagesColumns}
                onRowClick={this.selectPackage}
            />
        )
    }

    getVisibleAppliances() {
        const {appliances} = this.props
        const {query} = this.state

        if (!query) {
            return appliances
        }

        return appliances.filter(({name}) => contains(name, query))
    }

    renderAppliancesTable() {
        return (
            <Table
                fullHeight
                emptyMessage={__('No upgradable devices found')}
                title={__('Choose device for mass upgrade:')}
                rows={this.getVisibleAppliances()}
                columns={appliancesColumns}
                onRowClick={this.selectAppliance}
            />
        )
    }

    renderContent() {
        return (
            <Layout vertical className="firmware">
                {this.state.appliance
                    ? this.renderPackagesTable()
                    : this.renderAppliancesTable()
                }
            </Layout>
        )
    }
}

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    connect(
        ({firmware: {applianceSelection: {isLoading, error, appliances}, store}}) => {
            return {
                isLoading,
                error,
                appliances: appliances.map(id => {
                    const appliance = store.appliances.byIds[id]

                    return {
                        ...appliance,
                        upgradePackages: appliance.upgradePackages
                            .map(upgradePackageId => store.upgradePackages.byIds[upgradePackageId]),
                    }
                }),
            }
        },
        dispatch => bindActionCreators({fetch}, dispatch),
    ),
    withLoader(({fetch}) => {
        fetch()
    }),
)(FirmwarePage)

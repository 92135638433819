import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import autosizeInput from 'autosize-input'
import CrossIcon from 'icons/cross.svg'
import {withNamedError} from 'ui/Form'
import {Tag} from 'ui/Tags'
import DropDown, {ALIGN_LEFT, ALIGN_BOTTOM, ALIGN_WIDTH} from 'ui/DropDown'
import prepareInputName from 'utils/prepareInputName'
import Menu from './Menu'
import classes from 'classnames'
import {Option} from './Select'
import {__} from 'utils/i18n'
import {KEY_CODE_BACKSPACE, KEY_CODE_SUBMIT} from 'utils/keyCodes'
import Tags from 'ui/Tags'
import Spinner from 'ui/Spinner'

export class MultiSelectComponent extends PureComponent {

    static propTypes = {
        name: PropTypes.string,
        error: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        label: PropTypes.string,
        defaultValues: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
        })),
        values: PropTypes.arrayOf(PropTypes.shape({
            label: PropTypes.string,
            value: PropTypes.oneOfType([
                PropTypes.string,
                PropTypes.number,
                PropTypes.bool,
            ]),
        })),
        maxOptionsToShow: PropTypes.number,
        isCreatable: PropTypes.bool,
        maxSelectOptions: PropTypes.number,
        maxSelectOptionsLabel: PropTypes.string,
        isLoading: PropTypes.bool,
        hasSelectAll: PropTypes.bool,
        onAdd: PropTypes.func,
        onRemove: PropTypes.func,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
        onType: PropTypes.func,
    }

    static defaultProps = {
        defaultValues: [],
        maxOptionsToShow: 10,
    }

    align = ALIGN_LEFT | ALIGN_BOTTOM | ALIGN_WIDTH

    state = {}

    labels = {}

    inputRef = null

    dropDownRef = null

    constructor(props, context) {
        super(props, context)
        this.labels = this.createLabels(props.children)
        this.controlled = props.hasOwnProperty('values')

        const values = this.controlled ? props.values : props.defaultValues

        this.state = {
            values,
            input: '',
        }
    }

    handleInputRef = (input) => {
        this.inputRef = input

        if (input) {
            this.fixSize = autosizeInput(input)
        }
    }

    handleDropDownRef = (input) => {
        this.dropDownRef = input
    }

    componentWillReceiveProps(props, context) {
        this.labels = this.createLabels(props.children)

        if (this.controlled) {
            this.setState({values: props.values})
        }
    }

    createLabels(children) {
        return React.Children.toArray(children)
            .reduce((acc, {props}) => {
                if (!props || !props.hasOwnProperty('label')) {
                    return acc
                }

                const value = props.hasOwnProperty('value') ? props.value : props.label

                return {
                    ...acc,
                    [value]: props.label,
                }
            }, {})
    }

    handleElementAdd = (e, element) => {
        const elements = !element.props.selectAll
            ? [element]
            : React.Children.toArray(this.props.children)
                .filter(child => child && child.props && !child.disabled && !child.props.selectAll)

        this.add(e, elements.map(el => el.props.hasOwnProperty('value')
            ? el.props.value
            : el.props.label))
    }

    add = (e, values) => {
        const {values: stateValues} = this.state
        values = Array.isArray(values) ? values : [values]

        values.forEach(value => this.props.onAdd && this.props.onAdd(e, value))

        if (values.length > this.props.maxSelectOptions) {
            return
        }

        if (!this.controlled) {
            values = values.filter(value =>
                !stateValues.some(element => element.value === value))

            this.onType(e, '')
            this.setState({
                values: values.length
                    ? stateValues.concat(values.map(value => ({
                        value,
                        label: this.labels[value] || value,
                    })))
                    : stateValues,
            }, () => {
                if (this.inputRef) {
                    this.fixSize()
                    this.inputRef.focus()
                }
            })

            e.preventDefault()
            e.stopPropagation()
        }
    }

    remove = (e, value) => {
        e.stopPropagation()
        this.props.onRemove && this.props.onRemove(e, value)

        if (!this.controlled) {
            this.setState({
                ...this.state,
                values: this.state.values.filter((element) => element.value !== value),
            })
        }
    }

    getMultiName(name) {
        name = prepareInputName(name)
        return name.substr(-2) === '[]' ? name : `${name}[]`
    }

    isSelected = (value) => {
        return this.state.values.some(currentElement => currentElement.value === value)
    }

    inputFilter(value = '') {
        const inputValue = this.state.input.trim().toLowerCase()
        const compareValue = value.trim().substr(0, inputValue.length).toLowerCase()
        return compareValue === inputValue
    }

    getChildrenArray() {
        const {children, maxOptionsToShow, isCreatable, maxSelectOptions, hasSelectAll, maxSelectOptionsLabel} = this.props
        const {values} = this.state

        const items = React.Children.toArray(children)
            .filter(element => element && element.props)
            .filter(element => {
                const value = element.props.hasOwnProperty('value') ? element.props.value : element.props.label
                return !this.isSelected(value) && this.inputFilter(element.props.label)
            })

        if (maxSelectOptions && values.length >= maxSelectOptions) {
            return [<Option key="" disabled label={maxSelectOptionsLabel || __('Maximum number of values selected')}/>]
        }

        if (isCreatable && !items.some(element => !element.props.disabled)) {
            return [<Option key="" disabled label={__('Press Enter to create')}/>]
        }

        if (items.length === 0) {
            return [<Option key="" disabled label={__('Empty')}/>]
        }

        if (items.length > maxOptionsToShow) {
            return [
                ...items.slice(0, maxOptionsToShow),
                <Option key="" disabled label={__('... type for more elements')}/>,
                hasSelectAll ? <Option key="" label={__('Select All')} selectAll/> : null
            ].filter(value => value !== null)
        }

        if (hasSelectAll && items.length > 1) {
            return [
                ...items,
                <Option key="" label={__('Select All')} selectAll/>,
            ]
        }

        return items
    }

    renderItems() {
        if (this.props.isLoading) {
            return <Spinner/>
        }

        return this.getChildrenArray()
            .map(element => React.cloneElement(element, {
                onClick: (e) => {
                    this.handleElementAdd(e, element)
                },
            }))
    }

    onDropDownShow = () => {
        this.setState({focus: true})
    }

    onDropDownHide = (e) => {
        this.onType(e, '')
        this.setState({focus: false})
        this.inputRef && this.inputRef.blur()
    }

    onKeyDown = (e) => {
        const {onKeyDown, children, isCreatable, maxSelectOptions} = this.props
        const {values} = this.state
        const value = e.target.value.trim()
        let child

        onKeyDown && onKeyDown(e)

        switch (e.keyCode) {
            case KEY_CODE_BACKSPACE:
                if (this.state.input === '' && this.state.values.length > 0) {
                    e.preventDefault()
                    this.remove(e, this.state.values.slice(-1).pop().value)
                }

                break
            case KEY_CODE_SUBMIT:
                e.stopPropagation()

                child = React.Children.toArray(children)
                    .filter(element => element && element.props && !element.disabled)
                    .find(element => {
                        const {label} = element.props
                        return label && label.trim().toLowerCase() === value.toLowerCase()
                    })

                if (maxSelectOptions && values.length >= maxSelectOptions) {
                    return
                }

                if (child) {
                    this.handleElementAdd(e, child)
                } else if (isCreatable) {
                    this.add(e, value)
                }
                break
        }
    }

    onFocus = (e) => {
        this.props.onFocus && this.props.onFocus(e)
        this.dropDownRef && this.dropDownRef.show()
        this.setState({focus: true})
    }

    onBlur = (e) => {
        this.props.onBlur && this.props.onBlur(e)
    }

    onChange = (e) => {
        this.props.onChange && this.props.onChange(e)
        this.onType(e, e.target.value)
        this.dropDownRef && this.dropDownRef.setPosition()
    }

    onType = (e, text) => {
        this.props.onType && this.props.onType(e, text)
        this.setState({input: text})
    }

    formatError(error) {
        switch (typeof error) {
            case 'string':
                return error
            case 'object':
                return error[Object.keys(error)[0]]
            default:
                return 'New error format ' + JSON.stringify(error)
        }
    }

    isDirty() {
        const {values} = this.state
        return values && values.length > 0
    }

    render() {
        const {name, error, label} = this.props
        const {values, focus} = this.state

        const input = <div
            className={classes('input multiSelect', {
                'input--label': label,
                'input--dirty': this.isDirty(),
                'input--focus': focus,
            })}
        >
            {label && <span className="form-field-label">{label}</span>}

            <Tags className="input-field">
                {values.map(({value, label}, key) => (
                    <Tag {...{
                        onIconClick: e => this.remove(e, value),
                        key,
                        value,
                        label,
                        Icon: CrossIcon,
                    }} />
                ))}

                <input
                    key="input"
                    className="multiSelect-input"
                    ref={this.handleInputRef}
                    onChange={this.onChange}
                    onKeyDown={this.onKeyDown}
                    onFocus={this.onFocus}
                    onBlur={this.onBlur}
                    value={this.state.input}
                />
            </Tags>

            {error && <span className="form-field-error">{this.formatError(error)}</span>}

            {name && values.map(({value}, key) => (
                <input
                    key={key}
                    type="hidden"
                    value={value}
                    name={this.getMultiName(name)}/>
            ))}
        </div>

        return (
            <DropDown
                className="form-field"
                ref={this.handleDropDownRef}
                trigger={input}
                align={this.align}
                onShow={this.onDropDownShow}
                onHide={this.onDropDownHide}
                repositionOnTriggerInput
            >
                <Menu>
                    {this.renderItems()}
                </Menu>
            </DropDown>
        )
    }
}

export default withNamedError(MultiSelectComponent)
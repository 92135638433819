import React from 'react'
import classes from 'classnames'
import PropTypes from 'prop-types'

const mouseEvents = [
    'onClick',
    'onMouseDown',
    'onMouseUp',
    'onMouseMove',
]

const Toggle = (props) => {
    const {children, className, indeterminate, checked, disabled, ...inputProps} = props

    const labelProps = {
        className: classes('toggle', className),
    }

    if (checked != undefined) {
        inputProps.checked = checked

        inputProps.ref = (el) => {
            if (el) {
                el.indeterminate = !checked && indeterminate
            }
        }
    }

    mouseEvents.forEach(eventName => {
        if (inputProps[eventName]) {
            labelProps[eventName] = inputProps[eventName]
            delete inputProps[eventName]
        }
    })

    return (
        <span {...labelProps}>
            <input type="checkbox"
                   className="toggle-input"
                   {...{...inputProps, disabled}}/>

            <b className="toggle-box">
                <b className={classes('toggle-box-thumb')}></b>
            </b>

            {children ? <span className="toggle-label">{children}</span> : null}
        </span>
    )
}

Toggle.propTypes = {
    onClick: PropTypes.func,
    onMouseDown: PropTypes.func,
    onMouseUp: PropTypes.func,
    onMouseMove: PropTypes.func,
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    className: PropTypes.string,
}

export default Toggle
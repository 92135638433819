import {createAction} from 'redux-actions'
import createListActions from 'modules/higherOrder/createListActions'

export const {
    init,
    receive,
    reset,
    setStart,
    setQuery,
    setFilters,
    clearFilters,
    addFilters,
    removeFilters,
    fetch,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
} = createListActions('UPGRADE_STATUS/LIST')

export const invalidate = createAction('UPGRADE_STATUS/LIST/INVALIDATE')
import {bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showEditRole} from 'modules/modals/actions'
import IconEdit from 'icons/edit.svg'
import Button from 'ui/Button'

export default connect(
    () => ({
        flat: true,
        small: true,
        primary: true,
        Icon: IconEdit,
    }),
    (dispatch, {row: {id}}) => bindActionCreators({
        onClick: () => showEditRole(id),
    }, dispatch),
)(Button)
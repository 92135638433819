import React, {Component} from 'react'
import PropTypes from 'prop-types'
import IconDownload from 'icons/download.svg'

import Button from 'ui/Button'
import {buildDownloadReportHref} from 'api/reports'

export default class DownloadCell extends Component
{
    static propTypes = {
        id: PropTypes.number.isRequired,
        exportTypes: PropTypes.array.isRequired,
        disabled: PropTypes.bool,
    }

    render() {
        const {exportTypes, id, disabled} = this.props

        return (
            <div className="report-download">
                {exportTypes.map((exportType) => (
                    <Button
                        small
                        flat
                        Icon={IconDownload}
                        disabled={disabled}
                        key={exportType}
                        href={buildDownloadReportHref(id, exportType)}
                        children={exportType}
                    />
                ))}
            </div>
        )
    }
}

import createListSaga from 'modules/higherOrder/createListSaga'
import {selectPerPage} from 'modules/settings/selectors'
import {all} from 'redux-saga/effects'

import {fetchConfigCompatible as fetch, suggestConfigCompatible as suggest} from 'api/panels'
import * as actions from './actions'

import {update} from 'modules/panels/store/actions'

export default function* () {
    yield all([
        createListSaga({fetch, suggest}, actions, update, state => ({
            ...state.basicConfiguration.push,
            perPage: selectPerPage(state, 'pushBasicConfigurations'),
        })),
    ])
}
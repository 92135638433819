import {compose} from 'redux'

import Page from 'ui/Page'

import withDashboard, {withConnectedPanels} from 'containers/withDashboard'
import withLifeCycle from 'containers/withLifeCycle'
import {withPermission, withRejection} from 'containers/withPermission'
import page from 'permissions/dashboard/page'

import DashboardCharts from './DashboardCharts'
import withProps from 'containers/withProps'

export default compose(
    withPermission({isAllowed: page}),
    withRejection(),
    withDashboard(),
    withConnectedPanels(),
    withLifeCycle({
        onMount(
            {
                startPollConnectedPanels,
                startPollEventsRate,
                startPollOnline,
                startPollAllProcesses,
            },
        ) {
            startPollConnectedPanels()
            startPollEventsRate()
            startPollOnline()
            startPollAllProcesses()
        },
        onUnmount(
            {
                stopPollConnectedPanels,
                stopPollEventsRate,
                stopPollOnline,
                stopPollAllProcesses,
            },
        ) {
            stopPollConnectedPanels()
            stopPollEventsRate()
            stopPollOnline()
            stopPollAllProcesses()
        },
    }),
    withProps({
        Content: DashboardCharts,
    })
)(Page)
import withProps from 'containers/withProps'

import PanelsGridBar from './PanelsGridBar'
import PanelsTable from './PanelsTable'
import PanelsSearch from './PanelsSearch'

import Page from 'ui/Page'

export default withProps({
    Top: PanelsSearch,
    Bar: PanelsGridBar,
    Content: PanelsTable,
})(Page)
import React from 'react'

import {
    POWER,
    ARM,
    CHIME,
    TROUBLE,
    COMMUNICATION,
    SERVICE,
    WI_FI,
    READY,
    ARMED,
    AC,
} from 'constants/virtualKeypad/leds'

import IconPower from 'icons/power.svg'
import IconKeypadLock from 'icons/keypad/keypad-lock.svg'
import IconAlarm from 'icons/alarm.svg'
import IconAlert from 'icons/keypad/keypad-alert.svg'
import IconNetwork from 'icons/network.svg'
import IconAmdocs from 'icons/amdocs.svg'
import IconWiFi from 'icons/wi-fi.svg'
import IconAc from 'icons/keypad/keypad-ac.svg'
import IconReady from 'icons/check.svg'

import Tooltip from 'ui/Tooltip'

const renderArmIcon = (ledState) => <Tooltip key="keypad-lock" tooltip="Arming State">
    <IconKeypadLock className={`led led--away ${ledState}`}/>
</Tooltip>

const ledsIcons = [
    {
        name: POWER,
        led: (ledState) => <Tooltip key="power" tooltip="Power">
            <IconPower className={`led led--power ${ledState}`}/>
        </Tooltip>,
    },
    {
        name: READY,
        led: (ledState) => <Tooltip key="ready" tooltip="Ready">
            <IconReady className={`led led--ac ${ledState}`}/>
        </Tooltip>,
    },
    {
        name: ARM,
        led: renderArmIcon,
    },
    {
        name: ARMED,
        led: renderArmIcon,
    },
    {
        name: CHIME,
        led: (ledState) => <Tooltip key="alarm" tooltip="Chime">
            <IconAlarm className={`led led--alarm ${ledState}`} />
        </Tooltip>,
    },
    {
        name: TROUBLE,
        led: (ledState) => <Tooltip key="alert" tooltip="Trouble">
            <IconAlert className={`led led--alert ${ledState}`}/>
        </Tooltip>,
    },
    {
        name: COMMUNICATION,
        led: (ledState) => <Tooltip key="network" tooltip="Communication">
            <IconNetwork className={`led led--network ${ledState}`}/>
        </Tooltip>,
    },
    {
        name: SERVICE,
        led: (ledState) => <Tooltip key="amdocs" tooltip="Service">
            <IconAmdocs className={`led led--amdocs ${ledState}`}/>
        </Tooltip>,
    },
    {
        name: WI_FI,
        led: (ledState) => <Tooltip key="wifi" tooltip="Wi-Fi">
            <IconWiFi className={`led led--wi-fi ${ledState}`}/>
        </Tooltip>,
    },
    {
        name: AC,
        led: (ledState) => <Tooltip key="ac" tooltip="Ac">
            <IconAc className={`led led--ac ${ledState}`}/>
        </Tooltip>,
    },
]

export default ledsIcons
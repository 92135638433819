import {observeBatch} from 'modules/processes/manager/generateProcess'
import {all, call, put, takeEvery} from 'redux-saga/effects'
import {snackShow} from 'modules/snacks'

import * as actions from './actions'
import * as list from 'modules/upgradeStatus/list/actions'
import * as api from 'api/firmware'

export default function* () {
    yield all([
        takeEvery(actions.retry, watchRetry),
    ])
}

function* watchRetry({payload: ids}) {
    try {
        const {processes, lastError} = yield call(api.retry, ids)

        if (lastError) {
            yield put(snackShow(lastError))
        } else {
            yield observeBatch(processes)
            yield put(list.invalidate())
        }
    } catch (error) {
        yield put(snackShow(error.message))
    }
}

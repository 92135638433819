import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import {makeBasic} from 'permissions/panel/configuration/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanel from 'containers/withPanel'

import Button from 'ui/Button'
import {InteractiveBar} from 'ui/InteractiveBar'

import {__, __n} from 'utils/i18n'

class MakeBasicConfigurationInteractiveBar extends Component {
    static propTypes = {
        pendingExport: PropTypes.number,
        commitBasic: PropTypes.func,
        resetChanges: PropTypes.func,
    }

    render() {
        const {makeBasic, pendingExport, commitBasic, resetChanges} = this.props

        return (
            <InteractiveBar active={makeBasic || false}>
                {__n(
                    '1 configuration element selected for creating new basic configuration',
                    '%d configuration elements selected for creating new basic configuration',
                    pendingExport,
                )}
                <Button small onClick={commitBasic} disabled={!pendingExport}>{__('Create')}</Button>
                <Button small borderless onClick={resetChanges}>{__('Dismiss')}</Button>
            </InteractiveBar>
        )
    }
}

export default compose(
    withPermission({isVisible: makeBasic}),
    withVisibility(),
    withPanel(),
)(MakeBasicConfigurationInteractiveBar)
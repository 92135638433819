import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

export default class UnassignedAva extends PureComponent {

    static propTypes = {
        className: PropTypes.string,
        large: PropTypes.bool,
        small: PropTypes.bool,
    }

    render() {
        const {className, large, small, ...props} = this.props

        return (
            <div
                {...props}
                className={classes('ava', className, {
                    'ava--unassigned': true,
                    'ava--small': small,
                    'ava--large': large,
                    'ava--interactive': props.onClick,
                })}
            />
        )
    }
}
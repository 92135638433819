import classes from 'classnames'
import React, {Component, PureComponent} from 'react'
import Layout from 'ui/Layout'

export default class Page extends Component {

    state = {}

    renderTop() {
        const {Top} = this.props
        return Top && <Top {...this.props}/>
    }

    renderBar() {
        const {Bar} = this.props
        return Bar && <Bar {...this.props}/>
    }

    renderContent() {
        const {Content} = this.props
        return Content && <Content {...this.props}/>
    }

    renderInteractiveBar() {
        const {InteractiveBar} = this.props
        return InteractiveBar && <InteractiveBar/>
    }

    handleSetHeight = (height) => {
        this.setState({
            animated: !!this.state.contentStyle,
            contentStyle: {
                marginBottom: `-${height}px`,
                transform: `translate3d(0, ${height}px, 0)`,
                paddingBottom: `${height * 2}px`,
            },
        })
    }

    render() {
        return (
            <Layout vertical className="page">
                <div className="page-top">
                    {this.renderTop()}
                    {this.renderBar()}
                </div>

                <InteractiveBarContainer onSetHeight={this.handleSetHeight}>
                    {this.renderInteractiveBar()}
                </InteractiveBarContainer>

                <div className={classes('page-content', {
                    'page-content--animated': this.state.animated,
                })} style={this.state.contentStyle}>
                    {this.renderContent()}
                </div>
            </Layout>
        )
    }
}

class InteractiveBarContainer extends PureComponent {

    state = {
        active: {},
        children: [],
    }

    setHeight = () => {
        if (!this.element) {
            return
        }

        const height = this.element.offsetHeight

        if (this.height !== height) {
            this.props.onSetHeight(height)
            this.height = height
        }
    }

    handleRef = (element) => {
        this.element = element
        this.setHeight()
    }

    componentDidUpdate() {
        requestAnimationFrame(this.setHeight)
    }

    render() {
        return (
            <div className="page-interactiveBar-container">
                <div ref={this.handleRef} className="page-interactiveBar-items">
                    {this.props.children}
                </div>
            </div>
        )
    }
}


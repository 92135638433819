import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {cleanKey, cleanLog, enable, send} from 'modules/panels/keypad/actions'

export default function withKeypad() {
    return connect(
        (state, {panelId}) => {
            const panel = state.panels.store.byIds[panelId] || {}
            const keypad = state.panels.keypad[panelId] || {}

            return {
                panel,
                keypad,
                hasVirtualKeyboard: panel.isNeo || panel.info.isVirtualKeypad,
                softwareVersion: panel.info.panelSwVersionNumber,
            }
        },
        (dispatch, {panelId}) => bindActionCreators({
            enable: () => enable(panelId),
            send: (...arg) => send(...arg, panelId),
            cleanLog: () => cleanLog(panelId),
            cleanKey: () => cleanKey(panelId),
        }, dispatch),
    )
}
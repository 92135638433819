import React from 'react'
import autosize from 'autosize'

import {InputComponent} from 'ui/Input'
import {withNamedError} from 'ui/Form'

export class TextareaComponent extends InputComponent {

    static defaultProps = {
        autosize: true,
    }

    ref = (element) => {
        this.element = element
        this.props.autosize && element && autosize(element)
    }

    componentWillReceiveProps(props) {
        super.componentWillReceiveProps(props)

        if (props.autosize) {
            this.needUpdate = this.element.value !== props.value
        }
    }

    componentDidUpdate() {
        this.needUpdate && this.element && autosize.update(this.element)
        this.needUpdate = false
    }

    renderElement(props) {
        delete props.autosize

        return (
            <textarea {...props}
                      ref={this.ref}
                      value={this.state.value}
                      className="input-field"
                      onChange={this.onChange}
                      onFocus={this.onFocus}
                      onBlur={this.onBlur}
            />
        )
    }

}

export default withNamedError(TextareaComponent)
import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateSuggestString from 'api/base/generateSuggestString'
import mapSuggests from 'api/base/mapSuggests'
import generateFilterString from 'api/base/generateFilterString'
import parseDate from 'api/base/parseDate'

import dataMapper from 'utils/dataMapper'
import {get, post} from 'api/http'

const keysMap = {
    id: 'pca_id',
    name: 'pca_name',
    version: 'pca_version',
    created: 'pca_create_timestamp',
    vendor: 'cgm_name',
    updated: 'pca_update_timestamp',
}

export const mapBasicConfiguration = dataMapper(basicConfig => ({
    id: parseInt(basicConfig.pca_id),
    name: basicConfig.pca_name,
    version: basicConfig.pca_version,
    created: parseDate(basicConfig.pca_create_timestamp),
    vendor: basicConfig.cgm_name,
    updated: parseDate(basicConfig.pca_update_timestamp),
    configuration: basicConfig.current,
    basicValues: basicConfig.basicValues,
}))

export function fetch({start = 0, perPage: count = 10, filters}) {
    filters = generateFilterString(filters, keysMap)

    return get('pmaxconfigbasic/getallconfigs', {start, count, filter: filters, sort: '-pca_id'})
        .then(createRowsResponseParser(mapBasicConfiguration))
}

export function fetchOne(basicConfigId) {
    return get('pmaxconfigbasic/one', {pca_id: basicConfigId})
        .then(mapBasicConfiguration)
}

export function save({basicConfigId, diff, version}) {
    diff = Object.entries(diff)
    return post('pmaxconfigbasic/save', {pca_id: basicConfigId, diff, version})
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('pmaxconfigbasic/suggestconfigs', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function remove(ids: Array) {
    return post('pmaxconfigbasic/remove', {pca_id: ids})
}
import React, {PureComponent} from 'react'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import uniqid from 'uniqid'

import {saveSearchScope} from 'modules/settings/actions'

import CardForm from 'ui/CardForm'
import Input from 'ui/Input'

import path from 'utils/path'
import {__} from 'utils/i18n'

class SaveSearch extends PureComponent {

    static propTypes = {
        page: PropTypes.any,
        history: PropTypes.shape({
            push: PropTypes.func.isRequired,
        }).isRequired,
        filter: PropTypes.any,
        scopes: PropTypes.object,
        onSave: PropTypes.func.isRequired,
        onClose: PropTypes.func.isRequired,
    }

    rules = {
        name: {
            presence: true,
        },
    }

    handle = ({name}) => {
        const {onSave, onClose, page, history, filter} = this.props
        const scope = uniqid()
        onSave(page, scope, name, filter)
        onClose()
        history.push(path(page, {scope}))
    }

    render() {
        const {onClose} = this.props

        return (
            <CardForm onSubmit={this.handle}
                      onClose={onClose}
                      header={__('Save search')}
                      rules={this.rules}>

                <Input
                    autoFocus
                    name="name"
                    maxLength={30}
                    label={__('Name')}
                />

            </CardForm>
        )
    }

}

export default compose(
    connect(
        (state, {page}) => ({
            scopes: state.settings.scopes[page],
        }),
        dispatch => bindActionCreators({onSave: saveSearchScope}, dispatch),
    ),
    withRouter,
)(SaveSearch)
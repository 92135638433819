import moment from 'moment'
import humanTime from 'utils/humanTime'
import {textFormatResolve} from 'utils/keypad/logger'

export default function saveCSV(array, fileName) {
    let csvContent = 'data:text/csv;charset=utf-8,DATE,ACTION,MSG\r\n'
    const date = moment(Date.now()).format('MM/DD/YYYY @ h:mm:ss')
    fileName = `${fileName}_${date}.csv`

    console.log('array', array)
    const rows = array.map(item => [humanTime(item.time), textFormatResolve(item)])
    console.log('rows', rows)
    rows.forEach((rowArray) => {
        // console.log('rowArray', rowArray)
        let row = rowArray.join(',')
        csvContent += row + '\r\n' // add carriage return
    })

    const encodedUri = encodeURI(csvContent)
    const link = document.createElement('a')
    link.setAttribute('href', encodedUri)
    link.setAttribute('download', fileName)
    document.body.appendChild(link)

    link.click()

    setTimeout(() => {
        link.remove()
    }, 2000)
}
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import moduleTitle, {MODULE_TYPE_BBA, MODULE_TYPE_GPRS} from 'constants/moduleTypes'
import {gsmSignalLevelIcon, signalLevelTitle} from 'constants/signalLevelType'
import Communication from 'components/Panel/Communication'
import Tooltip from 'ui/Tooltip'

export const DeviceModules = ({children}) => <div className="device-modules">{children}</div>

export default class DeviceModule extends Component {

    static propTypes = {
        moduleType: PropTypes.oneOf([MODULE_TYPE_GPRS, MODULE_TYPE_BBA]).isRequired,
        module: PropTypes.shape({
            connected: PropTypes.bool,
            status: PropTypes.string,
        }),
        rssiLevel: PropTypes.string,
    }

    renderRssi() {
        const {rssiLevel} = this.props

        if (rssiLevel === undefined) {
            return
        }

        const Icon = gsmSignalLevelIcon(rssiLevel)

        return (
            <Tooltip className="device-icon-holder" tooltip={signalLevelTitle(rssiLevel)}>
                <Icon className="device-icon"/>
            </Tooltip>
        )
    }

    render() {
        const {moduleType, module} = this.props

        const moduleClasses = classes('device-module', {
            'device-module--notInstalled': !module,
        })

        return (
            <div className={moduleClasses}>
                <Communication
                    moduleType={moduleType}
                    module={module}
                    showTooltip={false}/>

                {moduleTitle(module)}

                {this.renderRssi()}
            </div>
        )
    }
}
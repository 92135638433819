import Button from 'ui/Button'
import {compose} from 'redux'

import {remove} from 'permissions/panel/actions'

import withConfirmation from 'containers/withConfirmation'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanel from 'containers/withPanel'
import withProps from 'containers/withProps'
import {withRouter} from 'react-router'

import IconRemove from 'icons/remove.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: remove}),
    withVisibility(),
    withPanel(),
    withRouter,
    withProps(({remove, history}) => ({
        Icon: IconRemove,
        title: __('Removing panels'),
        label: __('Remove'),
        onClick: remove,

        message: __('Do you really want to remove selected panels?'),
    })),
    withConfirmation(),
)(Button)
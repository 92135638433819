import {delay} from 'redux-saga'
import {all, take, select, call, put, takeEvery} from 'redux-saga/effects'
import isEqual from 'lodash-es/isEqual'

import * as api from 'api/processes'
import * as actions from './actions'
import {loggedIn} from 'modules/auth/sign/actions'

import {persistProcesses} from './persistProcesses'

export default function* () {
    yield all([
        manager(),
        takeEvery(loggedIn, persistProcesses),
    ])
}

function* manager() {
    while (true) {
        const {byKeys, ids} = yield select(state => state.processes.manager)

        const toRefresh = Object.values(byKeys)
            .filter(process => process.isRunning)
            .map(process => process.id)

        if (toRefresh.length) {
            try {
                const processes = yield call(api.status, toRefresh)

                const updated = processes
                    .map(process => ({
                        key: ids[process.id],
                        ...process,
                    }))
                    .filter(process => !isEqual(process, byKeys[process.key]))

                if (updated.length > 0) {
                    yield put(actions.receive(updated))
                }
            } catch (error) {
                // something went wrong
                // what should we do?
                console.error(error)
            }
        } else {
            // wait for new processes
            yield take([actions.observe, actions.restore])
        }

        yield delay(1000)
    }
}
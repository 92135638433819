import {handleActions} from 'redux-actions'
import IdSet from './store'
import {select, deselect, deselectAll} from './actions'

export default handleActions({
    [select](state, {payload}) {
        if (!payload) {
            return state
        }

        return state.add(payload)
    },

    [deselect](state, {payload}) {
        if (!payload) {
            return state
        }

        return state.remove(payload)
    },

    [deselectAll]() {
        return new IdSet
    },
}, new IdSet)



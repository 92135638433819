import {compose} from 'redux'

import {retry} from 'permissions/firmware/upgradeStatus'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withUpgradeStatus from 'containers/withUpgradeStatus'
import {withSelectionHandler} from 'containers/withSelection'

import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({
        isVisible: retry,
    }),
    withVisibility(),
    withUpgradeStatus(),
    withProps(({retry}) => ({
        label: __('Retry'),
        onClick: retry,
    })),
    withSelectionHandler(),
)(Button)

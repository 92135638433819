import React from 'react'
import PropTypes from 'prop-types'

import IconCloud from 'icons/cloud.svg'
import IconPanel from 'icons/panel.svg'
import ProgressBar from 'ui/ProgressBar'

const ProcessLoader = ({message, details, percentage}) => (
    <div className="processLoader">
        <div className="processLoader-pane">
            <IconCloud className="processLoader-icon"/>

            <div className="processLoader-animation">
                <div className="processLoader-animation-progress"/>
                <i className="processLoader-animation-line"/>
                <i className="processLoader-animation-line"/>
                <i className="processLoader-animation-line"/>
                <i className="processLoader-animation-line"/>
            </div>

            <IconPanel className="processLoader-icon"/>
        </div>

        <p className="processLoader-message">{message}</p>

        {!!(percentage || details) && (
            <p className="processLoader-details">
                {!!percentage && <ProgressBar value={percentage} inline/>}
                {details}
            </p>
        )}
    </div>
)

ProcessLoader.propTypes = {
    percentage: PropTypes.number,
    message: PropTypes.string,
    details: PropTypes.string,
}

export default ProcessLoader
import React, {PureComponent} from 'react'
import Definition from 'ui/Definition'

export default class Bypassed extends PureComponent {

    renderRow({name, description}, index) {
        return (
            <div key={index} className="rri-result-details-line">
                <Definition title={name} detail={description}/>
            </div>
        )
    }

    render() {
        return (
            <div>
                {this.props.rows.map(this.renderRow)}
            </div>
        )
    }
}

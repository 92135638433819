import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import IconCalendar from 'icons/calendar.svg'
import IconCross from 'icons/cross.svg'
import DatePicker from './DatePicker'
import Input from './Input'

import parse from 'utils/dateParser'
import DropDown, {ALIGN_BOTTOM, ALIGN_LEFT} from 'ui/DropDown'
import momentPropType from 'utils/momentPropType'

export default class InputDatePicker extends PureComponent {

    static propTypes = {
        range: PropTypes.bool,
        error: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        label: PropTypes.string,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            momentPropType,
            PropTypes.shape({
                from: momentPropType.isRequired,
                to: momentPropType,
            }),
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            momentPropType,
            PropTypes.shape({
                from: momentPropType.isRequired,
                to: momentPropType,
            }),
        ]),
        min: momentPropType,
        max: momentPropType,
        hideOnSelect: PropTypes.bool,
        clearable: PropTypes.bool,
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
    }

    static defaultProps = {
        clearable: true,
    }

    state = {}

    constructor(props) {
        super(props)
        this.controlled = props.hasOwnProperty('value')
        this.state = this.parse(this.controlled ? props.value : props.defaultValue)
    }

    componentWillReceiveProps({value}) {
        if (this.controlled) {
            this.setState(this.parse(value))
        }
    }

    parse(value, strict = false) {
        let {from, to} = parse(value, strict)
        return {from, to, value: this.getValue(from, to)}
    }

    isActive() {
        const {readOnly, disabled} = this.props
        return !readOnly && !disabled
    }

    onDatePickerChange = (from, to) => {
        this.setState({from, to, value: this.getValue(from, to)})
        this.props.onChange && this.props.onChange(from, to)

        if (from && (to || !this.props.range)) {
            this.refs.dropdown.hide()
        }
    }

    getValue(from, to) {
        if (!from) {
            return ''
        }

        return from.format('L') + (to ? ' — ' + to.format('L') : '')
    }

    onClear = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (!this.controlled) {
            this.setState(this.parse(''))
        }

        this.props.onChange && this.props.onChange(null, null)
    }

    onFocus = (e) => {
        if (this.isActive()) {
            this.refs.dropdown.show()
            this.props.onFocus && this.props.onFocus(e)
        }
    }

    onBlur = (e) => {
        const {onChange, onBlur} = this.props
        let value = this.parse(e.target.value, false)
        //this.refs.dropdown.hide()
        onBlur && onBlur(e)
        onChange && onChange(value.from, value.to)
        this.setState(value)
    }

    onChange = (e) => {
        const {from, to} = this.parse(e.target.value, true)

        this.setState({
            from,
            to,
            value: e.target.value,
        })
    }

    onDropDownHide = () => {
        this.setState({focus: false})
    }

    onDropDownShow = () => {
        this.setState({focus: true})
    }

    render() {
        const {range, min, max, clearable, ...props} = this.props
        const {focus, from, value, to} = this.state

        delete props.defaultValue

        const Icon = value ? IconCross : IconCalendar

        const input = (
            <Input
                {...props}
                value={value}
                hasFocus={focus}
                Icon={clearable ? Icon : null}
                onIconClick={value ? this.onClear : null}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
            />
        )

        if (!this.isActive()) {
            return input
        }

        return (
            <DropDown
                className={classNames('form-field', {
                    'form-field--datepicker': range,
                })}
                trigger={input}
                ref="dropdown"
                align={ALIGN_LEFT | ALIGN_BOTTOM}
                onShow={this.onDropDownShow}
                onHide={this.onDropDownHide}>

                <DatePicker
                    range={range}
                    from={from}
                    to={to}
                    min={min}
                    max={max}
                    value={value}
                    onChange={this.onDatePickerChange}
                />

            </DropDown>
        )
    }

}
import React from 'react'
import PropTypes from 'prop-types'
import {SelectComponent, Option} from 'ui/Select'
import {ALIGN_BOTTOM, ALIGN_LEFT, ALIGN_WIDTH} from 'ui/DropDown'
import {withNamedError} from 'ui/Form'
import {__} from 'utils/i18n'

export class AutocompleteComponent extends SelectComponent {

    align = ALIGN_LEFT | ALIGN_BOTTOM | ALIGN_WIDTH

    static propTypes = {
        ...SelectComponent.propTypes,
        onType: PropTypes.func,
        maxOptionsToShow: PropTypes.number,
    }

    static defaultProps = {
        maxOptionsToShow: 10,
    }

    getValueLabel() {
        const {focus, input} = this.state

        const valueLabel = focus
            ? input
            : super.getValueLabel()

        return valueLabel || ''
    }

    onFocus = (e) => {
        this.setState({prefix: '', input: this.getValueLabel()})
        e.target.select()
        this.dropDown && this.dropDown.show()
    }

    onType = (e, prefix) => {
        this.props.onType && this.props.onType(e, prefix)
        this.setState({prefix})
    }

    onChange = (e, value) => {
        this.setState({
            value: undefined,
            input: e.target.value,
        })
        this.onType(e, value !== undefined ? value : e.target.value)
    }

    onDropDownHide = (e) => {
        const {value, prefix} = this.state

        if (prefix && value === undefined) {
            const child = React.Children.toArray(this.props.children)
                .filter(element => element && element.props)
                .find(element => prefix.toLowerCase() === element.props.label.toLowerCase())

            if (child) {
                this.setState({value: child.props.value || child.props.label})
            }
        }

        this.setState({focus: false})
        this.input && this.input.blur()
    }

    set = (e, value) => {
        const label = this.labels[value] || value

        this.onChange(e, label)
        this.setState({value, input: label})

        this.props.onSet && this.props.onSet(e, value)
    }

    getChildrenArray() {
        const {children, maxOptionsToShow, hasMore} = this.props

        const items = React.Children.toArray(children)
            .filter(element => element && element.props)
            .filter(element => this.inputFilter(element.props.label))
            .sort((a, b) => a.props.label.toLowerCase() < b.props.label.toLowerCase() ? -1 : 1)

        if (items.length === 0) {
            return [<Option key="" disabled label={__('Not found')}/>]
        }

        if (hasMore || items.length > maxOptionsToShow) {
            return [
                ...items.slice(0, maxOptionsToShow),
                <Option key="" disabled label={__('... type for more elements')}/>,
            ]
        }

        return items
    }

    inputFilter(value = '') {
        const prefix = this.state.prefix || ''
        const inputValue = prefix.trim().toLowerCase()
        const compareValue = value.trim().substr(0, inputValue.length).toLowerCase()

        return compareValue === inputValue
    }
}

export default withNamedError(AutocompleteComponent)
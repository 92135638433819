import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {withRouter} from 'react-router'
import Button from 'ui/Button'

class LinkButton extends Component {
    static propTypes = {
        Icon: PropTypes.oneOfType([
            PropTypes.func,
            PropTypes.instanceOf(Component),
        ]),
        label: PropTypes.node,
        to: PropTypes.string.isRequired,
        shortcut: PropTypes.string,
        small: PropTypes.bool,
        primary: PropTypes.bool,
    }

    handleClick = (e) => {
        e.stopPropagation()

        const {history, to} = this.props
        history.push(to)
    }

    render() {
        const {Icon, label, children, shortcut, small, primary} = this.props

        return (
            <Button
                Icon={Icon}
                label={label}
                children={children}
                onClick={this.handleClick}
                shortcut={shortcut}
                small={small}
                primary={primary}
            />
        )
    }
}

export default withRouter(LinkButton)
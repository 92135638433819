import KeepOrder from 'pages/Panel/Keypad/KeypadParts/KeepOrder'
import React, {Component, Fragment} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import ProcessLoader from 'ui/ProcessLoader'

import humanTime from 'utils/humanTime'
import {__} from 'utils/i18n'

import {
    PANEL_TYPE_POWER_MASTER_360,
    PANEL_TYPE_POWER_MASTER_360R,
    PANEL_TYPE_POWER_MASTER_365,
} from 'constants/panelType'

import {
    pmaxLeds,
    pmaxLedsExtended,
    neoLeds,
} from 'constants/virtualKeypad/leds'

import getLedState from 'constants/virtualKeypad/ledStatus'

import {LONG_PRESS_DELAY} from 'constants/virtualKeypad/keys'

import saveCSV from 'utils/keypad/saveCSV'
import {MESSAGE_TYPE_KEY, MESSAGE_TYPE_LEDS, MESSAGE_TYPE_TEXT, textFormatResolve} from 'utils/keypad/logger'

import Layout, {ScrollView} from 'ui/Layout'
import Bar, {BarSpace} from 'ui/Bar'
import Buttons from 'ui/Buttons'
import Button from 'ui/Button'
import Card from 'ui/Card'
import List, {ListHintItem} from 'ui/List'
import Spinner from 'ui/Spinner'

import DropDownButton from 'ui/DropDownButton'
import Menu, {MenuDelimiter} from 'ui/Menu'
import Checkbox from 'ui/Checkbox'

import ledsIcons from './KeypadParts/leds'
import {neoDigits, pmaxDigits} from './KeypadParts/digits'

const extendedPmax = [
    PANEL_TYPE_POWER_MASTER_360,
    PANEL_TYPE_POWER_MASTER_360R,
    PANEL_TYPE_POWER_MASTER_365,
]

const lcdLengthNeo = 32
const lcdLengthPMax = 16

export default class KeypadContent extends Component {

    static propTypes = {
        panelId: PropTypes.number.isRequired,
        model: PropTypes.string,
        enabledBy: PropTypes.string,
        enabled: PropTypes.bool,
        leds: PropTypes.object,
        text1: PropTypes.string,
        log: PropTypes.array,
        cleanLog: PropTypes.func.isRequired,
        cleanKey: PropTypes.func.isRequired,
        keyCode: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        pos: PropTypes.object,
        serial: PropTypes.string,
        send: PropTypes.func.isRequired,
    }

    state = {
        filters: {
            [MESSAGE_TYPE_TEXT]: true,
            [MESSAGE_TYPE_LEDS]: true,
            [MESSAGE_TYPE_KEY]: true,
        },
        lcdLength: lcdLengthPMax,
    }

    sendData = null

    keyPressTimer = null

    constructor(props) {
        super(props)

        this.state.lcdLength = props.isNeo && lcdLengthNeo
    }

    componentWillReceiveProps(nextProps) {
        const {cleanKey} = this.props

        if (!nextProps.hasOwnProperty('keyCode')) {
            return
        }

        setTimeout(() => {
            cleanKey()
        }, 200)
    }

    handleKeyPress = key => () => {
        this.sendData = {key}

        this.keyPressTimer = setTimeout(() => {
            this.sendData = {key, longPress: true}
            this.props.send(this.sendData)
            this.sendData = null
        }, LONG_PRESS_DELAY)
    }

    handleKeyRelease = () => {
        clearTimeout(this.keyPressTimer)

        if (this.sendData) {
            this.props.send(this.sendData)
        }

        this.sendData = null
    }

    saveCSV = () => {
        const {log, panelId, serial} = this.props
        const fileName = `log_unitID-${panelId}_serial-${serial}`

        saveCSV(log, fileName)
    }

    getLedState = (ledName) => {
        const {leds} = this.props
        if (typeof leds === 'undefined') {
            return ''
        }

        return getLedState(leds[ledName])
    }

    handleFilterChange = (filtersNames, e) => {
        filtersNames = Array.isArray(filtersNames) ? filtersNames : [filtersNames]

        this.setState({
            filters: {
                ...this.state.filters,
                ...filtersNames.reduce((acc, name) => {
                    acc[name] = e.target.checked

                    return acc
                }, {}),
            },
        })
    }

    getPosition = ({column, line}) => {
        const {isNeo} = this.props

        if (isNeo) {
            line = parseInt(line)
            column = parseInt(column)
            return (line * (this.state.lcdLength / 2)) + column
        } else {
            return parseInt(column)
        }
    }

    textResolve = (text) => {
        const {lcdLength} = this.state

        if (text.length < lcdLength) {
            const diff = lcdLength - text.length
            return text + new Array(diff + 1).join('\xa0')
        } else {
            return text
        }
    }

    renderText(string) {
        // @see PMN-2998. Neo has the same bug as PMaster panels.
        // So, I hope that Iotega won't contain this bug with Hebrew
        // and here is the perfect place to perform checking
        return (<KeepOrder string={string}/>)
    }

    renderLcdText() {
        let text = this.props.text1 ? this.props.text1.replace(/ /g, '\xa0') : ''
        text = this.textResolve(text)

        if (typeof this.props.pos === 'undefined') {
            return this.renderText(text)
        }

        const pos = this.getPosition(this.props.pos)

        const lcdCaretClasses = classes('lcd-caret', {
            'lcd-caret--block': this.props.pos.cursor_type === 'BLOCK',
        })

        return (
            <Fragment>
                {this.renderText(text.substr(0, pos))}
                <span className={lcdCaretClasses}>{text.substr(pos, 1) || '\xa0'}</span>
                {this.renderText(text.substr(pos + 1))}
            </Fragment>
        )
    }

    renderLeds = () => {
        const {model, isNeo} = this.props
        let ledsIconsToRender

        switch (true) {
            case isNeo:
                ledsIconsToRender = ledsIcons.filter(({name}) => neoLeds.includes(name))
                break
            case extendedPmax.includes(model):
                ledsIconsToRender = ledsIcons.filter(({name}) => pmaxLedsExtended.includes(name))
                break
            default:
                ledsIconsToRender = ledsIcons.filter(({name}) => pmaxLeds.includes(name))
                break
        }

        return ledsIconsToRender.map(ledIcon => ledIcon.led(this.getLedState(ledIcon.name)))
    }

    renderDigits = () => {
        const {isNeo, keyCode} = this.props
        let digitsToRender

        switch (isNeo) {
            case true:
                digitsToRender = neoDigits
                break
            default:
                digitsToRender = pmaxDigits
                break
        }

        return digitsToRender.map((entry, index) => (
            <div key={index} className="keypad-digit-container">
                <Button active={keyCode == entry.keyCode}
                        borderless
                        onMouseDown={this.handleKeyPress(entry.keyCode)}
                        onMouseUp={this.handleKeyRelease}>
                    {entry.digit}
                </Button>
            </div>
        ))
    }

    render() {
        const {log, cleanLog, isConnecting, connected, isNeo} = this.props
        const {filters} = this.state

        if (isConnecting) {
            return <Spinner message={__('Connecting')}/>
        }

        const wrapper = text => (
            <KeepOrder string={text}/>
        )

        return (
            <Layout className="keypad-cards-wrapper">
                <Card className={classes('keypad-panel', {
                    'keypad-panel--neo': isNeo,
                })}>
                    {!connected && (
                        <ProcessLoader message={__('Connecting to the panel')}/>
                    )}

                    <Bar className="keypad-bar">
                        {this.renderLeds()}
                    </Bar>
                    <Bar className="keypad-bar keypad-bar-lcd">
                        <span className="lcd-text">
                            {this.renderLcdText()}
                        </span>
                    </Bar>

                    <div className="keypad-keys-container">
                        <div className="keypad-digits">
                            {this.renderDigits()}
                        </div>
                    </div>
                </Card>

                <Card className="keypad-log">
                    <Bar divider>
                        {__('LOG')}
                        <BarSpace/>
                        <Buttons>
                            <DropDownButton flat label={__('Filter')}>
                                <Menu className="keypad-filter">
                                    <Checkbox checked={Object.values(filters).reduce((acc, val) => acc && val, true)}
                                              label={__('Select all')}
                                              onChange={e => this.handleFilterChange(Object.keys(filters), e)}/>
                                    <MenuDelimiter/>
                                    <Checkbox checked={filters[MESSAGE_TYPE_KEY]}
                                              label={__('Key')}
                                              onChange={e => this.handleFilterChange(MESSAGE_TYPE_KEY, e)}/>
                                    <Checkbox checked={filters[MESSAGE_TYPE_LEDS]}
                                              label={__('Leds')}
                                              onChange={e => this.handleFilterChange(MESSAGE_TYPE_LEDS, e)}/>
                                    <Checkbox checked={filters[MESSAGE_TYPE_TEXT]}
                                              label={__('Text')}
                                              onChange={e => this.handleFilterChange(MESSAGE_TYPE_TEXT, e)}/>
                                </Menu>
                            </DropDownButton>
                            <Button flat onClick={cleanLog}>{__('CLEAN')}</Button>
                            <Button flat onClick={this.saveCSV}>{__('DOWNLOAD')}</Button>
                        </Buttons>
                    </Bar>
                    <ScrollView>
                        <List className="keypad-log-list">
                            {log && log.filter(({type}) => filters[type])
                                .map((logItem, index) => (
                                    <ListHintItem
                                        key={index}
                                        name={humanTime(logItem.time).time}
                                        text={wrapper(textFormatResolve(logItem))}
                                    />
                                ))}
                        </List>
                    </ScrollView>
                </Card>
            </Layout>
        )
    }
}
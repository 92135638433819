import React, {Component} from 'react'
import Button from 'ui/Button'
import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ui/Card'
import Logo from 'ui/Logo'
import {__} from 'utils/i18n'
import timezones, {getTz} from 'utils/timezones'

export default class About extends Component {
    handler = null

    state = {
        time: timezones.server(),
    }

    componentDidMount() {
        this.handler = setInterval(() => {
            this.setState({
                time: timezones.server(),
            })
        }, 1000)
    }

    componentWillUnmount() {
        clearInterval(this.handler)
    }

    render() {
        const {onClose} = this.props

        return (
            <Card className="about">
                <CardClose onClick={onClose}/>

                <CardHeader>
                    <Logo multiline/>
                </CardHeader>

                <CardContent>
                    <div className="about-info">
                        <p className="hint hint--gray">{__('Version')}</p>
                        {IPMP_VERSION}
                    </div>

                    <div className="about-info">
                        <p className="hint hint--gray">{__('Build number')}</p>
                        {IPMP_REVISION}
                    </div>

                    <div className="about-info">
                        <p className="hint hint--gray">{__('Server time')}</p>
                        {this.state.time.format('LL – LT')}
                    </div>

                    <div className="about-info">
                        <p className="hint hint--gray">{__('Server timezone')}</p>
                        {getTz()} {timezones.server().format('Z z')}
                    </div>
                </CardContent>

                <CardActions>
                    <Button primary onClick={onClose}>{__('Ok')}</Button>
                </CardActions>
            </Card>
        )
    }
}
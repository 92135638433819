import React from 'react'
import PropTypes from 'prop-types'

import Table from 'ui/Table'
import Error from 'ui/Error'
import TableSeparator from 'ui/Table/Separator'

export default class TableGrouped extends Table {

    static propTypes = {
        separatorCallback: PropTypes.func.isRequired,
    }

    renderRows() {
        const {rows, separatorCallback, ...props} = this.props

        if (!rows || rows.length === 0) {
            return <Error title={this.getEmptyMessage()}/>
        }

        const groups = rows.reduce((groups, row) => {
            const group = separatorCallback(row)

            if (!groups.hasOwnProperty(group)) {
                groups[group] = [row]
                return groups
            }

            groups[group].push(row)
            return groups
        }, {})

        let idx = 0

        return Object.keys(groups)
            .reduce((result, group, index) => {
                const header = (
                    <TableSeparator key={`separator${index}`}>
                        {group}
                    </TableSeparator>
                )

                const rows = groups[group].map(row => this.renderRow(row, idx++, props))

                return [
                    ...result,
                    header,
                    <div className="table-content" key={`group${index}`}>
                        {rows}
                    </div>
                ]
            }, [])
    }
}
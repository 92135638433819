import React, {Component} from 'react'
import {compose} from 'redux'

import bar from 'permissions/centralStations/bar'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import CentralStationsDropDown from './Bar/CentralStationsDropDown'
import RemoveCentralStations from './Bar/RemoveCentralStations'
import CentralStationsPager from './Bar/CentralStationsPager'
import CreateCentralStation from './Bar/CreateCentralStation'

import Bar, {BarSpace} from 'ui/Bar'

class CentralStationsGridBar extends Component {
    render() {
        return (
            <Bar>
                <CentralStationsDropDown/>
                <RemoveCentralStations/>

                <BarSpace/>

                <CentralStationsPager/>
                <CreateCentralStation/>
            </Bar>
        )
    }
}

export default compose(
    withPermission({isVisible: bar}),
    withVisibility(),
)(CentralStationsGridBar)
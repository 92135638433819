import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import IconTime from 'icons/time.svg'
import IconCross from 'icons/cross.svg'
import TimePicker from './TimePicker'
import Input from './Input'

import DropDown, {ALIGN_LEFT, ALIGN_BOTTOM} from 'ui/DropDown'
import {parseTime} from 'utils/dateParser'
import {KEY_CODE_SUBMIT} from 'utils/keyCodes'

export default class InputTimePicker extends PureComponent {

    static propTypes = {
        range: PropTypes.bool,
        error: PropTypes.oneOfType([
            PropTypes.arrayOf(PropTypes.string),
            PropTypes.string,
        ]),
        label: PropTypes.string,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.object,
        ]),
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        onBlur: PropTypes.func,
        onChange: PropTypes.func,
    }

    state = {}

    constructor(props) {
        super(props)
        this.controlled = props.hasOwnProperty('value')
        this.state = this.parse(this.controlled ? props.value : props.defaultValue)
    }

    componentWillReceiveProps({value}) {
        if (this.controlled) {
            this.setState(this.parse(value))
        }
    }

    parse(value) {
        return {value: parseTime(value).format('LT')}
    }

    isActive() {
        const {readOnly, disabled} = this.props
        return !readOnly && !disabled
    }

    onClear = (e) => {
        e.stopPropagation()
        e.preventDefault()

        if (!this.controlled) {
            this.setState(this.parse(''))
        }

        this.props.onChange && this.props.onChange(null, null)
    }

    onFocus = (e) => {
        if (this.isActive()) {
            this.refs.dropdown.show()
            //this.props.onFocus && this.props.onFocus(e)
        }
    }

    onBlur = (e) => {
        if (!this.controlled) {
            this.setState(this.parse(e.target.value, true))
        }

        this.props.onBlur && this.props.onBlur(e)
    }

    onChange = (e, value) => {
        if (this.controlled) {
            this.props.onChange && this.props.onChange(e, value)
        } else {
            this.setState(this.parse(value))
        }
    }

    onKeyUp = (e) => {
        if (e.keyCode === KEY_CODE_SUBMIT) {
            e.preventDefault()
            this.refs.dropdown.hide()
        }
    }

    onDropDownHide = () => {
        this.setState({focus: false})
    }

    onDropDownShow = () => {
        this.setState({focus: true})
    }

    render() {
        const {...props} = this.props
        const {focus, value} = this.state

        delete props.defaultValue

        if (!this.controlled) {
            props.value = value
        }

        const input = (
            <Input
                {...props}
                hasFocus={focus}
                Icon={props.value ? IconCross : IconTime}
                onIconClick={props.value ? this.onClear : null}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
            />
        )

        if (!this.isActive()) {
            return input
        }

        return (
            <DropDown
                className={classNames('form-field')}
                trigger={input}
                ref="dropdown"
                align={ALIGN_LEFT | ALIGN_BOTTOM}
                onShow={this.onDropDownShow}
                onHide={this.onDropDownHide}>

                <TimePicker
                    value={value}
                    onChange={this.onChange}
                    onKeyUp={this.onKeyUp}
                />

            </DropDown>
        )
    }

}
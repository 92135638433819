import {selectPerPage} from 'modules/settings/selectors'
import {all, put, takeEvery} from 'redux-saga/effects'

import {fetchUpgradable as fetch, suggestUpgradable as suggest} from 'api/panels'
import {upgrade} from 'api/firmware'
import * as actions from './actions'
import {update} from 'modules/panels/store/actions'
import createListSaga from 'modules/higherOrder/createListSaga'
import {__} from 'utils/i18n'
import {snackShow} from 'modules/snacks'
import {PROCESS_TYPE_NEO_UPGRADE} from 'constants/processTypes'
import {generateBatch} from 'modules/processes/manager/generateProcess'

export default function* () {
    yield all([
        createListSaga({fetch, suggest}, actions, update, state => ({
            ...state.firmware.list,
            scopes: state.settings.scopes.firmware,
            perPage: selectPerPage(state, 'firmware'),
        })),
        takeEvery(actions.upgrade, watchUpgrade),
    ])
}

function* watchUpgrade({payload: {panelIds, applianceId, upgradePackageId}}) {
    if (panelIds.length === 0) {
        return
    }

    const {execute} = yield generateBatch(PROCESS_TYPE_NEO_UPGRADE, panelIds)
    try {
        yield execute(upgrade, panelIds, applianceId, upgradePackageId)
        yield put(snackShow(__('Firmware will be upgraded')))
    } catch (error) {
        yield put(snackShow(error.message))
    }
}
import {compose} from 'redux'

import withSelection from 'containers/withSelection'
import withUpgradeStatus from 'containers/withUpgradeStatus'

import SelectionDropDown from 'components/SelectionDropDown'

export default compose(
    withUpgradeStatus(),
    withSelection(),
)(SelectionDropDown)

import React from 'react'
import {compose} from 'redux'

import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'
import withPushBasicConfigurations from 'containers/withPushBasicConfigurations'
import withSelection from 'containers/withSelection'
import withProps from 'containers/withProps'

import Table from 'ui/Table'
import {__} from 'utils/i18n'

import {pushBasic} from 'permissions/panels/selection'

import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'

const columns = [
    {
        name: () => __('Panel'),
        render: ({id, serial, account, webname}) => (
            <PanelCell {...{id, serial, account, webname}} />
        ),
    },
    {
        name: () => __('Group'),
        render: ({groupId: id, group: name}) => (
            <GroupCell {...{row: {id, name}}} />
        ),
    },
    {
        name: () => __('Model'),
        render: ({model}) => model || <span className="empty">&mdash;</span>,
    },
    {
        name: () => __('Configuration type'),
        render: ({configModel}) => configModel || <span className="empty">&mdash;</span>,
    },
]

export default compose(
    withPermission({
        isAllowed: pushBasic,
    }),
    withRejection(),
    withProps(({match}) => ({
        basicConfigId: parseInt(match.params.id),
    })),
    withPushBasicConfigurations(),
    withSelection(),
    withLoader(({fetch}) => fetch()),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No applicable units found'),
    }),
)(Table)

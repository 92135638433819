import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'
import Check from 'ui/Check'

export default class Radiobox extends Component {

    static propTypes = {
        label: PropTypes.node,
        name: PropTypes.string.isRequired,
        className: PropTypes.string,
        greyscale: PropTypes.bool,
        indeterminate: PropTypes.bool,
        checked: PropTypes.bool,
        disabled: PropTypes.bool,
        readOnly: PropTypes.bool,
        onChange: PropTypes.func,
        onClick: PropTypes.func,
        onMouseDown: PropTypes.func,
        onMouseUp: PropTypes.func,
        onMouseMov: PropTypes.func,
    }

    render() {
        const {label, children, ...props} = this.props

        return (
            <div className="form-field form-field--checkbox">
                <label>
                    <Check type="radio" {...props}/>
                    <span className={classes('checkbox-label', {
                        'checkbox-label--disabled': props.disabled,
                        'checkbox-label--readOnly': props.readOnly,
                    })}>{children || label}</span>
                </label>
            </div>
        )
    }
}
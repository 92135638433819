import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {withRouterPanelId} from 'containers/withPanel'

import {fetch, fetchMore, setRange, startPoll, stopPoll} from 'modules/panels/remoteInspections/actions'
import {initiateNow} from 'modules/remoteInspections/store/actions'
import {showScheduleRri} from 'modules/modals/actions'

export default function withPanelRemoteInspection() {
    return compose(
        withRouterPanelId(),
        connect(
            ({panels, remoteInspections}, {panelId}) => {
                const {page, ...state} = panels.remoteInspections[panelId] || {isLoading: true}

                const rows = page
                    ? page.map(key => remoteInspections.results[key])
                    : []

                return {
                    panelId,
                    rows,
                    remoteInspection: remoteInspections.store[panelId],
                    ...state,
                }
            },
            (dispatch, {panelId}) => bindActionCreators({
                fetch: () => fetch(panelId),
                fetchMore: () => fetchMore(panelId),
                startPoll: () => startPoll(panelId),
                stopPoll: () => stopPoll(panelId),
                setRange: (from, to) => setRange(panelId, from, to),
                initiateNow: () => initiateNow([panelId]),
                schedule: () => showScheduleRri([panelId]),
            }, dispatch),
        )
    )
}
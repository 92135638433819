import withProps from 'containers/withProps'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import React, {Component, Fragment} from 'react'

import list from 'permissions/firmware/list'
import {fetch, addFilters, removeFilters, clearFilters, fetchSuggests} from 'modules/firmware/list/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Search from 'components/Search/Search'
import SuggestFilter from 'components/Search/Filters/SuggestFilter'

import __ from 'utils/i18n'
import deviceType, {
    DEVICE_TYPE_CONTROL_PANEL,
    DEVICE_TYPE_POWER_LINK,
    DEVICE_TYPE_WIRED_KEYPAD,
    DEVICE_TYPE_ZONE_EXPANDER,
    DEVICE_TYPE_OUTPUT_EXPANDER,
    DEVICE_TYPE_POWER_SUPPLY,
    DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS,
    DEVICE_TYPE_PGH,
    DEVICE_TYPE_COMMUNICATOR,
    DEVICE_TYPE_AUDIO_VERIFICATION,
} from 'constants/deviceType'
import Button from 'ui/Button'
import IconGoBack from 'icons/go-back.svg'
import path from 'utils/path'

const FirmwareUpgradeSearch = compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withProps(() => ({
        filters: [
            new SuggestFilter('webname', __('Name')),
            new SuggestFilter('serial', __('Panel Id')),
            new SuggestFilter('account', __('Account')),
            new SuggestFilter('group', __('Group')),
            new SuggestFilter('model', __('Model')),
            new SuggestFilter(DEVICE_TYPE_CONTROL_PANEL, deviceType(DEVICE_TYPE_CONTROL_PANEL)),
            new SuggestFilter(DEVICE_TYPE_POWER_LINK, deviceType(DEVICE_TYPE_POWER_LINK)),
            new SuggestFilter(DEVICE_TYPE_WIRED_KEYPAD, deviceType(DEVICE_TYPE_WIRED_KEYPAD)),
            new SuggestFilter(DEVICE_TYPE_ZONE_EXPANDER, deviceType(DEVICE_TYPE_ZONE_EXPANDER)),
            new SuggestFilter(DEVICE_TYPE_OUTPUT_EXPANDER, deviceType(DEVICE_TYPE_OUTPUT_EXPANDER)),
            new SuggestFilter(DEVICE_TYPE_POWER_SUPPLY, deviceType(DEVICE_TYPE_POWER_SUPPLY)),
            new SuggestFilter(DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS, deviceType(DEVICE_TYPE_POWER_SUPPLY_WITH_OUTPUTS)),
            new SuggestFilter(DEVICE_TYPE_PGH, deviceType(DEVICE_TYPE_PGH)),
            new SuggestFilter(DEVICE_TYPE_COMMUNICATOR, deviceType(DEVICE_TYPE_COMMUNICATOR)),
            new SuggestFilter(DEVICE_TYPE_AUDIO_VERIFICATION, deviceType(DEVICE_TYPE_AUDIO_VERIFICATION)),
        ],
    })),
    connect(
        ({firmware: {list}}, {match}) => ({
            key: match.params.scope,
            selected: list.filters,
            suggests: list.suggests,
        }),

        dispatch => bindActionCreators({
            apply: fetch,
            onSelect: addFilters,
            onDeselect: removeFilters,
            onClear: clearFilters,
            onSuggest: fetchSuggests,
        }, dispatch),
    ),
)(Search)

export default class FirmwareUpgradeSearchBar extends Component {

    handleGoBack = () => {
        this.props.history.push(path('firmware'))
    }

    render() {
        return (
            <Fragment>
                <FirmwareUpgradeSearch className="firmware-search" {...this.props} />
                <Button onClick={this.handleGoBack} className="btn--goBack firmware-btn--goBack">
                    <IconGoBack/>
                </Button>
            </Fragment>
        )
    }
}

import React from 'react'
import {compose} from 'redux'
import withProps from 'containers/withProps'
import {__} from 'utils/i18n'
import TableGrouped from 'ui/Table/Grouped'
import {humanDate} from 'utils/humanTime'
import DateTime from 'ui/DateTime'

export const columns = [
    {
        width: 50,
        maxWidth: 70,
        render: ({eventId}) => eventId,
    },
    {
        width: 150,
        maxWidth: 200,
        render: ({timestamp}) => timestamp && <DateTime datetime={timestamp} timeFirst/>,
    },
    {
        width: 300,
        render: ({text}) => text,
    },
    {
        width: 200,
        render: ({appointment, partition}) => (
            <div>
                {appointment}
                {partition && <span className="partitions">
                    <span className="partition">{partition}</span>
                </span>}
            </div>
        ),
    },
]

export default compose(
    withProps({
        columns,
        emptyMessage: () => __('No entries'),
        separatorCallback(row) {
            return humanDate(row.timestamp)
        },
    }),
)(TableGrouped)
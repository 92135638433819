import React from 'react'
import PropTypes from 'prop-types'

import classes from 'classnames'

const Buttons = ({className, primary, flat, small, ...props}) => (
    <div className={classes('btns', {
        'btn--primary': primary,
        'btn--small': small,
        'btn--flat': flat,
    }, className)} {...props}/>
)

Buttons.propTypes = {
    className: PropTypes.string,
    small: PropTypes.bool,
    flat: PropTypes.bool,
}

export default Buttons

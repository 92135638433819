import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {refreshState as permission} from 'permissions/panels/selection'

import {refreshState} from 'modules/panels/store/actions'

import withProps from 'containers/withProps'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import {MenuItem} from 'ui/Menu'
import IconRefresh from 'icons/refresh.svg'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: permission}),
    withVisibility(),
    connect(
        null,
        dispatch => bindActionCreators({
            onClick: refreshState,
        }, dispatch),
    ),
    withProps(() => ({
        Icon: IconRefresh,
        children: __('Refresh State'),
    })),
    withSelectionHandler(),
)(MenuItem)

import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import {markAsViewed} from 'permissions/panel/reports/actions'
import page from 'permissions/panel/reports/page'

import Calendar, {CalendarItem} from 'ui/Calendar'
import rainbow from 'utils/rainbow'

import IconView from 'icons/viewed.svg'
import IconChecked from 'icons/check.svg'

import Report from './Report'

import {fetch, reset, review} from 'modules/panels/reports/actions'
import withLoader from 'containers/withLoader'
import {withPermission, withRejection} from 'containers/withPermission'

export class Reports extends Component {

    static propTypes = {
        isReviewActive: PropTypes.bool,
        fetch: PropTypes.func.isRequired,
        review: PropTypes.func.isRequired,
        reports: PropTypes.object,
        data: PropTypes.array,
    }

    renderItem = (report, key) => {
        const {review, isReviewActive} = this.props
        const {reportId, title, date, isReviewed} = report

        const props = {
            key,
            title,
            date,
            color: rainbow(reportId),
            children: <Report report={report}/>,
        }

        if (isReviewActive) {
            props.Icon = isReviewed ? IconChecked : IconView
            props.onIconClick = () => review(report.id)
        }

        return (
            <CalendarItem {...props}/>
        )
    }

    render() {
        const {reports, data, fetch} = this.props

        return (
            <Calendar
                {...reports}
                onSelectDate={fetch}>
                {data && data.map(this.renderItem)}
            </Calendar>
        )
    }
}

export default compose(
    withPermission({isAllowed: page, isReviewActive: markAsViewed}),
    withRejection(),
    connect(
        ({panels}, {match: {params: {id}}}) => {
            const state = panels.reports[id]
            const {error, data, ...reports} = state || {}

            return {
                isLoading: !state,
                error,
                reports,
                data,
            }
        },
        (dispatch, {match: {params: {id}}}) => bindActionCreators({
            reset: () => reset(parseInt(id)),
            fetch: (...arg) => fetch(...arg, parseInt(id)),
            review: (...arg) => review(...arg, parseInt(id)),
        }, dispatch),
    ),
    withLoader(({reset}) => reset()),
)(Reports)
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {
    showMarkForServiceModal,
} from 'modules/modals/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {withSelectionHandler} from 'containers/withSelection'

import {MenuItem} from 'ui/Menu'

import {__} from 'utils/i18n'
import {markForService} from 'permissions/panels/selection'

export default compose(
    withPermission({isVisible: markForService}),
    withVisibility(),
    connect(
        () => ({
            children: __('Mark for service'),
        }),
        dispatch => bindActionCreators({
            onClick: showMarkForServiceModal,
        }, dispatch),
    ),
    withSelectionHandler(),
)(MenuItem)

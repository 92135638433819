import React, {Component} from 'react'
import PropTypes from 'prop-types'

import Button from 'ui/Button'
import withConfirmation from 'containers/withConfirmation'
import {withRouterPanelId} from 'containers/withPanel'
import {upgrade} from 'modules/panels/firmware/actions'
import {__} from 'utils/i18n'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import {withPermission} from 'containers/withPermission'
import {upgrade as upgradePermission} from 'permissions/panel/firmware/actions'
import {withVisibility} from 'containers/withVisibility'

const ConfirmButton = withConfirmation()(Button)

class UpgradeButton extends Component {
    static propTypes = {
        packages: PropTypes.arrayOf(
            PropTypes.shape({
                    deviceType: PropTypes.string.required,
                    packageName: PropTypes.string.required,
                }
            )),
        upgrade: PropTypes.func.isRequired,
    }

    handleClick = () => {
        const {packages, upgrade} = this.props

        upgrade(packages)
    }

    render() {
        return (
            <ConfirmButton
                disabled={!this.props.packages.length}
                onClick={this.handleClick}
                title={__('Firmware Upgrade')}
                message={__('Upgrade Firmware?')}
                label={__('Upgrade')}
            />
        )
    }
}

export default compose(
    withPermission({
        isVisible: upgradePermission,
    }),
    withVisibility(),
    withRouterPanelId(),
    connect(
        null,
        (dispatch, {panelId}) => bindActionCreators({
            upgrade: (packages) => upgrade(panelId, packages),
        }, dispatch)
    )
)(UpgradeButton)
import {takeEvery, all, put, call, select} from 'redux-saga/effects'
import keyBy from 'lodash-es/keyBy'

import {scheduleInspectionByCsvFile, scheduleRri} from 'modules/forms/handlers'
import * as actions from './actions'
import * as api from 'api/remoteInspection'
import {generateBatch} from 'modules/processes/manager/generateProcess'
import {PROCESS_TYPE_REMOTEINSPECTION} from 'constants/processTypes'
import {snackShow} from 'modules/snacks'
import {takeEveryProcessProgress} from 'modules/processes/manager/takeProcess'

export default function* () {
    yield all([
        takeEvery(actions.initiateNow, watchInitiateNow),
        takeEvery(scheduleRri.SUCCESS, watchScheduleRri),
        takeEvery(actions.cancel, watchCancel),
        takeEvery(scheduleInspectionByCsvFile.SUCCESS, watchScheduleByCsvFile),
        takeEveryProcessProgress(PROCESS_TYPE_REMOTEINSPECTION, watchProcessProgress),
    ])
}

function* watchScheduleRri({meta: {ids, date: next, repetition}}) {
    yield put(actions.update(
        ids.map(id => ({id, repetition, next})),
    ))
}

function* watchInitiateNow({payload}) {
    const store = yield select(state => payload.map(id => state.remoteInspections.store[id]))

    const {execute} = yield generateBatch(PROCESS_TYPE_REMOTEINSPECTION, payload)

    yield put(actions.update(payload.map(
        id => ({
            id,
            result: 'progress',
            progress: 0,
        }),
    )))

    try {
        const failed = yield execute(api.initiateInspection, payload)

        if (failed.length > 0) {
            yield put(actions.update(
                store.filter(({id}) => failed.includes(id)),
            ))
        }
    } catch (error) {
        yield put(snackShow(error.message))
        yield put(actions.update(store))
    }
}

function* watchCancel({payload: panelIds}) {
    if (!panelIds.length) {
        return
    }

    const store = yield select(state => state.remoteInspections.store)

    yield put(actions.update(panelIds.map(
        id => ({
            id,
            next: null,
            repetition: null,
        }),
    )))

    try {
        yield call(api.cancelInspection, panelIds)
    } catch (error) {
        yield put(actions.update(panelIds.map(
            id => ({
                id,
                repetition: store[id].repetition,
                next: store[id].next,
            }),
        )))
        yield put(snackShow(error.message))
    }
}

function* watchScheduleByCsvFile({meta}) {
    const content = keyBy(meta, 'serial')
    const store = yield select(state => state.remoteInspections.store)

    const data = Object.values(store)
        .filter(({serial}) => !!content[serial])
        .map(({id, serial}) => {
            const {next: timestamp, repetition} = content[serial]
            const next = new Date

            next.setTime(timestamp)

            return {id, repetition, next}
        })

    yield put(actions.update(data))
}

function* watchProcessProgress({panelId, percentage}) {
    yield put(actions.update({
        id: panelId,
        result: 'progress',
        progress: percentage,
    }))
}
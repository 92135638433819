import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classes from 'classnames'

import {parseTime} from 'utils/dateParser'
import {__} from 'utils/i18n'

export default class TimePicker extends PureComponent {

    static propTypes = {
        onChange: PropTypes.func,
        onKeyUp: PropTypes.func,
        isMeridiem: PropTypes.bool,
        defaultValue: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
        ]),
        value: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.instanceOf(Date),
        ]),
    }

    state = {}

    constructor(props, context) {
        super(props, context)

        this.state = {
            value: parseTime(props.value || props.defaultValue),
            controlled: typeof props.value !== 'undefined',
        }
    }

    isMeridiem() {
        if (this.props.hasOwnProperty('isMeridiem')) {
            return this.props.isMeridiem
        }

        return moment().localeData().longDateFormat('LT').indexOf('A') >= 0
    }

    handleSet(val, unit) {
        return e => {
            const value = moment({
                minutes: this.state.value.minutes(),
                hours: this.state.value.hours(),
            }).set(unit, val)

            this.setState({value})

            this.props.onChange && this.props.onChange(e, value)
        }
    }

    renderMinute(minutes) {
        minutes = (minutes + 60) % 60

        return (
            <div className="timePicker-val" onClick={this.handleSet(minutes, 'minutes')}>
                {minutes}
            </div>
        )
    }

    renderMinutes() {
        const minutes = this.state.value.minutes()

        return (
            <div className="timePicker-section">
                <div className="timePicker-vals timePicker-vals--prev">
                    {[5, 3, 1].map(i => this.renderMinute(minutes - i))}
                </div>

                {minutes}

                <div className="timePicker-vals timePicker-vals--next">
                    {[1, 3, 5].map(i => this.renderMinute(minutes + i))}
                </div>
            </div>
        )
    }

    renderHour(hours, isMeridiem) {
        // normalize to 0 - 24
        hours = (hours + 24) % 24

        if (isMeridiem) {
            const pm = this.state.value.hours() >= 12 ? 12 : 0
            hours = (hours % 12) + pm
        }

        return (
            <div className="timePicker-val" onClick={this.handleSet(hours, 'hours')}>
                {isMeridiem
                    ? 1 + (hours + 11) % 12
                    : hours}
            </div>
        )
    }

    renderHours() {
        const isMeridiem = this.isMeridiem()

        const hours = this.state.value.hours()

        return (
            <div className="timePicker-section">
                <div className="timePicker-vals timePicker-vals--prev">
                    {[5, 3, 1].map(i => this.renderHour(hours - i, isMeridiem))}
                </div>

                {isMeridiem
                    ? 1 + (hours + 11) % 12
                    : hours
                }

                <div className="timePicker-vals timePicker-vals--next">
                    {[1, 3, 5].map(i => this.renderHour(hours + i, isMeridiem))}
                </div>
            </div>
        )
    }

    renderMeridiem() {
        if (!this.isMeridiem()) {
            return null
        }

        const hours = this.state.value.hours()
        const pm = hours >= 12

        if (pm) {
            return (
                <div className="timePicker-section">
                    <div className="timePicker-vals timePicker-vals--prev">
                        <div className="timePicker-val" onClick={this.handleSet(hours - 12, 'hours')}>
                            {__('AM')}
                        </div>
                    </div>

                    {__('PM')}
                </div>
            )
        }

        return (
            <div className="timePicker-section">
                {__('AM')}

                <div className="timePicker-vals timePicker-vals--next">
                    <div className="timePicker-val" onClick={this.handleSet(hours + 12, 'hours')}>
                        {__('PM')}
                    </div>
                </div>
            </div>
        )
    }

    render() {
        return (
            <div className={classes('timePicker', this.props.className)}>
                <div className="timePicker-content">
                    {this.renderHours()}

                    <div className="timePicker-delim">
                        :
                    </div>

                    {this.renderMinutes()}

                    <div className="timePicker-space"/>

                    {this.renderMeridiem()}
                </div>
            </div>
        )
    }
}
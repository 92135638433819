import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {SelectComponent, Option} from 'ui/Select'
import Togglebox from 'ui/Togglebox'

export default class ListValue extends PureComponent {

    static propTypes = {
        item: PropTypes.object.isRequired,
        value: PropTypes.number.isRequired,
        onChange: PropTypes.func.isRequired,
    }

    handleToggleChange = (e) => {
        const {items} = this.props.item

        const value = e.target.checked
            ? items[1][0]
            : items[0][0]

        this.handleChange(e, value)
    }

    handleChange = (e, value) => {
        this.props.onChange(value)
    }

    render() {
        const {item: {items}, value} = this.props

        if (items.length < 2) {
            return items[value] ? items[value][1] : value
        }

        if (items.length === 2) {
            return (
                <Togglebox
                    onChange={this.handleToggleChange}
                    label={items[1][1]}
                    checked={value === items[1][0]}
                    labelOff={items[0][1]}/>
            )
        }

        return (
            <SelectComponent value={value} onChange={this.handleChange}>
                {items.map(([key, value]) => <Option key={key} label={value.trim()} value={key}/>)}
            </SelectComponent>
        )
    }
}
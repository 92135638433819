import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import {markAsViewed, sendEmail} from 'permissions/panel/remoteInspections/actions'

import ActionsCell from 'components/Cell/RemoteInspections/ActionsCell'
import * as actions from 'modules/remoteInspections/results/actions'
import {withPermission} from 'containers/withPermission'

export default compose(
    withPermission({
        permissions: {
            markAsViewed,
            sendEmail,
        },
    }),
    connect(
        null,

        (dispatch, {result}) => bindActionCreators({
            markAsViewed: () => actions.markAsViewed(result.id),
            sendEmail: () => actions.sendEmail(result.id),
        }, dispatch),

        (_, {markAsViewed, sendEmail}, {permissions, ...props}) => {
            if (permissions.markAsViewed) {
                props.markAsViewed = markAsViewed
            }

            if (permissions.sendEmail) {
                props.sendEmail = sendEmail
            }

            return props
        },
    ),
)(ActionsCell)
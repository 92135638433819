import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Card, {CardActions, CardClose, CardContent, CardHeader} from 'ui/Card'
import Button from 'ui/Button'

import {__n, __} from 'utils/i18n'

export default class AddCentralStationLimitExceeded extends PureComponent {
    static propTypes = {
        onClose: PropTypes.func,
        maxCentralStations: PropTypes.number,
    }

    render() {
        const {maxCentralStations} = this.props

        return (
            <Card>
                <CardClose onClick={this.props.onClose}/>

                <CardHeader>
                    {__('Limit exceeded')}
                </CardHeader>

                <CardContent>
                    {__n('You cannot add more central stations', 'You cannot add more than %d central stations', maxCentralStations)}
                </CardContent>

                <CardActions>
                    <Button borderless onClick={this.props.onClose}>
                        {__('Dismiss')}
                    </Button>
                </CardActions>
            </Card>
        )
    }
}
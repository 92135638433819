import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {messageByError, CUSTOM_ERROR} from 'constants/errorType'
import {showActivatePanel} from 'modules/modals/actions'
import {activate} from 'permissions/panel/actions'
import {InteractiveBar} from 'ui/InteractiveBar'

import {withPermission} from 'containers/withPermission'

import Button from 'ui/Button'

import {__} from 'utils/i18n'

class ActivatePanelInteractiveBar extends Component {
    static propTypes = {
        isActivated: PropTypes.bool.isRequired,
        isActivating: PropTypes.bool,
        isFailed: PropTypes.bool,
        isNeo: PropTypes.bool,
        error: PropTypes.node,
    }

    getMessage() {
        const {isActivating, isFailed, error} = this.props

        if (isActivating) {
            return __('Panel activating...')
        }

        if (isFailed) {
            return messageByError(CUSTOM_ERROR, {message: __('Panel activation failed: %s', error)})
        }

        return __('This panels has not been activated yet and cannot be correctly managed from PowerManager.')
    }

    getButton() {
        const {showActivatePanel, isActivating, isActivateAllowed} = this.props

        if (isActivateAllowed && !isActivating) {
            return <Button onClick={showActivatePanel} small>{__('Activate')}</Button>
        }

        return null
    }

    render() {
        const {isActivated, isFailed, isNeo} = this.props

        return (
            <InteractiveBar warning danger={isFailed} active={isNeo && !isActivated}>
                {this.getMessage()}
                {this.getButton()}
            </InteractiveBar>
        )
    }
}

export default compose(
    connect(
        ({panels}, {id}) => ({
            isActivating: panels.store.byIds[id].isActivating,
            isActivated: panels.store.byIds[id].isActivated,
            isFailed: panels.store.byIds[id].isFailed,
            isNeo: panels.store.byIds[id].isNeo,
            error: panels.store.byIds[id].activationError,
        }),
        (dispatch, {id}) => bindActionCreators({
            showActivatePanel: () => showActivatePanel(id),
        }, dispatch),
    ),
    withPermission({isActivateAllowed: activate}),
)(ActivatePanelInteractiveBar)
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import Detector from './ChartsTab/Detector'

export default class ChartsTab extends Component {
    static propTypes = {
        panelId: PropTypes.number.isRequired,
        device: PropTypes.object,
        hasBrightness: PropTypes.bool,
        hasTemperature: PropTypes.bool,
    }

    range = {
        from: moment().subtract(1, 'day'),
        to: moment(),
    }

    render() {
        const {panelId, device, hasTemperature, hasBrightness} = this.props

        return (
            <div className="blocks charts">
                {hasTemperature && <Detector
                    type="temperature"
                    range={this.range}
                    panelId={panelId}
                    deviceId={device.id}
                />}

                {hasBrightness && <Detector
                    type="brightness"
                    range={this.range}
                    panelId={panelId}
                    deviceId={device.id}
                />}
            </div>
        )
    }
}
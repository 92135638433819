import {compose} from 'redux'

import list from 'permissions/reports/list'
import selection from 'permissions/reports/selection'
import {markAsViewed, download} from 'permissions/reports/actions'

import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import {withPermission, withRejection} from 'containers/withPermission'
import withSearchScopeLoader from 'containers/withSearchScopeLoader'
import withSelection from 'containers/withSelection'
import withReports from 'containers/withReports'
import withProps from 'containers/withProps'
import withLifeCycle from 'containers/withLifeCycle'

import ReportDownloadCell from './Cells/DownloadReportCell'
import ScheduleReportCell from './Cells/ScheduleReportCell'
import ReviewReportCell from './Cells/ReviewReportCell'
import StatusReportCell from './Cells/StatusReportCell'
import StateReportCell from './Cells/StateReportCell'

import Table from 'ui/Table'

import {__} from 'utils/i18n'

const columns = [
    {
        name: () => __('Name'),
        width: 50,
        render: ({name}) => name,
    },
    {
        name: () => __('Repetition'),
        width: 50,
        component: ScheduleReportCell,
    },
    {
        name: () => __('Finished'),
        component: StatusReportCell,
    },
    {
        name: () => __('Next'),
        component: StateReportCell,
    },
    {
        name: () => __('Files'),
        tooltip: false,
        permission: download,
        width: 180,
        fixed: true,
        component: ReportDownloadCell,
    },
    {
        tooltip: false,
        permission: markAsViewed,
        fixed: true,
        width: 50,
        component: ReviewReportCell,
    },
]

export default compose(
    withPermission({isAllowed: list, hasSelection: selection}),
    withRejection(),
    withReports(),
    withSelection(),
    withSearchScopeLoader(),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No reports found'),
    }),
    withTableColumnsPermission(),
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },
        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
)(Table)
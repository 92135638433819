import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withPanels from 'containers/withPanels'

import PagerComponent from 'ui/Pager'
import list from 'permissions/panels/list'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withPanels(),
)(PagerComponent)
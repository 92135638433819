import React, {Component} from 'react'

import UpgradeStatusPager from './Bar/UpgradeStatusPager'
import UpgradeStatusRetryButton from './Bar/UpgradeStatusRetryButton'
import UpgradeStatusSelectionDropDown from './Bar/UpgradeStatusSelectionDropDown'

import Bar, {BarSpace} from 'ui/Bar'

export default class extends Component {
    render() {
        return (
            <Bar>
                <UpgradeStatusSelectionDropDown/>
                <UpgradeStatusRetryButton/>

                <BarSpace/>

                <UpgradeStatusPager/>
            </Bar>
        )
    }
}

import {handleActions} from 'redux-actions'
import get from 'lodash-es/get'

import {
    update,
    clear,
    setRefreshing,
    setActivationStatus,
    setActivationFailed,
    setActivationSuccess,
} from './actions'

const defaultState = {
    byIds: {},
}

export default handleActions({
    [update](state, {payload}) {
        const byIds = payload.reduce((acc, row) => {
            const old = acc[row.id]

            const panel = {
                ...old,
                ...row,
                info: {
                    ...get(old, 'info', {}),
                    ...get(row, 'info', {}),
                },
            }

            return {
                ...acc,
                [row.id]: panel,
            }
        }, state.byIds || {})

        return {
            ...state,
            byIds,
        }
    },

    [setActivationStatus](state, {payload}) {
        const {panelId, isActivating} = payload

        return {
            ...state,
            byIds: {
                ...state.byIds,
                [panelId]: {
                    ...state.byIds[panelId],
                    isFailed: false,
                    isActivating: isActivating,
                },
            },
        }
    },

    [setActivationSuccess](state, {payload}) {
        const {panelId} = payload

        return {
            ...state,
            byIds: {
                ...state.byIds,
                [panelId]: {
                    ...state.byIds[panelId],
                    isActivated: true,
                    isActivating: false,
                },
            },
        }
    },

    [setActivationFailed](state, {payload}) {
        const {panelId, error} = payload

        return {
            ...state,
            byIds: {
                ...state.byIds,
                [panelId]: {
                    ...state.byIds[panelId],
                    isFailed: true,
                    isActivating: false,
                    activationError: error,
                },
            },
        }
    },

    [setRefreshing](state, {payload: {isRefreshing}}) {
        return {
            ...state,
            isRefreshing,
        }
    },

    [clear]() {
        return defaultState
    },
}, defaultState)
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import classes from 'classnames'

import SuccessIcon from 'icons/check.svg'
import FailedIcon from 'icons/cross.svg'
import Circle from 'ui/Circle'

export default class extends Component {

    static propTypes = {
        isSucceeded: PropTypes.bool.isRequired,
    }

    render() {
        const {isSucceeded} = this.props

        return (
            <Circle Icon={isSucceeded ? SuccessIcon : FailedIcon} className={classes('rri-status', {
                'success': isSucceeded,
                'danger': !isSucceeded,
            })}/>
        )
    }
}
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {setStart, fetch, reset, upgrade} from 'modules/firmware/list/actions'
import {selectFirmwareListRows} from 'modules/firmware/list/selectors'

const mapStateToProps = state => {
    const {start, total, error, isLoading} = state.firmware.list

    return {
        start,
        total,
        error,
        isLoading,
        rows: selectFirmwareListRows(state),
    }
}

const mapDispatchToProps = (dispatch, {match: {params: {applianceId, upgradePackageId}}}) =>
    bindActionCreators({
        setStart,
        reset,
        fetch: () => fetch(applianceId, upgradePackageId),
        upgrade: panels => upgrade(panels, applianceId, upgradePackageId),
    }, dispatch)

export default function withUpgradablePanels() {
    return compose(
        withRouter,
        withPerPage('firmware'),
        connect(
            mapStateToProps,
            mapDispatchToProps,
        )
    )
}

import createRowsResponseParser from 'api/base/createRowsResponseParser'
import generateFilterString from 'api/base/generateFilterString'
import mapSuggests from 'api/base/mapSuggests'
import generateSuggestString from 'api/base/generateSuggestString'
import mapValidationErrors from 'api/base/mapValidationErrors'
import {get, post} from 'api/http'

import dataMapper from 'utils/dataMapper'

const keysMap = {
    id: 'cls_id',
    name: 'cls_name',
    protocol: 'csp_name',
    protocolId: 'csp_id',
    heartBeat: 'cls_heart_beat',
    retryTime: 'cls_retry_time',
    retryCount: 'cls_retry_count',
    host: 'hpa_host',
    port: 'hpa_port',
    ssl: 'cls_ssl',
    receiver: 'cls_receiver',
    line: 'cls_line',
    serialPortId: 'spa_id',
    device: 'spa_device',
    isDeferred: 'cls_deferred_event_delivery',
}

const centralStationMapper = data => ({
    id: parseInt(data.cls_id),
    isDeferred: data.cls_deferred_event_delivery === 'yes',
    name: data.cls_name,
    protocol: data.csp_name,
    ssl: data.cls_ssl,
    host: data.hpa_host,
    port: data.hpa_port,
    protocolId: parseInt(data.csp_id),
    serialPortId: !isNaN(parseInt(data.spa_id)) ? parseInt(data.spa_id) : null,
    device: data.spa_device,
    connectionType: data.spa_id ? 'serial' : 'tcp',
    isHeartBeat: parseInt(data.cls_heart_beat) !== 0,
    heartBeat: parseInt(data.cls_heart_beat),
    retryTime: parseInt(data.cls_retry_time),
    retryCount: parseInt(data.cls_retry_count),
    receiver: parseInt(data.cls_receiver),
    line: parseInt(data.cls_line),
})

const mapCentralStation = dataMapper(centralStationMapper)

const mapProfile = ({etp_id, etp_name, etp_prio}) => ({
    id: parseInt(etp_id),
    name: etp_name,
    priority: parseInt(etp_prio),
})

const mapFormData = data => ({
    cls_name: data.name,
    csp_id: data.protocolId ? parseInt(data.protocolId) : 0,
    cls_heart_beat: data.heartBeat ? parseInt(data.heartBeat) : 0,
    cls_retry_time: data.retryTime ? parseInt(data.retryTime) : null,
    cls_retry_count: data.retryCount ? parseInt(data.retryCount) : 0,
    spa_id: data.serialPortId ? parseInt(data.serialPortId) : null,
    hpa_host: data.host || null,
    hpa_port: data.port ? parseInt(data.port) : null,
    cls_ssl: data.ssl || 'none',
    cls_receiver: data.receiver ? parseInt(data.receiver) : 33,
    cls_line: data.line ? parseInt(data.line) : 2,
})

const mapProtocols = row => ({
    id: parseInt(row.csp_id),
    name: row.csp_name,
})

const mapSerialPorts = row => ({
    id: parseInt(row.spa_id),
    name: row.spa_device,
})



export function fetch({start = 0, perPage: count = 10, filters, query}) {
    const filter = generateFilterString(filters, keysMap)

    return get('/centralstation/getallstations', {start, count, filter, query})
        .then(createRowsResponseParser(mapCentralStation))
}

export function fetchEventProfiles() {
    return get('/centralstation/getprofiles')
        .then(({rows, count}) => rows.map(mapProfile))
}

export function create(data) {
    return post('/centralstation/add', {
        form: mapFormData(data),
    })
        .then(centralStationMapper)
        .catch(e => mapValidationErrors(e, keysMap))
}

export function edit(data, id) {
    return post('/centralstation/edit', {
        cls_id: id,
        form: mapFormData(data),
    })
        .catch(e => mapValidationErrors(e, keysMap))
}

export function getSerialPorts() {
    return get('/centralstation/serials')
        .then(({rows, count}) => rows.map(mapSerialPorts))
}

export function getProtocols() {
    return get('/centralstation/protocols')
        .then(({rows, count}) => rows.map(mapProtocols))
}

export function getCentralStation(id) {
    return get('/centralstation/one', {cls_id: parseInt(id)})
        .then(mapCentralStation)
}

export function suggest(fields, start = 0, count = 5) {
    const suggest = generateSuggestString(fields, keysMap)

    return get('centralstation/suggeststations', {suggest, start, count})
        .then(mapSuggests(fields, keysMap))
}

export function remove(ids) {
    return post('/centralstation/remove', {form: {cls_id: ids}})
}

export function canAdd() {
    return get('/centralstation/canadd')
}
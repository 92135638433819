import {connect} from 'react-redux'
import {bindActionCreators} from 'redux'

import {
    select,
    selectNext,
    selectPrevious,
} from 'modules/remoteInspections/list/actions'

import {markAsViewed, sendEmail} from 'modules/remoteInspections/results/actions'
import {initiateNow} from 'modules/remoteInspections/store/actions'

export default function withRemoteInspectionsResult() {
    return connect(
        ({remoteInspections: {store, results, list}}) => {
            const {selectedId, hasNext, hasPrev} = list
            const inspection = selectedId ? store[selectedId] : null
            const result = inspection && inspection.latestId ? results[inspection.latestId] : null

            return {
                inspection,
                result,
                hasNext,
                hasPrev,
            }
        },

        (dispatch) => bindActionCreators({
            select,
            selectNext,
            selectPrevious,
            markAsViewed,
            sendEmail,
            initiateNow,
        }, dispatch),

        ({inspection, result, ...state}, {markAsViewed, sendEmail, initiateNow, ...dispatch}, own) => ({
            inspection,
            result,
            ...state,
            ...dispatch,

            review: () => result && markAsViewed([result.id]),
            sendEmail: () => result && sendEmail([result.id]),
            initiate: () => inspection && initiateNow([inspection.id]),

            ...own,
        }),
    )
}

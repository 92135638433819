import withLifeCycle from 'containers/withLifeCycle'
import React from 'react'
import {compose} from 'redux'

import {markAsViewed} from 'permissions/events/actions'

import withLoader from 'containers/withLoader'
import withProps from 'containers/withProps'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'

import Table from 'ui/Table'
import DateTime from 'ui/DateTime'
import {SeverityCell, EventAppointmentCell} from 'ui/TableCells'
import Tooltip from 'ui/Tooltip'

import PanelCell from 'components/TableCells/PanelCell'
import CameraIcon from 'icons/camera.svg'
import InfoIcon from 'icons/info.svg'

import __ from 'utils/i18n'
import path from 'utils/path'

import MarkAsViewed from './TableCell/MarkAsViewed'

const columns = [
    {
        name: () => __('Date'),
        maxWidth: 200,
        render: ({datetime}) => <DateTime datetime={datetime} timeFirst/>,
    },
    {
        name: () => __('Panel'),
        maxWidth: 180,
        render: ({panelId, serial, account, webname}) => <PanelCell {...{id: panelId, serial, account, webname}}/>,
    },
    {
        width: 50,
        fixed: true,
        render: ({hasVideo}) => hasVideo ? <CameraIcon/> : null,
    },
    {
        name: () => __('Severity'),
        maxWidth: 550,
        render: ({severity, description}) => <SeverityCell {...{severity, description}}/>,
    },
    {
        name: () => __('Appointment'),
        render: ({zone, appointment, partitions}) => <EventAppointmentCell {...{zone, appointment, partitions}} />,
    },
    {
        width: 50,
        fixed: true,
        render: ({binMessage}) => binMessage ? <Tooltip tooltip={binMessage}><InfoIcon/></Tooltip> : null,
    },
    {
        tooltip: false,
        permission: markAsViewed,
        fixed: true,
        width: 50,
        component: MarkAsViewed,
    },
]

export default compose(
    withLifeCycle({
        onMount({startPoll}) {
            startPoll()
        },

        onUnmount({stopPoll}) {
            stopPoll()
        },
    }),
    withLoader(),
    withProps(({history, scope}) => ({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No events found'),
        onRowClick({id, serial}) {
            history.push(path('events', {
                scope,
                id,
                serial,
            }))
        },
    })),
    withTableColumnsPermission(),
)(Table)
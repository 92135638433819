import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import PropTypes from 'prop-types'
import {compose} from 'redux'


import {content} from 'permissions/panel/page'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import Menu, {MenuLink} from 'ui/Menu'
import NavTabs, {NavTabLink, NavTabPopup} from 'ui/NavTabs'

import path from 'utils/path'
import {__} from 'utils/i18n'


class PanelPageTabs extends Component {
    static propTypes = {
        menu: PropTypes.object,
    }

    static defaultProps = {
        menu: {},
    }

    state = {
        items: Infinity,
    }

    calculate = () => {
        const {children} = this.node
        this.widths = Array.from(children, child => child.getBoundingClientRect().width)
    }

    resize = () => {
        let {width} = this.node.getBoundingClientRect()

        // This is "other" menu item size.
        // Let's assume it's 140 px :)
        width -= 140

        let items = this.widths.filter(w => {
            width -= w
            return width >= 0
        }).length

        if (items === this.widths.length - 1) {
            // don't hide one item, cuz it is not so smart
            items += 1
        }

        this.setState({items})
    }

    handleRef = (node) => this.node = node

    componentDidMount() {
        this.calculate()
        this.resize()

        window.addEventListener('resize', this.resize)
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.resize)
    }

    render() {
        const {match: {params}, menu} = this.props
        const {items} = this.state

        const directLinks = Object.keys(menu).slice(0, items)
        const popupLinks = Object.keys(menu).slice(items)

        return (
            <NavTabs onRef={this.handleRef}>
                {directLinks.map(key => {
                    const {label, path: pathName, exact} = menu[key]

                    return (
                        <NavTabLink className="sss" key={key} to={path(pathName, params)} exact={exact}>
                            {label()}
                        </NavTabLink>
                    )
                })}

                {popupLinks.length > 0 &&
                <NavTabPopup label={__('Other')}>
                    <Menu>
                        {popupLinks.map(key => {
                            const {label, path: pathName} = menu[key]

                            return (
                                <MenuLink key={key} to={path(pathName, params)}>
                                    {label()}
                                </MenuLink>
                            )
                        } )}
                    </Menu>
                </NavTabPopup>}
            </NavTabs>
        )
    }
}

export default compose(
    withRouter,
    withPermission({
        isVisible: content,
    }),
    withVisibility(),
)(PanelPageTabs)
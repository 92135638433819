import React, {Component} from 'react'
import PropTypes from 'prop-types'

import DropDownButton from 'ui/DropDownButton'
import {MenuItem} from 'ui/Menu'
import Menu from 'ui/Menu'

import {__} from 'utils/i18n'

export default class SelectionDropDown extends Component {

    static propTypes = {
        rows: PropTypes.arrayOf(PropTypes.object).isRequired,
        selection: PropTypes.shape({
            has: PropTypes.func.isRequired,
            hasAny: PropTypes.func.isRequired,
            isEmpty: PropTypes.func.isRequired,
        }).isRequired,
        select: PropTypes.func,
        deselect: PropTypes.func,
        deselectAll: PropTypes.func,
    }

    toggleSelection = () => {
        if (this.isAnyRowSelected()) {
            this.deselectAll()
        } else {
            this.selectAll()
        }
    }

    selectAll = () => {
        this.props.select && this.props.select(this.props.rows)
    }

    deselectAll = () => {
        this.props.deselect && this.props.deselect(this.props.rows)
    }

    clearSelection = () => {
        this.props.deselectAll && this.props.deselectAll()
    }

    isAllRowsSelected() {
        const {rows, selection} = this.props
        return selection && rows && rows.length && selection.has(rows)
    }

    isAnyRowSelected() {
        const {rows, selection} = this.props
        return selection && rows && selection.hasAny(rows)
    }

    render() {
        const {selection, ...props} = this.props

        delete props.select
        delete props.close
        delete props.deselectAll
        delete props.onUnmount
        delete props.rows

        return (
            <DropDownButton
                {...props}
                checkbox
                checked={!!this.isAllRowsSelected()}
                indeterminate={!selection.isEmpty()}
                shortcut="a"
                onChange={this.toggleSelection}>
                <Menu>
                    <MenuItem onClick={this.selectAll} disabled={false && this.isAllRowsSelected()}>
                        {__('All')}
                    </MenuItem>
                    <MenuItem onClick={this.deselectAll}>
                        {__('None')}
                    </MenuItem>
                    <MenuItem onClick={this.clearSelection} disabled={false && selection.isEmpty()}>
                        {__('Clear')}
                    </MenuItem>
                </Menu>
            </DropDownButton>
        )
    }

}
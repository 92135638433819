import React from 'react'
import {compose} from 'redux'

import list from 'permissions/installers/list'
import selection from 'permissions/installers/selection'

import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'
import withSelection from 'containers/withSelection'
import withInstallers from 'containers/withInstallers'
import {withPermission, withRejection} from 'containers/withPermission'
import DateTime from 'ui/DateTime'
import Definition from 'ui/Definition'

import InstallerStatusChanger from './TableCell/InstallerStatusChanger'

import Table from 'ui/Table'

import {__} from 'utils/i18n'

const columns = [
    {
        name: () => __('Installer'),
        render: ({name, email}) => <Definition title={name} detail={email}/>,
    },
    {
        name: () => __('Telephone'),
        render: ({telephone}) => telephone,
    },
    {
        name: () => __('Created'),
        render: ({createdAt}) => <DateTime datetime={createdAt}/>,
    },
    {
        name: () => __('Status'),
        tooltip: false,
        component: InstallerStatusChanger,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withInstallers(),
    withSelection(),
    withLoader(({init}) => init()),
    withProps(() => ({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No installers found'),
    })),
)(Table)
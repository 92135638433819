import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {compose} from 'redux'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import {download} from 'permissions/panel/configuration/actions'

import configurationFindNode from 'utils/configuration/configurationFindNode'
import ConfigurationRows from 'components/Configuration/ConfigurationRows'

import Error from 'ui/Error'
import Button from 'ui/Button'

import humanTime from 'utils/humanTime'
import {__} from 'utils/i18n'

const DownloadButton = compose(
    withPermission({isVisible: download}),
    withVisibility(),
)(Button)

export default class DeviceConfiguration extends Component {

    static propTypes = {
        configuration: PropTypes.object.isRequired,
        onChange: PropTypes.func.isRequired,
        sectionName: PropTypes.string,
    }

    render() {
        const {sectionName, changes, configuration, onChange, refresh} = this.props
        const node = configuration && configurationFindNode(configuration, sectionName)

        if (!node) {
            return <Error title={__('No configuration available for the given device type')}/>
        }

        return (
            <div className="blocks">
                <div className="block">
                    <div className="configuration-outdateNotice">
                        <h6 className="title title--medium">{__('This information can be outdated!')}</h6>

                        <div className="configuration-outdateNotice--content">
                            {__('Configuration was downloaded %s', humanTime(configuration.timestamp))}
                        </div>

                        <DownloadButton onClick={refresh} flat>{__('Refresh')}</DownloadButton>
                    </div>
                </div>

                <div className="hr"/>

                <ConfigurationRows
                    nodes={node.val}
                    changes={changes}
                    onChange={onChange}
                />
            </div>
        )
    }
}

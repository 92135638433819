import {createAction} from 'redux-actions'

export const send = createAction('FORM/SEND',
    (name, meta) => name,
    (name, meta) => meta,
)

export const receive = createAction('FORM/RECEIVE',
    (name, promise) => promise
        .then((result) => ({name, success: true, result}))
        .catch(error => {
            window.err = error
            console.log(error.errors)
            const {message, errors} = error
            return {name, error: message, errors}
        }),
    (name, promise, meta) => meta,
)
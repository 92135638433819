import {compose} from 'redux'

import list from 'permissions/groups/list'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withGroups from 'containers/withGroups'

import PagerComponent from 'ui/Pager'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withGroups(),
)(PagerComponent)
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import withDevices from 'containers/withDevices'

import Bar, {BarSpace} from 'ui/Bar'
import Buttons from 'ui/Buttons'

import WalktestStatus from './WalktestStatus'
import StopWalktestButton from './Buttons/StopWalktestButton'
import StartWalktestButton from './Buttons/StartWalktestButton'
import ToggleWalktestButton from './Buttons/ToggleWalktestButton'

class WalktestDevicesBar extends Component {
    static propTypes = {
        walktest: PropTypes.shape({
            isRunning: PropTypes.bool,
        }),
    }

    render() {
        const {walktest: {isRunning}} = this.props

        return (
            <Bar>
                <WalktestStatus/>

                <BarSpace/>

                <Buttons>
                    {isRunning
                        ? <StopWalktestButton/>
                        : <StartWalktestButton/>
                    }

                    <ToggleWalktestButton/>
                </Buttons>
            </Bar>
        )
    }
}

export default withDevices()(WalktestDevicesBar)
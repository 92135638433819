import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Button from 'ui/Button'

const SNACK_TIMEOUT = 5000

class SnackBar extends Component {

    static propTypes = {
        snack: PropTypes.shape({
            id: PropTypes.any.isRequired,
            text: PropTypes.node.isRequired,
            actionLabel: PropTypes.string,
            action: PropTypes.object,
        }).isRequired,

        dispatch: PropTypes.func.isRequired,

        hide: PropTypes.func.isRequired,
    }

    componentDidMount() {
        this.iId = setTimeout(this.handleDismiss, SNACK_TIMEOUT)
    }

    componentWillUnmount() {
        if (this.iId) {
            clearTimeout(this.iId)
            this.iId = null
        }
    }

    handleDismiss = () => {
        const {snack, hide} = this.props
        hide(snack.id)
    }

    handleAction = () => {
        const {snack, dispatch} = this.props
        snack.action && dispatch(snack.action)
        this.handleDismiss()
    }

    render() {
        const {text, actionLabel} = this.props.snack

        return (
            <div className="snackBar">
                <div className="snackBar-text">
                    {text}
                </div>

                {actionLabel && (
                    <Button
                        borderless
                        onClick={this.handleAction}
                        label={actionLabel}
                    />
                )}
            </div>
        )
    }
}

const Snacks = ({snacks, hide, dispatch}) => {
    if (!snacks || !snacks.length) {
        return null
    }

    // show first snack
    const snack = snacks[0]

    return (
        <SnackBar
            key={snack.id}
            snack={snack}
            dispatch={dispatch}
            hide={hide}
        />
    )
}

export default Snacks
import PropTypes from 'prop-types'
import React, {Component} from 'react'

import CardForm from 'ui/CardForm'

import decorate from 'utils/decorate'
import {__} from 'utils/i18n'

export default class extends Component {
    static propTypes = {
        users: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number,
                isRemovable: PropTypes.bool,
            }),
        ),
        onClose: PropTypes.func,
        header: PropTypes.string,
    }

    render() {
        const {users, onClose, header} = this.props

        return (
            <CardForm showDismiss={true}
                      header={header}
                      onClose={onClose}
                      onSubmit={() => ({})}
                      editable={false}>
                {decorate(
                    __('Users %s has related users or roles and cannot be removed. Remove related users and roles before.'),
                    users.map(({name}) => name).join(', '),
                )}
            </CardForm>
        )
    }
}
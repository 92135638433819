import {selectBatchProcesses} from 'modules/processes/manager/selectors'
import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {withRouter} from 'react-router'
import {bindActionCreators, compose} from 'redux'

import {stop} from 'modules/processes/store/actions'
import processType, {getProcessLinkByType} from 'constants/processType'
import path from 'utils/path'
import BatchProcessesTable from './BatchProcessesTable'
import Button from 'ui/Button'
import Card, {CardActions, CardClose, CardHeader} from 'ui/Card'

import {__} from 'utils/i18n'

class BatchInfo extends Component {

    static propTypes = {
        batch: PropTypes.shape({
            type: PropTypes.string.isRequired,
        }).isRequired,

        processes: PropTypes.arrayOf(
            PropTypes.shape({
                id: PropTypes.number.isRequired,
                panelId: PropTypes.number.isRequired,
            }),
        ),

        stop: PropTypes.func.isRequired,
    }

    handleClick = ({panelId}) => {
        const {history, batch, onClose} = this.props
        const link = getProcessLinkByType(batch.type)

        if (!link) {
            return
        }

        history.push(path(link, {id: panelId}))
        onClose()
    }

    render() {
        const {onClose, batch, processes, stop} = this.props
        const {type} = batch

        return (
            <Card className="batchInfo card--wide-2">
                <CardClose onClick={onClose}/>

                <CardHeader>
                    {processType(type)}
                </CardHeader>

                <BatchProcessesTable
                    fullHeight
                    className="batchInfo-table"
                    onRowClick={this.handleClick}
                    onStop={stop}
                    rows={processes}
                />

                <CardActions>
                    <Button primary onClick={onClose} shortcut="enter">
                        {__('OK')}
                    </Button>
                </CardActions>
            </Card>
        )
    }
}


export default compose(
    withRouter,
    connect(
        (state, {batchId}) => selectBatchProcesses(state, batchId),
        dispatch => bindActionCreators({
            stop,
        }, dispatch),
    ),
)(BatchInfo)
import Pager from 'ui/Pager'
import {compose} from 'redux'

import list from 'permissions/users/selection'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withUsers from 'containers/withUsers'

export default compose(
    withPermission({isVisible: list}),
    withVisibility(),
    withUsers(),
)(Pager)
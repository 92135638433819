import React, {Component} from 'react'
import PropTypes from 'prop-types'
import {connect} from 'react-redux'
import {compose, bindActionCreators} from 'redux'

import {showAddPanelModal} from 'modules/modals/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'

import {add} from 'permissions/panels/bar'

import Button from 'ui/Button'
import IconPlus from 'icons/plus.svg'

import {__} from 'utils/i18n'

class AddPanelButton extends Component {
    static propTypes = {
        showAddPanelModal: PropTypes.func.isRequired,
    }

    render() {
        const {showAddPanelModal} = this.props

        return (
            <Button onClick={showAddPanelModal} Icon={IconPlus}>
                {__('Add Panel')}
            </Button>
        )
    }
}

export default compose(
    withPermission({isVisible: add}),
    withVisibility(),
    connect(
        null,
        dispatch => bindActionCreators({
            showAddPanelModal,
        }, dispatch),
    ),
)(AddPanelButton)
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'

import {showSuspendFaultsModal} from 'modules/modals/actions'
import {withRouterPanelId} from 'containers/withPanel'
import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withProps from 'containers/withProps'

import {MenuItem} from 'ui/Menu'

import {__} from 'utils/i18n'
import {suspendFaults} from 'permissions/panels/selection'

export default compose(
    withProps(() => ({
        children: __('Suspend Faults'),
    })),
    withPermission({isVisible: suspendFaults}),
    withVisibility(),
    withRouterPanelId(),
    connect(
        null,
        (dispatch, {panelId}) => bindActionCreators({
            onClick: () => showSuspendFaultsModal([panelId]),
        }, dispatch),
    ),
)(MenuItem)

import React, {PureComponent} from 'react'
import {compose, bindActionCreators} from 'redux'
import {connect} from 'react-redux'
import PropTypes from 'prop-types'

import CardForm from 'ui/CardForm'
import CentralStationFormFields, {rules} from './CentralStationFormFields'
import AddCentralStationLimitExceeded from './AddCentralStationLimitExceeded'

import withInterruption from 'containers/withInterruption'
import withForm from 'containers/withForm'
import {addCentralStation} from 'modules/forms/handlers'
import {__} from 'utils/i18n'

import {fetchCanAdd} from 'modules/centralStations/licensing/actions'
import {fetch as fetchSerialPorts} from 'modules/centralStations/serialPorts/actions'
import {fetch as fetchProtocols} from 'modules/centralStations/protocols/actions'

import withLoader from 'containers/withLoader'

class AddCentralStation extends PureComponent {
    static propTypes = {
        onClose: PropTypes.any,
        handle: PropTypes.any,
        serials: PropTypes.any,
        protocols: PropTypes.any,
        isLoading: PropTypes.bool,
        serialPorts: PropTypes.array,
    }

    defaultData = {
        isHeartBeat: true,
        heartBeat: 25,
        retryTime: 10,
        retryCount: 4,
        connectionType: 'tcp',
        ssl: 'none',
        receiver: 33,
        line: 2,
    }

    handle = (data) => {
        const {handle} = this.props

        if (!data.isHeartBeat) {
            data.heartBeat = 0
        }

        handle(data)
    }

    render() {
        const {onClose, isLoading, serialPorts, protocols, ...props} = this.props
        return (
            <CardForm
                confirmOnDismiss
                rules={rules}
                header={__('Create Central Station')}
                onClose={onClose}
                isLoading={isLoading}
                onSubmit={this.handle}
                {...props}
            >
                <CentralStationFormFields
                    isNew
                    data={this.defaultData}
                    serialPorts={serialPorts}
                    protocols={protocols}
                />
            </CardForm>
        )
    }
}

export default compose(
    connect(
        state => {
            const {serialPorts, protocols, licensing} = state.centralStations

            return {
                isLoading: protocols.isLoading || serialPorts.isLoading || licensing.isLoading,
                serialPorts: serialPorts.data,
                protocols: protocols.data,
                creatingAllowed: licensing.add,
                maxCentralStations: licensing.max,
            }
        },
        dispatch => bindActionCreators({
            fetchSerialPorts,
            fetchProtocols,
            fetchCanAdd,
        }, dispatch)
    ),
    withLoader(
        ({fetchSerialPorts, fetchProtocols, fetchCanAdd}) => {
            fetchSerialPorts()
            fetchProtocols()
            fetchCanAdd()
        },
    ),
    withInterruption(({creatingAllowed}) => !creatingAllowed, AddCentralStationLimitExceeded),
    withForm(addCentralStation)
)(AddCentralStation)
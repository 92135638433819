import {compose} from 'redux'

import walktest from 'permissions/panel/devices/walktest'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withDevices from 'containers/withDevices'
import withProps from 'containers/withProps'

import WalkIcon from 'icons/walk.svg'
import DiagnosticIcon from 'icons/build.svg'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: walktest}),
    withVisibility(),
    withDevices(),
    withProps(({showWalktest, toggleWalktest}) => ({
        onClick: toggleWalktest,
        Icon: showWalktest ? DiagnosticIcon : WalkIcon,
        label: showWalktest ? __('Diagnostics') : __('Walktest'),
    })),
)(Button)
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {Line} from 'react-chartjs-2'

import {__} from 'utils/i18n'
import momentPropType from 'utils/momentPropType'

export default class Chart extends Component {
    static propTypes = {
        range: PropTypes.shape({
            from: momentPropType,
            to: momentPropType,
        }).isRequired,

        data: PropTypes.shape({
            min: PropTypes.array,
            max: PropTypes.array,
            avg: PropTypes.array,
            exact: PropTypes.array,
        }).isRequired,
    }

    getDataset() {
        const {label, data: {min, max, avg, exact}} = this.props

        const styles = {
            min: {
                label: __('Minimum'),
                fill: '+2',
                pointRadius: 1,
                pointHitRadius: 2,
                data: min || [],
            },
            avg: {
                label: __('Average'),
                pointRadius: 3,
                pointHitRadius: 3,
                borderColor: '#2d72b9',
                pointBorderWidth: 1,
                pointBorderColor: 'white',
                pointBackgroundColor: '#2d72b9',
                fill: false,
                data: avg || [],
            },
            max: {
                label: __('Maximum'),
                pointRadius: 1,
                pointHitRadius: 2,
                fill: false,
                data: max || [],
            },
            exact: {
                label,
                pointRadius: 3,
                pointHitRadius: 3,
                borderColor: '#2d72b9',
                pointBorderWidth: 1,
                pointBorderColor: 'white',
                pointBackgroundColor: '#2d72b9',
                fill: false,
                data: exact || [],
            },
        }

        if (exact) {
            return {
                datasets: [styles.exact],
            }
        }

        return {
            datasets: [styles.min, styles.avg, styles.max],
        }
    }

    getOptions() {
        const {range: {from, to}, type} = this.props
        const tooltipFormat = 'llll [GMT]Z'
        const titleCallback = (item) => {
            const {xLabel} = Array.isArray(item) ? item[0] : item

            return xLabel.replace('GMT+00:00', 'GMT')
                .replace(/(.*)(GMT[+-]?)0?(\d+)(:[1-9]\d)?.*/, '$1$2$3$4')
        }

        const defs = {
            animation: false,
            legend: false,
        }
        const displayFormats = {
            hour: 'LT',
        }

        const options = {
            temperature: {
                ...defs,
                tooltips: {
                    callbacks: {
                        title: titleCallback,
                        label: (item) => {
                            const {yLabel} = Array.isArray(item) ? item[0] : item

                            return `${Number(yLabel).toFixed(1)} ℃`
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                suggestedMax: 40,
                                suggestedMin: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: 'time',
                            distribution: 'linear',
                            time: {
                                min: from,
                                max: to,
                                tooltipFormat,
                                displayFormats,
                            },
                        },
                    ],
                },
            },
            brightness: {
                ...defs,
                tooltips: {
                    callbacks: {
                        title: titleCallback,
                        label: (item) => {
                            const {yLabel} = Array.isArray(item) ? item[0] : item

                            return `${Number(yLabel).toFixed(1)} lux`
                        },
                    },
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                max: 1300,
                                min: 0,
                            },
                        },
                    ],
                    xAxes: [
                        {
                            type: 'time',
                            distribution: 'linear',
                            time: {
                                min: from,
                                max: to,
                                tooltipFormat,
                                displayFormats,
                            },
                        },
                    ],
                },
            },
        }

        return options[type]
    }

    render() {
        const data = this.getDataset()
        const options = this.getOptions()

        return <Line data={data} options={options}/>
    }
}
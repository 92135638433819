import {compose} from 'redux'

import {enroll} from 'permissions/panel/devices/actions'

import {withPermission} from 'containers/withPermission'
import {withVisibility} from 'containers/withVisibility'
import withDevices from 'containers/withDevices'
import withProps from 'containers/withProps'

import PlusIcon from 'icons/plus.svg'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

export default compose(
    withPermission({isVisible: enroll}),
    withVisibility(),
    withDevices(),
    withProps(({showAddDevice}) => ({
        Icon: PlusIcon,
        onClick: showAddDevice,
        label: __('Add device'),
    })),
)(Button)
// libraries
import React from 'react'
import {compose} from 'redux'

import {
    withPermission,
    withRejection,
} from 'containers/withPermission'
import withLifeCycle from 'containers/withLifeCycle'
import withTableColumnsPermission from 'containers/withTableColumnsPermission'
import withSelection from 'containers/withSelection'
import withSearchScopeLoader from 'containers/withSearchScopeLoader'
import withPanels from 'containers/withPanels'
import withProps from 'containers/withProps'

// application
import Table from 'ui/Table'
import Ava from 'ui/Ava'
import UnassignedAva from 'ui/UnassignedAva'
import PanelCell from 'components/TableCells/PanelCell'
import GroupCell from 'components/TableCells/GroupCell'
import humanTime from 'utils/humanTime'

import RRICell from './TableCell/RRICell'
import ApplicationCell from './TableCell/ApplicationCell'
import CommunicationCell from './TableCell/CommunicationCell'
import UnhandledEventsCell from './TableCell/UnhandledEventsCell'
import FaultsCell from './TableCell/FaultsCell'

// utils
import {__} from 'utils/i18n'

import list from 'permissions/panels/list'
import apps from 'permissions/panels/apps'
import selection from 'permissions/panels/selection'
import events from 'permissions/events/page'
import remoteInspections from 'permissions/remoteInspections/page'

const columns = [
    {
        name: () => __('Panel'),
        maxWidth: 180,
        render: ({id, serial, account, webname}) => (
            <PanelCell {...{id, serial, account, webname}} />
        ),
    },
    {
        name: () => __('Group'),
        maxWidth: 100,
        render: ({groupId: id, group: name}) => (
            <GroupCell {...{row: {id, name}}}/>
        ),
    },
    {
        tooltip: false,
        width: 60,
        fixed: true,
        render: ({modules, model}) => <CommunicationCell model={model} {...modules}/>,
    },
    {
        name: () => __('Model'),
        maxWidth: 150,
        render: ({model}) => model || <span className="empty">&mdash;</span>,
    },
    {
        name: () => __('Events'),
        tooltip: false,
        permission: events,
        maxWidth: 120,
        render: ({serial, alerts, alarms}) => (
            <UnhandledEventsCell {...{alerts, alarms, serial}} />
        ),
    },
    {
        width: 44,
        fixed: true,
        tooltip: ({user}) => user || __('Unassigned'),
        render: ({user, userId}) => {
            if (userId) {
                return <Ava small id={userId} name={user}/>
            }

            return <UnassignedAva small/>
        },
    },
    {
        name: () => __('Faults'),
        tooltip: false,
        render: ({faultsSince, faults}) => <FaultsCell {...{faultsSince, faults}} />,
    },
    {
        name: () => __('Apps'),
        tooltip: false,
        permission: apps,
        width: 70,
        fixed: true,
        render: ApplicationCell,
    },
    {
        name: () => __('RI'),
        permission: remoteInspections,
        width: 40,
        fixed: true,
        tooltip: ({rriDate}) => rriDate ? humanTime(rriDate) : __('RRI never initiated'),
        render: ({id, rri, rriDate}) => <RRICell {...{id, rri, rriDate}}/>,
    },
]

export default compose(
    withPermission({
        isAllowed: list,
        hasSelection: selection,
    }),
    withRejection(),
    withSelection(),
    withPanels(),
    withLifeCycle({
        onMount: ({startPoll}) => {
            startPoll()
        },
        onUnmount: ({stopPoll}) => {
            stopPoll()
        },
    }),
    withSearchScopeLoader(),
    withProps({
        fullHeight: true,
        columns,
        emptyMessage: () => __('No panels found'),
    }),
    withTableColumnsPermission(),
)(Table)
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import Definition from 'ui/Definition'
import csProfileType from 'constants/csProfileType'
import Button from 'ui/Button'

export default class CentralStationCard extends Component {
    static propTypes = {
        edit: PropTypes.func.isRequired,
        station: PropTypes.object.isRequired,
        links: PropTypes.array,
        onMouseLeave: PropTypes.func,
        onMouseEnter: PropTypes.func,
    }

    handleMouseLeave = () => {
        const {station, onMouseLeave} = this.props
        onMouseLeave && onMouseLeave(station)
    }

    handleMouseEnter = () => {
        const {station, onMouseEnter} = this.props
        onMouseEnter && onMouseEnter(station)
    }

    handleClick = () => {
        const {station, edit} = this.props
        edit && edit(station)
    }

    render() {
        const {links, station} = this.props
        const {name, protocol} = station

        const profiles = Object
            .values(this.props.profiles)
            .filter(({id}) => links && links.includes(id))

        return (
            <div className="card central-station"
                 onMouseLeave={this.handleMouseLeave}
                 onMouseEnter={this.handleMouseEnter}
            >
                <div className="central-station-header">
                    <Definition title={name} detail={protocol}/>

                    <Button small
                            borderless
                            primary
                            onClick={this.handleClick}>
                        {__('Edit')}
                    </Button>
                </div>

                <div className="central-station-links">
                    {profiles
                        .map(({id, name}) =>
                            <span key={id} className="central-station-link">{csProfileType(name)}</span>
                        )}

                    {profiles.length == 0 &&
                    <span className="empty">{__('Empty')}</span>
                    }
                </div>
            </div>
        )
    }
}
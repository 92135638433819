import React from 'react'
import PropTypes from 'prop-types'

import Error from 'ui/Error'
import Card, {CardActions, CardContent, CardClose} from 'ui/Card'
import Button from 'ui/Button'

import {__} from 'utils/i18n'

const CardModalError = ({onClose, title, message}) => (
    <Card className="card-modal-error">
        <CardClose onClick={onClose} />

        <CardContent>
            <Error title={title} message={message} />
        </CardContent>

        <CardActions>
            <Button primary onClick={onClose}>
                {__('Ok')}
            </Button>
        </CardActions>
    </Card>
)

CardModalError.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string.isRequired,
    onClose: PropTypes.func.isRequired,
}

export default CardModalError
import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import {__} from 'utils/i18n'
import CardForm from 'ui/CardForm'

import withForm from 'containers/withForm'
import {enablePlinkLogging} from 'modules/forms/handlers'

import PlinkLogging, {rules} from './Fieldset/PlinkLogging'

class EnablePlinkLogging extends PureComponent {
    static propTypes = {
        panelId: PropTypes.number,
        handle: PropTypes.func,
    }

    handle = (form) => {
        const {handle, panelId} = this.props

        handle(panelId, form)
    }

    render() {
        return (
            <CardForm
                onSubmit={this.handle}
                header={__('Enable Plink Logging')}
                rules={rules}
                submitLabel={__('Enable')}
                {...this.props}>
                <PlinkLogging />
            </CardForm>
        )
    }
}

export default withForm(enablePlinkLogging)(EnablePlinkLogging)
import FilterItemsMenu from 'components/Search/Autocomplete/FilterItemsMenu'
import FiltersMenu from 'components/Search/Autocomplete/FiltersMenu'
import React, {Component} from 'react'
import PropTypes from 'prop-types'

import CrossIcon from 'icons/cross.svg'
import Hotkey from 'ui/Hotkeys'
import SlideDown from 'ui/SlideDown'
import OuterClick from 'ui/OuterClick'

import FilterDefinition from './Filters/Filter'
import {__} from 'utils/i18n'

export default class SearchAutoComplete extends Component {

    static propTypes = {
        filters: PropTypes.arrayOf(PropTypes.instanceOf(FilterDefinition)),
        suggestCount: PropTypes.objectOf(PropTypes.number),
        suggests: PropTypes.object,
        onSuggest: PropTypes.func,
        onSearch: PropTypes.func,
        disabled: PropTypes.bool,
    }

    state = {
        value: '',
        filter: null,
        queryPos: null,
    }

    componentWillMount() {
        if (this.props.query) {
            this.setState({
                queryPos: this.props.selected.length,
            })
        }
    }

    moveLineToEnd() {
        this.setState({}, () => this.lineElement && (this.lineElement.scrollLeft += 100000))
    }

    componentWillReceiveProps({selected, query}) {
        if (selected && this.props.selected && this.props.selected.length < selected.length) {
            this.moveLineToEnd()
        }

        if (!query) {
            this.setState({queryPos: null})
            return
        }

        if (query !== this.props.query) {
            this.setState({queryPos: selected.length})
            return
        }

        if (this.props.selected !== selected) {
            const removed = this.props.selected
                .slice(0, this.queryPos)
                .filter(({$}) => selected.every(item => item.$ !== $))
                .length

            this.setState({queryPos: this.state.queryPos - removed})
        }
    }

    handleSelectItem = item => {
        this.props.onSelect(item)
        this.setState({filter: null, value: ''})
    }

    handleSelectFilter = filter => this.setState({filter, value: ''})

    handleSearch = () => this.onQuery(this.state.value)

    handleClearQuery = () => this.props.onQuery && this.props.onQuery(null)

    onQuery = (query = null) => {
        const {onQuery} = this.props

        if (onQuery) {
            onQuery(query)
            this.setState({value: ''})
        }
    }

    handleBackspace = e => {
        const {value, filter, queryPos} = this.state

        if (value !== '') {
            return
        }

        if (filter) {
            this.setState({filter: null})
            return
        }

        const {selected, filters, disabled, onDeselect} = this.props

        if (queryPos >= selected.length) {
            this.handleClearQuery()
            return
        }

        if (selected.length === 0) {
            return
        }

        const lastFilter = selected[selected.length - 1]

        onDeselect(lastFilter)

        if (!disabled) {
            this.setState({
                filter: filters.find(filter => filter.name === lastFilter.name),
            })
        }
    }

    handleFocus = () => {
        this.activate()
        this.setState({focus: true})
    }

    handleBlur = () => {
        this.setState({focus: false})
    }

    activate() {
        if (!this.props.disabled && !this.state.active) {
            this.setState({active: true})
        }
    }

    componentDidUpdate() {
        if (this.inputElement && this.lineElement && this.state.active) {
            const left = this.inputElement.offsetLeft - this.lineElement.scrollLeft

            if (left !== this.state.left) {
                //eslint-disable-next-line react/no-did-update-set-state
                this.setState({left})
            }
        }
    }

    handleDeselect = item => {
        this.props.onDeselect(item)

        if (this.state.active) {
            this.setState({}, () => this.inputElement && this.inputElement.focus())
        }
    }

    handleChange = e => {
        const {value} = e.target
        this.activate()
        this.setState({value})
    }

    handleOuterClick = () => {
        this.setState({active: false})
    }

    handleMenuHide = () => {
        this.setState(({active}) => {
            if (!active) {
                return {filter: null, value: ''}
            }

            return {}
        })
    }

    handleLineRef = lineElement => {
        this.lineElement = lineElement
        lineElement && this.moveLineToEnd()
    }

    handleInputRef = inputElement => this.inputElement = inputElement

    renderMenu() {
        const {filters, suggests, selected, onSuggest, onQuery} = this.props
        const {filter, value} = this.state

        if (!filter) {
            return (
                <FiltersMenu
                    onSelect={this.handleSelectFilter}
                    onSearch={onQuery ? this.handleSearch : null}
                    filters={filters}
                    prefix={value}
                />
            )
        }

        return (
            <FilterItemsMenu
                filter={filter}
                suggest={suggests && suggests[filter.name]}
                onSuggest={onSuggest}
                selected={selected}
                onSelect={this.handleSelectItem}
                prefix={value}
                onHide
            />
        )
    }

    renderSelectedFilters() {
        const {filters, selected, query} = this.props

        const items = selected.map((item, index) => {
            const filter = filters.find(({name}) => name === item.name)

            const name = filter ? filter.title : item.name
            const label = filter ? filter.getItemLabel(item.value) : item.value

            return (
                <div className="search-autoComplete-item" key={index} onClick={() => this.handleDeselect(item)}>
                    <span className="search-autoComplete-name">{name}</span>
                    <span className="search-autoComplete-value">{label || __('Empty')}</span>
                </div>
            )
        })

        if (query) {
            const searchItem = (
                <div key="search" className="search-autoComplete-item" onClick={this.handleClearQuery}>
                    <span className="search-autoComplete-name">{__('Search')}</span>
                    <span className="search-autoComplete-value">{query}</span>
                </div>
            )
            items.splice(this.state.queryPos, 0, searchItem)
        }

        return items
    }

    render() {
        const {query, selected, onClear, disabled} = this.props
        const {active, focus, filter, value} = this.state

        const clearable = query || (selected && selected.length > 0)

        return (
            <OuterClick className="search-autoComplete" onOuterClick={active ? this.handleOuterClick : null}>
                {clearable && <CrossIcon className="search-autoComplete-clear" onClick={onClear}/>}

                <div className="search-autoComplete-line" ref={this.handleLineRef}>
                    {this.renderSelectedFilters()}

                    {filter && <div className="search-autoComplete-name">{filter.title}</div>}

                    <label className="search-autoComplete-input">
                        <input
                            ref={this.handleInputRef}
                            className="search-autoComplete-input-element"
                            onFocus={this.handleFocus}
                            onBlur={this.handleBlur}
                            onChange={this.handleChange}
                            placeholder={__('Search')}
                            value={value}/>

                        {focus && <Hotkey shortcut="backspace" action={this.handleBackspace} scope="input"/>}
                        {focus && disabled && <Hotkey shortcut="enter" action={this.handleSearch} scope="input"/>}

                        <SlideDown
                            popdown
                            className="search-autoComplete-popup"
                            onHide={this.handleMenuHide}
                            style={{left: this.state.left + 'px'}}
                        >
                            {active && this.renderMenu()}
                        </SlideDown>
                    </label>
                </div>
            </OuterClick>
        )
    }
}
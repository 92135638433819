import {compose} from 'redux'

import withPanelRemoteInspection from 'containers/withPanelRemoteInspection'
import withProps from 'containers/withProps'
import withLoader from 'containers/withLoader'

import Button from 'ui/Button'

import {__} from 'utils/i18n'
import {withVisibility} from 'containers/withVisibility'

export default compose(
    withPanelRemoteInspection(),
    withProps(({fetchMore, hasMore}) => ({
        isVisible: hasMore,
        isLoading: hasMore && hasMore.isLoading,
        onClick: fetchMore,
        label: __('Load More'),
        className: 'btnHolder',
    })),
    withVisibility(),
    withLoader(),
)(Button)
import {__} from 'utils/i18n'
import {warn} from 'utils/log'

export const ZONE_TYPE_PUSH_BUTTON = 'PUSH_BUTTON'
export const ZONE_TYPE_KEYFOB = 'KEYFOB'
export const ZONE_TYPE_SMOKE = 'SMOKE'
export const ZONE_TYPE_GAS = 'GAS'
export const ZONE_TYPE_FLOOD = 'FLOOD'
export const ZONE_TYPE_SMOKE_OR_HEAT = 'SMOKE_OR_HEAT'
export const ZONE_TYPE_FIRE = 'FIRE'
export const ZONE_TYPE_EMERGENCY = 'EMERGENCY'
export const ZONE_TYPE_DELAY_1 = 'DELAY_1'
export const ZONE_TYPE_DELAY_2 = 'DELAY_2'
export const ZONE_TYPE_HOME_DELAY = 'HOME_DELAY'
export const ZONE_TYPE_GUARD_KEYBOX = 'GUARD_KEYBOX'
export const ZONE_TYPE_INTERIOR = 'INTERIOR'
export const ZONE_TYPE_INTERIOR_FOLLOW = 'INTERIOR_FOLLOW'
export const ZONE_TYPE_PERIMETER = 'PERIMETER'
export const ZONE_TYPE_PERIMETER_FOLLOW = 'PERIMETER_FOLLOW'
export const ZONE_TYPE_H24_SILENT = '24H_SILENT'
export const ZONE_TYPE_H24_AUDIBLE = '24H_AUDIBLE'
export const ZONE_TYPE_OUTDOOR = 'OUTDOOR'
export const ZONE_TYPE_CO = 'CO'
export const ZONE_TYPE_NO_ZONE_TYPE = 'NO_ZONE_TYPE'
export const ZONE_TYPE_TEMPERATURE = 'TEMPERATURE'

export const ZONE_TYPE_NULL_PGM = 'NULL_PGM'
export const ZONE_TYPE_BURGLARY_AND_FIRE_BELL_FOLLOWER = 'BURGLARY_AND_FIRE_BELL_FOLLOWER'
export const ZONE_TYPE_DELAYED_FIRE_AND_BURGLARY = 'DELAYED_FIRE_AND_BURGLARY'
export const ZONE_TYPE_SENSOR_RESET = 'SENSOR_RESET'
export const ZONE_TYPE_2_WIRE_SMOKE = '2_WIRE_SMOKE'
export const ZONE_TYPE_COURTESY_PULSE = 'COURTESY_PULSE'
export const ZONE_TYPE_KEYPAD_BUZZER_FOLLOW = 'KEYPAD_BUZZER_FOLLOW'
export const ZONE_TYPE_READY_TO_ARM = 'READY_TO_ARM'
export const ZONE_TYPE_SYSTEM_ARMED_STATUS = 'SYSTEM_ARMED_STATUS'
export const ZONE_TYPE_AWAY_ARMED_STATUS = 'AWAY_ARMED_STATUS'
export const ZONE_TYPE_STAY_ARMED_STATUS = 'STAY_ARMED_STATUS'
export const ZONE_TYPE_AWAY_ARMED_WITH_NO_ZONE_BYPASSES_STATUS = 'AWAY_ARMED_WITH_NO_ZONE_BYPASSES_STATUS'
export const ZONE_TYPE_COMMAND_OUTPUT_1 = 'COMMAND_OUTPUT_1'
export const ZONE_TYPE_COMMAND_OUTPUT_2 = 'COMMAND_OUTPUT_2'
export const ZONE_TYPE_COMMAND_OUTPUT_3 = 'COMMAND_OUTPUT_3'
export const ZONE_TYPE_COMMAND_OUTPUT_4 = 'COMMAND_OUTPUT_4'
export const ZONE_TYPE_PARTITION_STATUS_ALARM_MEMORY = 'PARTITION_STATUS_ALARM_MEMORY'
export const ZONE_TYPE_HOLDUP_OUTPUT = 'HOLDUP_OUTPUT'
export const ZONE_TYPE_24_HOUR_SILENT_INPUT = '24_HOUR_SILENT_INPUT'
export const ZONE_TYPE_24_HOUR_AUDIBLE_INPUT = '24_HOUR_AUDIBLE_INPUT'
export const ZONE_TYPE_TLM_AND_ALARM = 'TLM_AND_ALARM'
export const ZONE_TYPE_KISSOFF = 'KISSOFF'
export const ZONE_TYPE_GROUND_START = 'GROUND_START'
export const ZONE_TYPE_ALTERNATE_COMMUNICATOR = 'ALTERNATE_COMMUNICATOR'
export const ZONE_TYPE_SYSTEM_TROUBLE = 'SYSTEM_TROUBLE'
export const ZONE_TYPE_LATCHED_SYSTEM_EVENT_STROBE = 'LATCHED_SYSTEM_EVENT_(STROBE)'
export const ZONE_TYPE_SYSTEM_TAMPER = 'SYSTEM_TAMPER'
export const ZONE_TYPE_DC_TROUBLE = 'DC_TROUBLE'
export const ZONE_TYPE_PROXIMITY_TAG_USED = 'PROXIMITY_TAG_USED'
export const ZONE_TYPE_PARTITION_PROX_USED = 'PARTITION_PROX_USED'
export const ZONE_TYPE_BELL_STATUS_AND_PROGRAMMING_ACCESS_OUTPUT = 'BELL_STATUS_AND_PROGRAMMING_ACCESS_OUTPUT'
export const ZONE_TYPE_REMOTE_OPERATION = 'REMOTE_OPERATION'
export const ZONE_TYPE_OPEN_AFTER_ALARM = 'OPEN_AFTER_ALARM'
export const ZONE_TYPE_ZONE_FOLLOWER_PGM_BY_ZONE = 'ZONE_FOLLOWER_PGM_BY_ZONE'
export const ZONE_TYPE_ZONE_FOLLOWER_1_8 = 'ZONE_FOLLOWER_1-8'
export const ZONE_TYPE_ZONE_FOLLOWER_9_16 = 'ZONE_FOLLOWER_9-16'
export const ZONE_TYPE_ZONE_FOLLOWER_17_24 = 'ZONE_FOLLOWER_17-24'
export const ZONE_TYPE_ZONE_FOLLOWER_25_32 = 'ZONE_FOLLOWER_24-32'
export const ZONE_TYPE_ZONE_FOLLOWER_33_40 = 'ZONE_FOLLOWER_33-40'
export const ZONE_TYPE_ZONE_FOLLOWER_41_48 = 'ZONE_FOLLOWER_41-48'
export const ZONE_TYPE_ZONE_FOLLOWER_49_56 = 'ZONE_FOLLOWER_49-56'
export const ZONE_TYPE_ZONE_FOLLOWER_57_64 = 'ZONE_FOLLOWER_57-64'
export const ZONE_TYPE_ZONE_FOLLOWER_65_72 = 'ZONE_FOLLOWER_65-72'
export const ZONE_TYPE_ZONE_FOLLOWER_73_80 = 'ZONE_FOLLOWER_73-80'
export const ZONE_TYPE_ZONE_FOLLOWER_81_88 = 'ZONE_FOLLOWER_81-88'
export const ZONE_TYPE_ZONE_FOLLOWER_89_96 = 'ZONE_FOLLOWER_89-96'
export const ZONE_TYPE_ZONE_FOLLOWER_97_104 = 'ZONE_FOLLOWER_97-104'
export const ZONE_TYPE_ZONE_FOLLOWER_105_112 = 'ZONE_FOLLOWER_105-112'
export const ZONE_TYPE_ZONE_FOLLOWER_113_120 = 'ZONE_FOLLOWER_113-120'
export const ZONE_TYPE_ZONE_FOLLOWER_121_128 = 'ZONE_FOLLOWER_121-128'
export const ZONE_TYPE_EXTERNAL_SIREN = 'EXTERNAL_SIREN'
export const ZONE_TYPE_DELAY_STAY_AWAY = 'DELAY_STAY_AWAY'
export const ZONE_TYPE_INTERIOR_STAY_AWAY = 'INTERIOR_STAY_AWAY'
export const ZONE_TYPE_NON_ALARM = 'NON_ALARM'
export const ZONE_TYPE_DOORBELL_ZONE = 'DOORBELL_ZONE'
export const ZONE_TYPE_PIR = 'PIR'
export const ZONE_TYPE_MAGNET = 'MAGNET'
export const ZONE_TYPE_TOWER_32 = 'TOWER_32'
export const ZONE_TYPE_TOWER_20 = 'TOWER_20'
export const ZONE_TYPE_NOT_SECURITY = 'NOT_SECURITY'
export const ZONE_TYPE_24H_SILENT = '24H_SILENT'
export const ZONE_TYPE_24H_AUDIBLE = '24H_AUDIBLE'
export const ZONE_TYPE_ARMING_KEY = 'ARMING_KEY'
export const ZONE_TYPE_INTERIOR_DELAY = 'INTERIOR_DELAY'
export const ZONE_TYPE_TAMPER = 'TAMPER'
export const ZONE_TYPE_LINE_FAIL = 'LINE_FAIL'
export const ZONE_TYPE_PSU_FAIL = 'PSU_FAIL'
export const ZONE_TYPE_PANIC = 'PANIC'
export const ZONE_TYPE_SHOCK_CONTACT_G2 = 'SHOCK_CONTACT_G2'
export const ZONE_TYPE_TYPE_55 = 'TYPE_55'
export const ZONE_TYPE_TYPE_56 = 'TYPE_56'
export const ZONE_TYPE_TYPE_57 = 'TYPE_57'
export const ZONE_TYPE_TYPE_58 = 'TYPE_58'
export const ZONE_TYPE_TYPE_59 = 'TYPE_59'
export const ZONE_TYPE_TYPE_60 = 'TYPE_60'
export const ZONE_TYPE_TYPE_61 = 'TYPE_61'
export const ZONE_TYPE_TYPE_62 = 'TYPE_62'
export const ZONE_TYPE_TYPE_63 = 'TYPE_63'
export const ZONE_TYPE_TYPE_64 = 'TYPE_64'
export const ZONE_TYPE_WL_COMMANDER = 'WL_COMMANDER'
export const ZONE_TYPE_NIGHT = 'NIGHT'
export const ZONE_TYPE_FINAL_DOOR_SET = 'FINAL_DOOR_SET'
export const ZONE_TYPE_24HR_SPRINKLER = '24HR_SPRINKLER'
export const ZONE_TYPE_24HR_NON_LATCHING = '24HR_NON_LATCHING'
export const ZONE_TYPE_INSTANT = 'INSTANT'
export const ZONE_TYPE_DELAYED_FIRE = 'DELAYED_FIRE'
export const ZONE_TYPE_STANDARD_FIRE = 'STANDARD_FIRE'
export const ZONE_TYPE_INSTANT_STAY_AWAY = 'INSTANT_STAY_AWAY'
export const ZONE_TYPE_DAY_ZONE = 'DAY_ZONE'
export const ZONE_TYPE_24HR_BURGLARY = '24HR_BURGLARY'
export const ZONE_TYPE_24HR_BELL_BUZZER = '24HR_BELL_BUZZER'
export const ZONE_TYPE_24HR_SUPERVISORY = '24HR_SUPERVISORY'
export const ZONE_TYPE_24HR_SUPERVISORY_BUZZER = '24HR_SUPERVISORY_BUZZER'
export const ZONE_TYPE_AUTO_VERIFY_FIRE = 'AUTO_VERIFY_FIRE'
export const ZONE_TYPE_FIRE_SUPERVISORY = 'FIRE_SUPERVISORY'
export const ZONE_TYPE_24HR_GAS = '24HR_GAS'
export const ZONE_TYPE_24HR_CO = '24HR_CO'
export const ZONE_TYPE_24HR_HOLD_UP = '24HR_HOLD_UP'
export const ZONE_TYPE_24HR_PANIC = '24HR_PANIC'
export const ZONE_TYPE_24HR_HEAT = '24HR_HEAT'
export const ZONE_TYPE_24HR_MEDICAL = '24HR_MEDICAL'
export const ZONE_TYPE_24HR_EMERGENCY = '24HR_EMERGENCY'
export const ZONE_TYPE_24HR_FLOOD = '24HR_FLOOD'
export const ZONE_TYPE_24HR_LATCHING_TAMPER = '24HR_LATCHING_TAMPER'
export const ZONE_TYPE_24HR_NON_ALARM = '24HR_NON_ALARM'
export const ZONE_TYPE_24HR_HIGH_TEMPERATURE = '24HR_HIGH_TEMPERATURE'
export const ZONE_TYPE_24HR_LOW_TEMPERATURE = '24HR_LOW_TEMPERATURE'
export const ZONE_TYPE_24HR_MASKING = '24HR_MASKING'
export const ZONE_TYPE_24HR_CASH_MACHINE_ATM = '24HR_CASH_MACHINE_ATM'
export const ZONE_TYPE_MOMENTARY_ARM = 'MOMENTARY_ARM'
export const ZONE_TYPE_MAINTAINED_ARM = 'MAINTAINED_ARM'
export const ZONE_TYPE_MOMENTARY_DISARM = 'MOMENTARY_DISARM'
export const ZONE_TYPE_MAINTAINED_DISARM = 'MAINTAINED_DISARM'
export const ZONE_TYPE_PUSH_TO_SET = 'PUSH_TO_SET'
export const ZONE_TYPE_DOOR_CONTROL_POST = 'DOOR_CONTROL_POST'
export const ZONE_TYPE_DOOR_CONTROL_ARM = 'DOOR_CONTROL_ARM'
export const ZONE_TYPE_DOOR_CONTROL_REX = 'DOOR_CONTROL_REX'
export const ZONE_TYPE_EXTERNAL_AC_TROUBLE = 'EXTERNAL_AC_TROUBLE'
export const ZONE_TYPE_EXTERNAL_DC_TROUBLE = 'EXTERNAL_DC_TROUBLE'
export const ZONE_TYPE_EXTERNAL_AUX_TROUBLE = 'EXTERNAL_AUX_TROUBLE'
export const ZONE_TYPE_EXTERNAL_TROUBLE = 'EXTERNAL_TROUBLE'



export default function zoneType(zoneType) {
    switch (zoneType) {
        case ZONE_TYPE_PUSH_BUTTON: return __('Push button')
        case ZONE_TYPE_KEYFOB: return __('Keyfob')
        case ZONE_TYPE_SMOKE: return __('Smoke')
        case ZONE_TYPE_GAS: return __('Gas')
        case ZONE_TYPE_FLOOD: return __('Flood')
        case ZONE_TYPE_SMOKE_OR_HEAT: return __('Smoke or heat')
        case ZONE_TYPE_FIRE: return __('Fire')
        case ZONE_TYPE_EMERGENCY: return __('Emergency')
        case ZONE_TYPE_DELAY_1: return __('Delay 1')
        case ZONE_TYPE_DELAY_2: return __('Delay 2')
        case ZONE_TYPE_HOME_DELAY: return __('Home delay')
        case ZONE_TYPE_GUARD_KEYBOX: return __('Guard keybox')
        case ZONE_TYPE_INTERIOR: return __('Interior')
        case ZONE_TYPE_INTERIOR_FOLLOW: return __('Interior follow')
        case ZONE_TYPE_PERIMETER: return __('Perimeter')
        case ZONE_TYPE_PERIMETER_FOLLOW: return __('Perimeter follow')
        case ZONE_TYPE_H24_SILENT: return __('24h silent')
        case ZONE_TYPE_H24_AUDIBLE: return __('24h audible')
        case ZONE_TYPE_OUTDOOR: return __('Outdoor')
        case ZONE_TYPE_CO: return __('Co')
        case ZONE_TYPE_NO_ZONE_TYPE: return __('No zone type')
        case ZONE_TYPE_TEMPERATURE: return __('Temperature')
        case ZONE_TYPE_NULL_PGM: return __('Null PGM')
        case ZONE_TYPE_BURGLARY_AND_FIRE_BELL_FOLLOWER: return __('Burglary and fire bell follower')
        case ZONE_TYPE_DELAYED_FIRE_AND_BURGLARY: return __('Delayed burglary and fire')
        case ZONE_TYPE_SENSOR_RESET: return __('Sensor reset')
        case ZONE_TYPE_2_WIRE_SMOKE: return __('2 wire smoke')
        case ZONE_TYPE_COURTESY_PULSE: return __('Courtesy pulse')
        case ZONE_TYPE_KEYPAD_BUZZER_FOLLOW: return __('Keypad buzzer follow')
        case ZONE_TYPE_READY_TO_ARM: return __('Ready to arm')
        case ZONE_TYPE_SYSTEM_ARMED_STATUS: return __('System armed status')
        case ZONE_TYPE_AWAY_ARMED_STATUS: return __('Away armed status')
        case ZONE_TYPE_STAY_ARMED_STATUS: return __('Stay armed status')
        case ZONE_TYPE_AWAY_ARMED_WITH_NO_ZONE_BYPASSES_STATUS: return __('Away armed with no bypass')
        case ZONE_TYPE_COMMAND_OUTPUT_1: return __('Command output 1')
        case ZONE_TYPE_COMMAND_OUTPUT_2: return __('Command output 2')
        case ZONE_TYPE_COMMAND_OUTPUT_3: return __('Command output 3')
        case ZONE_TYPE_COMMAND_OUTPUT_4: return __('Command output 4')
        case ZONE_TYPE_PARTITION_STATUS_ALARM_MEMORY: return __('Partition status alarm memory')
        case ZONE_TYPE_HOLDUP_OUTPUT: return __('Hold up output')
        case ZONE_TYPE_24_HOUR_SILENT_INPUT: return __('24h silent input')
        case ZONE_TYPE_24_HOUR_AUDIBLE_INPUT: return __('24h audible input')
        case ZONE_TYPE_TLM_AND_ALARM: return __('TLM and alarm')
        case ZONE_TYPE_KISSOFF: return __('Kissoff')
        case ZONE_TYPE_GROUND_START: return __('Ground start')
        case ZONE_TYPE_ALTERNATE_COMMUNICATOR: return __('Alternate communicator')
        case ZONE_TYPE_SYSTEM_TROUBLE: return __('System trouble')
        case ZONE_TYPE_LATCHED_SYSTEM_EVENT_STROBE: return __('Latched system event strobe')
        case ZONE_TYPE_SYSTEM_TAMPER: return __('System tamper')
        case ZONE_TYPE_DC_TROUBLE: return __('DC trouble')
        case ZONE_TYPE_PROXIMITY_TAG_USED: return __('Proximity tag used')
        case ZONE_TYPE_PARTITION_PROX_USED: return __('Partition proximity used')
        case ZONE_TYPE_BELL_STATUS_AND_PROGRAMMING_ACCESS_OUTPUT: return __('Bell and programming access output')
        case ZONE_TYPE_REMOTE_OPERATION: return __('Remote operation')
        case ZONE_TYPE_OPEN_AFTER_ALARM: return __('Open after alarm')
        case ZONE_TYPE_ZONE_FOLLOWER_PGM_BY_ZONE: return __('Zone follower PGM by zone')
        case ZONE_TYPE_ZONE_FOLLOWER_1_8: return __('Zone follower 1-8')
        case ZONE_TYPE_ZONE_FOLLOWER_9_16: return __('Zone follower 9-16')
        case ZONE_TYPE_ZONE_FOLLOWER_17_24: return __('Zone follower 17-24')
        case ZONE_TYPE_ZONE_FOLLOWER_25_32: return __('Zone follower 25-32')
        case ZONE_TYPE_ZONE_FOLLOWER_33_40: return __('Zone follower 33-40')
        case ZONE_TYPE_ZONE_FOLLOWER_41_48: return __('Zone follower 41-48')
        case ZONE_TYPE_ZONE_FOLLOWER_49_56: return __('Zone follower 49-56')
        case ZONE_TYPE_ZONE_FOLLOWER_57_64: return __('Zone follower 57-64')
        case ZONE_TYPE_ZONE_FOLLOWER_65_72: return __('Zone follower 65-72')
        case ZONE_TYPE_ZONE_FOLLOWER_73_80: return __('Zone follower 73-80')
        case ZONE_TYPE_ZONE_FOLLOWER_81_88: return __('Zone follower 81-88')
        case ZONE_TYPE_ZONE_FOLLOWER_89_96: return __('Zone follower 89-96')
        case ZONE_TYPE_ZONE_FOLLOWER_97_104: return __('Zone follower 97-104')
        case ZONE_TYPE_ZONE_FOLLOWER_105_112: return __('Zone follower 105-112')
        case ZONE_TYPE_ZONE_FOLLOWER_113_120: return __('Zone follower 113-120')
        case ZONE_TYPE_ZONE_FOLLOWER_121_128: return __('Zone follower 121-128')
        case ZONE_TYPE_EXTERNAL_SIREN: return __('External siren')
        case ZONE_TYPE_DELAY_STAY_AWAY: return __('Delay stay away')
        case ZONE_TYPE_INTERIOR_STAY_AWAY: return __('Interior stay away')
        case ZONE_TYPE_NON_ALARM: return __('Non alarm')
        case ZONE_TYPE_DOORBELL_ZONE: return __('Doorbell zone')
        case ZONE_TYPE_PIR: return __('PIR')
        case ZONE_TYPE_MAGNET: return __('Magnet')
        case ZONE_TYPE_TOWER_32: return __('Tower 32')
        case ZONE_TYPE_TOWER_20: return __('Tower 20')
        case ZONE_TYPE_NOT_SECURITY: return __('Not security')
        case ZONE_TYPE_24H_SILENT: return __('24h silent')
        case ZONE_TYPE_24H_AUDIBLE: return __('24h audible')
        case ZONE_TYPE_ARMING_KEY: return __('Arming key')
        case ZONE_TYPE_INTERIOR_DELAY: return __('Interior delay')
        case ZONE_TYPE_TAMPER: return __('Tamper')
        case ZONE_TYPE_LINE_FAIL: return __('Line fail')
        case ZONE_TYPE_PSU_FAIL: return __('PSU fail')
        case ZONE_TYPE_PANIC: return __('Panic')
        case ZONE_TYPE_SHOCK_CONTACT_G2: return __('Shock contact G2')
        case ZONE_TYPE_TYPE_55: return __('Custom1')
        case ZONE_TYPE_TYPE_56: return __('Custom2')
        case ZONE_TYPE_TYPE_57: return __('Type 57')
        case ZONE_TYPE_TYPE_58: return __('Type 58')
        case ZONE_TYPE_TYPE_59: return __('Type 59')
        case ZONE_TYPE_TYPE_60: return __('Type 60')
        case ZONE_TYPE_TYPE_61: return __('Type 61')
        case ZONE_TYPE_TYPE_62: return __('Type 62')
        case ZONE_TYPE_TYPE_63: return __('Type 63')
        case ZONE_TYPE_TYPE_64: return __('Type 64')
        case ZONE_TYPE_WL_COMMANDER: return __('Wireless commander')
        case ZONE_TYPE_NIGHT: return __('Night')
        case ZONE_TYPE_FINAL_DOOR_SET: return __('Final door set')
        case ZONE_TYPE_24HR_SPRINKLER: return __('24h sprinkler')
        case ZONE_TYPE_24HR_NON_LATCHING: return __('24h non latching')
        case ZONE_TYPE_INSTANT: return __('Instant')
        case ZONE_TYPE_DELAYED_FIRE: return __('Delayed fire')
        case ZONE_TYPE_STANDARD_FIRE: return __('Standard fire')
        case ZONE_TYPE_INSTANT_STAY_AWAY: return __('Instant stay away')
        case ZONE_TYPE_DAY_ZONE: return __('Day zone')
        case ZONE_TYPE_24HR_BURGLARY: return __('24h burglary')
        case ZONE_TYPE_24HR_BELL_BUZZER: return __('24h bell buzzer')
        case ZONE_TYPE_24HR_SUPERVISORY: return __('24h supervisory')
        case ZONE_TYPE_24HR_SUPERVISORY_BUZZER: return __('24h supervisory buzzer')
        case ZONE_TYPE_AUTO_VERIFY_FIRE: return __('Auto verify fire')
        case ZONE_TYPE_FIRE_SUPERVISORY: return __('Fire supervisory')
        case ZONE_TYPE_24HR_GAS: return __('24h gas')
        case ZONE_TYPE_24HR_CO: return __('24h CO')
        case ZONE_TYPE_24HR_HOLD_UP: return __('24h hold up')
        case ZONE_TYPE_24HR_PANIC: return __('24h panic')
        case ZONE_TYPE_24HR_HEAT: return __('24h heat')
        case ZONE_TYPE_24HR_MEDICAL: return __('24h medical')
        case ZONE_TYPE_24HR_EMERGENCY: return __('24h emergency')
        case ZONE_TYPE_24HR_FLOOD: return __('24h flood')
        case ZONE_TYPE_24HR_LATCHING_TAMPER: return __('24h latching tamper')
        case ZONE_TYPE_24HR_NON_ALARM: return __('24h non alarm')
        case ZONE_TYPE_24HR_HIGH_TEMPERATURE: return __('24h high temperature')
        case ZONE_TYPE_24HR_LOW_TEMPERATURE: return __('24h low temperature')
        case ZONE_TYPE_24HR_MASKING: return __('24h masking')
        case ZONE_TYPE_24HR_CASH_MACHINE_ATM: return __('24h cash machine ATM')
        case ZONE_TYPE_MOMENTARY_ARM: return __('Momentary arm')
        case ZONE_TYPE_MAINTAINED_ARM: return __('Maintained arm')
        case ZONE_TYPE_MOMENTARY_DISARM: return __('Momentary disarm')
        case ZONE_TYPE_MAINTAINED_DISARM: return __('Maintained disarm')
        case ZONE_TYPE_PUSH_TO_SET: return __('Push to set')
        case ZONE_TYPE_DOOR_CONTROL_POST: return __('Door control post')
        case ZONE_TYPE_DOOR_CONTROL_ARM: return __('Door control arm')
        case ZONE_TYPE_DOOR_CONTROL_REX: return __('Door control rex')
        case ZONE_TYPE_EXTERNAL_AC_TROUBLE: return __('External AC trouble')
        case ZONE_TYPE_EXTERNAL_DC_TROUBLE: return __('External DC trouble')
        case ZONE_TYPE_EXTERNAL_AUX_TROUBLE: return __('External AUX trouble')
        case ZONE_TYPE_EXTERNAL_TROUBLE: return __('External trouble')
    }

    warn(`Unknown zoneType ${zoneType}`)

    return zoneType
}
import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import withPerPage from 'containers/withPerPage'

import {init, reset, setStart, remove, startPoll, stopPoll} from 'modules/users/list/actions'
import {setEnabled} from 'modules/users/store/actions'
import {showAddUserModal, showEditUserModal, showRemoveUserModal} from 'modules/modals/actions'

const mapStateToProps = () =>
    ({users: {list: {page, ...list}, store: {byIds}}}) => ({
        ...list,
        rows: page.map(id => byIds[id]),
    })

const mapDispatchToProps = () => dispatch =>
    bindActionCreators({
        init,
        startPoll,
        stopPoll,
        reset,
        onPageChange: setStart,
        showAddUser: showAddUserModal,
        showEditUser: showEditUserModal,
        showRemoveUser: showRemoveUserModal,
        remove,
        enable: (ids) => setEnabled(ids, true),
        suspend: (ids) => setEnabled(ids, false),
    }, dispatch)

export default function withUsers() {
    return compose(
        withPerPage('users'),
        connect(
            mapStateToProps,
            mapDispatchToProps,
        ),
    )
}

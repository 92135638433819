import React, {PureComponent} from 'react'
import PropTypes from 'prop-types'

import Buttons from 'ui/Buttons'
import Button from 'ui/Button'
import DropDown from 'ui/DropDownButton'
import Menu, {MenuItem} from 'ui/Menu'

import PrevIcon from 'icons/arrow-prev.svg'
import NextIcon from 'icons/arrow-next.svg'

import classes from 'classnames'
import {__} from 'utils/i18n'

const perPageRows = [15, 50, 100, 200]

export default class Pager extends PureComponent {

    static propTypes = {
        onPageChange: PropTypes.func.isRequired,
        onPerPageChange: PropTypes.func,
        total: PropTypes.number,
        perPage: PropTypes.number,
        start: PropTypes.number,
        disabled: PropTypes.bool,
    }

    handlePrevPage = () => {
        const perPage = this.props.perPage || 1
        const offset = this.props.start - perPage

        offset >= 0 && this.handlePageChange(offset)
    }

    handleNextPage = () => {
        const {start, perPage, total} = this.props
        const offset = start + (perPage || 1)

        offset < total && this.handlePageChange(offset)
    }

    handlePageChange(current) {
        this.props.onPageChange && this.props.onPageChange(current)
    }

    setPerPage = perPage => {
        this.props.onPerPageChange && this.props.onPerPageChange(perPage)
        this.handlePageChange(0) // set first page
    }

    bindings = {
        right: this.handleNextPage,
        left: this.handlePrevPage,
    }

    getTotalLabel() {
        const {total} = this.props

        if (!total) {
            return ''
        }

        if (total === Infinity) {
            return __('of %s', '100000+')
        }

        return __('of %s', total)
    }

    renderPosition() {
        const {perPage, total, start, onPerPageChange} = this.props
        const to = perPage > 1 ? Math.min(total, start + perPage) : null

        const label = [
            start + 1,
            to ? ' — ' + to : '',
            this.getTotalLabel(),
        ].join(' ')

        if (!onPerPageChange) {
            return <div className="pager-text">{label}</div>
        }

        return (
            <DropDown borderless noUpcase label={label}>
                <Menu>
                    {perPageRows.map(pages => (
                        <MenuItem key={pages} onClick={() => this.setPerPage(pages)}>
                            {pages === perPage ? <strong>{pages}</strong> : pages}
                        </MenuItem>
                    ))}
                </Menu>
            </DropDown>
        )
    }

    render() {
        const {className, perPage, total, start, disabled} = this.props

        return (
            <div className={classes('pager', className)}>
                {this.renderPosition()}

                <Buttons>
                    <Button
                        shortcut="left"
                        onClick={this.handlePrevPage}
                        disabled={disabled || start <= 0}>
                        <PrevIcon/>
                    </Button>

                    <Button
                        shortcut="right"
                        onClick={this.handleNextPage}
                        disabled={disabled || start + perPage >= total}>
                        <NextIcon/>
                    </Button>
                </Buttons>
            </div>
        )
    }

}
import {createAction} from 'redux-actions'

import createListActions from 'modules/higherOrder/createListActions'
import toIds from 'utils/toIds'

export const fetch = createAction('FIRMWARE/LIST/FETCH', (applianceId, upgradePackageId) => ({
    applianceId,
    upgradePackageId,
}))

export const {
    init,
    reset,
    receive,
    setStart,
    setQuery,
    setFilters,
    addFilters,
    removeFilters,
    clearFilters,
    fetchSuggests,
    receiveSuggests,
    startPoll,
    stopPoll,
} = createListActions('FIRMWARE/LIST')

export const upgrade = createAction(
    'FIRMWARE/LIST/UPGRADE',
    (panels, applianceId, upgradePackageId) => ({panelIds: toIds(panels), applianceId, upgradePackageId}),
)
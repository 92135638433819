import {combineReducers} from 'redux'

import list from './list/reducer'
import recent from './recent/reducer'
import store from './store/reducer'
import remarks from './remarks/reducer'
import remoteInspections from './remoteInspections/reducer'
import state from './state/reducer'
import keypad from './keypad/reducer'
import locations from './locations/reducer'
import logs from './logs/reducer'
import one from './one/reducer'
import plink from './plink/reducer'
import processes from './processes/reducer'
import firmware from './firmware/reducer'
import reports from './reports/reducer'
import events from './events/reducer'
import configuration from './configuration/reducer'

export default combineReducers({
    list,
    recent,
    store,
    remarks,
    remoteInspections,
    state,
    keypad,
    locations,
    logs,
    one,
    plink,
    processes,
    reports,
    events,
    firmware,
    configuration,
})

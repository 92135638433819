import {
    createAction
} from 'redux-actions'

export const clear = createAction('PANELS/REMOTE_INSPECTIONS/CLEAR')
export const fetch = createAction('PANELS/REMOTE_INSPECTIONS/FETCH', panelId => ({panelId}))
export const fetchMore = createAction('PANELS/REMOTE_INSPECTIONS/FETCH_MORE', panelId => ({panelId}))
export const update = createAction('PANELS/REMOTE_INSPECTIONS/UPDATE', null, (_, panelId, count) => ({panelId, count}))
export const setRange = createAction('PANELS/REMOTE_INSPECTIONS/SET_RANGE', (panelId, from = null, to = null) => ({
    panelId,
    from,
    to,
}))
export const startPoll = createAction('PANELS/REMOTE_INSPECTIONS/START_POLL', panelId => ({panelId}))
export const stopPoll = createAction('PANELS/REMOTE_INSPECTIONS/STOP_POLL', panelId => ({panelId}))
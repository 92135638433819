import classes from 'classnames'

import {
    PANEL_STATUS_CONNECTED,
    PANEL_STATUS_INSTALLED,
    PANEL_STATUS_OFFLINE,
    PANEL_STATUS_ONLINE,
    PANEL_STATUS_UNKNOWN,
    panelStatusName,
    selectMaxStatus,
} from 'constants/panelStatuses'
import IconOffline from 'icons/offline.svg'

import IconOnline from 'icons/online.svg'
import PropTypes from 'prop-types'
import React, {Component} from 'react'
import Tooltip from 'ui/Tooltip'

export default class PanelStatus extends Component {
    static propTypes = {
        panel: PropTypes.shape({
            connected: PropTypes.bool,
            online: PropTypes.bool,
        }),
    }

    state = {
        panelStatus: 'unknown',
    }

    componentWillReceiveProps(props, ctx) {
        this.setState({panelStatus: this.calculateStatus(props)})
    }

    calculateStatus(props) {
        const {modules} = props.panel

        if (!modules) {
            return PANEL_STATUS_UNKNOWN
        }

        const byModules = Object.values(modules).map((module) => {
            if (module) {
                if (module.connected) {
                    return PANEL_STATUS_CONNECTED
                }

                if (module.state) {
                    return module.state
                }

                return PANEL_STATUS_INSTALLED
            }

            return PANEL_STATUS_UNKNOWN
        })

        return selectMaxStatus(byModules)
    }

    getStatusText() {
        return panelStatusName(this.state.panelStatus)
    }

    getStatusClassName() {
        const {panelStatus} = this.state

        switch (panelStatus) {
            case PANEL_STATUS_CONNECTED:
                return 'panelStatus-icon--connected'
            case PANEL_STATUS_ONLINE:
                return 'panelStatus-icon--online'
            case PANEL_STATUS_OFFLINE:
                return 'panelStatus-icon--offline'
            case PANEL_STATUS_INSTALLED:
                return 'panelStatus-icon--installed'
            case PANEL_STATUS_UNKNOWN:
            default:
                return 'panelStatus-icon--unknown'
        }
    }

    render() {
        const {panel: {connected, online}} = this.props
        const Icon = (online || connected) ? IconOnline : IconOffline

        return (
            <Tooltip tooltip={this.getStatusText()} className="panelStatus">
                <Icon className={classes('panelStatus-icon', this.getStatusClassName())}/>
            </Tooltip>
        )
    }

}
import {createAction} from 'redux-actions'

export const update = createAction('USER_SETTINGS/UPDATE')
export const revert = createAction('USER_SETTINGS/REVERT')
export const changePerPage = createAction('USER_SETTINGS/CHANGE_PER_PAGE')
export const saveSearchScope = createAction('USER_SETTINGS/SAVE_SEARCH',
    (page, scope, name, filters) => ({page, scope, name, filters}),
)
export const removeSearchScope = createAction('USER_SETTINGS/REMOVE_SEARCH_SCOPE', (page, scope) => ({page, scope}))
export const setLanguage = createAction('USER_SETTINGS/SET_LANGUAGE')
export const setAutologout = createAction('USER_SETTINGS/SET_AUTOLOGOUT')

export const setAlarmsEnabled = createAction('USER_SETTINGS/SET_ALARMS_ENABLED', (isEnabled = true) => ({isEnabled}))
export const setAlarmsNotificationsEnabled = createAction('USER_SETTINGS/SET_ALARMS_NOTIFICATIONS_ENABLED', (isEnabled = true) => ({isEnabled}))
export const setAlarmsSoundEnabled = createAction('USER_SETTINGS/SET_ALARMS_SOUND_ENABLED', (isSoundEnabled = true) => ({isSoundEnabled}))
import {handleActions} from 'redux-actions'
import keyBy from 'lodash-es/keyBy'
import uniqBy from 'lodash-es/uniqBy'

import {
    observe,
    removeBatch,
    removeFinishedBatches,
    receive,
    restore,
} from './actions'

const makeBatch = (id, processes) => {
    const {type} = processes[0]

    const counters = processes.reduce((counters, {status}) => ({
        ...counters,
        [status]: counters[status] ? counters[status] + 1 : 1,
    }), {})

    const started = processes.reduce(
        (date, {started}) => started < date ? started : date,
        new Date(),
    )

    const finished = processes.every(({isRunning}) => !isRunning)
        ? processes.reduce(
            (date, {finished}) => finished && finished > date ? finished : date,
            new Date(),
        )
        : null

    const panels = uniqBy(processes, process => process.panelId).length

    const webnames = processes
        .map(process => process.webname)
        .filter((serial, index, serials) => serials.indexOf(serial) === index)

    return {
        id,
        webname: webnames.length === 1 ? webnames.pop() : null,
        panels,
        type,
        started,
        finished,
        counters: {
            notStarted: 0,
            succeeded: 0,
            handled: 0,
            failed: 0,
            start: 0,
            ...counters,
        },
        processes: processes.map(process => process.key).filter(key => key),
    }
}

const defaultState = {
    batches: {},
    byKeys: {},
    ids: {},
}

const purge = (state) => {
    const batchProcesses = Object.values(state.batches).reduce(
        (acc, {processes}) => {
            processes.forEach(key => acc[key] = true)
            return acc
        },
        {},
    )

    const clear = Object.values(state.byKeys)
        .filter(process => !process.isRunning)
        .filter(process => !batchProcesses.hasOwnProperty(process.key))

    if (clear.length === 0) {
        return state
    }

    state.byKeys = Object.assign({}, state.byKeys)
    state.ids = Object.assign({}, state.ids)

    clear.forEach(process => {
        delete state.byKeys[process.key]
        delete state.ids[process.id]
    })

    return state
}

export default handleActions({
    [observe](state, {payload: processes, meta: {batchId}}) {
        if (!processes || !processes.length) {
            return state
        }

        const batches = batchId
            ? {
                ...state.batches,
                [batchId]: makeBatch(batchId, processes),
            }
            : state.batches

        const ids = processes.filter(process => process.id)
            .reduce(
                (acc, {id, key}) => ({
                    ...acc,
                    [id]: key,
                }),
                state.ids,
            )

        return {
            batches,
            byKeys: {
                ...state.byKeys,
                ...keyBy(processes, 'key'),
            },
            ids,
        }
    },

    [receive](state, {payload}) {
        const byKeys = {
            ...state.byKeys,
            ...keyBy(payload, 'key'),
        }

        const batches = Object.values(state.batches)
            .filter(batch => payload.some(({id}) => batch.processes.includes(state.ids[id])))
            .reduce((batches, batch) => {
                const processes = batch.processes.map(key => byKeys[key])

                return {
                    ...batches,
                    [batch.id]: makeBatch(batch.id, processes),
                }
            }, state.batches)

        return {
            ...state,
            batches,
            byKeys,
        }
    },

    [removeBatch](state, {payload: {batchId}}) {
        if (!state.batches.hasOwnProperty(batchId)) {
            return state
        }

        const batches = Object.assign({}, state.batches)

        delete batches[batchId]

        return purge({
            ...state,
            batches,
        })
    },


    [removeFinishedBatches](state) {
        let dryRun = true

        const batches = Object.values(state.batches)
            .reduce((acc, batch) => {
                const {handled, start} = batch.counters

                if (handled > 0 || start > 0) {
                    return {
                        ...acc,
                        [batch.id]: batch,
                    }
                }

                dryRun = false
                return acc
            }, {})

        if (dryRun) {
            return state
        }

        return purge({
            ...state,
            batches,
        })
    },

    [restore](state, {payload: newState}) {
        return newState || state
    },
}, defaultState)